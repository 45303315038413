import {getAxiosConfig} from '@ecosio/components';
import {wrappedGET} from '../helpers/fetcher';

const TYPES = {
  REQUEST: 'issues/request',
  RESPONSE: 'issues/response',
  ERROR: 'issues/error',
};

const initialState = {
  data: [],
  error: null,
  loading: true,
  paging: {},
};

const issueListRequest = () => ({
  type: TYPES.REQUEST,
});

const issueListResponse = (data) => {
  const {content, ...rest} = data;
  return {
    type: TYPES.RESPONSE,
    data: content,
    paging: {...rest},
  };
};

const issueListeError = (error) => ({
  type: TYPES.ERROR,
  error,
});

export const fetchPage = (query) => (dispatch) => {
  const cfg = getAxiosConfig(query);

  if (query.sort) {
    cfg.params.sort = query.sort;
  } else {
    cfg.params.sort = 'createdAt,desc';
  }

  dispatch(issueListRequest());
  const url = '/api/issues';
  return wrappedGET(url, cfg)
    .then((res) => {
      if (res.status !== 200) {
        dispatch(issueListeError(res.statusText));
      } else {
        dispatch(issueListResponse(res.data));
      }
    })
    .catch((err) => dispatch(issueListeError(err.message)));
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case TYPES.RESPONSE:
      return {
        ...state,
        error: null,
        loading: false,
        data: action.data,
        paging: action.paging,
      };

    case TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
