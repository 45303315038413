import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {FormattedDate, FormattedTime} from 'react-intl';

/**
 *
 * TODO: @ecosio/components DateFormat should be replaced with this component
 *
 * Renders a date string formatted to the users current locale
 * @param date should be either a Unix timestamp or a valid ISO 8601 date string
 * @param format
 */
const DateFormat = ({date, format}) => {
  if (date === undefined) {
    return <div />;
  }

  return (
    <Fragment>
      {(format === 'short' && <DateOnly date={date} />) || (
        <DateTime date={date} />
      )}
    </Fragment>
  );
};

DateFormat.propTypes = {
  date: PropTypes.any,
  format: PropTypes.string,
};

const DateOnly = ({date}) => {
  return <FormattedDate value={date} />;
};

DateOnly.propTypes = {
  date: PropTypes.any,
};

const DateTime = ({date}) => {
  return (
    <React.Fragment>
      <FormattedDate value={date} />
      &nbsp;
      <FormattedTime value={date} />
    </React.Fragment>
  );
};

DateTime.propTypes = {
  date: PropTypes.any,
};

export default DateFormat;
