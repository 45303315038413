import React from 'react';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {Icon, Popup} from 'semantic-ui-react';
import {sidebarPropTypes} from '../index';
import {BackButton} from './BackButton';

const EXCLUDED_FROM_VALIDATION = ['SUPPLIER_PERFORMANCE'];

const isPageValid = (pageValidation, type) => {
  if (EXCLUDED_FROM_VALIDATION.includes(type)) {
    return null;
  } else {
    return pageValidation[type];
  }
};

/**
 * Renders the SidebarNavItems for a single supplier. The supplier
 * is not in the PRE-SSA state.
 */
export const SupplierDetailSidebar = ({
  supplierDetailPages,
  supplierId,
  supplier,
  offeringCompany,
}) => {
  // This builds the list of sidebar items shown in the detail view
  let detailItems = [];
  if (supplierDetailPages && supplierId) {
    detailItems = supplierDetailPages
      // TODO: constant
      .filter((page) => page.type !== 'PRE_ASSESSMENT')
      .map((page, idx) => {
        const url = `/supplier/details/${supplierId}/${page.type.toLowerCase()}`;

        if (!supplier.pageValidation) {
          console.warn('Supplier has no pageValidation object. ', supplierId);
          return null;
        }
        const supplierValid = isPageValid(supplier.pageValidation, page.type);
        const validationIconVisible =
          supplierValid !== null && !offeringCompany;
        return (
          <li key={idx}>
            <NavLink to={url} data-spec={`sideBarItem-${page.type}`}>
              <FormattedMessage id={`${page.type}_MENU_ITEM`} />
            </NavLink>
            {validationIconVisible && (
              <div className="description">
                <Popup
                  trigger={
                    <Icon
                      name={supplierValid ? 'check circle' : 'times circle'}
                    />
                  }
                  position="right center">
                  {
                    <FormattedMessage
                      id={supplierValid ? 'LSA_PAGE_VALID' : 'LSA_PAGE_INVALID'}
                    />
                  }
                </Popup>
              </div>
            )}
          </li>
        );
      });
  }

  return [
    <BackButton offeringCompany={offeringCompany} key="back-button" />,
    ...detailItems,
  ];
};

SupplierDetailSidebar.propTypes = sidebarPropTypes;
