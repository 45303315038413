import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage as Msg} from 'react-intl';
import {Form as SemForm, Button, Segment} from 'semantic-ui-react';
import {toast} from '@ecosio/components';
import {wrappedPOST} from '../../helpers/fetcher';
import DateFormat from '../DateFormat';
import {BoldContent} from './IssueTracker/IssueTrackerHelpers';
import AttachmentCommentWrapper from './AttachmentCommentWrapper';
import {commentShape} from './IssueTracker/shapes.js';

class CommentEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {editedComment: null};
  }

  onChangeValuesFromEditedComment = (text, attachments) => {
    this.setState({
      editedComment: {
        text,
        attachments,
      },
    });
  };

  onSubmitEditedIssueComment = (commentUuid) => {
    const {issueUuid, onLoadIssue, onEditComment} = this.props;
    const {editedComment} = this.state;
    const url = `/api/issues/${issueUuid}/comment/${commentUuid}`;
    return wrappedPOST(url, editedComment)
      .then((res) => {
        if (res.status === 204) {
          onLoadIssue();
          onEditComment(false);
        } else {
          toast({
            title: 'Issue Error',
            description: <Msg id="COMMENT_UPDATE_ERROR" />,
            type: 'error',
            time: 10000,
          });
          console.error("Comment couldn't be updated", res.statusText);
        }
      })
      .catch((res) => {
        toast({
          title: 'Issue Error',
          description: <Msg id="COMMENT_UPDATE_ERROR" />,
          type: 'error',
          time: 10000,
        });
        console.error("Comment couldn't be updated", res.message);
      });
  };

  render() {
    const {onEditComment, editedCommentUuid, comment, isUser} = this.props;

    return (
      <Segment.Group
        style={{
          marginLeft: isUser ? '40px' : '0px',
        }}>
        <Segment
          color="purple"
          style={{
            backgroundColor: isUser ? '#F7F7F7' : '#FFFFFF',
            fontStyle: isUser ? 'italic' : 'normal',
          }}>
          <span style={{paddingRight: '40px'}}>
            <BoldContent>{comment.authorName}</BoldContent>
          </span>
          <span>
            <DateFormat date={comment.createdAt} />
          </span>
        </Segment>
        <Segment clearing>
          <SemForm
            onSubmit={() => this.onSubmitEditedIssueComment(editedCommentUuid)}>
            <AttachmentCommentWrapper
              onChange={(text, attachments) =>
                this.onChangeValuesFromEditedComment(text, attachments)
              }
              attachments={comment.attachments || []}
              commentText={comment.text || ''}
            />
            <Button
              negative
              type="button"
              floated="right"
              onClick={() => onEditComment(false)}>
              <Msg id="GENERAL_CANCEL" />
            </Button>
            <Button color="green" type="submit" floated="right">
              <Msg id="GENERAL_SEND" />
            </Button>
          </SemForm>
        </Segment>
      </Segment.Group>
    );
  }
}

CommentEditForm.propTypes = {
  onLoadIssue: PropTypes.func,
  issueUuid: PropTypes.string,
  isUser: PropTypes.bool,
  onEditComment: PropTypes.func,
  editedCommentUuid: PropTypes.string,
  commentUuid: PropTypes.string,
  comment: commentShape,
};

export default CommentEditForm;
