import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import initServices from './helpers/initServices';

initServices();

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

export default ReactDOM.render(
  <App />,
  document.getElementById('root') || document.createElement('div')
);

if (module.hot) {
  module.hot.accept();
}
