import React from 'react';
import {Link, NavLink, Redirect} from 'react-router-dom';
import {
  hasPaging,
  DataGrid,
  Pagination,
  FormatDateTime,
  FormatByteSize,
} from '@ecosio/components';
import {
  Segment,
  Menu,
  Header,
  Message,
  Button,
  Icon,
  Popup,
} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import qs from 'qs';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {isDev} from '../../../util';
import {fetchCategories} from '../../../reducers/categories';
import {CONTEXT_PATH} from '../../../constants';
import {convertDirection, parseSort} from '../Drawings/DrawingList';
import DocumentFilter from './DocumentFilter';

export const DownloadButton = ({uuid, status, size, text, ...props}) => (
  <Button
    data-spec={`download-button-${uuid}`}
    size={size}
    icon
    positive
    href={`${
      isDev ? 'http://127.0.0.1:8822' : ''
    }${CONTEXT_PATH}/api/documents/${uuid}/download`}
    disabled={status !== 'PICKEDUP' && status !== 'DATA_RECEIVED'}
    {...props}>
    <Icon name="download" />
    {size === 'medium' && <FormattedMessage id={text} />}
  </Button>
);

DownloadButton.propTypes = {
  uuid: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  size: PropTypes.string,
  text: PropTypes.string,
};

export const Recipient = ({recipient}) => {
  switch (recipient.type) {
    case 'ALL':
      return <FormattedMessage id="GENERAL_ALL" />;
    case 'SUPPLIER':
      return (
        <Link to={`/supplier/details/${recipient.uuid}`}>{recipient.name}</Link>
      );
    case 'CATEGORY':
      return recipient.name ? (
        <Link to={`/suppliers/list?assignedCategoryIds=${recipient.uuid}`}>
          {recipient.name}
        </Link>
      ) : null;
  }
};

Recipient.propTypes = {
  recipient: PropTypes.object.isRequired,
};

const Recipients = ({recipients}) => {
  const list = recipients
    .filter((r) => !r.hidden)
    .map((r, idx) => <Recipient key={idx} recipient={r} />);
  if (list.length === 0) {
    return [];
  } else {
    return list.reduce((prev, current) => [prev, ', ', current]);
  }
};

const DetailsLink = ({uuid}) => (
  <Popup
    trigger={
      <Button
        as={Link}
        to={`/documents/general/details/${uuid}`}
        size="small"
        primary
        compact
        icon>
        <Icon name="eye" />
      </Button>
    }
    content={<FormattedMessage id="GENERAL_SHOW_DETAILS" />}
    hideOnScroll
    on="hover"
    position="top center"
  />
);

DetailsLink.propTypes = {
  uuid: PropTypes.string.isRequired,
};

const DownloadLink = ({document}) => (
  <Popup
    trigger={
      <DownloadButton
        status={document.status}
        uuid={document.uuid}
        compact
        size="small"
      />
    }
    content={<FormattedMessage id="GENERAL_DOWNLOAD" />}
  />
);

DownloadLink.propTypes = {
  document: PropTypes.object.isRequired,
};

const dataGridConfig = (isOfferingCompany) => ({
  idSelector: 'uuid',
  fields: [
    {
      id: 'createdAt',
      label: 'DRAWING_UPLOAD_DATE',
      sortable: true,
      hidden: false,
      render: (Table, value) => (
        <Table.Cell collapsing>
          <FormatDateTime dateString={value} />
        </Table.Cell>
      ),
    },
    {
      id: 'recipients',
      label: 'GENERAL_RECIPIENTS',
      hidden: !isOfferingCompany,
      sortable: true,
      render: (Table, value) => (
        <Table.Cell>
          <Recipients recipients={value} />
        </Table.Cell>
      ),
    },
    {
      id: 'expiryDate',
      label: 'DRAWING_AVAILABLE_UNTIL',
      sortable: true,
      hidden: false,
      render: (Table, value) => (
        <Table.Cell collapsing>
          <FormatDateTime dateString={value} stripTimezone={false} />
        </Table.Cell>
      ),
    },
    {
      id: 'title',
      label: 'GENERAL_TITLE',
      sortable: true,
      hidden: false,
      render: (Table, value) => <Table.Cell>{value}</Table.Cell>,
    },
    {
      id: 'fileSize',
      label: 'GENERAL_FILE_SIZE',
      sortable: true,
      hidden: false,
      render: (Table, value) => (
        <Table.Cell collapsing>
          {typeof value === 'number' && value >= 0 && FormatByteSize(value)}
        </Table.Cell>
      ),
    },
    {
      id: '',
      label: 'GENERAL_ACTIONS',
      hidden: false,
      render: (Table, value, row) => (
        <Table.Cell collapsing>
          <DetailsLink uuid={row.uuid} />
          <DownloadLink document={row} />
        </Table.Cell>
      ),
    },
  ],
});

@hasPaging()
class DocumentListTable extends React.PureComponent {
  static propTypes = {
    content: PropTypes.array,
    loading: PropTypes.bool,
    page: PropTypes.object,
    offeringCompany: PropTypes.bool,
  };

  onSort = ({col, dir}) => {
    const parsedQuery = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    if (!dir) {
      parsedQuery.sort = undefined;
      return this.props.history.push({
        ...this.props.location,
        search: `?${qs.stringify(parsedQuery)}`,
      });
    }
    const direction = convertDirection(dir);

    const fieldMapping = {
      title: 'title.raw',
      recipients: 'recipients.name.keyword',
    };

    if (fieldMapping[col]) {
      col = fieldMapping[col];
    }

    const search = qs.stringify({
      ...parsedQuery,
      sort: `${col},${direction}`,
    });

    this.props.history.push({
      search: `?${search}`,
    });
  };

  render() {
    const {content, loading, page, offeringCompany} = this.props;
    const renderFooter = ({Table, cols}) => {
      return (
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan={cols}>
              <Pagination paging={page} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      );
    };
    const parsedQuery = qs.parse(location.search, {ignoreQueryPrefix: true});
    const [initialSort, initialDirection] = parseSort(parsedQuery);

    return (
      <DataGrid
        initialSortColumn={initialSort}
        initialSortDirection={initialDirection}
        translated
        data={content}
        config={dataGridConfig(offeringCompany)}
        table={{striped: false}}
        loading={loading}
        renderFooter={renderFooter}
        onSort={this.onSort}
        emptyState={{
          icon: 'file',
          header: 'NO_DOCUMENTS_FOUND',
          subHeader: 'NO_DOCS_FOUND_SUB',
        }}
      />
    );
  }
}

const mapStateToProps = ({config, locales}) => ({
  config,
  locale: locales.locale,
});

@connect(mapStateToProps, {fetchCategories})
class DocumentList extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    // TODO find config shape
    config: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    fetchCategories: PropTypes.func.isRequired,
  };

  state = {
    page: {
      totalPages: 0,
      number: 0,
    },
    content: [],
    loading: true,
  };

  componentDidMount() {
    const {config} = this.props;
    const offeringCompany = config.userConfig.offeringCompany;
    if (offeringCompany) {
      this.props.fetchCategories();
    }
  }

  fetchPage = (query) => {
    return axios
      .get(`/api/documents?${qs.stringify(query)}`)
      .then(({data}) => {
        const {content, ...rest} = data;
        this.setState({
          loading: false,
          error: null,
          page: rest,
          content,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          loading: false,
          error: error,
        });
      });
  };

  render() {
    const {config, location, locale} = this.props;
    const {error} = this.state;
    const query = qs.parse(location.search.substr(1));
    const currentStatus = query.status;
    const offeringCompany = config.userConfig.offeringCompany;

    const tabs = ['ACTIVE', 'EXPIRED'].map((status, idx) => (
      <Menu.Item
        key={idx}
        as={NavLink}
        to={{search: `?status=${status}`}}
        isActive={() => currentStatus === status}>
        <FormattedMessage id={`FILE_STATUS_${status}`} />
      </Menu.Item>
    ));

    if (error) {
      return (
        <Message error>
          <Message.Header>
            <FormattedMessage id="GENERAL_ERROR" />
          </Message.Header>
          <p>{error.message || JSON.stringify(error)}</p>
        </Message>
      );
    }

    return (
      <>
        <Menu tabular attached>
          {tabs}
        </Menu>
        <Segment attached>
          <Header size="large" as="h1">
            <Header.Content>
              <FormattedMessage id="DOCUMENTS_GENERAL_HEADER" />
              <Header.Subheader>
                <Header.Subheader>
                  <FormattedMessage id="DOCUMENTS_GENERAL_SUBHEADER" />
                </Header.Subheader>
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Segment>

        {offeringCompany && (
          <Segment attached clearing>
            <Button
              data-spec="document-upload-button"
              floated="right"
              positive
              icon
              as={Link}
              to="/documents/general/create">
              <Icon name="upload" />
              &nbsp;
              <FormattedMessage id="DMS_UPLOAD_FILE" />
            </Button>
          </Segment>
        )}

        <Segment attached>
          <DocumentFilter
            location={this.props.location}
            history={this.props.history}
            offeringCompany={offeringCompany}
            locale={locale}
          />
        </Segment>

        <Segment attached>
          <DocumentListTable
            fetchPage={this.fetchPage}
            offeringCompany={offeringCompany}
            {...this.props}
            {...this.state}
          />
        </Segment>
      </>
    );
  }
}

const DocumentListWrapper = ({location, ...props}) => {
  const query = qs.parse(location.search.substr(1));
  if (!query.status) {
    return (
      <Redirect
        to={{search: '?status=ACTIVE', pathname: '/documents/general/list'}}
      />
    );
  } else {
    return <DocumentList location={location} {...props} />;
  }
};

export default DocumentListWrapper;
