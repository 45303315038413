import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Loader, Segment, Header, Message} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {fetchCategories} from '../../../reducers/categories';
import DocumentForm, {recipientOptions} from './DocumentForm';

const fields = {
  title: {
    path: 'title',
    type: 'text',
    input: {
      label: 'DMS_DOCUMENT_TITLE',
      required: true,
    },
  },
  description: {
    path: 'description',
    type: 'textarea',
    input: {
      type: 'text',
      label: 'DMS_DOCUMENT_DESCRIPTION',
    },
  },
  recipientType: {
    path: 'recipientType',
    type: 'dropdown',
    options: recipientOptions,
    input: {
      type: 'text',
      label: 'DOCUMENT_RECIPIENTS',
      required: true,
    },
  },
  supplier: {
    path: 'supplier',
    type: 'dropdown-autocomplete',
    input: {
      label: 'GENERAL_SUPPLIER',
      url: '/api/suppliers/suggest',
    },
  },
  category: {
    path: 'category',
    type: 'dropdown',
    input: {
      label: 'PRODUCT_GROUP',
      width: 8,
    },
  },
  expiryDate: {
    path: 'expiryDate',
    type: 'date',
    input: {
      type: 'text',
      label: 'DMS_DOCUMENT_EXPIRY_DATE',
      required: true,
      showTimeSelect: true,
    },
  },
  upload: {
    path: 'upload',
    type: 'upload',
    input: {
      label: 'GENERAL_UPLOAD',
    },
  },
};

const mapStateToProps = ({locales, categories}) => ({
  locale: locales.locale,
  categoriesLoading: categories.loading,
});

@connect(mapStateToProps, {fetchCategories})
export default class EditDocument extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    categoriesLoading: PropTypes.bool.isRequired,
    fetchCategories: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    error: null,
    document: null,
  };

  async componentDidMount() {
    this.props.fetchCategories();

    const {uuid} = this.props.match.params;
    try {
      const response = await axios.get(`/api/documents/${uuid}`);
      const document = response.data;
      const expiryDate = document.expiryInfo?.expiryDate;

      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        document: {
          ...document,
          recipients: document.recipients.filter((r) => !r.hidden),
          expiryDate,
        },
        error: null,
        loading: false,
      });
    } catch (error) {
      console.error(error);
      // TODO remove me once this lint understands async/await
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        error,
        loading: false,
        document: null,
      });
    }
  }

  onSubmit = async (values) => {
    const {uuid} = this.props.match.params;

    const request = {
      title: values.title,
      description: values.description,
      recipients: values.recipients,
      expiryDate: values.expiryDate,
    };

    try {
      const doUpload = Boolean(values.upload);

      if (doUpload) {
        this.setState({
          uploading: true,
        });
      }

      await axios.post(`/api/documents/${uuid}/update`, request);
      if (doUpload) {
        const formData = new window.FormData();
        formData.append('file', values.upload);
        await axios.post(`/api/documents/${uuid}/upload`, formData);
      }
      this.props.history.push(`/documents/general/details/${uuid}`);
    } catch (error) {
      console.error(error);
      this.setState({
        error,
        uploading: false,
      });
    }
  };

  render() {
    const {loading, error, document, uploading} = this.state;
    const {locale, categoriesLoading} = this.props;

    if (loading || categoriesLoading || !document) {
      return <Loader active />;
    }

    return (
      <>
        <Segment.Group>
          <Segment color="purple">
            <Header as="h1" size="large">
              <FormattedMessage
                id="DMS_EDIT_DOCUMENT_HEADER"
                values={{fileName: document.title}}
              />
            </Header>
          </Segment>

          <Segment>
            {error && (
              <Message error>
                <Message.Header>
                  <FormattedMessage id="GENERAL_ERROR" />
                </Message.Header>
                <p>{error.message}</p>
              </Message>
            )}

            <DocumentForm
              onSubmit={this.onSubmit}
              locale={locale}
              initialValues={document}
              fields={fields}
              uploading={uploading}
              backLink={`/documents/general/details/${document?.uuid}`}
            />
          </Segment>
        </Segment.Group>
      </>
    );
  }
}
