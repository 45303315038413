import {wrappedGET} from '../helpers/fetcher';

const TYPES = {
  DRAWING_DETAILS_REQUEST: 'drawingDetails/request',
  DRAWING_DETAILS_RESPONSE: 'drawingDetails/response',
  DRAWING_DETAILS_ERROR: 'drawingDetails/error',
};

const drawingDetailsRequest = () => ({
  type: TYPES.DRAWING_DETAILS_REQUEST,
});

const drawingDetailsResponse = (value) => ({
  type: TYPES.DRAWING_DETAILS_RESPONSE,
  value,
});

const drawingDetailsError = (error) => ({
  type: TYPES.DRAWING_DETAILS_ERROR,
  error,
});

export const fetchDetails = (id) => (dispatch) => {
  dispatch(drawingDetailsRequest());
  return wrappedGET(`/api/drawings/${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(drawingDetailsResponse(res.data));
      } else {
        dispatch(drawingDetailsError(res.statusText));
      }
    })
    .catch((res) => dispatch(drawingDetailsError(res.message)));
};

const initialState = {
  value: null,
  error: null,
  loading: false,
};

export const drawingDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.DRAWING_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TYPES.DRAWING_DETAILS_RESPONSE:
      return {
        ...state,
        loading: false,
        value: action.value,
        error: null,
      };
    case TYPES.DRAWING_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
