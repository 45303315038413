import React from 'react';
import {Icon} from 'semantic-ui-react';
import {issueShape} from './IssueTracker/shapes';

export const isAssigneeCompleted = (assignee) => {
  return (
    assignee?.name &&
    assignee?.department &&
    assignee?.phoneNumber &&
    assignee?.email
  );
};

export const isDimension1Completed = (complaintReport) => {
  return (
    isAssigneeCompleted(complaintReport?.dimension1?.assignees?.assignee) &&
    isAssigneeCompleted(complaintReport?.dimension1?.assignees?.teamLead)
  );
};

export const isDimension2Completed = (complaintReport) => {
  return complaintReport?.dimension2?.freeText;
};

export const isDimension3Completed = (complaintReport) => {
  return (
    complaintReport?.dimension3?.timestamp &&
    complaintReport?.dimension3?.freeText
  );
};

export const isDimension4Completed = (complaintReport) => {
  return complaintReport?.dimension4?.freeText;
};

export const isDimension5Completed = (complaintReport) => {
  return (
    complaintReport?.dimension5?.timestamp &&
    complaintReport?.dimension5?.freeText
  );
};

export const isDimension6Completed = (complaintReport) => {
  return (
    complaintReport?.dimension6?.timestamp &&
    complaintReport?.dimension6?.freeText
  );
};

export const isDimension7Completed = (complaintReport) => {
  return (
    complaintReport?.dimension7?.timestamp &&
    complaintReport?.dimension7?.freeText
  );
};

export const isDimension8Completed = (complaintReport) => {
  return (
    complaintReport?.dimension8?.assignees?.assignee?.name &&
    complaintReport?.dimension8?.timestamp
  );
};

const hasd3ComplaintData = (complaintReport) => {
  try {
    if (!complaintReport) {
      return false;
    }
    return (
      isDimension1Completed(complaintReport) &&
      isDimension2Completed(complaintReport) &&
      isDimension3Completed(complaintReport)
    );
  } catch (err) {
    console.warn(err);
    return false;
  }
};

const hasFullComplaintData = (complaintReport) => {
  try {
    if (!complaintReport) {
      return false;
    }
    return (
      isDimension1Completed(complaintReport) &&
      isDimension2Completed(complaintReport) &&
      isDimension3Completed(complaintReport) &&
      isDimension4Completed(complaintReport) &&
      isDimension5Completed(complaintReport) &&
      isDimension6Completed(complaintReport) &&
      isDimension7Completed(complaintReport) &&
      isDimension8Completed(complaintReport)
    );
  } catch (err) {
    console.warn(err);
    return false;
  }
};

const CheckIcon = ({text}) => (
  <div style={{display: 'inline-block', marginRight: 10}}>
    <Icon circular name="check" size="small" color="grey" inverted />
    <span style={{marginRight: 10}}>{text}</span>
  </div>
);
const WarnIcon = ({text}) => (
  <div style={{display: 'inline-block', marginRight: 10}}>
    <Icon circular name="warning sign" size="small" color="red" inverted />
    <span style={{marginRight: 10}}>{text}</span>
  </div>
);

const ComplaintCell = ({issue}) => {
  return (
    <div data-spec="complaint-report" style={{textAlign: 'center'}}>
      {hasd3ComplaintData(issue?.complaintReport) ? (
        <CheckIcon text="D3" />
      ) : (
        <WarnIcon text="D3" />
      )}
      {hasFullComplaintData(issue?.complaintReport) ? (
        <CheckIcon text="D8" />
      ) : (
        <WarnIcon text="D8" />
      )}
      <div style={{clear: 'both'}} />
    </div>
  );
};

ComplaintCell.propTypes = {
  issue: issueShape,
};

export default ComplaintCell;
