import React from 'react';
import {connect} from 'react-redux';
import {
  Header,
  List,
  Label,
  Loader,
  Message,
  Grid,
  Segment,
  Icon,
  Button,
} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {FormatByteSize} from '@ecosio/components';
import {fetchDetails} from '../../../reducers/drawingDetails';
import {isDev} from '../../../util';
import DateFormat from '../../DateFormat';
import {CONTEXT_PATH} from '../../../constants';
import {SenderOrReceiverComponent} from './SenderOrReceiverComponent';
import StatusLabel from './StatusLabel';

const mapStateToProps = ({drawingDetails, config}) => ({
  drawingDetails,
  config: config.userConfig,
});

@connect(mapStateToProps, {fetchDetails})
export default class DrawingDetails extends React.Component {
  componentDidMount() {
    const id = this.getId();
    this.props.fetchDetails(id);
  }

  getId = (props = this.props) => {
    return props.match.params.uuid;
  };

  render() {
    const drawingDetails = this.props.drawingDetails;

    const {offeringCompany} = this.props.config;

    if (drawingDetails.error) {
      return (
        <Message negative>
          <Message.Header>
            <FormattedMessage id="GENERAL_ERROR" />
          </Message.Header>
          <p>
            <FormattedMessage id="DRAWING_DETAILS_ERROR" />
            :&nbsp;{drawingDetails.error}
          </p>
        </Message>
      );
    }
    if (drawingDetails.value) {
      const drawing = drawingDetails.value;
      const downloadHistory = drawing.downloadRecords || [];
      const materialNumbers = drawing.materialNumbers;
      const stocklistNumbers = drawing.stocklistNumbers;
      const drawingNames = drawing.drawingNames;
      const showDownloadButton = !drawing.exceptionMessage;
      const showError = offeringCompany && drawing.exceptionMessage;

      return (
        <React.Fragment>
          <Segment color="purple">
            <Header as="h1" size="large">
              <FormattedMessage id="DRAWING_DETAILS_HEADER" />
              &nbsp;<em>{drawing.orderNumber}</em>
            </Header>
          </Segment>

          <Segment color="purple">
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={10}>
                  <Header as="h2" size="medium">
                    <FormattedMessage id="DRAWING_INFORMATION" />
                  </Header>

                  <List divided relaxed>
                    <List.Item>
                      <List.Header>
                        <FormattedMessage id="GENERAL_DMS_REFERENCE_NUMBER" />
                      </List.Header>
                      <List.Description>{drawing.orderNumber}</List.Description>
                    </List.Item>

                    <List.Item>
                      <List.Header>
                        {offeringCompany ? (
                          <FormattedMessage id="GENERAL_SUPPLIER" />
                        ) : (
                          <FormattedMessage id="GENERAL_PARTNER" />
                        )}
                      </List.Header>
                      <List.Description>
                        <SenderOrReceiverComponent
                          drawing={drawing}
                          offeringCompany={offeringCompany}
                        />
                      </List.Description>
                    </List.Item>

                    {showError && (
                      <List.Item>
                        <List.Header>
                          <FormattedMessage id="GENERAL_ERROR" />
                        </List.Header>
                        <List.Description>
                          <code>{drawing.exceptionMessage}</code>
                        </List.Description>
                      </List.Item>
                    )}

                    <List.Item>
                      <List.Header>
                        <FormattedMessage id="DRAWING_UPLOAD_DATE" />
                      </List.Header>
                      <List.Description>
                        <DateFormat date={drawing.createdAt} />
                      </List.Description>
                    </List.Item>

                    <List.Item>
                      <List.Header>
                        <FormattedMessage id="DRAWING_AVAILABLE_UNTIL" />
                      </List.Header>
                      <List.Description>
                        <DateFormat date={drawing.expiryInfo.expiryDate} />
                      </List.Description>
                    </List.Item>

                    <List.Item>
                      <List.Header>
                        <FormattedMessage id="GENERAL_STATUS" />
                      </List.Header>
                      <List.Description>
                        <StatusLabel status={drawing.status} />
                      </List.Description>
                    </List.Item>

                    {materialNumbers && (
                      <List.Item>
                        <List.Header>
                          <FormattedMessage id="GENERAL_MATERIAL_NUMBERS" />
                        </List.Header>
                        <List.Description>
                          <Label.Group>
                            {materialNumbers.map((name, idx) => (
                              <Label key={idx}>{name}</Label>
                            ))}
                          </Label.Group>
                        </List.Description>
                      </List.Item>
                    )}

                    {stocklistNumbers && (
                      <List.Item>
                        <List.Header>
                          <FormattedMessage id="GENERAL_STOCK_LIST_NUMBERS" />
                        </List.Header>
                        <List.Description>
                          <Label.Group>
                            {stocklistNumbers.map((num, idx) => (
                              <Label key={idx}>
                                <Icon name="file outline" /> {num}
                              </Label>
                            ))}
                          </Label.Group>
                        </List.Description>
                      </List.Item>
                    )}

                    {drawingNames && (
                      <List.Item>
                        <List.Header>
                          <FormattedMessage id="GENERAL_DRAWING_NAMES" />
                        </List.Header>
                        <List.Description>
                          <Label.Group>
                            {drawingNames.map((name, idx) => (
                              <Label key={idx}>
                                <Icon name="file outline" /> {name}
                              </Label>
                            ))}
                          </Label.Group>
                        </List.Description>
                      </List.Item>
                    )}
                  </List>
                </Grid.Column>

                <Grid.Column width={6}>
                  <Header size="medium" as="h2">
                    <FormattedMessage id="GENERAL_FILE" />
                  </Header>
                  <List relaxed divided>
                    <List.Item>
                      <List.Header>
                        <FormattedMessage id="GENERAL_FILE_NAME" />
                      </List.Header>
                      <List.Description>{drawing.fileName}</List.Description>
                    </List.Item>

                    <List.Item>
                      <List.Header>
                        <FormattedMessage id="GENERAL_FILE_SIZE" />
                      </List.Header>
                      <List.Description>
                        {FormatByteSize(drawing.fileSize || 0)}
                      </List.Description>
                    </List.Item>

                    <List.Item>
                      <List.Header>
                        <FormattedMessage id="GENERAL_MD5_HASH" />
                      </List.Header>
                      <List.Description as="code">
                        {drawing.fileChecksum}
                      </List.Description>
                    </List.Item>
                  </List>
                  {showDownloadButton && (
                    <Button
                      positive
                      href={`${
                        isDev ? 'http://127.0.0.1:8822' : ''
                      }${CONTEXT_PATH}/drawings/${drawing.uuid}/download`}
                      target="_blank"
                      disabled={
                        drawing.status !== 'PICKEDUP' &&
                        drawing.status !== 'DATA_RECEIVED'
                      }
                      data-spec="drawing-download-button">
                      <Icon name="download" />{' '}
                      <FormattedMessage id="GENERAL_DOWNLOAD" />
                    </Button>
                  )}

                  <Header size="medium" as="h2">
                    <FormattedMessage id="DRAWING_DOWNLOAD_HISTORY" />
                  </Header>
                  <List divided>
                    {downloadHistory.length === 0 ? (
                      <p>
                        <FormattedMessage id="DRAWING_NO_DOWNLOADS" />
                      </p>
                    ) : (
                      downloadHistory.map((entry, idx) => (
                        <List.Item key={idx}>
                          <List style={{padding: '0'}}>
                            <List.Item>
                              <b>{entry.userName}</b>
                            </List.Item>
                            <List.Item>
                              <DateFormat date={entry.downloadedAt} />
                            </List.Item>
                          </List>
                        </List.Item>
                      ))
                    )}
                  </List>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </React.Fragment>
      );
    }

    return <Loader active />;
  }
}
