import React from 'react';
import PropTypes from 'prop-types';
import {Popup, List, Button, Icon} from 'semantic-ui-react';
import {FormattedMessage as Msg} from 'react-intl';
import {CONTEXT_PATH} from '../../../constants';

const DownloadIssueButton = ({
  issueUuid,
  closed,
  locale,
  isOfferingCompany,
}) => {
  return (
    <Popup
      position="bottom right"
      on="click"
      content={
        <List>
          {closed && isOfferingCompany && (
            <List.Item>
              <a
                href={`${CONTEXT_PATH}/api/issues/${issueUuid}/download/ZIP`}
                data-spec="zipDownloadLink">
                <Msg id="GENERAL_ZIP" />
              </a>
            </List.Item>
          )}
          <List.Item>
            <a
              href={`${CONTEXT_PATH}/api/issues/${issueUuid}/download/PDF?locale=${locale}`}
              data-spec="pdfDownloadLink">
              <Msg id="GENERAL_PDF" />
            </a>
          </List.Item>
          <List.Item>
            <a
              href={`${CONTEXT_PATH}/api/issues/${issueUuid}/download/XML?locale=${locale}`}
              data-spec="xmlDownloadLink">
              <Msg id="GENERAL_XML" />
            </a>
          </List.Item>
        </List>
      }
      trigger={
        <Button floated="right" positive data-spec="issueDownloadButton" icon>
          <Icon name="download" /> <Msg id="GENERAL_DOWNLOAD" />
        </Button>
      }
    />
  );
};

DownloadIssueButton.propTypes = {
  issueUuid: PropTypes.string,
  closed: PropTypes.bool,
  locale: PropTypes.string,
  isOfferingCompany: PropTypes.bool,
};

export default DownloadIssueButton;
