import PropTypes from 'prop-types';

//TODO: specify each IssueType
export const supportedIssueTypeShape = PropTypes.shape({
  supportedIssueTypes: PropTypes.arrayOf(PropTypes.any.isRequired),
});

export const qualityAssuranceConfigShape = PropTypes.shape({
  supportedIssueTypes: supportedIssueTypeShape,
});

export const qualityAssruanceShape = PropTypes.shape({
  configuration: qualityAssuranceConfigShape,
  imageUrl: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
});

export const supplyModuleShape = PropTypes.shape({
  DEMAND_PREVIEW: PropTypes.any,
  DOCUMENT_MANAGEMENT: PropTypes.any,
  ISSUE_TRACKER: PropTypes.any,
  QUALITY_ASSURANCE: qualityAssruanceShape,
  SUPPLIER_DATABASE: PropTypes.any,
  SUPPLIER_PERFORMANCE: PropTypes.any,
});

export const supplyConfigurationShape = PropTypes.shape({
  error: PropTypes.any,
  loading: PropTypes.bool,
  supplierId: PropTypes.any,
  supplyModules: supplyModuleShape,
});

export const articleInspectionRequirementsShape = PropTypes.shape({
  uuid: PropTypes.string,
  labelTranslationKey: PropTypes.string,
});

export const articleInspectionShape = PropTypes.shape({
  articleInspectionRequirements: PropTypes.arrayOf(
    articleInspectionRequirementsShape
  ),
  defaultTemplate: PropTypes.string,
  editIssueHeaderOfferingCompany: PropTypes.bool,
  editIssueHeaderUsingCompany: PropTypes.bool,
  editIssueSpecificDataOfferingCompany: PropTypes.bool,
  editIssueSpecificDataUsingCompany: PropTypes.bool,
  //TODO specify
  issueTemplates: PropTypes.any,
  issueType: PropTypes.string,
  nameTranslationKey: PropTypes.string,
  sender: PropTypes.string,
  uiCreationByOfferingCompany: PropTypes.bool,
  uiCreationByUsingCompany: PropTypes.bool,
});
