import React, {Fragment} from 'react';
import {Grid, Button} from 'semantic-ui-react';
import {FormattedMessage as Msg} from 'react-intl';

const DimensionEditButton = ({
  panelKey,
  onEditDimension,
  offeringCompany,
  editIssueSpecificDataOfferingCompany,
  editIssueSpecificDataUsingCompany,
}) => {
  const showButton = offeringCompany
    ? editIssueSpecificDataOfferingCompany
    : editIssueSpecificDataUsingCompany;

  return (
    <Fragment>
      {showButton && (
        <Grid.Row>
          <Grid.Column>
            <Button
              data-spec={`${panelKey}EditButton`}
              type="button"
              floated="right"
              primary
              onClick={() => onEditDimension(panelKey)}>
              <Msg id="EDIT_DIMENSION" />
            </Button>
          </Grid.Column>
        </Grid.Row>
      )}
    </Fragment>
  );
};

export default DimensionEditButton;
