import React, {Fragment} from 'react';
import {
  Form,
  RenderContents,
  Content,
  Input,
  FormGroup,
} from '@ecosio/pathform';
import {
  List,
  Header,
  Table,
  Segment,
  Button,
  Popup,
  Grid,
  Icon,
} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import qs from 'qs';
import {Link} from 'react-router-dom';
import {CONTEXT_PATH} from '../constants';
import DateFormat from './DateFormat';

/**
 * A component that either renders a <Form /> element or a <RenderContents />
 * depending on the useForm parameter
 * @param useForm boolean
 * @param props rest of the props
 * @param children any children
 */
export const FormWrapper = ({useForm, children, ...props}) => {
  if (useForm) {
    return <Form {...props}>{children}</Form>;
  } else {
    return <RenderContents {...props}>{children}</RenderContents>;
  }
};

export const NotSpecified = () => (
  <span style={{color: 'darkgrey'}}>
    <FormattedMessage id="NOT_SPECIFIED" />
  </span>
);

//exists also in IssueTracker Helpers refactoring needed
export const BoldContent = ({children}) => (
  <span style={{fontWeight: '700'}}>{children}</span>
);

//TODO: my brain hurts when i see this, needs proper refactoring
export const FieldContent = ({
  value,
  date,
  dateTime,
  leftCaption,
  label,
  checkbox,
  dataSpec,
  inline,
  link,
}) => {
  if (!value && !checkbox) {
    return (
      <Fragment>
        {label && (
          <BoldContent>
            <Fragment>
              <FormattedMessage id={label} />:
            </Fragment>
          </BoldContent>
        )}
        {!inline ? (
          <p data-spec={dataSpec}>
            {leftCaption}
            <NotSpecified />
          </p>
        ) : (
          <span data-spec={dataSpec}>
            {leftCaption}
            <NotSpecified />
          </span>
        )}
      </Fragment>
    );
  } else if (checkbox) {
    return (
      <Fragment>
        {label && (
          <BoldContent>
            <Fragment>
              <FormattedMessage id={label} />:
            </Fragment>
          </BoldContent>
        )}
        <p data-spec={dataSpec}>
          {leftCaption}
          {value ? (
            <Icon name="checkmark" color="green" size="large" />
          ) : (
            <Icon name="close" color="red" size="large" />
          )}
        </p>
      </Fragment>
    );
  } else if (value && date) {
    return (
      <Fragment>
        {label && (
          <BoldContent>
            <Fragment>
              <FormattedMessage id={label} />:
            </Fragment>
          </BoldContent>
        )}
        <p data-spec={dataSpec}>
          {leftCaption}
          <DateFormat date={value} format="short" />
        </p>
      </Fragment>
    );
    //eslint-disable-next-line no-dupe-else-if
  } else if (value && date && inline) {
    return (
      <span data-spec={dataSpec}>
        {leftCaption}
        <DateFormat date={value} format="short" />
      </span>
    );
  } else if (value && dateTime) {
    return (
      <Fragment>
        {label && (
          <BoldContent>
            <Fragment>
              <FormattedMessage id={label} />:
            </Fragment>
          </BoldContent>
        )}
        <p data-spec={dataSpec}>
          {leftCaption}
          <DateFormat date={value} />
        </p>
      </Fragment>
    );
  } else if (inline) {
    return (
      <span data-spec={dataSpec}>
        {leftCaption}
        {value}
      </span>
    );
  } else {
    return (
      <Fragment>
        {label && (
          <BoldContent>
            <Fragment>
              <FormattedMessage id={label} />:
            </Fragment>
          </BoldContent>
        )}
        {!link ? (
          <p data-spec={dataSpec}>
            {leftCaption}
            {value}
          </p>
        ) : (
          <p data-spec={dataSpec}>
            <Link to={link}>{value}</Link>
          </p>
        )}
      </Fragment>
    );
  }
};

export const TableHeaderItem = ({label}) => (
  <Table.HeaderCell>
    <FormattedMessage id={label} />
  </Table.HeaderCell>
);

export const TableCellItem = ({content, ...props}) => {
  // Don't hide zero values
  const contentValid = content !== null && content !== undefined;
  return (
    <Table.Cell {...props}>
      {contentValid ? content : <NotSpecified />}
    </Table.Cell>
  );
};

export const InputWrapper = ({useForm, path, translateOptions, ...props}) => {
  if (useForm) {
    let name = props.name;
    if (!name) {
      name = path;
    }
    return <Input path={path} name={name} {...props} />;
  } else {
    if (translateOptions === true) {
      return <TranslateItem path={path} />;
    }
    return <TabbedItem path={path} />;
  }
};

export const TranslateItem = ({path}) => {
  return (
    <Content
      path={path}
      render={({label, content}) => {
        return (
          <List.Item divided="true">
            <List.Content floated="right">
              {<FormattedMessage id={`COUNTRY_${content}`} /> || (
                <NotSpecified />
              )}
            </List.Content>
            <List.Content>
              <strong>
                <FormattedMessage id={label} />:
              </strong>
            </List.Content>
          </List.Item>
        );
      }}
    />
  );
};

export const TabbedItem = ({path}) => (
  <Content
    path={path}
    render={({label, content}) => {
      return (
        <List.Item divided="true">
          <List.Content floated="right">
            {content || <NotSpecified />}
          </List.Content>
          <List.Content>
            <strong>
              <FormattedMessage id={label} />:
            </strong>
          </List.Content>
        </List.Item>
      );
    }}
  />
);

TabbedItem.propTypes = {
  path: PropTypes.string.isRequired,
};

export const FormGroupWrapper = ({useForm, children, ...props}) => {
  if (useForm) {
    return <FormGroup {...props}>{children}</FormGroup>;
  } else {
    return <List {...props}>{children}</List>;
  }
};

export const getDownloadUrl = (
  supplierId,
  pageType,
  attachmentId,
  abilityId
) => {
  const params = qs.stringify({type: pageType, abilityUuid: abilityId});

  return `${CONTEXT_PATH}/api/suppliers/${supplierId}/attachments/${attachmentId}?${params}`;
};

export const DownloadButton = ({downloadUrl, originalFileName}) => (
  <Popup
    trigger={
      <Button icon compact as="a" target="_blank" href={downloadUrl} positive>
        <Icon name="download" />
        &nbsp;Download
      </Button>
    }
    content={
      <FormattedMessage
        id="DOWNLOAD_BUTTON_INFO"
        values={{file: originalFileName}}
      />
    }
  />
);

export const SmallDownloadButton = ({
  downloadUrl,
  originalFileName,
  size,
  inline,
}) => {
  if (!downloadUrl) {
    return null;
  }
  return (
    <Popup
      trigger={
        <Button
          positive
          type="button"
          as="a"
          href={downloadUrl}
          target="_blank"
          icon="download"
          style={
            inline
              ? {
                  marginLeft: '0.5rem',
                }
              : {
                  height: '50%',
                  marginTop: '27px',
                }
          }
          size={size}
        />
      }
      content={
        <FormattedMessage
          id="DOWNLOAD_EXISTING_ATTACHMENT"
          values={{file: originalFileName}}
        />
      }
    />
  );
};

export const validateSize = (file, intl) => {
  // TODO: Load the 5 MB limit from the corresponding spring property vie the config request
  const MAX_FILE_SIZE = 1000 * 1000 * 5;

  if (file?.size > MAX_FILE_SIZE) {
    return intl.formatMessage({id: 'UPLOAD_FILE_TOO_BIG'});
  } else {
    return undefined;
  }
};

export const UploadWrapper = injectIntl(
  ({
    path,
    name,
    useForm,
    downloadUrl,
    inline,
    originalFileName,
    buttonSize,
    downloadButton,
    intl,
  }) => {
    if (useForm) {
      return (
        <Input
          validate={(value) => validateSize(value, intl)}
          path={path}
          name={name || path}
          downloadUrl={downloadUrl}
          downloadButton={
            downloadButton && (
              <SmallDownloadButton
                inline={inline}
                downloadUrl={downloadUrl}
                originalFileName={originalFileName}
                size={buttonSize}
              />
            )
          }
          inline={inline}
        />
      );
    } else {
      if (downloadUrl) {
        return (
          <DownloadButton
            downloadUrl={downloadUrl}
            originalFileName={originalFileName}
          />
        );
      } else {
        // TODO ?
        return null;
      }
    }
  }
);

UploadWrapper.propTypes = {
  path: PropTypes.string.isRequired,
  name: PropTypes.string,
  useForm: PropTypes.bool.isRequired,
  downloadUrl: PropTypes.string,
  inline: PropTypes.bool,
  originalFileName: PropTypes.string,
  buttonSize: PropTypes.string,
};

UploadWrapper.defaultProps = {
  downloadButton: true,
};

export const TextBoxWrapper = ({useForm, path, ...props}) => {
  if (useForm) {
    let name = props.name;
    if (!name) {
      name = path;
    }
    return <Input path={path} name={name} {...props} />;
  } else {
    return (
      <Content
        path={path}
        render={({label, content}) => {
          let inner;
          if (content) {
            const html = content.replace(/\n/g, '<br />');
            inner = <span dangerouslySetInnerHTML={{__html: html}} />;
          } else {
            inner = <NotSpecified />;
          }

          return (
            <Segment>
              <Header size="small" as="h3">
                <FormattedMessage id={label} />
              </Header>
              {inner}
            </Segment>
          );
        }}
      />
    );
  }
};

export const ResponsiveGridColumn = ({children, widescreen, computer}) => (
  <Grid.Column
    widescreen={widescreen}
    computer={computer}
    mobile={16}
    tablet={16}>
    {children}
  </Grid.Column>
);

ResponsiveGridColumn.propTypes = {
  children: PropTypes.any,
  widescreen: PropTypes.any,
  computer: PropTypes.any,
};

ResponsiveGridColumn.defaultProps = {
  widescreen: 12,
  computer: 16,
};

export const RemoveButton = ({fields, idx}) => (
  <Button
    style={{
      maxHeight: '38px',
    }}
    icon
    negative
    type="button"
    onClick={() => fields.remove(idx)}>
    <Icon name="delete" />
  </Button>
);

RemoveButton.propTypes = {
  fields: PropTypes.object,
  idx: PropTypes.number,
};

export const SubHeaderMeta = ({state, assignee, closedBy, closedAt}) => (
  <List horizontal relaxed>
    <List.Item>
      <List.Content>
        <List.Header>
          <Fragment>
            <FormattedMessage id="GENERAL_ASSIGNEE" />:
          </Fragment>
        </List.Header>
        {assignee ? (
          <span data-spec="assignee">{assignee}</span>
        ) : (
          <NotSpecified />
        )}
      </List.Content>
    </List.Item>
    {state === 'CLOSED' && (
      <Fragment>
        <List.Item>
          <List.Content>
            <List.Header>
              <Fragment>
                <FormattedMessage id="GENERAL_CLOSED_AT" />:
              </Fragment>
            </List.Header>
            <DateFormat date={closedAt} />
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <List.Header>
              <Fragment>
                <FormattedMessage id="GENERAL_CLOSED_BY" />:
              </Fragment>
            </List.Header>
            {closedBy}
          </List.Content>
        </List.Item>
      </Fragment>
    )}
  </List>
);

SubHeaderMeta.propTypes = {
  state: PropTypes.string,
  assignee: PropTypes.string,
  closedBy: PropTypes.string,
  closedAt: PropTypes.string,
};
