import React, {Fragment} from 'react';
import {FormattedMessage as Msg} from 'react-intl';
import {Header, Grid, List, Icon, Segment} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {InputArray, Submit} from '@ecosio/pathform';
import {
  InputWrapper,
  FormGroupWrapper,
  TextBoxWrapper,
  UploadWrapper,
  getDownloadUrl,
  NotSpecified,
  ResponsiveGridColumn,
} from '../FormHelpers';
import {supplierShape} from './shapes';

const CustomerEntry = ({name, useForm}) => (
  <Grid.Row>
    <Grid.Column width={8}>
      <FormGroupWrapper useForm={useForm} widths="equal">
        <InputWrapper
          useForm={useForm}
          path="customerReferences.customers.name"
          name={`${name}.name`}
        />
        <InputWrapper
          useForm={useForm}
          path="customerReferences.customers.description"
          name={`${name}.description`}
        />
      </FormGroupWrapper>
    </Grid.Column>
  </Grid.Row>
);

CustomerEntry.propTypes = {
  name: PropTypes.string,
  useForm: PropTypes.bool,
};

const ReferenceCustomerView = ({useForm, supplier}) => {
  return (
    <Fragment>
      <Grid.Row>
        <Grid.Column>
          <Header size="large">
            <Msg id="BIGGEST_CUSTOMERS" />
          </Header>
        </Grid.Column>
      </Grid.Row>
      {useForm ? (
        <UsingCompanyView useForm={useForm} supplier={supplier} />
      ) : (
        <OfferingCompanyView supplier={supplier} />
      )}
    </Fragment>
  );
};

ReferenceCustomerView.propTypes = {
  useForm: PropTypes.bool,
  supplier: supplierShape,
};

const CustomerListItem = ({item}) => (
  <List.Item>
    {item.name ? (
      <List.Content>
        <List.Header>{item.name}</List.Header>
        <List.Description>
          {item.description || <NotSpecified />}
        </List.Description>
      </List.Content>
    ) : (
      <NotSpecified />
    )}
  </List.Item>
);

const OfferingCompanyView = ({supplier}) => {
  return (
    <Grid.Row>
      <Grid.Column>
        <List ordered relaxed>
          {supplier.customerReferences.customers.map((item, idx) => (
            <CustomerListItem item={item} key={idx} />
          ))}
        </List>
      </Grid.Column>
    </Grid.Row>
  );
};

OfferingCompanyView.propTypes = {
  supplier: supplierShape,
};

const UsingCompanyView = ({useForm}) => {
  return (
    <Grid.Row>
      <Grid.Column width={8}>
        <InputArray name="customerReferences.customers">
          {({fields}) => {
            return (
              <List as="ol">
                {fields.map((name, idx) => {
                  return (
                    <List.Item key={idx} as="li" value={`${idx + 1}.`}>
                      <CustomerEntry name={name} useForm={useForm} />
                    </List.Item>
                  );
                })}
              </List>
            );
          }}
        </InputArray>
      </Grid.Column>
    </Grid.Row>
  );
};

UsingCompanyView.propTypes = {
  useForm: PropTypes.bool,
};

const ReferenceCustomers = ({useForm, supplier}) => {
  const attachment = supplier.customerReferences.attachment;
  const downloadUrl =
    attachment.uuid &&
    getDownloadUrl(supplier.uuid, 'REFERENCE_CUSTOMERS', attachment.uuid);

  return (
    <Segment>
      <Grid data-spec="referenceCustomers-grid">
        <Grid.Row>
          <Grid.Column>
            <Header size="huge" data-spec="reference-customers-page-header">
              <Msg
                id="REFERENCE_CUSTOMERS_PAGE_HEADER"
                values={{company: supplier.contactData.company}}
              />
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <ResponsiveGridColumn widescreen={8} computer={8}>
            <FormGroupWrapper>
              <TextBoxWrapper
                useForm={useForm}
                path="customerReferences.freeText"
                name="customerReferences.freeText"
              />
            </FormGroupWrapper>
          </ResponsiveGridColumn>
        </Grid.Row>
        <Grid.Row>
          <ResponsiveGridColumn widescreen={8} computer={8}>
            <UploadWrapper
              useForm={useForm}
              path="customerReferences.attachment.file"
              originalFileName={attachment.originalFileName}
              downloadUrl={downloadUrl}
            />
          </ResponsiveGridColumn>
        </Grid.Row>

        <ReferenceCustomerView supplier={supplier} useForm={useForm} />
        {useForm && (
          <Grid.Row>
            <ResponsiveGridColumn widescreen={8} computer={8}>
              <Submit positive floated="right">
                <Icon name="checkmark" />
                &nbsp;
                <Msg id="GENERAL_SAVE" />
              </Submit>
            </ResponsiveGridColumn>
          </Grid.Row>
        )}
      </Grid>
    </Segment>
  );
};
export default ReferenceCustomers;

ReferenceCustomers.propTypes = {
  useForm: PropTypes.bool,
  supplier: supplierShape,
};
