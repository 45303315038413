import React, {PureComponent, Fragment} from 'react';
import {FormattedMessage as Msg} from 'react-intl';
import {Header, Grid, Icon, Table, Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {InputArray, Submit} from '@ecosio/pathform';
import {
  InputWrapper,
  TableHeaderItem,
  TableCellItem,
  UploadWrapper,
  getDownloadUrl,
  FormGroupWrapper,
  ResponsiveGridColumn,
} from '../FormHelpers';
import {supplierShape, configurationShape} from './shapes';

const AbilitiesCertificvatesView = ({
  supplier,
  useForm,
  configuration,
  locale,
}) => (
  <Fragment>
    {useForm ? (
      <UsingAbilitiesCertificatesView
        supplier={supplier}
        useForm={useForm}
        configuration={configuration}
        locale={locale}
      />
    ) : (
      <OfferingAbilitiesCertificatesView
        supplier={supplier}
        configuration={configuration}
        locale={locale}
      />
    )}
  </Fragment>
);

AbilitiesCertificvatesView.propTypes = {
  configuration: configurationShape,
  locale: PropTypes.string,
  supplier: supplierShape,
  useForm: PropTypes.bool,
};

const OfferingAbilitiesCertificatesView = ({
  supplier,
  locale,
  configuration,
}) => {
  return (
    <Grid>
      <Grid.Row columns={1}>
        <ResponsiveGridColumn>
          <Table collapsing columns={4}>
            <Table.Header>
              <Table.Row>
                <TableHeaderItem label="BE_PARTY_NAME" />
                <TableHeaderItem label="GENERAL_STATUS" />
                <TableHeaderItem label="GENERAL_DESCRIPTION" />
                <TableHeaderItem label="GENERAL_DOWNLOAD" />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {supplier.abilities.map((ability, idx) => {
                const configItem = configuration.abilityConfiguration.requiredAbilities.find(
                  (item) => item.uuid === ability.uuid
                );
                const attachment = ability.attachment;
                const downloadUrl =
                  attachment.uuid &&
                  getDownloadUrl(
                    supplier.uuid,
                    'ABILITIES_AND_CERTIFICATES',
                    attachment.uuid,
                    ability.uuid
                  );
                return (
                  <Table.Row key={idx}>
                    <TableCellItem
                      width={3}
                      content={configItem.name[locale]}
                    />
                    <TableCellItem
                      content={
                        ability.status === 'YES' ? (
                          <Icon name="checkmark" color="green" />
                        ) : (
                          <Icon name="close" color="red" />
                        )
                      }
                      width={1}
                    />
                    <TableCellItem content={ability.description} width={3} />
                    <Table.Cell width={1}>
                      <UploadWrapper
                        useForm={false}
                        path="abilities.attachment.file"
                        downloadUrl={downloadUrl}
                        originalFileName={attachment.originalFileName}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </ResponsiveGridColumn>
      </Grid.Row>
    </Grid>
  );
};

OfferingAbilitiesCertificatesView.propTypes = {
  configuration: configurationShape,
  locale: PropTypes.string,
  supplier: supplierShape,
};

const UsingAbilitiesCertificatesView = ({
  supplier,
  configuration,
  useForm,
  locale,
}) => {
  return (
    <Segment.Group compact>
      <InputArray name="abilities" subscription={{}}>
        {({fields}) => {
          return fields.map((name, idx) => {
            const ability = supplier.abilities[idx];
            const configItem = configuration.abilityConfiguration.requiredAbilities.find(
              (item) => item.uuid === ability.uuid
            );
            const attachment = ability.attachment;
            const downloadUrl =
              attachment.uuid &&
              getDownloadUrl(
                supplier.uuid,
                'ABILITIES_AND_CERTIFICATES',
                attachment.uuid,
                ability.uuid
              );
            return (
              <Segment key={idx}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <Header>{configItem.name[locale]}</Header>
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <FormGroupWrapper useForm={useForm}>
                        <InputWrapper
                          useForm={useForm}
                          path="abilities.status"
                          name={`${name}.status`}
                        />

                        <InputWrapper
                          width={6}
                          useForm={useForm}
                          path="abilities.description"
                          name={`${name}.description`}
                        />

                        <UploadWrapper
                          useForm={useForm}
                          path="abilities.attachment.file"
                          name={`${name}.attachment.file`}
                          inline={false}
                          downloadUrl={downloadUrl}
                          originalFileName={attachment.originalFileName}
                          buttonSize="small"
                        />
                      </FormGroupWrapper>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            );
          });
        }}
      </InputArray>
      <Segment clearing>
        <Submit positive floated="right">
          <Icon name="checkmark" />
          &nbsp;
          <Msg id="GENERAL_SAVE" />
        </Submit>
      </Segment>
    </Segment.Group>
  );
};

UsingAbilitiesCertificatesView.propTypes = {
  configuration: configurationShape,
  locale: PropTypes.string,
  supplier: supplierShape,
  useForm: PropTypes.bool,
};

const mapStateToProps = ({locales}) => {
  return {
    locale: locales.locale,
  };
};

@connect(mapStateToProps)
export default class AbilitiesCertificates extends PureComponent {
  render() {
    const {supplier, locale, configuration, useForm} = this.props;
    return (
      <Segment>
        <Header size="large" data-spec="abilities-certificates-page-header">
          <Msg
            id="ABILITIES_CERTIFICATES_PAGE_HEADER"
            values={{company: supplier.contactData.company}}
          />
        </Header>

        <AbilitiesCertificvatesView
          style={{marginBottom: '14px'}}
          supplier={supplier}
          useForm={useForm}
          configuration={configuration}
          locale={locale}
        />
      </Segment>
    );
  }
}

AbilitiesCertificates.propTypes = {
  configuration: configurationShape,
  locale: PropTypes.string,
  supplier: supplierShape,
  useForm: PropTypes.bool,
};
