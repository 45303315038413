import React from 'react';
import {SidebarNavItem} from '@ecosio/customer-layout';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import qs from 'qs';

/**
 * Renders the sidebar for an offering company.
 */
export const OfferingCompanySidebar = ({listViews}) => {
  const listItems = [];
  let i = 0;
  // This builds the list of sidebar items shown in the list view.
  for (const view of listViews) {
    const {type, filters} = view;
    // each filter in the listView creates a sidebar item
    for (const filter of filters) {
      const {nameTranslationKey, type: filterType} = filter;
      if (filter.level !== 0) {
        continue;
      }
      // the filterType in the filter object does now map directly to an enum
      // value in the ASSESSMENT_STATES. Hence the map.
      const url = `/suppliers/${type.toLowerCase()}?firstLevel=${filterType}`;

      // since it's really only one single rout we're rendering here, we ned
      // to help the router to find out if the current NavItem is active
      const isActive = (match, location) => {
        const query = qs.parse(location.search.substr(1));
        // Default to the ACTIVE filter
        if (!query.firstLevel) {
          query.firstLevel = 'ACTIVE';
        }

        // if the status filter from the search query matches the nav item, we're active
        return query.firstLevel === filterType;
      };

      listItems.push(
        <SidebarNavItem
          key={i}
          link={
            <NavLink to={url} isActive={isActive}>
              <FormattedMessage id={nameTranslationKey} />
            </NavLink>
          }
          description={null}
        />
      );
      i++;
    }
  }
  return listItems;
};

OfferingCompanySidebar.propTypes = {
  listViews: PropTypes.array,
};
