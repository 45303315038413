import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import {Segment, Button, Header, Grid} from 'semantic-ui-react';
import {Form as PathForm, FormGroup, Input} from '@ecosio/pathform';
import {Link} from 'react-router-dom';
import {FormattedMessage as Msg} from 'react-intl';
import AttachmentList from '../../AttachmentList';
import {supplyModuleShape} from '../../shapes';
import {issueShape} from '../../IssueTracker/shapes';
import OrderSelector from '../../OrderSelector';
import FavoriteButton from '../../FavoriteButton';
import FavoriteList from '../../FavoriteList';

class ArticleInspectionEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attachments: [],
    };
  }

  onUpload = (files) => {
    this.setState((prevState) => ({
      attachments: prevState.attachments.concat(files.map((f) => ({file: f}))),
    }));
  };

  render() {
    const {issue, fields, onSubmit, onAttachmentRemoved} = this.props;
    const {attachments} = this.state;
    return (
      <PathForm
        fields={fields}
        initialValues={issue}
        onSubmit={(values) =>
          onSubmit(values, 'ARTICLE_INSPECTION', issue.state, attachments)
        }>
        {(ctx) => (
          <Fragment>
            <Segment.Group>
              <Segment color="purple">
                <Header size="large">
                  <Header.Content>
                    <Msg id="GENERAL_ARTICLE_INSPECTION" />
                    :&nbsp;
                    {issue.articleInspectionData.articleInspectionReference}
                  </Header.Content>
                </Header>
                <div>
                  <FavoriteButton
                    issue={issue}
                    config={this.props.config}
                    onFollow={this.props.onFollow}
                    onUnfollow={this.props.onUnFollow}
                  />
                </div>
                <Input
                  name="assignee.uuid"
                  path="assignee.uuid"
                  defaultText={issue?.assignee?.name}
                  width="4"
                />
              </Segment>
              <Segment clearing>
                <FormGroup>
                  <Input name="subject" path="subject" width="16" />
                </FormGroup>
                <FormGroup>
                  <Input name="body" path="body" width="16" />
                </FormGroup>
                <FormGroup>
                  <Input
                    name="articleInspectionData.priorArticleInspection"
                    path="articleInspectionData.priorArticleInspection"
                    width={4}
                  />
                </FormGroup>
                <AttachmentList
                  onUpload={this.onUpload}
                  attachments={issue?.attachments}
                  onRemove={onAttachmentRemoved}
                />
              </Segment>
            </Segment.Group>
            <Segment.Group>
              <Segment>
                <Header>
                  <Msg id="GENERAL_PURCHASER" />
                </Header>
                <FormGroup widths="equal">
                  <Input
                    path="offeringCompanyAssignee.name"
                    name="offeringCompanyAssignee.name"
                  />
                  <Input
                    path="offeringCompanyAssignee.phoneNumber"
                    name="offeringCompanyAssignee.phoneNumber"
                  />
                  <Input
                    path="offeringCompanyAssignee.email"
                    name="offeringCompanyAssignee.email"
                  />
                </FormGroup>
              </Segment>
              <Segment>
                <Header>
                  <Msg id="GENERAL_ORDER_INFORMATION" />
                </Header>
                <FormGroup widths="equal">
                  <OrderSelector ctx={ctx} disabled={true} />
                  <Input name="factoryIdentifier" path="factoryIdentifier" />
                </FormGroup>
                <FormGroup widths="equal">
                  <Input
                    name="articleInspectionData.orderDate"
                    path="articleInspectionData.orderDate"
                    stripTimezone={false}
                  />
                  <Input
                    name="articleInspectionData.orderQuantity"
                    path="articleInspectionData.orderQuantity"
                  />
                  <Input
                    name="articleInspectionData.deliveryDate"
                    path="articleInspectionData.deliveryDate"
                    stripTimezone={false}
                  />
                </FormGroup>
              </Segment>
              <Segment>
                <Header>
                  <Msg id="GENERAL_ARTICLE_INFORMATION" />
                </Header>
                <FormGroup widths="equal">
                  <Input name="materialNumber" path="materialNumber" />
                  <Input name="drawingRevision" path="drawingRevision" />
                  <Input
                    name="supplierMaterialNumber"
                    path="supplierMaterialNumber"
                  />
                </FormGroup>
                <FormGroup widths="equal">
                  <Input
                    name="materialShortText.de"
                    path="materialShortText.de"
                  />
                </FormGroup>
              </Segment>
              {this.props.config?.userConfig?.offeringCompany && (
                <Segment>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <FavoriteList issue={issue} asHeader={true} />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              )}
              <Segment clearing>
                <Header>
                  <Msg id="INTERNAL_INFORMATION" />
                </Header>
                <FormGroup widths="equal">
                  <Input
                    name="articleInspectionData.researchAndDevelopmentContact"
                    path="articleInspectionData.researchAndDevelopmentContact"
                  />
                  <Input />
                  <Input />
                </FormGroup>
                <FormGroup>
                  <Input
                    name="articleInspectionData.testBenchAttempt"
                    path="articleInspectionData.testBenchAttempt"
                    width={2}
                  />
                  <Input
                    name="articleInspectionData.testBenchAttemptDescription"
                    path="articleInspectionData.testBenchAttemptDescription"
                    width={7}
                  />
                  <Input
                    name="articleInspectionData.testBenchAttemptDate"
                    path="articleInspectionData.testBenchAttemptDate"
                    stripTimezone={false}
                    width={7}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    name="articleInspectionData.fieldTestDone"
                    path="articleInspectionData.fieldTestDone"
                    width={2}
                  />
                  <Input
                    name="articleInspectionData.fieldTestDescription"
                    path="articleInspectionData.fieldTestDescription"
                    width={7}
                  />
                  <Input
                    name="articleInspectionData.fieldTestDate"
                    path="articleInspectionData.fieldTestDate"
                    stripTimezone={false}
                    width={7}
                  />
                </FormGroup>
                <FormGroup widths="equal">
                  <Input
                    name="articleInspectionData.qaContact"
                    path="articleInspectionData.qaContact"
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    name="articleInspectionData.createTestPlan"
                    path="articleInspectionData.createTestPlan"
                    width={2}
                  />
                  <Input
                    name="articleInspectionData.freeTextTestPlan"
                    path="articleInspectionData.freeTextTestPlan"
                    width={7}
                  />
                  <Input
                    name="articleInspectionData.dateTestPlan"
                    path="articleInspectionData.dateTestPlan"
                    stripTimezone={false}
                    width={7}
                  />
                </FormGroup>

                <Button
                  type="button"
                  negative
                  floated="right"
                  as={Link}
                  to={`/quality-assurance/view/${issue?.issueNumber}`}>
                  <Msg id="GENERAL_CANCEL" />
                </Button>
                <Button type="submit" positive floated="right">
                  <Msg id="GENERAL_SEND" />
                </Button>
              </Segment>
            </Segment.Group>
          </Fragment>
        )}
      </PathForm>
    );
  }
}

ArticleInspectionEdit.propTypes = {
  issue: issueShape,
  onSubmit: PropTypes.func.isRequired,
  supplyConfiguration: supplyModuleShape,
  onAttachmentRemoved: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};

export default ArticleInspectionEdit;
