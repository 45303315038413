import React from 'react';
import {Button, Form, Header, Popup, Segment} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import qs from 'qs';
import {Form as PathForm, FormGroup, Input, Reset} from '@ecosio/pathform';
import {get} from 'lodash';
import {connect} from 'react-redux';
import AssignedCategorySelector from '../SupplierDetails/PageHeader/AssignedCategorySelector';
import {ASSESSMENT_STATES} from '../../helpers/assessment_states';

const FILTERS = {
  ALL: {
    status: true,
    onboardingStatus: true,
    preCheckState: true,
    testRequestDone: true,
  },
  ACTIVE: {
    status: false,
    onboardingStatus: false,
    preCheckState: false,
    testRequestDone: false,
  },
  NEW: {
    status: true,
    onboardingStatus: false,
    preCheckState: true,
    testRequestDone: true,
  },
  PRE_SSA: {
    status: false,
    onboardingStatus: false,
    preCheckState: true,
    testRequestDone: true,
  },
  SSA_IN_PROGRESS: {
    status: false,
    onboardingStatus: false,
    preCheckState: true,
    testRequestDone: true,
  },
  REJECTED: {
    status: false,
    onboardingStatus: false,
    preCheckState: true,
    testRequestDone: true,
  },
};

// maps to the java class SupplierSearchFilter
const DEFAULT_FILTER = {
  query: '',
  sort: 'name.raw,asc',
};

const fields = (intl) => ({
  query: {
    path: 'query',
    type: 'text',
    input: {
      type: 'text',
      disabled: false,
    },
  },
  createdFrom: {
    path: 'createdFrom',
    type: 'date',
    input: {
      type: 'text',
      label: 'CREATED_FROM',
    },
  },
  createdTo: {
    path: 'createdTo',
    type: 'date',
    input: {
      type: 'text',
      label: 'CREATED_TO',
    },
  },
  assignedCategoryIds: {
    path: 'assignedCategoryIds',
    type: 'dropdown',
    input: {
      type: 'text',
      multiple: true,
      label: 'ASSIGNED_CATEGORY_ID',
    },
  },
  categoryIds: {
    path: 'categoryIds',
    type: 'dropdown',
    input: {
      type: 'text',
      multiple: true,
      label: 'CATEGORY_ID',
    },
  },
  onlyMyCategories: {
    path: 'onlyMyCategories',
    type: 'checkbox',
    input: {
      type: 'checkbox',
      label: 'FILTER_ONLY_MY_CATEGORIES',
      toggle: true,
    },
  },
  employeesMax: {
    path: 'employeesMax',
    type: 'number',
    input: {
      type: 'text',
      label: 'FILTER_EMPLOYEES_MAX',
    },
  },
  employeesMin: {
    path: 'employeesMin',
    type: 'number',
    input: {
      type: 'text',
      label: 'FILTER_EMPLOYEES_MIN',
    },
  },
  revenueMin: {
    path: 'revenueMin',
    type: 'number',
    input: {
      type: 'text',
      label: 'FILTER_REVENUE_MIN',
    },
  },
  revenueMax: {
    path: 'revenueMax',
    type: 'number',
    input: {
      type: 'text',
      label: 'FILTER_REVENUE_MAX',
    },
  },
  status: {
    path: 'status',
    type: 'dropdown',
    input: {
      type: 'text',
      multiple: true,
      label: 'ASSESSMENT_STATUS',
    },
    options: Object.keys(ASSESSMENT_STATES).map((key) => {
      return {
        value: key,
        text: intl.formatMessage({id: key}, {postponeDate: ''}),
        key,
      };
    }),
  },
  onboardingStatus: {
    path: 'onboardingStatus',
    type: 'dropdown',
    input: {
      type: 'text',
      multiple: true,
      label: 'ONBOARDING_STATUS',
    },
    options: ['IN_PROGRESS', 'WAITING_FOR_OPERATOR', 'COMPLETED'].map(
      (value) => ({
        value,
        key: value,
        text: `ONBOARDING_${value}`,
      })
    ),
  },
  countryOfHeadquarters: {
    path: 'countryOfHeadquarters',
    type: 'dropdown',
    input: {
      type: 'text',
      multiple: true,
      label: 'COUNTRY_OF_HEADQUARTERS',
    },
  },
  preCheckState: {
    path: 'preCheckState',
    type: 'dropdown',
    input: {
      type: 'text',
      multiple: true,
      label: 'PRE_CHECK_STATE',
    },
    options: ['OPEN', 'REJECTED', 'ACCEPTED'].map((state) => ({
      value: state,
      key: state,
      text: `PRE_CHECK_${state}`,
    })),
  },
  testRequestDone: {
    path: 'testRequestDone',
    type: 'checkbox',
    input: {
      type: 'checkbox',
      label: 'TEST_REQUEST_DONE',
    },
  },
});

// TODO add sort/order back eventually?
const AdvancedPop = ({locale, countries, filter, ctx, ...props}) => {
  const visible = FILTERS[filter];
  const isOnlyMyCategoriesAssigned = ctx.values.onlyMyCategoriesAssigned;
  const isOnlyMyCategoriesSupplier = ctx.values.onlyMyCategoriesSupplier;

  return (
    <Popup {...props} position="bottom right" on="click" wide="very">
      <div style={{minWidth: '600px', padding: '0.5rem'}}>
        <Header as="h3">
          <Header.Content>
            <FormattedMessage id="GENERAL_FILTERS" />
          </Header.Content>
        </Header>
        <div className="ui form">
          <Form.Group>
            <Input
              name="createdFrom"
              path="createdFrom"
              stripTimezone={false}
              width={8}
            />
            <Input
              name="createdTo"
              path="createdTo"
              stripTimezone={false}
              width={8}
            />
          </Form.Group>

          <Segment>
            <AssignedCategorySelector
              locale={locale}
              name="assignedCategoryIds"
              valueProperty="uuid"
              disabled={isOnlyMyCategoriesAssigned}
            />
            <Input name="onlyMyCategoriesAssigned" path="onlyMyCategories" />
          </Segment>

          <Segment>
            <AssignedCategorySelector
              locale={locale}
              name="categoryIds"
              valueProperty="uuid"
              disabled={isOnlyMyCategoriesSupplier}
            />
            <Input name="onlyMyCategoriesSupplier" path="onlyMyCategories" />
          </Segment>

          <Form.Group>
            {visible.status && <Input name="status" path="status" width={8} />}
            {visible.onboardingStatus && (
              <Input
                name="onboardingStatus"
                path="onboardingStatus"
                translateOptions
                width={8}
              />
            )}
          </Form.Group>

          <Form.Group>
            <Input width={8} name="employeesMin" path="employeesMin" />
            <Input width={8} name="employeesMax" path="employeesMax" />
          </Form.Group>

          <Form.Group>
            <Input width={8} name="revenueMin" path="revenueMin" />
            <Input width={8} name="revenueMax" path="revenueMax" />
          </Form.Group>

          <Form.Group>
            <Input
              name="countryOfHeadquarters"
              path="countryOfHeadquarters"
              options={countries}
              translateOptions
              search
              width={16}
            />
          </Form.Group>

          {visible.preCheckState && (
            <Form.Group>
              <Input
                name="preCheckState"
                path="preCheckState"
                width={8}
                translateOptions
              />
              <Input name="testRequestDone" path="testRequestDone" width={8} />
            </Form.Group>
          )}

          <FormGroup>
            <Form.Field>
              <label />
              <Reset floated="right" resetValues={{}}>
                <FormattedMessage id="GENERAL_RESET_FORM" />
              </Reset>
            </Form.Field>
          </FormGroup>
        </div>
      </div>
    </Popup>
  );
};

AdvancedPop.propTypes = {
  intl: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  countries: PropTypes.array.isRequired,
  filter: PropTypes.string.isRequired,
};

const mapStateToProps = ({categories, locales, supplyConfiguration}) => ({
  categories,
  locale: locales.locale,
  supplyConfiguration,
});

@connect(mapStateToProps)
class SupplierFilter extends React.Component {
  constructor(props) {
    super(props);

    const {location} = this.props;
    // qs returns an empty object at parseerror or empty string
    // substr needed for querystring indicator questionmark
    const searchObj =
      location.search && location.search.trim() !== ''
        ? qs.parse(this.props.location.search.substr(1))
        : {};

    // Don't store "false" or "true", store boolean values
    if (searchObj.onlyMyCategoriesAssigned) {
      searchObj.onlyMyCategoriesAssigned =
        searchObj.onlyMyCategoriesAssigned === 'true';
    }
    if (searchObj.onlyMyCategoriesSupplier) {
      searchObj.onlyMyCategoriesSupplier =
        searchObj.onlyMyCategoriesSupplier === 'true';
    }

    this.state = {query: Object.assign({}, DEFAULT_FILTER, searchObj)};
  }

  onApply = (values) => {
    const {firstLevel, secondLevel} = this.state.query;

    const filters = {
      ...values,
      // The value of the date picker is the full ISO 8601
      // date/time string, but the backend only expects a date (YYYY-MM-DD)
      // so we need to trim the values
      createdFrom: values.createdFrom && values.createdFrom.substr(0, 10),
      createdTo: values.createdTo && values.createdTo.substr(0, 10),
      firstLevel,
      secondLevel,
    };
    const query = qs.stringify(filters, {indices: false, skipNulls: true});
    this.props.history.push({
      search: `?${query}`,
    });
  };

  render() {
    const {intl, categories, locale, supplyConfiguration} = this.props;
    if (categories.loading || !categories.data || supplyConfiguration.loading) {
      return null;
    }

    const countries = get(
      supplyConfiguration,
      'supplyModules.SUPPLIER_DATABASE.configuration.countryOptions'
    );

    const {firstLevel, secondLevel} = this.state.query;
    const filter =
      (firstLevel === 'NEW' && secondLevel ? secondLevel : firstLevel) ||
      'ACTIVE';

    return (
      <React.Fragment>
        <Header as="h3">
          <Header.Content>
            <FormattedMessage id="GENERAL_FILTER_PANEL_HEADER" />
          </Header.Content>
        </Header>
        <PathForm
          onSubmit={this.onApply}
          fields={fields(intl)}
          initialValues={this.state.query}>
          {(ctx) => (
            <FormGroup>
              <Input
                name="query"
                path="query"
                renderLabel={false}
                width={15}
                placeholder={intl.formatMessage({
                  id: 'SUPPLIER_SEARCH_PLACEHOLDER',
                })}
              />

              <AdvancedPop
                intl={intl}
                countries={countries}
                locale={locale}
                filter={filter}
                ctx={ctx}
                trigger={
                  <Button
                    color="purple"
                    type="button"
                    data-spec="advanced-filter-button">
                    <FormattedMessage id="GENERAL_ADVANCED" />
                  </Button>
                }
              />
              <Button type="submit" positive data-spec="apply-filters-button">
                <FormattedMessage id="GENERAL_APPLY" />
              </Button>
            </FormGroup>
          )}
        </PathForm>
      </React.Fragment>
    );
  }
}

SupplierFilter.propTypes = {
  intl: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  categories: PropTypes.array,
  locale: PropTypes.string.isRequired,
  supplyConfiguration: PropTypes.object.isRequired,
};

SupplierFilter.defaultProps = {
  locale: 'en',
  supplyConfiguration: {},
};

export default injectIntl(SupplierFilter);
