import React from 'react';
import {Icon, Dropdown} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import {LocaleAnchor} from '@ecosio/customer-layout';

const UserManualDropDown = ({userConfig, locale}) => {
  const userManualUrls = userConfig.userManualUrls;

  const trigger = (
    <span>
      <Icon name="question circle outline" />
    </span>
  );

  return (
    <Dropdown pointing="top right" item trigger={trigger}>
      <Dropdown.Menu>
        <Dropdown.Item
          text={<FormattedMessage id="DOWNLOAD_MANUAL" />}
          key={userManualUrls[locale]}
          as={LocaleAnchor}
          url={userManualUrls[locale]}
          target="_blank"
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

UserManualDropDown.propTypes = {
  userConfig: PropTypes.shape({
    userManualUrls: PropTypes.shape({
      en: PropTypes.string,
      de: PropTypes.string,
    }),
  }),
  locale: PropTypes.string,
};

export default UserManualDropDown;
