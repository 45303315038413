import React from 'react';
import PropTypes from 'prop-types';
import {Menu} from 'semantic-ui-react';
import {isEmpty} from 'lodash';
import EdiPermissionDropdown from './EdiPermissionDropdown';
import ModuleDropdown, {shouldShowModule} from './ModuleDropdown';
import UserDropdown from './UserDropdown';
import LocaleDropdown from './LocaleDropdown';
import UserManualDropdown from './UserManualDropdown';

const Header = ({
  locale,
  localesMap,
  logoUrl,
  onSwitchLocale,
  onSwitchEdiPermission,
  userConfig,
  selectedModule,
  baseUrl = '',
}) => (
  <div>
    <Menu position="right" inverted style={{marginBottom: '0px'}}>
      <Menu.Item>
        {logoUrl ? (
          <a href={logoUrl}>
            <div className="ui companylogo" />
          </a>
        ) : (
          <div className="ui companylogo" />
        )}
      </Menu.Item>
      <ModuleDropdown userConfig={userConfig} selectedModule={selectedModule} />
      {onSwitchEdiPermission ? (
        <Menu.Menu>
          <EdiPermissionDropdown
            onSwitchEdiPermission={onSwitchEdiPermission}
            userConfig={userConfig}
          />
        </Menu.Menu>
      ) : null}

      <Menu.Menu>
        <UserDropdown userConfig={userConfig} baseUrl={baseUrl} />
      </Menu.Menu>

      {isEmpty(userConfig.userManualUrls) ? null : (
        <Menu.Menu>
          <UserManualDropdown userConfig={userConfig} locale={locale} />
        </Menu.Menu>
      )}

      <Menu.Menu>
        <LocaleDropdown
          locale={locale}
          localesMap={localesMap}
          onSwitchLocale={onSwitchLocale}
        />
      </Menu.Menu>
    </Menu>
  </div>
);

export {shouldShowModule};
export default Header;

// TODO: import userConfig from auth project
Header.propTypes = {
  baseUrl: PropTypes.string,
  locale: PropTypes.string,
  localesMap: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      locale: PropTypes.string,
      flag: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  logoUrl: PropTypes.string,
  onSwitchLocale: PropTypes.func,
  onSwitchEdiPermission: PropTypes.func,
  userConfig: PropTypes.object,
  selectedModule: PropTypes.string,
};
