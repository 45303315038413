// See https://codesandbox.io/s/4xq2qpzw79

const UPDATE_FORM_STATE = 'finalForm/UPDATE_FORM_STATE';

// Reducer
export function reducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_FORM_STATE:
      return {
        ...state,
        [action.form]: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export const updateFormState = (form, state) => ({
  type: UPDATE_FORM_STATE,
  form,
  payload: state,
});

// Selectors
export const getFormState = (state, form) =>
  (state && state.formState && state.formState[form]) || {};
