import React from 'react';
import {FormSpy} from 'react-final-form';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {updateFormState} from '../reducers/formState';

const formState = {};

export const getFormState = (formName) => formState[formName];

const FormStateToRedux = ({form, updateFormState}) => (
  <FormSpy
    subscription={{valid: true, values: true}}
    onChange={(state) => {
      const previousState = formState[form];
      formState[form] = state;

      // Optimization: Only dispatch redux action if form.valid has changed
      if (state?.valid !== previousState?.valid) {
        updateFormState(form, {
          ...state,
          values: null,
        });
      }
    }}
  />
);

FormStateToRedux.propTypes = {
  form: PropTypes.string.isRequired,
  updateFormState: PropTypes.func.isRequired,
};

export default connect(undefined, {updateFormState})(FormStateToRedux);
