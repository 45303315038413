import React from 'react';
import {SidebarNavItem} from '@ecosio/customer-layout';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import qs from 'qs';

export const QualityAssuranceSidebar = ({qualityAssurance}) => {
  const qa = qualityAssurance?.configuration?.supportedIssueTypes;
  if (!qa) {
    return null;
  }
  const query = qs.parse(location.search.substr(1));

  return qa.map((issueTypes, idx) => {
    const issueType = issueTypes.issueType;
    const name = issueTypes.nameTranslationKey;
    return (
      <SidebarNavItem
        key={idx}
        link={
          <NavLink
            data-spec={`SIDEBAR_ITEM_${issueType}`}
            to={`/quality-assurance?issueType=${issueType}&status=OPEN`}
            isActive={() => query.issueType === issueType}>
            <FormattedMessage
              id={`QUALITY_ASSURANCE_${name}`}
              values={{value: issueType.toLowerCase()}}
            />
          </NavLink>
        }
        description={null}
      />
    );
  });
};

const configurationShape = PropTypes.shape({
  supportedIssueTypes: PropTypes.array,
});

QualityAssuranceSidebar.propTypes = {
  configuration: configurationShape,
  imageUrl: PropTypes.string,
  uuid: PropTypes.string,
};
