import React from 'react';
import {Header, Segment, Message} from 'semantic-ui-react';
import axios from 'axios';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import {fetchCategories} from '../../../reducers/categories';
import DocumentForm, {recipientOptions} from './DocumentForm';

const fields = {
  recipientType: {
    path: 'recipientType',
    type: 'dropdown',
    options: recipientOptions,
    input: {
      type: 'text',
      label: 'DOCUMENT_RECIPIENTS',
      required: true,
    },
  },
  supplier: {
    path: 'supplier',
    type: 'dropdown-autocomplete',
    input: {
      label: 'GENERAL_SUPPLIER',
      url: '/api/suppliers/suggest',
    },
  },
  category: {
    path: 'category',
    type: 'dropdown',
    input: {
      label: 'PRODUCT_GROUP',
      width: 8,
    },
  },
  upload: {
    path: 'upload',
    type: 'upload',
    input: {
      label: 'GENERAL_UPLOAD',
      required: true,
    },
  },
  'payload.title': {
    path: 'payload.title',
    type: 'text',
    input: {
      type: 'text',
      label: 'DMS_DOCUMENT_TITLE',
      required: true,
    },
  },
  'payload.description': {
    path: 'payload.description',
    type: 'textarea',
    input: {
      type: 'text',
      label: 'DMS_DOCUMENT_DESCRIPTION',
    },
  },
  'payload.expiryDate': {
    path: 'payload.expiryDate',
    type: 'date',
    input: {
      type: 'text',
      label: 'DMS_DOCUMENT_EXPIRY_DATE',
      required: true,
      showTimeSelect: true,
    },
  },
};

const mapStateToProps = ({locales}) => ({locale: locales.locale});

const initialValues = {
  recipients: [{}],
};

@connect(mapStateToProps, {fetchCategories})
export default class CreateDocument extends React.Component {
  static propTypes = {
    fetchCategories: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
  };

  state = {
    error: null,
    uploading: false,
  };

  componentDidMount() {
    this.props.fetchCategories();
  }

  doUpload = async (values) => {
    this.setState({
      uploading: true,
    });
    const fileSize = values.upload.size;
    const fileName = values.upload.name;

    const payload = {
      payload: {
        ...values.payload,
        recipients: values.recipients,
        fileSize,
        fileName,
      },
    };

    try {
      const metadataResponse = await axios.post('/api/documents', payload);
      let location = metadataResponse.headers.location;
      if (location) {
        // Remove trailing slash, if there is one
        if (location.endsWith('/')) {
          location = location.substring(0, location.length - 2);
        }
        // Extract the file UUID from the Location header
        const idx = location.lastIndexOf('/');
        const uuid = location.substring(idx + 1);

        const formData = new window.FormData();
        formData.append('file', values.upload);

        await axios.post(`/api/documents/${uuid}`, formData);
        this.props.history.push('/documents/general/list');
      }
    } catch (err) {
      console.error(err);
      this.setState({
        error: err,
        uploading: false,
      });
    }
  };

  render() {
    const {locale} = this.props;
    const {error, uploading} = this.state;

    return (
      <Segment.Group>
        <Segment color="purple">
          <Header as="h1" size="large">
            <FormattedMessage id="DMS_UPLOAD_HEADER" />
          </Header>
        </Segment>
        <Segment>
          {error && (
            <Message error>
              <Message.Header>
                <FormattedMessage id="GENERAL_ERROR" />
              </Message.Header>
              <p>{error.message}</p>
            </Message>
          )}
          <DocumentForm
            onSubmit={this.doUpload}
            uploading={uploading}
            locale={locale}
            initialValues={initialValues}
            fields={fields}
            propertyMapping={{
              title: 'payload.title',
              description: 'payload.description',
              expiryDate: 'payload.expiryDate',
            }}
            backLink="/documents/general/list"
          />
        </Segment>
      </Segment.Group>
    );
  }
}
