import React from 'react';
import {Icon, Popup} from 'semantic-ui-react';
import {injectIntl} from 'react-intl';
import {intlShape} from '@ecosio/components';
import {CONTEXT_PATH} from '../../constants';
import {attachmentShape} from './IssueTracker/shapes';

const AttachmentDownloadButton = ({attachment, intl}) => {
  return (
    <Popup
      content={intl.formatMessage({id: 'GENERAL_DOWNLOAD'})}
      trigger={
        <a
          data-spec={`att-dnl-${attachment.uuid}`}
          target="_blank"
          rel="noopener noreferrer"
          href={`${CONTEXT_PATH}/api/attachments/${attachment.uuid}`}>
          <Icon name="download" />
        </a>
      }
    />
  );
};

AttachmentDownloadButton.propTypes = {
  attachment: attachmentShape.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(AttachmentDownloadButton);
