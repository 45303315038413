import React from 'react';
import {Header, Segment, Grid, List, Loader} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {InputWrapper, ResponsiveGridColumn} from '../FormHelpers';
import {wrappedGET} from '../../helpers/fetcher';
import {
  OfferingCompanyContactList,
  OfferingCompanyAttachmentsList,
} from './GeneralContacts';
import {supplierShape, configurationShape} from './shapes';

const CATEGORY_TRANSLATIONS = [
  'GENERAL_CATEGORY',
  'GENERAL_SUB_CATEGORY',
  'GENERAL_SUB_CATEGORY_2',
];

export const SelectedCategories = ({selectedCategories, locale}) => {
  return (
    <List>
      {selectedCategories.map((categories, idx) => (
        <List.Item key={idx}>
          <List horizontal relaxed divided>
            {categories.map((category, idx) => (
              <List.Item key={idx}>
                <List.Content>
                  <List.Header>
                    <FormattedMessage id={CATEGORY_TRANSLATIONS[idx]} />
                  </List.Header>
                  <List.Description style={{marginTop: '0.5rem'}}>
                    {category.name[locale]}
                  </List.Description>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </List.Item>
      ))}
    </List>
  );
};

SelectedCategories.propTypes = {
  selectedCategories: PropTypes.array,
  locale: PropTypes.string,
};

const blockInFieldsObject = (keys, blockName) => {
  return keys.some((f) => f.startsWith(blockName));
};

const FactsAndFigures = injectIntl(({configuration, data, intl}) => {
  return (
    <List horizontal relaxed divided>
      {Object.keys(data).map((key, idx) => {
        const value = data[key];
        const unitTranslationKey = configuration.units[key];
        const field = configuration.formFields[key];
        const label = field.input.label;
        const formattedValue = intl.formatNumber(value);

        return (
          <List.Item key={idx}>
            <List.Content>
              <List.Header>
                <FormattedMessage id={label} />:
              </List.Header>

              <List.Description style={{marginTop: '0.5rem'}}>
                <FormattedMessage
                  id={unitTranslationKey}
                  values={{value: formattedValue}}
                />
              </List.Description>
            </List.Content>
          </List.Item>
        );
      })}
    </List>
  );
});

class PreAssessment extends React.Component {
  state = {
    factsAndFigures: null,
    loading: true,
  };

  componentDidMount() {
    const {supplier} = this.props;

    wrappedGET(`/api/suppliers/${supplier.uuid}/preSsaFactsAndFigures`)
      .then((resp) =>
        this.setState({loading: false, factsAndFigures: resp.data})
      )
      .catch((error) => {
        console.error(error);
        // todo
      });
  }

  render() {
    const {
      fields,
      supplier,
      selectedCategories,
      locale,
      useForm,
      configuration,
    } = this.props;

    const {factsAndFigures, loading} = this.state;

    if (loading) {
      return <Loader active />;
    }

    const keys = Object.keys(fields);
    const hasAddressData = blockInFieldsObject(keys, 'addressData');
    const hasContactData = blockInFieldsObject(keys, 'contactData');
    const hasCompanyContacts = blockInFieldsObject(keys, 'companyContacts');
    const hasAttachments = supplier.attachments.length > 0;
    const countries = configuration.countryOptions;

    return (
      <Segment.Group>
        <Segment>
          <Grid divided stackable>
            <Grid.Row columns={2}>
              {hasAddressData && (
                <Grid.Column>
                  <Header size="medium" as="h2">
                    <FormattedMessage id="GENERAL_ADDRESS_DATA" />
                  </Header>
                  <List>
                    <InputWrapper
                      useForm={useForm}
                      path="contactData.company"
                    />
                    <InputWrapper useForm={useForm} path="addressData.street" />
                    <InputWrapper useForm={useForm} path="addressData.number" />
                    <InputWrapper
                      useForm={useForm}
                      path="addressData.postalCode"
                    />
                    <InputWrapper useForm={useForm} path="addressData.city" />
                    <InputWrapper useForm={useForm} path="addressData.region" />
                    <InputWrapper
                      useForm={useForm}
                      path="addressData.country"
                      translateOptions
                      options={countries}
                    />
                  </List>
                </Grid.Column>
              )}
              {hasContactData && (
                <Grid.Column>
                  <Header size="medium" as="h2">
                    <FormattedMessage id="GENERAL_CONTACT_DATA" />
                  </Header>
                  <List>
                    <InputWrapper
                      useForm={useForm}
                      path="contactData.telephone"
                    />
                    <InputWrapper useForm={useForm} path="contactData.fax" />
                    <InputWrapper useForm={useForm} path="contactData.email" />
                    <InputWrapper
                      useForm={useForm}
                      path="contactData.website"
                    />
                    <InputWrapper useForm={useForm} path="loginEmail" />
                  </List>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Segment>
        {hasCompanyContacts && (
          <Segment>
            <Grid divided stackable>
              <Grid.Row>
                <ResponsiveGridColumn>
                  <Header as="h2" size="medium">
                    <FormattedMessage id="GENERAL_COMPANY_CONTACTS" />
                  </Header>
                </ResponsiveGridColumn>
              </Grid.Row>

              <Grid.Row>
                <ResponsiveGridColumn>
                  <OfferingCompanyContactList supplier={supplier} />
                </ResponsiveGridColumn>
              </Grid.Row>
            </Grid>
          </Segment>
        )}
        <Segment>
          <Header as="h2" size="medium">
            <FormattedMessage id="GENERAL_PRODUCT_GROUPS" />
          </Header>
          <SelectedCategories
            selectedCategories={selectedCategories}
            supplier={supplier}
            locale={locale}
          />
        </Segment>

        {hasAttachments && (
          <Segment>
            <Header as="h2" size="medium">
              <FormattedMessage id="GENERAL_ATTACHMENTS" />
            </Header>
            <OfferingCompanyAttachmentsList supplier={supplier} />
          </Segment>
        )}

        <Segment>
          <Header as="h2" size="medium">
            <FormattedMessage id="GENERAL_FACTS_AND_FIGURES_CURRENT_YEAR" />
          </Header>
          <FactsAndFigures
            configuration={configuration.factsAndFigures}
            data={factsAndFigures}
          />
        </Segment>
      </Segment.Group>
    );
  }
}

PreAssessment.propTypes = {
  configuration: configurationShape,
  fields: PropTypes.shape({}),
  locale: PropTypes.string,
  selectedCategories: PropTypes.array,
  supplier: supplierShape,
  useForm: PropTypes.bool,
};

export default PreAssessment;
