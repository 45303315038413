import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Icon, Accordion} from 'semantic-ui-react';
import {FormattedMessage as Msg} from 'react-intl';
import {get} from 'lodash';
import {Form as PathForm} from '@ecosio/pathform';
import {issueShape} from '../../IssueTracker/shapes';
import {wrappedPOST} from '../../../../helpers/fetcher';
import {supplyModuleShape} from '../../shapes';
import DimensionOne from './Dimensions/DimensionOne';
import DimensionTwo from './Dimensions/DimensionTwo';
import DimensionThree from './Dimensions/DimensionThree';
import DimensionFour from './Dimensions/DimensionFour';
import DimensionFive from './Dimensions/DimensionFive';
import DimensionSix from './Dimensions/DimensionSix';
import DimensionSeven from './Dimensions/DimensionSeven';
import DimensionEight from './Dimensions/DimensionEight';

const checkIcon = (
  <Icon circular name="check" size="small" color="grey" inverted />
);
const warnIcon = (
  <Icon circular name="warning sign" size="small" color="red" inverted />
);

const DimensionIcon = ({dimensionData, paths}) => {
  const isWarn = paths
    .map((path) => get(dimensionData, path))
    .some((str) => str == null || str === '');
  return isWarn ? warnIcon : checkIcon;
};

const dimension1Paths = [
  'assignees.assignee.name',
  'assignees.assignee.department',
  'assignees.assignee.phoneNumber',
  'assignees.assignee.email',
  'assignees.teamLead.name',
  'assignees.teamLead.department',
  'assignees.teamLead.phoneNumber',
  'assignees.teamLead.email',
];
const dimension2Paths = ['freeText'];
const dimension3Paths = ['freeText', 'timestamp'];
const dimension4Paths = ['freeText'];
const dimension5Paths = ['freeText', 'timestamp'];
const dimension6Paths = ['freeText', 'timestamp'];
const dimension7Paths = ['freeText', 'timestamp'];
const dimension8Paths = ['assignees.assignee.name', 'timestamp'];

class DimensionPanels extends Component {
  constructor(props) {
    super(props);
    this.state = {edit: false};
  }

  onEditDimension = (panelKey) => {
    this.setState({
      edit: panelKey,
    });
  };

  onCancelDimension = () => {
    this.setState({
      edit: false,
    });
  };

  onSubmit = (values) => {
    const {onUpdateIssue} = this.props;
    wrappedPOST('/api/issues/update', values)
      .then((res) => {
        onUpdateIssue(res.data);
        this.setState({edit: false});
      })
      .catch((err) => console.error(err));
  };

  render() {
    const {
      issue,
      offeringCompany,
      template,
      editIssueSpecificDataOfferingCompany,
      editIssueSpecificDataUsingCompany,
    } = this.props;

    const {edit} = this.state;
    const defaultTemplate = template.defaultTemplate;
    const dimensionFields = template.issueTemplates[defaultTemplate].formFields;
    const dimensionData = issue.complaintData.complaintReport;
    return (
      <PathForm
        onSubmit={this.onSubmit}
        fields={dimensionFields}
        initialValues={issue}>
        {() => (
          <Accordion
            styled
            exclusive={false}
            panels={makePanels(
              dimensionData,
              offeringCompany,
              this.onEditDimension,
              this.onCancelDimension,
              edit,
              editIssueSpecificDataOfferingCompany,
              editIssueSpecificDataUsingCompany
            )}
            fluid
          />
        )}
      </PathForm>
    );
  }
}

const makePanels = (
  dimensionData,
  offeringCompany,
  onEditDimension,
  onCancelDimension,
  edit,
  editIssueSpecificDataOfferingCompany,
  editIssueSpecificDataUsingCompany
) => {
  return [
    {
      key: 'd1',
      title: {
        content: (
          <span data-spec="d1">
            <DimensionIcon
              dimensionData={dimensionData.dimension1}
              paths={dimension1Paths}
            />
            <Msg id="DIMENSION_D1" />
          </span>
        ),
        key: 'd1-title',
      },
      content: {
        content: (
          <DimensionOne
            dimensionData={dimensionData.dimension1}
            offeringCompany={offeringCompany}
            onEditDimension={onEditDimension}
            onCancelDimension={onCancelDimension}
            edit={edit}
            panelKey={'d1'}
            editIssueSpecificDataOfferingCompany={
              editIssueSpecificDataOfferingCompany
            }
            editIssueSpecificDataUsingCompany={
              editIssueSpecificDataUsingCompany
            }
          />
        ),
        key: 'd1-content',
      },
    },
    {
      key: 'd2',
      title: {
        content: (
          <span data-spec="d2">
            <DimensionIcon
              dimensionData={dimensionData.dimension2}
              paths={dimension2Paths}
            />
            <Msg id="DIMENSION_D2" />
          </span>
        ),
        key: 'd2-title',
      },
      content: {
        content: (
          <DimensionTwo
            dimensionData={dimensionData.dimension2}
            offeringCompany={offeringCompany}
            onEditDimension={onEditDimension}
            onCancelDimension={onCancelDimension}
            edit={edit}
            panelKey={'d2'}
            editIssueSpecificDataOfferingCompany={
              editIssueSpecificDataOfferingCompany
            }
            editIssueSpecificDataUsingCompany={
              editIssueSpecificDataUsingCompany
            }
          />
        ),
        key: 'd2-content',
      },
    },
    {
      key: 'd3',
      title: {
        content: (
          <span data-spec="d3">
            <DimensionIcon
              dimensionData={dimensionData.dimension3}
              paths={dimension3Paths}
            />
            <Msg id="DIMENSION_D3" />
          </span>
        ),
        key: 'd3-title',
      },
      content: {
        content: (
          <DimensionThree
            dimensionData={dimensionData.dimension3}
            offeringCompany={offeringCompany}
            onEditDimension={onEditDimension}
            onCancelDimension={onCancelDimension}
            edit={edit}
            panelKey={'d3'}
            editIssueSpecificDataOfferingCompany={
              editIssueSpecificDataOfferingCompany
            }
            editIssueSpecificDataUsingCompany={
              editIssueSpecificDataUsingCompany
            }
          />
        ),
        key: 'd3-content',
      },
    },
    {
      key: 'd4',
      title: {
        content: (
          <span data-spec="d4">
            <DimensionIcon
              dimensionData={dimensionData.dimension4}
              paths={dimension4Paths}
            />
            <Msg id="DIMENSION_D4" />
          </span>
        ),
        key: 'd4-title',
      },
      content: {
        content: (
          <DimensionFour
            dimensionData={dimensionData.dimension4}
            offeringCompany={offeringCompany}
            onEditDimension={onEditDimension}
            onCancelDimension={onCancelDimension}
            edit={edit}
            panelKey={'d4'}
            editIssueSpecificDataOfferingCompany={
              editIssueSpecificDataOfferingCompany
            }
            editIssueSpecificDataUsingCompany={
              editIssueSpecificDataUsingCompany
            }
          />
        ),
        key: 'd4-content',
      },
    },
    {
      key: 'd5',
      title: {
        content: (
          <span data-spec="d5">
            <DimensionIcon
              dimensionData={dimensionData.dimension5}
              paths={dimension5Paths}
            />
            <Msg id="DIMENSION_D5" />
          </span>
        ),
        key: 'd5-title',
      },
      content: {
        content: (
          <DimensionFive
            dimensionData={dimensionData.dimension5}
            offeringCompany={offeringCompany}
            onEditDimension={onEditDimension}
            onCancelDimension={onCancelDimension}
            edit={edit}
            panelKey={'d5'}
            editIssueSpecificDataOfferingCompany={
              editIssueSpecificDataOfferingCompany
            }
            editIssueSpecificDataUsingCompany={
              editIssueSpecificDataUsingCompany
            }
          />
        ),
        key: 'd5-content',
      },
    },
    {
      key: 'd6',
      title: {
        content: (
          <span data-spec="d6">
            <DimensionIcon
              dimensionData={dimensionData.dimension6}
              paths={dimension6Paths}
            />
            <Msg id="DIMENSION_D6" />
          </span>
        ),
        key: 'd6-title',
      },
      content: {
        content: (
          <DimensionSix
            dimensionData={dimensionData.dimension6}
            offeringCompany={offeringCompany}
            onEditDimension={onEditDimension}
            onCancelDimension={onCancelDimension}
            edit={edit}
            panelKey={'d6'}
            editIssueSpecificDataOfferingCompany={
              editIssueSpecificDataOfferingCompany
            }
            editIssueSpecificDataUsingCompany={
              editIssueSpecificDataUsingCompany
            }
          />
        ),
        key: 'd6-content',
      },
    },
    {
      key: 'd7',
      title: {
        content: (
          <span data-spec="d7">
            <DimensionIcon
              dimensionData={dimensionData.dimension7}
              paths={dimension7Paths}
            />
            <Msg id="DIMENSION_D7" />
          </span>
        ),
        key: 'd7-title',
      },
      content: {
        content: (
          <DimensionSeven
            dimensionData={dimensionData.dimension7}
            offeringCompany={offeringCompany}
            onEditDimension={onEditDimension}
            onCancelDimension={onCancelDimension}
            edit={edit}
            panelKey={'d7'}
            editIssueSpecificDataOfferingCompany={
              editIssueSpecificDataOfferingCompany
            }
            editIssueSpecificDataUsingCompany={
              editIssueSpecificDataUsingCompany
            }
          />
        ),
        key: 'd7-content',
      },
    },
    {
      key: 'd8',
      title: {
        content: (
          <span data-spec="d8">
            <DimensionIcon
              dimensionData={dimensionData.dimension8}
              paths={dimension8Paths}
            />
            <Msg id="DIMENSION_D8" />
          </span>
        ),
        key: 'd8-title',
      },
      content: {
        content: (
          <DimensionEight
            dimensionData={dimensionData.dimension8}
            offeringCompany={offeringCompany}
            onEditDimension={onEditDimension}
            onCancelDimension={onCancelDimension}
            edit={edit}
            panelKey={'d8'}
            editIssueSpecificDataOfferingCompany={
              editIssueSpecificDataOfferingCompany
            }
            editIssueSpecificDataUsingCompany={
              editIssueSpecificDataUsingCompany
            }
          />
        ),
        key: 'd8-content',
      },
    },
  ];
};

DimensionPanels.propTypes = {
  onUpdateIssue: PropTypes.func,
  //TODO: specify
  template: PropTypes.any,
  offeringCompany: PropTypes.bool.isRequired,
  issue: issueShape,
  supplyConfiguration: supplyModuleShape,
  editIssueSpecificDataOfferingCompany: PropTypes.bool.isRequired,
  editIssueSpecificDataUsingCompany: PropTypes.bool.isRequired,
};

export default DimensionPanels;
