import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage as Msg} from 'react-intl';
import {Loader} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {shapes} from '@ecosio/auth';

import {
  fetchSupplierExchange,
  postSupplier,
} from '../../reducers/supplierExchange';
import {fetchCategories} from '../../reducers/categories';
import {FormWrapper} from '../FormHelpers';
import FormStateToRedux from '../FormStateToRedux';
import {convertToFormData, findOfferingCompanyId} from '../../util';
import {ASSESSMENT_STATES} from '../../helpers/assessment_states';
import PreAssessment from './PreAssessment';
import PageHeader from './PageHeader';
import GeneralContacts from './GeneralContacts';
import ReferenceCustomers from './ReferenceCustomers';
import Products from './Products';
import CoreCompetencies from './CoreCompetencies';
import AbilitiesCertificates from './AbilitiesCertificates';
import FactsAndFigures from './FactsAndFigures';
import Locations from './Locations';
import {matchShape, supplierExchangeShape} from './shapes';

const findPageConfiguration = (sidebarConfiguration, currentPage) => {
  return sidebarConfiguration.pages.find((page) => page.type === currentPage);
};

const isPreAssessment = (supplier) => {
  return (
    supplier.status === ASSESSMENT_STATES.PRE_ASSESSMENT_UNDER_REVIEW ||
    supplier.status === ASSESSMENT_STATES.PRE_ASSESSMENT_REJECTED
  );
};

const mapStateToProps = ({config, supplierExchange, locales}) => ({
  config,
  supplierExchange,
  locale: locales.locale,
});

@connect(mapStateToProps, {
  fetchSupplierExchange,
  postSupplier,
  fetchCategories,
})
export default class SupplierDetails extends React.Component {
  static propTypes = {
    config: shapes.userConfig,
    fetchCategories: PropTypes.func,
    fetchSupplierExchange: PropTypes.func,
    history: PropTypes.shape({
      replace: PropTypes.func,
    }),
    locale: PropTypes.string,
    match: matchShape,
    postSupplier: PropTypes.func,
    supplierExchange: supplierExchangeShape,
  };

  state = {notification: null};

  componentDidMount() {
    const {uuid} = this.props.match.params;
    const config = this.props.config.userConfig;
    if (uuid) {
      const offeringCompanyId = findOfferingCompanyId(config);
      this.fetchSupplier(offeringCompanyId, uuid);
    }
  }

  componentDidUpdate(prevProps) {
    const {uuid, page} = this.props.match.params;
    const oldPage = prevProps.match.params.page;
    const oldUuid = prevProps.match.params.uuid;

    if (uuid && uuid !== oldUuid) {
      const offeringCompanyId = this.props.config?.userConfig?.company?.uuid;
      this.fetchSupplier(offeringCompanyId, uuid);
    }
    // Switching form pages, fetch supplier again (workaround for form state issues)
    else if (uuid && uuid === oldUuid && page !== oldPage) {
      const isOfferingCompany = this.props.config?.userConfig?.offeringCompany;

      if (!isOfferingCompany) {
        const offeringCompanyId = this.props.config?.userConfig?.company?.uuid;
        this.fetchSupplier(offeringCompanyId, uuid, false);
      }
    }
  }

  fetchSupplier = (offeringCompanyId, uuid, withCategories = true) => {
    this.props
      .fetchSupplierExchange(offeringCompanyId, uuid)
      .then((data) => {
        const {match} = this.props;
        const {supplier, configuration} = data;

        if (withCategories) {
          this.props.fetchCategories(supplier.uuid);
        }

        const formId = supplier.formId;
        const sidebarConfiguration = configuration.sidebarConfigurations.find(
          (config) => config.formId === formId
        );

        let page = null;
        const currentPage = match.params.page;
        if (!currentPage) {
          if (isPreAssessment(supplier)) {
            page = 'PRE_ASSESSMENT';
          } else {
            page = sidebarConfiguration.pages.filter(
              (p) => p.type !== 'PRE_ASSESSMENT'
            )[0].type;
          }
          this.props.history.replace({
            pathname: `/supplier/details/${
              supplier.uuid
            }/${page.toLowerCase()}`,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  onSubmit = (values) => {
    let supplier = values;
    if (values.currentPage) {
      const currentPage = values.currentPage;
      const pageValidation = Object.assign({}, values.pageValidation, {
        [currentPage]: true,
      });
      supplier = Object.assign({}, values, {pageValidation, currentPage});
      // This is not part of the supplier entity, so delete it
      delete supplier.currentPage;
      const formData = convertToFormData(supplier);
      this.props
        .postSupplier(formData)
        .then(() => {
          this.setState({
            notification: {
              positive: true,
              header: <Msg id="GENERAL_SUBMIT_SUCCESS_HEADER" />,
              body: <Msg id="GENERAL_SUBMIT_SUCCESS_MSG" />,
            },
          });
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          console.error(error);
          this.setState({
            notification: {
              negative: true,
              header: <Msg id="GENERAL_SUBMIT_ERROR_HEADER" />,
              body: <Msg id="GENERAL_SUBMIT_ERROR_MSG" />,
            },
          });
        });
    }
  };

  setNotification = (notification) => {
    this.setState({
      notification,
    });
  };

  hideNotification = () => {
    this.setNotification(null);
  };

  render() {
    const {
      config,
      supplierExchange: {supplier, configuration, selectedCategories, loading},
      match,
      locale,
    } = this.props;
    let currentPage = match.params.page;

    if (loading || !currentPage) {
      return <Loader active />;
    }

    // TODO nice 404 page
    if (!supplier) {
      return <p>404 - Not found</p>;
    }

    const formId = supplier.formId;
    const sidebarConfiguration = configuration.sidebarConfigurations.find(
      (config) => config.formId === formId
    );

    // convert to CONSTANT_CASE
    currentPage = currentPage.toUpperCase();
    const pageConfiguration = findPageConfiguration(
      sidebarConfiguration,
      currentPage
    );
    const isOfferingCompany = config.userConfig.offeringCompany;
    const fields = pageConfiguration.fields;

    let form = null;

    // Facts and figures page has to be stand-alone because it doesn't operate
    // on the supplier entity
    if (currentPage === 'FACTS_AND_FIGURES') {
      return (
        <FactsAndFigures
          notification={this.state.notification}
          hideNotification={this.hideNotification}
          setNotification={this.setNotification}
          supplier={supplier}
          useForm={!isOfferingCompany}
          configuration={configuration}
          currentPage={currentPage}
        />
      );
    }

    switch (currentPage) {
      case 'PRE_ASSESSMENT':
        form = (
          <PreAssessment
            fields={fields}
            supplier={supplier}
            selectedCategories={selectedCategories}
            locale={locale}
            useForm={!isOfferingCompany}
            configuration={configuration}
          />
        );
        break;
      case 'GENERAL_AND_CONTACTS':
        form = (
          <GeneralContacts
            fields={fields}
            supplier={supplier}
            locale={locale}
            useForm={!isOfferingCompany}
            selectedCategories={selectedCategories}
            configuration={configuration}
          />
        );
        break;
      case 'REFERENCE_CUSTOMERS':
        form = (
          <ReferenceCustomers
            fields={fields}
            supplier={supplier}
            locale={locale}
            useForm={!isOfferingCompany}
          />
        );
        break;
      case 'PRODUCTS':
        form = (
          <Products
            fields={fields}
            supplier={supplier}
            locale={locale}
            useForm={!isOfferingCompany}
          />
        );
        break;
      case 'CORE_COMPETENCIES':
        form = (
          <CoreCompetencies
            fields={fields}
            supplier={supplier}
            locale={locale}
            useForm={!isOfferingCompany}
          />
        );
        break;
      case 'ABILITIES_AND_CERTIFICATES':
        form = (
          <AbilitiesCertificates
            fields={fields}
            supplier={supplier}
            locale={locale}
            useForm={!isOfferingCompany}
            configuration={configuration}
          />
        );
        break;
      case 'LOCATIONS':
        form = (
          <Locations
            fields={fields}
            supplier={supplier}
            locale={locale}
            useForm={!isOfferingCompany}
            configuration={configuration}
          />
        );
        break;
    }

    return (
      <React.Fragment>
        <FormWrapper
          useForm={!isOfferingCompany}
          onSubmit={(values) => {
            this.onSubmit({
              ...values,
              currentPage,
            });
          }}
          fields={fields}
          subscription={{invalid: true, pristine: true}}
          initialValues={supplier}>
          {() => {
            return (
              <>
                {!isOfferingCompany && <FormStateToRedux form="supplier" />}
                <PageHeader
                  notification={this.state.notification}
                  hideNotification={this.hideNotification}
                  setNotification={this.setNotification}
                  supplier={supplier}
                  assessmentState={supplier.status}
                  offeringCompany={isOfferingCompany}
                  currentPage={currentPage}
                />
                {form}
              </>
            );
          }}
        </FormWrapper>
      </React.Fragment>
    );
  }
}
