import React, {Component} from 'react';
import {
  Segment,
  Button,
  Grid,
  GridRow,
  GridColumn,
  Header,
} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import MasterdataMessage, {formatMessage} from './MasterdataMessage';

const mapStateToProps = ({supplyConfiguration}) => {
  return {config: supplyConfiguration};
};

@connect(mapStateToProps)
class MappingLogFailedStatus extends Component {
  constructor(props) {
    super(props);
    this.state = '';
  }

  render() {
    const {intl, config} = this.props;

    return (
      <Segment.Group>
        <Helmet>
          <title>
            {formatMessage(
              intl,
              'MAPPING_LOG_FAILED_STATUS_PAGE_TAB_TITLE',
              config
            )}
          </title>
        </Helmet>
        <Segment color="purple">
          <Header as="h1">
            <MasterdataMessage translationKey="GENERATING_MAPPING_LOG_FAILED" />
          </Header>
        </Segment>
        <Segment>
          <Grid>
            <GridRow>
              <GridColumn width={8}>
                <Segment attached clearing>
                  <Header as="h3">
                    <MasterdataMessage translationKey="MAPPING_LOG_FAILED_PAGE_BODY" />
                  </Header>
                  <Link to={`/masterdata/document-upload`}>
                    <Button color="green">
                      <MasterdataMessage translationKey="BACK_BUTTON_NAME_ON_MAPPING_LOG_FAIL" />
                    </Button>
                  </Link>
                </Segment>
              </GridColumn>
            </GridRow>
          </Grid>
        </Segment>
      </Segment.Group>
    );
  }
}

MappingLogFailedStatus.propTypes = {
  intl: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
};
export default injectIntl(MappingLogFailedStatus);
