/* eslint-disable radar/no-duplicated-branches */
import {wrappedPUT, wrappedPOST, wrappedGET} from '../helpers/fetcher';

const TYPES = {
  ISSUE_CREATE_REQUEST: 'issue/createRequest',
  ISSUE_CREATE_RESPONSE: 'issue/createResponse',
  ISSUE_CREATE_ERROR: 'issue/createError',
  ISSUE_GET_REQUEST: 'issue/getRequest',
  ISSUE_GET_RESPONSE: 'issue/getResponse',
  ISSUE_GET_ERROR: 'issue/getError',
  ISSUE_EDIT_REQUEST: 'issue/editRequest',
  ISSUE_EDIT_RESPONSE: 'issue/editResponse',
  ISSUE_EDIT_ERROR: 'issue/editError',
  ISSUE_UPDATE_REQUEST: 'issue/updateRequest',
  ISSUE_UPDATE_RESPONSE: 'issue/updateResponse',
  ISSUE_UPDATE_ERROR: 'issue/updateError',
};

// object with issue subject and body
const issueCreateRequest = () => ({
  type: TYPES.ISSUE_CREATE_REQUEST,
});

// data:id probably
const issueCreateResponse = (data) => ({
  type: TYPES.ISSUE_CREATE_RESPONSE,
  data,
});

const issueCreateError = (error) => ({
  type: TYPES.ISSUE_CREATE_ERROR,
  error,
});

const issueGetRequest = () => ({
  type: TYPES.ISSUE_GET_REQUEST,
});

const issueGetResponse = (data) => ({
  type: TYPES.ISSUE_GET_RESPONSE,
  data,
});

const issueGetError = (error) => ({
  type: TYPES.ISSUE_GET_ERROR,
  error,
});

const issueUpdateRequest = () => ({
  type: TYPES.ISSUE_UPDATE_REQUEST,
});

const issueUpdateResponse = (data) => ({
  type: TYPES.ISSUE_UPDATE_RESPONSE,
  data,
});

const issueUpdateError = (error) => ({
  type: TYPES.ISSUE_UPDATE_ERROR,
  error,
});

const issueEditRequest = () => ({
  type: TYPES.ISSUE_EDIT_REQUEST,
});

const issueEditResponse = (data) => ({
  type: TYPES.ISSUE_EDIT_RESPONSE,
  data,
});

const issueEditError = (error) => ({
  type: TYPES.ISSUE_EDIT_ERROR,
  error,
});

export const editIssueHeader = (issue) => (dispatch) => {
  dispatch(issueEditRequest());
  const url = '/api/issues/';
  return wrappedPUT(url, issue)
    .then((res) => {
      if (res.status === 200) {
        return dispatch(issueEditResponse(res.data));
      } else {
        return dispatch(issueEditError(res.statusText));
      }
    })
    .catch((res) => dispatch(issueEditError(res.message)));
};

export const postCreatedIssue = (issue) => (dispatch) => {
  dispatch(issueCreateRequest());
  const url = '/api/issues';
  return wrappedPOST(url, issue)
    .then((res) => {
      if (res.status === 201) {
        return dispatch(issueCreateResponse(res.data));
      } else {
        return dispatch(issueCreateError(res.statusText));
      }
    })
    .catch((res) => dispatch(issueCreateError(res.message)));
};

export const getSingleIssue = (id) => (dispatch) => {
  dispatch(issueGetRequest());
  const url = `/api/issues/${id}`;
  return wrappedGET(url)
    .then((res) => {
      if (res.status === 200) {
        return dispatch(issueGetResponse(res.data));
      } else {
        return dispatch(issueGetError(res.statusText));
      }
    })
    .catch((res) => {
      dispatch(issueGetError(res.message));
    });
};
/**
 * comment is an object with properties text and attacmhents
 *  (see CreateCommentRequest in supply-api)
 */
export const postComment = (comment, issueId) => (dispatch) => {
  dispatch(issueUpdateRequest());
  const url = `/api/issues/${issueId}/comment`;
  return wrappedPOST(url, comment)
    .then((res) => {
      if (res.status === 200) {
        return dispatch(issueUpdateResponse(res.data));
      } else {
        return dispatch(issueUpdateError(res.statusText));
      }
    })
    .catch((res) => {
      dispatch(issueUpdateError(res.message));
    });
};

const initialState = {
  issue: null,
  loading: true,
  error: null,
};

export const issueReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ISSUE_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TYPES.ISSUE_CREATE_RESPONSE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case TYPES.ISSUE_CREATE_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case TYPES.ISSUE_GET_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TYPES.ISSUE_GET_RESPONSE:
      return {
        ...state,
        issue: action.data,
        loading: false,
        error: null,
      };
    case TYPES.ISSUE_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case TYPES.ISSUE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TYPES.ISSUE_UPDATE_RESPONSE:
      return {
        ...state,
        issue: action.data,
        loading: false,
        error: null,
      };
    case TYPES.ISSUE_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case TYPES.ISSUE_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TYPES.ISSUE_EDIT_RESPONSE:
      return {
        ...state,
        issue: action.data,
        loading: false,
        error: null,
      };
    case TYPES.ISSUE_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
