import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {get} from 'lodash';
import {Grid, Header, Icon, Message, Segment} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {hasPaging} from '@ecosio/components';

import {fetchDemandPreviewPage} from '../../reducers/demandPreview';
import DemandPreviewTable from './DemandPreviewTable';
import DemandPreviewFilter from './DemandPreviewFilter';

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPage: (query, uuid) => dispatch(fetchDemandPreviewPage(query, uuid)),
  };
};

const mapStateToProps = ({demandPreview, config}) => ({
  demandPreview: demandPreview,
  config,
});

const EmptyStateComponent = () => (
  <React.Fragment>
    <Grid
      data-spec="emptyState-grid"
      centered
      container
      columns={1}
      verticalAlign="middle"
      style={{height: '60vh'}}>
      <Grid.Row>
        <Grid.Column textAlign="center">
          <Header as="h2" icon textAlign="center">
            <Icon name="chart bar" color="grey" circular />
            <Header.Content data-spec="emptyState-header-content">
              <FormattedMessage id="GENERAL_NO_DEMAND_PREVIEWS_HEADER" />
            </Header.Content>
            <Header.Subheader data-spec="emptyState-sub-header-content">
              <FormattedMessage id="GENERAL_NO_DEMAND_PREVIEWS_SUBHEADER" />
            </Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </React.Fragment>
);

export const SubHeader = ({offeringCompany, supplierName, match}) => {
  if (!offeringCompany || !supplierName || !get(match, 'params.uuid', false)) {
    return null;
  }

  return <Header.Subheader>{supplierName}</Header.Subheader>;
};

SubHeader.propTypes = {
  offeringCompany: PropTypes.bool.isRequired,
  supplierName: PropTypes.any,
  match: PropTypes.object.isRequired,
};

@connect(mapStateToProps, mapDispatchToProps)
@hasPaging('uuid')
export default class DemandPreview extends Component {
  onLinkClick = (e, {id, timerange}) => {
    this.props.fetchExcel(id, timerange);
  };

  render() {
    const {demandPreview, config, match, location, history} = this.props;

    let supplierName = null;
    if (demandPreview.data.length) {
      // TODO: dangerous access
      supplierName = demandPreview.data[0].receiverName;
    }
    // const showSubHeader = config.userConfig.offeringCompany && supplierName

    return (
      <Fragment>
        <Segment color="purple" attached="top">
          <Header>
            <FormattedMessage id="DEMAND_PREVIEW_HEADER" />
            <SubHeader
              match={match}
              offeringCompany={config.userConfig.offeringCompany}
              supplierName={supplierName}
            />
          </Header>
        </Segment>
        <Segment attached="bottom">
          <DemandPreviewFilter location={location} history={history} />
        </Segment>
        <Message attached="top" warning>
          <Message.Header>
            <FormattedMessage id="DEMAND_PREVIEW_HEADER_1" />
          </Message.Header>
          <Message.List>
            <Message.Item>
              <FormattedMessage id="DEMAND_PREVIEW_LIST_1" />
            </Message.Item>
            <Message.Item>
              <FormattedMessage id="DEMAND_PREVIEW_LIST_2" />
            </Message.Item>
            <Message.Item>
              <FormattedMessage id="DEMAND_PREVIEW_LIST_3" />
              <Message.List>
                <Message.Item>
                  <FormattedMessage id="DEMAND_PREVIEW_LIST_3_1" />
                </Message.Item>
                <Message.Item>
                  <FormattedMessage id="DEMAND_PREVIEW_LIST_3_2" />
                </Message.Item>
              </Message.List>
            </Message.Item>
            <Message.Item>
              <FormattedMessage id="DEMAND_PREVIEW_LIST_4" />
              <Message.List>
                <Message.Item>
                  <FormattedMessage id="DEMAND_PREVIEW_LIST_4_1" />
                </Message.Item>
                <Message.Item>
                  <FormattedMessage id="DEMAND_PREVIEW_LIST_4_2" />
                </Message.Item>
                <Message.Item>
                  <FormattedMessage id="DEMAND_PREVIEW_LIST_4_3" />
                </Message.Item>
                <Message.Item>
                  <FormattedMessage id="DEMAND_PREVIEW_LIST_4_4" />
                </Message.Item>
                <Message.Item>
                  <FormattedMessage id="DEMAND_PREVIEW_LIST_4_5" />
                </Message.Item>
              </Message.List>
            </Message.Item>
            <Message.Item>
              <FormattedMessage id="DEMAND_PREVIEW_LIST_5" />
            </Message.Item>
            <Message.Item>
              <FormattedMessage id="DEMAND_PREVIEW_LIST_6" />
            </Message.Item>
          </Message.List>
        </Message>

        <Segment color="purple" attached="bottom">
          {demandPreview.data.length ? (
            <DemandPreviewTable
              config={config}
              data={demandPreview.data}
              paging={demandPreview.paging}
              onLinkClick={this.onLinkClick}
            />
          ) : (
            <EmptyStateComponent />
          )}
        </Segment>
      </Fragment>
    );
  }
}

DemandPreview.propTypes = {
  fetchExcel: PropTypes.func,
  demandPreview: PropTypes.object,
  config: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};
