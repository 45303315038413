import React from 'react';
import {Button, Header, Segment, Grid, Icon, Message} from 'semantic-ui-react';
import {Form as PathForm, Input, FormGroup} from '@ecosio/pathform';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {validateSize} from '../../FormHelpers';
import RecipientSelector from './RecipientSelector';
import {DownloadButton} from './DocumentList';

export const recipientOptions = [
  {
    value: 'ALL',
    text: 'ALL_SUPPLIERS',
  },
  {
    value: 'CATEGORY',
    text: 'RECIPIENT_TYPE_CATEGORY',
  },
  {
    value: 'SUPPLIER',
    text: 'RECIPIENT_TYPE_SUPPLIER',
  },
];

const DocumentForm = ({
  onSubmit,
  initialValues,
  locale,
  fields,
  propertyMapping,
  uploading,
  intl,
  backLink,
}) => {
  const titleProp = propertyMapping?.title || 'title';
  const descriptionProp = propertyMapping?.description || 'description';
  const expiryProp = propertyMapping?.expiryDate || 'expiryDate';

  const isEdit = initialValues?.uuid && initialValues?.status;

  return (
    <PathForm fields={fields} onSubmit={onSubmit} initialValues={initialValues}>
      {({values}) => (
        <Grid>
          <Grid.Column width={8}>
            <Grid.Row>
              {isEdit && (
                <Message info>
                  <Message.Header>
                    <FormattedMessage id="GENERAL_INFORMATION" />
                  </Message.Header>
                  <p>
                    <FormattedMessage id="DMS_EXISTING_FILE_OVERWRITE_MESSAGE" />
                  </p>
                </Message>
              )}

              <Segment attached>
                <Header size="medium" as="h2">
                  <FormattedMessage id="DMS_DOCUMENT_RECIPIENTS" />
                </Header>
                <RecipientSelector locale={locale} values={values} />
              </Segment>
              <Segment attached clearing>
                <FormGroup widths="equal">
                  <Input name={titleProp} path={titleProp} />
                </FormGroup>

                <FormGroup>
                  <Input
                    name={expiryProp}
                    path={expiryProp}
                    stripTimezone={false}
                    width={16}
                  />
                </FormGroup>

                <FormGroup widths="equal">
                  <Input name={descriptionProp} path={descriptionProp} />
                </FormGroup>

                <FormGroup>
                  <Input
                    path="upload"
                    name="upload"
                    validate={(value) => validateSize(value, intl)}
                  />
                  {isEdit && (
                    <DownloadButton
                      size="medium"
                      status={initialValues.status}
                      uuid={initialValues.uuid}
                      text="DMS_DOWNLOAD_EXISTING_FILE"
                      style={{maxHeight: '35px'}}
                    />
                  )}
                </FormGroup>

                <Button
                  data-spec="document-upload-submit"
                  type="submit"
                  positive
                  floated="right"
                  loading={uploading}
                  disabled={uploading}>
                  <Icon name="checkmark" />
                  &nbsp;
                  <FormattedMessage id="DMS_UPLOAD_SUBMIT" />
                </Button>

                <Button
                  type="button"
                  negative
                  floated="right"
                  as={Link}
                  to={backLink}>
                  <FormattedMessage id="GENERAL_CANCEL" />
                </Button>
              </Segment>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      )}
    </PathForm>
  );
};

DocumentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  locale: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  propertyMapping: PropTypes.object,
  uploading: PropTypes.bool,
  intl: PropTypes.object,
  backLink: PropTypes.string.isRequired,
};

export default injectIntl(DocumentForm);
