import React, {Component} from 'react';
import {MappingValidationLog} from '@ecosio/components';
import {
  Header,
  Button,
  Segment,
  Grid,
  GridColumn,
  GridRow,
  Form,
  FormGroup,
  Input,
  Loader,
} from 'semantic-ui-react';
import {injectIntl} from 'react-intl';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {wrappedPOST, wrappedGET} from '../../helpers/fetcher';
import MasterdataMessage, {formatMessage} from './MasterdataMessage';

const mapStateToProps = ({supplyConfiguration}) => {
  return {config: supplyConfiguration};
};

@connect(mapStateToProps)
class DocumentUploadPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: '',
      status: '',
      message: '',
      response: null,
      mappingLogData: null,
      sendToHubLoading: false,
    };
  }

  reset = () => {
    this.setState({
      selectedFile: '',
      status: '',
      message: '',
      response: null,
      mappingLogData: null,
      sendToHubLoading: false,
      value: '',
    });
  };

  onChangeHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
      value: event.target.value,
    });
  };

  async getValidationLog(logBlobkey, logBucket) {
    try {
      const response = await wrappedGET(
        `/api/documents/edi/getMappingLog?key=${logBlobkey}&bucket=${logBucket}`
      );
      this.setState({
        status: 'Invalid',
        message: <MasterdataMessage translationKey="UPLOAD_FILE_INVALID" />,
        mappingLogData: response.data,
      });
    } catch (error) {
      console.error(error);
    }
  }

  onUploadButtonClickHandler = () => {
    const data = new FormData();
    data.append('file', this.state.selectedFile);
    this.setState({
      status: 'Loading',
    });

    wrappedPOST('/api/documents/edi/uploadFile', data)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            status: 'Upload_Valid',
            message: <MasterdataMessage translationKey="UPLOAD_FILE_VALID" />,
            response: res.data,
          });
        } else {
          this.setState({
            message: (
              <MasterdataMessage translationKey="UPLOAD_UNKNOWN_ERROR" />
            ),
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          this.setState({
            response: error.response.data,
          });
          const {logBlobKey, logBucket} = this.state.response;

          if (!logBlobKey) {
            this.props.history.push(
              '/masterdata/document-upload/mapping-log-status'
            );
          } else {
            this.getValidationLog(logBlobKey, logBucket);
          }
        } else {
          this.setState({
            message: (
              <MasterdataMessage translationKey="UPLOAD_UNKNOWN_ERROR" />
            ),
          });
        }
        console.error(error);
      });
  };

  onSendButtonClickHandler = () => {
    const {resultBlobKey, resultBucket, resultSize} = this.state.response;
    const {selectedFile} = this.state;
    this.setState({
      sendToHubLoading: true,
    });
    wrappedPOST(`/api/documents/edi/sendToHub`, {
      resultBlobKey,
      resultBucket,
      resultSize,
    })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            sendToHubLoading: false,
            message: (
              <MasterdataMessage translationKey="DOCUMENT_SENT_SUCCESSFULLY" />
            ),
          });
          this.props.history.push(
            '/masterdata/document-upload/status',
            selectedFile.name
          );
        } else {
          this.setState({
            sendToHubLoading: false,
            message: (
              <MasterdataMessage translationKey="DOCUMENT_SENDING_FAILED" />
            ),
          });
        }
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          this.setState({
            sendToHubLoading: false,
            message: (
              <MasterdataMessage translationKey="DOCUMENT_SENDING_FAILED" />
            ),
          });
        }
        console.error(error);
      });
  };

  render() {
    const {status, mappingLogData, message} = this.state;

    const {intl, config} = this.props;

    return (
      <Segment.Group>
        <Helmet>
          <title>{formatMessage(intl, 'UPLOAD_PAGE_TAB_TITLE', config)}</title>
        </Helmet>
        <Segment color="purple">
          <Header as="h1">
            <MasterdataMessage translationKey="UPLOAD_HEADER" />
          </Header>
        </Segment>

        <Segment>
          <Grid>
            <GridRow>
              <GridColumn width={8}>
                <Segment attached clearing>
                  <Form>
                    <FormGroup inline>
                      <Form.Field required>
                        <label>
                          <MasterdataMessage translationKey="FILE_UPLOAD_LABEL" />
                        </label>
                      </Form.Field>
                      <Form.Field required>
                        <Input
                          data-spec="document-browse-button"
                          type="file"
                          name="file"
                          value={this.state.value}
                          onChange={this.onChangeHandler}
                          disabled={status === 'Upload_Valid' ? true : false}
                        />
                      </Form.Field>
                    </FormGroup>

                    <FormGroup>
                      {status === 'Loading' ? (
                        <Loader size="medium" active inline="centered">
                          <MasterdataMessage translationKey="LOADER_TEXT_WHILE_UPLOADING" />
                        </Loader>
                      ) : status !== 'Invalid' ? (
                        <Header as="h3">{message}</Header>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                    <FormGroup>
                      {status === 'Loading' || status === 'Upload_Valid' ? (
                        ''
                      ) : (
                        <Button
                          data-spec="document-upload-button"
                          type="button"
                          floated="right"
                          color={!this.state.selectedFile ? 'grey' : 'green'}
                          onClick={this.onUploadButtonClickHandler}
                          disabled={!this.state.selectedFile}>
                          <MasterdataMessage translationKey="UPLOAD_BUTTON_NAME" />
                        </Button>
                      )}
                      {status === 'Upload_Valid' ? (
                        <Button
                          data-spec="document-send-button"
                          type="button"
                          floated="left"
                          color="green"
                          loading={this.state.sendToHubLoading}
                          onClick={this.onSendButtonClickHandler}>
                          <MasterdataMessage translationKey="SEND_BUTTON_NAME" />
                        </Button>
                      ) : (
                        ''
                      )}

                      {status === 'Upload_Valid' ? (
                        <Button
                          data-spec="document-send-cancel-button"
                          type="button"
                          color="green"
                          onClick={this.reset}>
                          <MasterdataMessage translationKey="CANCEL_BUTTON_NAME" />
                        </Button>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                  </Form>
                </Segment>
                <br />
                {status === 'Invalid' ? (
                  <MappingValidationLog Log={mappingLogData} />
                ) : (
                  ''
                )}
              </GridColumn>
            </GridRow>
          </Grid>
        </Segment>
      </Segment.Group>
    );
  }
}

DocumentUploadPage.propTypes = {
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  config: PropTypes.object,
};

export default injectIntl(DocumentUploadPage);
