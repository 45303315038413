import React from 'react';
import PropTypes from 'prop-types';
import {Input} from '@ecosio/pathform';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';

const categoryToOption = (category, locale, valueProperty) => ({
  text: category.name[locale],
  description: category.externalId,
  value: category[valueProperty],
  key: category.uuid,
});

const matchesQuery = (o, query) => {
  let match = false;
  const lower = query.toLowerCase();
  if (o.text) {
    match = o.text.toLowerCase().includes(lower);
  }
  if (o.description) {
    match = match || o.description.toLowerCase().includes(lower);
  }

  return match;
};

const customSearch = (options, query) => {
  return options.filter((o) => matchesQuery(o, query));
};

const mapStateToProps = ({categories}) => ({categories});

@injectIntl
@connect(mapStateToProps)
export default class AssignedCategorySelector extends React.Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    categories: PropTypes.object,
    onResultSelect: PropTypes.func,
    intl: PropTypes.any,
    name: PropTypes.string,
    valueProperty: PropTypes.string,
    disabled: PropTypes.bool,
    path: PropTypes.string,
    renderLabel: PropTypes.bool,
  };

  static defaultProps = {
    name: 'assignedCategoryId',
    valueProperty: 'uuid',
    renderLabel: true,
  };

  constructor(props) {
    super(props);

    const {locale, valueProperty} = props;
    const categories = props.categories?.data || [];

    this.state = {
      options: categories.map((c) =>
        categoryToOption(c, locale, valueProperty)
      ),
    };
  }

  handleChange = (e, {value}) => {
    if (this.props.onResultSelect) {
      this.props.onResultSelect(value);
    }
  };

  render() {
    const {intl, name, disabled, path, renderLabel} = this.props;
    const {options} = this.state;
    const placeholder = intl.formatMessage({
      id: 'CATEGORY_SELECTOR_PLACEHOLDER',
    });
    return (
      <Input
        name={name}
        path={path != null ? path : name}
        options={options}
        search={customSearch}
        selection
        translateOptions={false}
        placeholder={placeholder}
        disabled={disabled}
        renderLabel={renderLabel}
        renderMultipleLabel={({description}) => description}
        renderText={({text, description}) => (
          <React.Fragment>
            {text}
            <span className="description">{description}</span>
          </React.Fragment>
        )}
      />
    );
  }
}
