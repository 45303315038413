import React, {Component} from 'react';
import {
  Segment,
  Grid,
  Button,
  Icon,
  Header,
  Modal,
  Popup,
} from 'semantic-ui-react';
import {FormattedMessage as Msg} from 'react-intl';
import {
  Form as PathForm,
  Input,
  InputArray,
  ExternalSubmit,
} from '@ecosio/pathform';
import {connect} from 'react-redux';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import {wrappedPOST} from '../../../../helpers/fetcher';
import {issueShape} from '../../IssueTracker/shapes';

const validateIssue = (issue) => {
  const errors = {};

  if (!issue.assignee?.uuid) {
    errors.assignee = {
      uuid: 'GENERAL_REQUIRED',
    };
  }
  return errors;
};

const mapStateToProps = ({locales}) => ({locale: locales.locale});

@connect(mapStateToProps)
class ArticleInspectionRequiredList extends Component {
  constructor(props) {
    super(props);
    this.state = {edit: false, setToOpen: false, modalVisible: false};
  }

  onEditRequiredFields = () => {
    this.setState((prevState) => ({
      edit: !prevState.edit,
    }));
  };

  onSubmit = (values) => {
    const {onUpdateIssue} = this.props;
    if (this.state.setToOpen) {
      values.state = 'OPEN';
    }

    wrappedPOST('/api/issues/update', values)
      .then((res) => {
        return onUpdateIssue(res.data);
      })
      .catch((e) => console.error(e));

    this.setState({edit: false, setToOpen: false, modalVisible: false});
  };

  onCloseModal = () => {
    this.setState({modalVisible: false, setToOpen: false});
  };

  onSaveAndOpen = () => {
    this.setState({setToOpen: true, modalVisible: true});
  };

  render() {
    const {
      issue,
      template,
      offeringCompany,
      editIssueSpecificDataOfferingCompany,
      editIssueSpecificDataUsingCompany,
      locale,
    } = this.props;

    const showEditButton =
      (issue.state !== 'CLOSED' &&
        offeringCompany &&
        editIssueSpecificDataOfferingCompany) ||
      (!offeringCompany && editIssueSpecificDataUsingCompany);

    const {articleInspectionRequirements} = template;
    const {edit} = this.state;

    const defaultTemplate = template.defaultTemplate;
    const articleInspectionRequiredFields =
      template.issueTemplates[defaultTemplate].formFields;

    return (
      <Segment clearing>
        <Modal open={this.state.modalVisible} onClose={this.onCloseModal}>
          <Modal.Content>
            <Msg id="GENERAL_CONFIRM_MESSAGE" />
          </Modal.Content>
          <Modal.Actions>
            <ExternalSubmit
              positive
              formid="article-inspection-requirements-form">
              <Msg id="GENERAL_CONFIRM" />
            </ExternalSubmit>
            <Button negative onClick={this.onCloseModal} type="button">
              <Msg id="GENERAL_CANCEL" />
            </Button>
          </Modal.Actions>
        </Modal>

        <Header>
          <Msg id="ARTICLE_INSPECTION_REQUIRED_LIST" />
        </Header>
        {!edit ? (
          <Grid>
            <Grid.Row columns="4">
              {issue.articleInspectionData.articleInspectionRequirements.map(
                (required, idx) => {
                  const selected = articleInspectionRequirements.find(
                    (selected) => selected.uuid === required.uuid
                  );
                  return (
                    <Grid.Column key={required.uuid}>
                      <p
                        data-spec={`requiredListViewItem${idx + 1}`}
                        style={{
                          color: required.selected ? 'black' : 'lightgrey',
                        }}>
                        {required.selected && (
                          <Icon name="angle double right" />
                        )}
                        {selected.label[locale]}
                      </p>
                    </Grid.Column>
                  );
                }
              )}
            </Grid.Row>
            {showEditButton && (
              <Grid.Row>
                <Grid.Column>
                  <Button
                    data-spec="editRequiredListButton"
                    primary
                    floated="right"
                    onClick={this.onEditRequiredFields}>
                    <Msg id="ARTICLE_INSPECTION_EDIT_LIST" />
                  </Button>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        ) : (
          <PathForm
            validate={validateIssue}
            formid="article-inspection-requirements-form"
            onSubmit={this.onSubmit}
            id="ai-req-list"
            fields={articleInspectionRequiredFields}
            initialValues={issue}>
            {({invalid}) => (
              <InputArray name="articleInspectionData.articleInspectionRequirements">
                {({fields}) => (
                  <Grid>
                    <Grid.Row columns="4">
                      {fields.map((name, idxCol) => {
                        const issuePath = get(issue, name);
                        const requiredTranslationKey = articleInspectionRequirements.find(
                          (selected) => selected.uuid === issuePath.uuid
                        );
                        return (
                          <Grid.Column key={idxCol}>
                            <Input
                              name={`${name}.selected`}
                              path="articleInspectionData.articleInspectionRequirements"
                              renderLabel={false}
                              label={requiredTranslationKey.label[locale]}
                            />
                          </Grid.Column>
                        );
                      })}
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Button
                          negative
                          floated="right"
                          type="button"
                          onClick={this.onEditRequiredFields}>
                          <Msg id="GENERAL_CANCEL" />
                        </Button>
                        <Button
                          floated="right"
                          type="submit"
                          data-spec="saveRequiredList">
                          <Msg id="GENERAL_SAVE" />
                        </Button>
                        {issue.state === 'CREATED' && (
                          <Popup
                            style={invalid ? undefined : {opacity: '0'}}
                            content={
                              <Msg id="ARTICLE_INSPECTION_SUBMIT_DISABLED_MESSAGE" />
                            }
                            trigger={
                              <span style={{float: 'right'}}>
                                <Button
                                  data-spec="saveRequiredListAndSetToOpen"
                                  disabled={invalid}
                                  positive
                                  type="button"
                                  onClick={this.onSaveAndOpen}>
                                  <Msg id="ARTICLE_INSPECTION_SAVE_AND_SET_TO_OPEN" />
                                </Button>
                              </span>
                            }
                          />
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                )}
              </InputArray>
            )}
          </PathForm>
        )}
      </Segment>
    );
  }
}

ArticleInspectionRequiredList.propTypes = {
  issue: issueShape,
  //TODO:SPECIFY!!!
  onUpdateIssue: PropTypes.func,
  template: PropTypes.any,
  offeringCompany: PropTypes.bool,
  editIssueSpecificDataOfferingCompany: PropTypes.bool,
  editIssueSpecificDataUsingCompany: PropTypes.bool,
};

export default ArticleInspectionRequiredList;
