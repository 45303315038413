import {SidebarNavItem} from '@ecosio/customer-layout';
import {NavLink} from 'react-router-dom';
import {Icon} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import React from 'react';

export const BackButton = ({offeringCompany}) => {
  const backLink =
    window.localStorage && window.localStorage.getItem('backLink');
  if (offeringCompany) {
    return (
      <SidebarNavItem
        key="-1"
        link={
          <NavLink to={backLink || '/suppliers/list/all'}>
            <Icon name="arrow left" />
            &nbsp;
            <FormattedMessage id="SUPPLIER_DETAILS_BACK_BUTTON" />
          </NavLink>
        }
      />
    );
  } else {
    return null;
  }
};
