// AUTO-GENERATED file by GenerateFrontendEnums.java
// These constants map to the java enum at.erpel.web.al.entity.supplierDatabase.AssessmentState
export const ASSESSMENT_STATES = {
  PRE_ASSESSMENT_UNDER_REVIEW: 'PRE_ASSESSMENT_UNDER_REVIEW',
  PRE_ASSESSMENT_REJECTED: 'PRE_ASSESSMENT_REJECTED',
  WAITING_FOR_OPERATOR: 'WAITING_FOR_OPERATOR',
  ASSESSMENT: 'ASSESSMENT',
  ASSESSMENT_UNDER_REVIEW: 'ASSESSMENT_UNDER_REVIEW',
  ASSESSMENT_REJECTED: 'ASSESSMENT_REJECTED',
  ASSESSMENT_POSTPONED: 'ASSESSMENT_POSTPONED',
  ASSESSMENT_COMPLETED: 'ASSESSMENT_COMPLETED',
};
