import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {FormSpy} from 'react-final-form';
import qs from 'qs';
import {injectIntl} from 'react-intl';
import {Input} from '@ecosio/pathform';
import {wrappedGET} from '../../helpers/fetcher';

@injectIntl
export default class OrderSelector extends Component {
  static propTypes = {
    ctx: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    intl: PropTypes.obejct,
  };

  state = {
    positionOptions: [],
    lineItems: [],
  };

  componentDidMount() {
    const {values} = this.props.ctx;
    // If there is a pre-selected value, fetch the position options when mounting the component
    if (values?.orderNumber) {
      this.fetchPositionOptions(values.orderNumber, this.props.ctx, false);
    }
  }

  fetchPositionOptions = (orderNumber, ctx, unsetValues = true) => {
    if (unsetValues) {
      ctx.form.batch(() => {
        ctx.form.change('materialNumber', null);
        ctx.form.change('materialShortText.de', null);
        ctx.form.change('lineItemId', null);
        ctx.form.change('orderPosition', null);
        ctx.form.change('factoryIdentifier', null);
      });
    }

    const query = qs.stringify({
      documentNumber: orderNumber,
      sort: 'positionNumber,asc',
      size: 50,
    });

    const {intl} = this.props;

    wrappedGET(`/api/lineItem/suggest/materialNumber?${query}`)
      .then((res) => {
        this.setState({
          lineItems: res.data,
          positionOptions: res.data.map((lineItem) => ({
            text: `${lineItem.positionNumber} (${intl.formatMessage({
              id: `MESSAGETYPE_${lineItem.documentType}`,
            })})`,
            description: lineItem.customersArticleNumber,
            value: lineItem.positionNumber,
            key: lineItem.uuid,
          })),
        });
      })
      .catch((err) => console.error(err));
  };

  setLineItemValues = (value, ctx) => {
    const {lineItems} = this.state;
    const selectedLineItem = lineItems.find(
      (item) => item.positionNumber === value
    );
    ctx.form.batch(() => {
      ctx.form.change(
        'materialNumber',
        selectedLineItem.customersArticleNumber
      );
      ctx.form.change('materialShortText.de', selectedLineItem.description);
      ctx.form.change('lineItemId', selectedLineItem.uuid);
      ctx.form.change(
        'factoryIdentifier',
        selectedLineItem.consignee?.unloadingPoint
      );
    });
  };

  render() {
    const {ctx, disabled} = this.props;
    const {positionOptions} = this.state;

    return (
      <FormSpy subscription={{values: true}}>
        {({values}) => {
          const {orderNumber} = values;
          return (
            <Fragment>
              <Input
                name="orderNumber"
                path="orderNumber"
                width="8"
                defaultText={orderNumber}
                onInputChange={(e, value) =>
                  this.fetchPositionOptions(value, ctx)
                }
                fetchOnMount
              />
              <Input
                name="orderPosition"
                path="orderPosition"
                width="8"
                disabled={disabled || Boolean(!orderNumber)}
                options={positionOptions}
                translateOptions={false}
                onInputChange={(e, value) => this.setLineItemValues(value, ctx)}
              />
            </Fragment>
          );
        }}
      </FormSpy>
    );
  }
}
