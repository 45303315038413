// This is essentially just a configuration file
/* eslint-disable radar/no-identical-functions */

import React from 'react';
import {Popup, Button, Icon} from 'semantic-ui-react';
import {Link, withRouter} from 'react-router-dom';
import {FormattedMessage as Msg} from 'react-intl';
import PropTypes from 'prop-types';
import DateFormat from '../DateFormat';
import {NotSpecified} from '../FormHelpers';
import {StateLabel} from './StateLabel';
import ComplaintCell from './ComplaintCell';

export const formatEmail = (email) => {
  if (!email) {
    return email;
  }

  const index = email.indexOf('@');
  if (index !== -1) {
    return email.substring(0, index);
  } else {
    return email;
  }
};

const Email = ({email}) => {
  if (!email) {
    return null;
  }

  const abbreviated = formatEmail(email);
  return <Popup content={email} trigger={<span>{abbreviated}</span>} />;
};

Email.propTypes = {
  email: PropTypes.string,
};

const DetailsLink = withRouter(({issueNumber, location, issueType}) => {
  return (
    <Popup
      trigger={
        <Button
          as={Link}
          to={`${location.pathname}/view/${issueNumber}?issueType=${issueType}`}
          size="small"
          primary
          compact
          icon>
          <Icon name="eye" />
        </Button>
      }
      content={<Msg id="GENERAL_SHOW_DETAILS" />}
      hideOnScroll
      on="hover"
      position="top center"
    />
  );
});

// TODO investigate why this fixes a test
//DetailsLink.propTypes = {
//  issueNumber: PropTypes.number.isRequired
//};

export const specialReleaseConfig = (statusHidden) => ({
  idSelector: 'uuid',
  fields: [
    {
      id: 'supplier',
      label: 'GENERAL_SUPPLIER',
      hidden: false,
      hideable: false,
      sortable: true,
      render: (Table, value) => (
        <Table.Cell>
          {value?.name ? (
            <Link to={`/supplier/details/${value.uuid}`}>{value?.name}</Link>
          ) : (
            <NotSpecified />
          )}
        </Table.Cell>
      ),
    },
    {
      id: 'orderNumber',
      label: 'GENERAL_ORDER',
      hidden: false,
      hideable: false,
      sortable: true,
    },
    {
      id: 'orderPosition',
      label: 'ISSUE_POSTION_SHORT',
      hidden: false,
      hideable: false,
    },
    {
      id: 'materialNumber',
      label: 'ISSUE_CUSTOMER_MATERIAL',
      hidden: false,
      hideable: false,
    },
    {
      id: 'factoryIdentifier',
      label: 'GENERAL_PLANT',
      hidden: false,
      hideable: false,
    },
    {
      id: 'subject',
      label: 'GENERAL_SHORTTEXT',
      hidden: false,
      hideable: false,
      sortable: true,
    },
    {
      id: 'assignee',
      label: 'GENERAL_ASSIGNEE',
      hidden: false,
      hideable: false,
      render: (Table, value) => (
        <Table.Cell>
          <Email email={value?.name} />
        </Table.Cell>
      ),
    },
    {
      id: 'createdAt',
      label: 'GENERAL_CREATED',
      hidden: false,
      hideable: false,
      sortable: true,
      render: (Table, value) => (
        <Table.Cell collapsing>
          {value && <DateFormat date={value} />}
        </Table.Cell>
      ),
    },
    {
      id: 'lastUpdatedBySupplier',
      label: 'GENERAL_LAST_UPDATED_BY_SUPPLIER',
      hidden: false,
      hideable: false,
      sortable: true,
      render: (Table, value) => (
        <Table.Cell>{value && <DateFormat date={value} />}</Table.Cell>
      ),
    },
    {
      id: 'closedBy',
      label: 'GENERAL_CLOSED_BY',
      hidden: statusHidden,
      hideable: false,
      render: (Table, value) => (
        <Table.Cell collapsing>
          <Email email={value} />
        </Table.Cell>
      ),
    },
    {
      id: 'closedAt',
      label: 'GENERAL_CLOSED_AT',
      hidden: statusHidden,
      hideable: false,
      sortable: true,
      render: (Table, value) => (
        <Table.Cell collapsing>
          {value && <DateFormat date={value} />}
        </Table.Cell>
      ),
    },
    {
      id: 'state',
      label: 'GENERAL_STATE',
      hidden: false,
      hideable: false,
      sortable: true,
      render: (Table, value, row) => (
        <Table.Cell textAlign="center" collapsing>
          <StateLabel state={row.state} />
        </Table.Cell>
      ),
    },
    {
      id: 'specialReleaseState',
      label: 'SPECIAL_RELEASE_STATE',
      hidden: statusHidden,
      hideable: false,
      sortable: true,
      render: (Table, value, row) => (
        <Table.Cell textAlign="center" collapsing>
          <StateLabel state={row.specialReleaseState} />
        </Table.Cell>
      ),
    },
    {
      id: '',
      label: 'GENERAL_ACTIONS',
      hidden: false,
      hideable: false,
      render: (Table, value, row) => {
        return (
          <Table.Cell textAlign="center" collapsing>
            <DetailsLink
              issueNumber={row.issueNumber}
              issueType={row.issueType}
            />
          </Table.Cell>
        );
      },
    },
  ],
});

export const complaintConfig = (statusHidden) => ({
  idSelector: 'uuid',
  selectable: true,
  fields: [
    {
      id: 'supplier',
      label: 'GENERAL_SUPPLIER',
      hidden: false,
      hideable: true,
      sortable: true,
      render: (Table, value) => (
        <Table.Cell>
          <Link to={`/supplier/details/${value.uuid}`}>{value?.name}</Link>
        </Table.Cell>
      ),
    },
    {
      id: 'externalComplaintReference',
      label: 'COMPLAINT_NUMBER',
      hidden: false,
      hideable: false,
    },
    {
      id: 'orderNumber',
      label: 'GENERAL_ORDER',
      hidden: false,
      hideable: false,
      sortable: true,
    },
    {
      id: 'orderPosition',
      label: 'POSITION_SHORT',
      hidden: false,
      hideable: false,
    },
    {
      id: 'materialNumber',
      label: 'ISSUE_CUSTOMER_MATERIAL',
      hidden: false,
      hideable: false,
    },
    // hidden because of https://gitlab.ecosio.com/code/frontend/-/issues/1626
    // {
    //   id: 'supplierMaterialNumber',
    //   label: 'SUPPLIER_MATERIAL_NUMBER',
    //   hidden: false,
    //   hideable: false
    // },
    {
      id: 'factoryIdentifier',
      label: 'GENERAL_PLANT',
      hidden: false,
      hideable: false,
    },
    {
      id: 'subject',
      label: 'GENERAL_SHORTTEXT',
      hidden: false,
      hideable: false,
      sortable: true,
    },
    {
      id: 'assignee',
      label: 'GENERAL_ASSIGNEE',
      hidden: false,
      hideable: false,
      render: (Table, value) => (
        <Table.Cell collapsing>
          <Email email={value?.name} />
        </Table.Cell>
      ),
    },
    {
      id: 'complaintData',
      label: 'GENERAL_REPORT_DATA',
      hidden: false,
      hideable: false,
      render: (Table, value) => {
        return (
          <Table.Cell>
            <ComplaintCell issue={value} />
          </Table.Cell>
        );
      },
    },
    {
      id: 'createdAt',
      label: 'GENERAL_CREATED',
      hidden: false,
      hideable: false,
      sortable: true,
      render: (Table, value) => (
        <Table.Cell collapsing>
          {value && <DateFormat date={value} />}
        </Table.Cell>
      ),
    },
    {
      id: 'lastUpdatedBySupplier',
      label: 'GENERAL_LAST_UPDATED_BY_SUPPLIER',
      hidden: false,
      hideable: false,
      sortable: true,
      render: (Table, value) => (
        <Table.Cell>{value && <DateFormat date={value} />}</Table.Cell>
      ),
    },
    {
      id: 'closedBy',
      label: 'GENERAL_CLOSED_BY',
      hidden: statusHidden,
      hideable: false,
      render: (Table, value) => (
        <Table.Cell collapsing>
          <Email email={value} />
        </Table.Cell>
      ),
    },
    {
      id: 'closedAt',
      label: 'GENERAL_CLOSED_AT',
      hidden: statusHidden,
      hideable: false,
      sortable: true,
      render: (Table, value) => (
        <Table.Cell collapsing>
          {value && <DateFormat date={value} />}
        </Table.Cell>
      ),
    },
    {
      id: 'state',
      label: 'GENERAL_STATE',
      hidden: false,
      hideable: false,
      sortable: true,
      render: (Table, value, row) => (
        <Table.Cell textAlign="center" collapsing>
          <StateLabel state={row.state} />
        </Table.Cell>
      ),
    },
    {
      id: 'complaintState',
      label: 'COMPLAINT_STATE',
      hidden: false,
      hideable: false,
      sortable: true,
      render: (Table, value, row) => (
        <Table.Cell textAlign="center" collapsing>
          <StateLabel state={row.complaintState} />
        </Table.Cell>
      ),
    },
    {
      id: '',
      label: 'GENERAL_ACTIONS',
      hidden: false,
      hideable: false,
      render: (Table, value, row) => (
        <Table.Cell textAlign="center" collapsing>
          <DetailsLink
            issueNumber={row.issueNumber}
            issueType={row.issueType}
          />
        </Table.Cell>
      ),
    },
  ],
});

export const articleInspectionConfig = (statusHidden) => ({
  idSelector: 'uuid',
  fields: [
    {
      id: 'supplier',
      label: 'GENERAL_SUPPLIER',
      hidden: false,
      hideable: false,
      sortable: true,
      render: (Table, value) => (
        <Table.Cell>
          <Link to={`/supplier/details/${value.uuid}`}>{value?.name}</Link>
        </Table.Cell>
      ),
    },
    {
      id: 'articleInspectionReference',
      label: 'ARTICLE_INSPECTION_REFERENCE',
      hidden: false,
      hideable: false,
    },
    {
      id: 'orderNumber',
      label: 'GENERAL_ORDER',
      hidden: false,
      hideable: false,
      sortable: true,
    },
    {
      id: 'orderPosition',
      label: 'ISSUE_POSTION_SHORT',
      hidden: false,
      hideable: false,
    },
    {
      id: 'materialNumber',
      label: 'ISSUE_CUSTOMER_MATERIAL',
      hidden: false,
      hideable: false,
    },
    {
      id: 'factoryIdentifier',
      label: 'GENERAL_PLANT',
      hidden: false,
      hideable: false,
    },
    {
      id: 'deliveryDate',
      label: 'GENERAL_DELIVERY_DATE',
      hidden: false,
      hideable: false,
      sortable: true,
    },
    {
      id: 'subject',
      label: 'GENERAL_SHORTTEXT',
      hidden: false,
      hideable: false,
      sortable: true,
    },
    {
      id: 'assignee',
      label: 'GENERAL_ASSIGNEE',
      hidden: false,
      hideable: false,
      render: (Table, value) => (
        <Table.Cell collapsing>
          <Email email={value?.name} />
        </Table.Cell>
      ),
    },

    {
      id: 'createdAt',
      label: 'GENERAL_CREATED',
      hidden: false,
      hideable: false,
      sortable: true,
      render: (Table, value) => (
        <Table.Cell collapsing>
          {value && <DateFormat date={value} />}
        </Table.Cell>
      ),
    },
    {
      id: 'lastUpdatedBySupplier',
      label: 'GENERAL_LAST_UPDATED_BY_SUPPLIER',
      hidden: false,
      hideable: false,
      render: (Table, value) => (
        <Table.Cell>{value && <DateFormat date={value} />}</Table.Cell>
      ),
    },
    {
      id: 'closedBy',
      label: 'GENERAL_CLOSED_BY',
      hidden: statusHidden,
      hideable: false,
      render: (Table, value) => (
        <Table.Cell collapsing>
          <Email email={value} />
        </Table.Cell>
      ),
    },
    {
      id: 'closedAt',
      label: 'GENERAL_CLOSED_AT',
      hidden: statusHidden,
      hideable: false,
      sortable: true,
      render: (Table, value) => (
        <Table.Cell collapsing>
          {value && <DateFormat date={value} />}
        </Table.Cell>
      ),
    },
    {
      id: 'state',
      label: 'GENERAL_STATE',
      hidden: false,
      hideable: false,
      sortable: true,
      render: (Table, value, row) => (
        <Table.Cell textAlign="center" collapsing>
          <StateLabel state={row.state} />
        </Table.Cell>
      ),
    },
    {
      id: 'articleInspectionState',
      label: 'ARTICLE_INSPECTION_STATE',
      hidden: statusHidden,
      hideable: false,
      sortable: true,
      render: (Table, value, row) => (
        <Table.Cell textAlign="center" collapsing>
          <StateLabel state={row.articleInspectionState} />
        </Table.Cell>
      ),
    },
    {
      id: '',
      label: 'GENERAL_ACTIONS',
      hidden: false,
      hideable: false,
      render: (Table, value, row) => (
        <Table.Cell textAlign="center" collapsing>
          <DetailsLink
            issueNumber={row.issueNumber}
            issueType={row.issueType}
          />
        </Table.Cell>
      ),
    },
  ],
});

export const generalIssueConfig = (statusHidden) => ({
  idSelector: 'uuid',
  fields: [
    {
      id: 'supplier',
      label: 'GENERAL_SUPPLIER',
      hidden: false,
      hideable: false,
      render: (Table, value) => (
        <Table.Cell>
          {value?.name ? (
            <Link to={`/supplier/details/${value.uuid}`}>{value?.name}</Link>
          ) : (
            <NotSpecified />
          )}
        </Table.Cell>
      ),
    },
    {
      id: 'context',
      label: 'GENERAL_CONTEXT',
      hidden: false,
      hodeable: false,
      render: (Table, value) => (
        <Table.Cell>{<Msg id={`GENERAL_${value}`} />}</Table.Cell>
      ),
    },
    {
      id: 'subject',
      label: 'GENERAL_SHORTTEXT',
      hidden: false,
      hideable: false,
      sortable: true,
    },
    {
      id: 'assignee',
      label: 'GENERAL_ASSIGNEE',
      hidden: false,
      hideable: false,
      render: (Table, value) => (
        <Table.Cell>
          <Email email={value?.name} />
        </Table.Cell>
      ),
    },
    {
      id: 'createdAt',
      label: 'GENERAL_CREATED',
      hidden: false,
      hideable: false,
      render: (Table, value) => (
        <Table.Cell collapsing>
          {value && <DateFormat date={value} />}
        </Table.Cell>
      ),
    },
    {
      id: 'lastUpdatedBySupplier',
      label: 'GENERAL_LAST_UPDATED_BY_SUPPLIER',
      hidden: false,
      hideable: false,
      render: (Table, value) => (
        <Table.Cell>{value && <DateFormat date={value} />}</Table.Cell>
      ),
    },
    {
      id: 'closedBy',
      label: 'GENERAL_CLOSED_BY',
      hidden: statusHidden,
      hideable: false,
      render: (Table, value) => (
        <Table.Cell collapsing>
          <Email email={value} />
        </Table.Cell>
      ),
    },
    {
      id: 'closedAt',
      label: 'GENERAL_CLOSED_AT',
      hidden: statusHidden,
      hideable: false,
      render: (Table, value) => (
        <Table.Cell collapsing>
          {value && <DateFormat date={value} />}
        </Table.Cell>
      ),
    },
    {
      id: 'generalIssueState',
      label: 'GENERAL_ISSUE_STATE',
      hidden: false,
      hideable: false,
      render: (Table, value, row) => (
        <Table.Cell textAlign="center" collapsing>
          <StateLabel state={row.state} />
        </Table.Cell>
      ),
    },
    {
      id: '',
      label: 'GENERAL_ACTIONS',
      hidden: false,
      hideable: false,
      render: (Table, value, row) => (
        <Table.Cell textAlign="center" collapsing>
          <DetailsLink
            issueNumber={row.issueNumber}
            issueType={row.issueType}
          />
        </Table.Cell>
      ),
    },
  ],
});

export const ideasConfig = (statusHidden) => ({
  idSelector: 'uuid',
  fields: [
    {
      id: 'supplier',
      label: 'GENERAL_SUPPLIER',
      hidden: false,
      hideable: false,
      render: (Table, value) => (
        <Table.Cell>
          {value?.name ? (
            <Link to={`/supplier/details/${value.uuid}`}>{value?.name}</Link>
          ) : (
            <NotSpecified />
          )}
        </Table.Cell>
      ),
    },
    {
      id: 'subject',
      label: 'GENERAL_SHORTTEXT',
      hidden: false,
      hideable: false,
      sortable: true,
    },
    {
      id: 'assignee',
      label: 'GENERAL_ASSIGNEE',
      hidden: false,
      hideable: false,
      render: (Table, value) => (
        <Table.Cell>
          <Email email={value?.name} />
        </Table.Cell>
      ),
    },
    {
      id: 'createdAt',
      label: 'GENERAL_CREATED',
      hidden: false,
      hideable: false,
      render: (Table, value) => (
        <Table.Cell>{value && <DateFormat date={value} />}</Table.Cell>
      ),
    },
    {
      id: 'lastUpdatedBySupplier',
      label: 'GENERAL_LAST_UPDATED_BY_SUPPLIER',
      hidden: false,
      hideable: false,
      render: (Table, value) => (
        <Table.Cell>{value && <DateFormat date={value} />}</Table.Cell>
      ),
    },
    {
      id: 'closedBy',
      label: 'GENERAL_CLOSED_BY',
      hidden: statusHidden,
      hideable: false,
      render: (Table, value) => (
        <Table.Cell collapsing>
          <Email email={value} />
        </Table.Cell>
      ),
    },
    {
      id: 'closedAt',
      label: 'GENERAL_CLOSED_AT',
      hidden: statusHidden,
      hideable: false,
      render: (Table, value) => (
        <Table.Cell collapsing>
          {value && <DateFormat date={value} />}
        </Table.Cell>
      ),
    },
    {
      id: 'ideasState',
      label: 'GENERAL_IDEAS_STATE',
      hidden: false,
      hideable: false,
      render: (Table, value, row) => (
        <Table.Cell textAlign="center" collapsing>
          <StateLabel state={row.state} />
        </Table.Cell>
      ),
    },
    {
      id: '',
      label: 'GENERAL_ACTIONS',
      hidden: false,
      hideable: false,
      render: (Table, value, row) => {
        return (
          <Table.Cell textAlign="center" collapsing>
            <DetailsLink
              issueNumber={row.issueNumber}
              issueType={row.issueType}
            />
          </Table.Cell>
        );
      },
    },
  ],
});
