import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Segment, Button, Header, Loader} from 'semantic-ui-react';
import {Form as PathForm, FormGroup, Input} from '@ecosio/pathform';
import {Link} from 'react-router-dom';
import {FormattedMessage as Msg} from 'react-intl';
import {connect} from 'react-redux';
import {shapes} from '@ecosio/auth';
import {wrappedPOST} from '../../../helpers/fetcher';
import {issueShape} from '../IssueTracker/shapes';
import AttachmentList from '../AttachmentList';
import {makeUploadRequests} from '../IssueModuleEdit';

class IdeasCreate extends Component {
  state = {
    attachments: [],
    submitting: false,
  };

  onUpload = (files) => {
    this.setState((prevState) => ({
      attachments: prevState.attachments.concat(files.map((f) => ({file: f}))),
    }));
  };

  onSubmit = (values) => {
    const uuid = this.props.match.params.id;
    const issue = Object.assign({}, values, {
      issueType: 'IDEAS',
      context: 'IDEAS',
      state: 'OPEN',
      materialShortText: {
        de: values.materialShortText?.de,
        en: values.materialShortText?.de,
      },
    });

    this.setState({submitting: true});
    let outerIssueNumber;
    return wrappedPOST(uuid ? '/api/issues/update' : '/api/issues', issue)
      .then((res) => {
        const issueNumber = res.data.issueNumber;
        outerIssueNumber = issueNumber;
        return makeUploadRequests(this.state.attachments, issueNumber);
      })
      .then(() => {
        this.setState({submitting: false});
        this.props.history.push(`/ideas/view/${outerIssueNumber}`);
      })
      .catch((error) => {
        this.setState({submitting: false});
        console.error(error);
      });
  };

  render() {
    const {issue, onAttachmentRemoved} = this.props;
    const isEdit = this.props.match.params.id != null;
    const {offeringCompany} = this.props.config.userConfig;

    if (!issue && isEdit) {
      return <Loader active />;
    }

    const {
      supportedIssueTypes,
    } = this.props.supplyConfiguration?.supplyModules?.IDEAS?.configuration;
    const generalIssueTemplate = supportedIssueTypes?.issueType;
    const fields =
      generalIssueTemplate?.issueTemplates?.issueTemplate?.formFields;
    const newFields = Object.assign({}, fields, {
      subject: {
        input: {
          label: 'GENERAL_GENERAL_ISSUE_SHORT_TEXT',
          type: 'text',
          required: true,
        },
        path: 'subject',
        type: 'text',
      },
      body: {
        input: {
          type: 'text',
          required: true,
          label: 'GENERAL_GENERAL_ISSUE_LONG_TEXT',
        },
        path: 'subject',
        type: 'textarea',
      },
      'attachments.file': {
        path: 'attachments.file',
        type: 'upload',
        input: {
          label: 'GENERAL_UPLOAD',
        },
      },
      'assignee.uuid': {
        path: 'assignee.uuid',
        type: 'dropdown-autocomplete',
        input: {
          url: '/api/users/suggest',
          label: 'GENERAL_ASSIGNEE',
          defaultText: issue?.assignee?.name,
        },
      },
      'supplier.uuid': {
        path: 'supplier.uuid',
        type: 'dropdown-autocomplete',
        input: {
          url: '/api/suppliers/suggest',
          label: 'GENERAL_SUPPLIER',
          defaultText: issue?.supplier?.name,
        },
      },
    });
    return (
      <PathForm
        onSubmit={this.onSubmit}
        fields={newFields}
        initialValues={issue}>
        {() => (
          <Segment.Group>
            <Segment color="purple" clearing>
              <Header size="large">
                <Header.Content>
                  <Msg id="GENERAL_IDEAS" />
                </Header.Content>
              </Header>
              {offeringCompany && (
                <Fragment>
                  <FormGroup>
                    <Input
                      name="supplier.uuid"
                      path="supplier.uuid"
                      width="4"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      name="assignee.uuid"
                      path="assignee.uuid"
                      width="4"
                    />
                  </FormGroup>
                </Fragment>
              )}

              <FormGroup>
                <Input name="subject" path="subject" width="16" />
              </FormGroup>

              <FormGroup>
                <Input name="body" path="body" width="16" />
              </FormGroup>

              <AttachmentList
                onUpload={this.onUpload}
                attachments={issue?.attachments}
                onRemove={onAttachmentRemoved}
              />

              <Button
                type="button"
                negative
                floated="right"
                as={Link}
                to="/ideas?issueType=IDEAS">
                <Msg id="GENERAL_CANCEL" />
              </Button>
              <Button
                disabled={this.state.submitting}
                loading={this.state.submitting}
                type="submit"
                positive
                floated="right">
                <Msg id="GENERAL_SEND" />
              </Button>
            </Segment>
          </Segment.Group>
        )}
      </PathForm>
    );
  }
}

const supplyShape = PropTypes.shape({
  configuration: PropTypes.object,
  imageUrl: PropTypes.string,
  type: PropTypes.string,
  uuid: PropTypes.string,
});

const supplyModulesShape = PropTypes.shape({
  DEMAND: supplyShape,
  DOCUMENT_MANAGMENT: supplyShape,
  ISSUE_TRACKER: supplyShape,
  QUALITY_ASSURANCE: supplyShape,
  SUPPLIER_DATABASE: supplyShape,
  SUPPLIER_PERFORMANCE: supplyShape,
});

const supplyConfigShape = PropTypes.shape({
  error: PropTypes.bool,
  loading: PropTypes.bool,
  supplierId: PropTypes.string,
  supplyModules: supplyModulesShape,
});

IdeasCreate.propTypes = {
  config: shapes.config,
  supplyConfiguration: supplyConfigShape,
  match: PropTypes.any,
  history: PropTypes.any,
  onSubmit: PropTypes.func,
  issue: issueShape,
};

const mapStateToProps = ({config, supplyConfiguration}) => ({
  config,
  supplyConfiguration,
});

export default connect(mapStateToProps, null)(IdeasCreate);
