import {SidebarNavItem} from '@ecosio/customer-layout';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import React from 'react';

export const DocumentManagementSidebar = () => {
  return [
    <SidebarNavItem
      key={0}
      link={
        <NavLink
          to="/documents/general/list"
          data-spec="documents-sidebar-item-link">
          <FormattedMessage id="DOCUMENTS_GENERAL_MENU_ITEM" />
        </NavLink>
      }
      description={null}
      data-spec="documents-sidebar-item"
    />,
    <SidebarNavItem
      key={1}
      link={
        <NavLink
          to="/documents/drawings/list"
          data-spec="drawings-sidebar-item-link">
          <FormattedMessage id="DRAWINGS_MENU_ITEM" />
        </NavLink>
      }
      description={null}
      data-spec="drawings-sidebar-item"
    />,
  ];
};
