import React from 'react';
import PropTypes from 'prop-types';
import {Item, Popup, Icon} from 'semantic-ui-react';

export const SidebarHeader = ({avatar, header, subheader}) => (
  <div className="head">
    <Item.Group>
      <Item>
        {avatar ? <Item.Image src={avatar} size="mini" avatar /> : null}
        <Item.Content className={avatar ? 'content hasAvatar' : 'content'}>
          <strong>{header}</strong>
          {subheader ? <div className="company">{subheader}</div> : null}
        </Item.Content>
      </Item>
    </Item.Group>
  </div>
);

SidebarHeader.propTypes = {
  avatar: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
};

/**
 * WARNING: do not export this to users of this library.
 *
 * The Popup component from semantic-ui react does not seem
 * to work when exposed in a library.
 */
export const SidebarDescription = ({description}) => (
  <div className="description">
    <Popup trigger={<Icon name="info circle" />} position="right center">
      {description}
    </Popup>
  </div>
);

SidebarDescription.propTypes = {
  description: PropTypes.any,
};

export const SidebarNavItem = ({link, description}) => (
  <li>
    {link}
    {description ? <SidebarDescription description={description} /> : null}
  </li>
);

SidebarNavItem.propTypes = {
  link: PropTypes.any,
  description: PropTypes.any,
};

export const SidebarNav = ({items}) => (
  <nav role="navigation">
    <ul>
      {(items || []).map((item, idx) =>
        item.link ? (
          <SidebarNavItem
            key={item.key || idx}
            link={item.link}
            description={item.description || null}
          />
        ) : null
      )}
    </ul>
  </nav>
);

SidebarNav.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.any,
      description: PropTypes.any,
    })
  ),
};
