// TODO this code is an exact duplicate of the CategorySelector in supply-ssa-frontend

import React, {Component} from 'react';
import {FormGroup, Input, InputArray} from '@ecosio/pathform';
import {Button, Icon} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {FormSpy} from 'react-final-form';
import range from 'lodash/range';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const fieldNames = ['mainCategory', 'subCategory1', 'subCategory2'];

// Determine the list of dropdown values to show, given the parent ID
const categoriesForParentId = (categories, parentId, locale, sort) => {
  const result = categories
    .filter((c) => !!parentId && c.parent === parentId)
    .map((c) => ({
      text: c.name[locale],
      value: c.uuid,
    }));
  if (sort) {
    return result.sort(({text: t1}, {text: t2}) => {
      if (t1 < t2) {
        return -1;
      } else if (t1 > t2) {
        return 1;
      } else {
        return 0;
      }
    });
  } else {
    return result;
  }
};

const DropdownInput = ({name, path, currentDepth, ...props}) => {
  return (
    <Input
      translateOptions={false}
      name={`${name}[${currentDepth}]`}
      path={`${path}.${fieldNames[currentDepth]}`}
      upward
      {...props}
    />
  );
};

DropdownInput.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  currentDepth: PropTypes.number.isRequired,
};

const mapStateToProps = ({categories}) => ({categories});

/**
 * Renders a list of <FormGroup />, each with a number of
 * dropdown fields (equal to the number passed in as the `depth` property)
 */
@connect(mapStateToProps)
export default class CategorySelector extends Component {
  static propTypes = {
    depth: PropTypes.number.isRequired,
    categories: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    sort: PropTypes.bool,
    multiple: PropTypes.bool,
  };

  static defaultProps = {
    sort: false,
  };

  render() {
    if (!this.props.categories) {
      return null;
    }

    const {data: categories, rootEl} = this.props.categories;
    if (!categories) {
      return null;
    }
    const {depth, path, name, locale, sort, multiple} = this.props;
    const rootId = rootEl ? rootEl.uuid : undefined;

    // The value at `path` in the form is a nested list
    // of UUIDs where each sublist describes a path in the
    // category tree (e.g. `[["1", "2", "3"], ["4", "5"]]`)
    return (
      <FormSpy subscription={{values: true}}>
        {({values, form}) => {
          return (
            <InputArray name={name}>
              {({fields}) => {
                return (
                  <React.Fragment>
                    {multiple && (
                      <Button
                        style={{marginBottom: '1rem'}}
                        compact
                        type="button"
                        positive
                        icon
                        onClick={() => fields.push([])}>
                        <Icon name="add" />
                        &nbsp;
                        <FormattedMessage id="ADD_PRODUCT_GROUP" />
                      </Button>
                    )}

                    {fields.map((name, idx) => {
                      const array = get(values, name);
                      return (
                        <FormGroup
                          key={idx}
                          widths="equal"
                          className="cat_wrap">
                          {range(0, depth).map((currentDepth) => {
                            // Render the list of inputs

                            const arraySafe = array || [];
                            // Determine the parent ID to filter the list
                            // of categories. The first dropdown has the root
                            // ID as its parent, the ones after that have the selected
                            // value of the dropdown before it
                            const parentId =
                              currentDepth === 0
                                ? rootId
                                : arraySafe[currentDepth - 1];
                            const options = categoriesForParentId(
                              categories,
                              parentId,
                              locale,
                              sort
                            );
                            const isEmpty = !options || options.length === 0;

                            return (
                              <DropdownInput
                                className={`cat_drop_${currentDepth}`}
                                key={currentDepth}
                                name={name}
                                path={path}
                                currentDepth={currentDepth}
                                options={options}
                                disabled={isEmpty}
                                onInputChange={(e, value) => {
                                  // Drop the values after the value that was just selected
                                  // e.g. if the first dropdown changes, drop the values
                                  // that were selected in the second and third one.
                                  const existingValues = array.slice(
                                    0,
                                    currentDepth
                                  );
                                  const newValues = [...existingValues, value];
                                  form.change(name, newValues);
                                }}
                              />
                            );
                          })}
                          {idx >= 1 && (
                            <Button
                              style={{
                                marginTop: '23px',
                                marginBottom: '1px',
                              }}
                              negative
                              type="button"
                              icon="remove"
                              onClick={() => fields.remove(idx)}
                            />
                          )}
                        </FormGroup>
                      );
                    })}
                  </React.Fragment>
                );
              }}
            </InputArray>
          );
        }}
      </FormSpy>
    );
  }
}
