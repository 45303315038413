import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import {Button, Segment, Icon} from 'semantic-ui-react';
import {Attachment} from '@ecosio/components';
import DateFormat from '../DateFormat';
import {CONTEXT_PATH} from '../../constants';
import {BoldContent} from './IssueTracker/IssueTrackerHelpers';
import {commentShape} from './IssueTracker/shapes.js';

const CommentEditView = ({onEditComment, comment, isUser, showEditButton}) => {
  const commentUuid = comment.uuid;
  return (
    <Segment.Group style={{marginLeft: isUser ? '40px' : '0px'}}>
      <Segment
        clearing
        color="purple"
        style={{
          backgroundColor: isUser ? '#F7F7F7' : '#FFFFFF',
          fontStyle: isUser ? 'italic' : 'normal',
        }}>
        <span style={{paddingRight: '40px'}}>
          <BoldContent>{comment.authorName}</BoldContent>
        </span>
        <span>
          <DateFormat date={comment.createdAt} />
        </span>
        {showEditButton && (
          <Button
            compact
            primary
            icon
            floated="right"
            onClick={() => onEditComment(commentUuid)}>
            <Icon name="edit" />
          </Button>
        )}
      </Segment>
      <Segment>
        <ReactMarkdown source={comment.text} />
        <Attachment
          attachments={comment.attachments}
          contextPath={CONTEXT_PATH}
        />
      </Segment>
    </Segment.Group>
  );
};

CommentEditView.defaultProps = {
  showEditButton: true,
};

CommentEditView.propTypes = {
  onEditComment: PropTypes.func,
  isUser: PropTypes.bool,
  comment: commentShape,
  showEditButton: PropTypes.bool,
};

export default CommentEditView;
