import React from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import {Button, Header, Form, Select} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';

const DEFAULT_FILTER = {
  dateShortcut: 'ALL',
  query: '',
  sort: 'createdAt,desc',
};

const DATE_FILTER_SHORTCUTS = [
  'ALL',
  'TODAY',
  'YESTERDAY',
  'LAST_3_DAYS',
  'LAST_WEEK',
];

class DemandPreviewFilter extends React.Component {
  constructor(props) {
    super(props);

    const {location} = this.props;
    // qs returns an empty object at parseerror or empty string
    // substr needed for querystring indicator questionmark
    const searchObj =
      location.search && location.search.trim() !== ''
        ? qs.parse(location.search.substr(1))
        : {};

    this.state = Object.assign({}, DEFAULT_FILTER, searchObj);
  }
  onApply = () => {
    const query = qs.stringify(this.state);
    this.props.history.push({
      search: `?${query}`,
    });
  };

  onChangeInput = (e) => {
    this.setState({
      query: e.target.value,
    });
  };

  onChangeDateShortcut = (e, {value}) => {
    this.setState(
      {
        dateShortcut: value,
      },
      this.onApply
    );
  };

  render() {
    const {intl} = this.props;
    const DATE_FILTERS = DATE_FILTER_SHORTCUTS.map((key) => {
      return {
        value: key,
        text: intl.formatMessage({
          id: `DMS_DATE_FILTER_${key}`,
        }),
        key,
      };
    });

    return (
      <React.Fragment>
        <Header size="medium" as="h3">
          <Header.Content>
            <FormattedMessage id="GENERAL_FILTER_PANEL_HEADER" />
          </Header.Content>
        </Header>
        <Form onSubmit={this.onApply} id="drawing-filter">
          <Form.Input type="text" action>
            {/* autofocus if we are currently filtering */}
            <input
              className="_si_search"
              placeholder={intl.formatMessage({
                id: 'DRAWING_SEARCH_PLACEHOLDER',
              })}
              onChange={this.onChangeInput}
              value={this.state.query}
              autoFocus={this.state.query && this.state.query > 0}
            />
            <Select
              className="_si_datesc"
              onChange={this.onChangeDateShortcut}
              compact
              options={DATE_FILTERS}
              value={this.state.dateShortcut}
              style={{width: '250px'}}
            />
            <Button type="submit" positive>
              <FormattedMessage id="GENERAL_APPLY" />
            </Button>
          </Form.Input>
        </Form>
      </React.Fragment>
    );
  }
}

DemandPreviewFilter.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(DemandPreviewFilter);
