import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Header,
  Message,
  Loader,
  Button,
  Label,
  Icon,
  Segment,
  Menu,
  Popup,
} from 'semantic-ui-react';
import {Helmet} from 'react-helmet';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import qs from 'qs';
import {get} from 'lodash';
import {MODULE_TYPES} from '@ecosio/auth';
import {hasPaging, Pagination, EmptyTableState} from '@ecosio/components';
import {fetchPage} from '../../reducers/supplierList';
import DateFormat from '../DateFormat';
import ModuleConditional from '../ModuleConditional';
import {ASSESSMENT_STATES} from '../../helpers/assessment_states';
import {fetchCategories} from '../../reducers/categories';
import SupplierFilter from './SupplierFilter';

const COLUMNS = {
  ALL: {
    status: true,
    preCheckState: true,
    testRequestDone: true,
    onboardingStatus: true,
  },
  ACTIVE: {
    status: true,
    preCheckState: true,
    testRequestDone: true,
    onboardingStatus: true,
  },
  NEW: {
    status: true,
    preCheckState: true,
    testRequestDone: true,
    onboardingStatus: true,
  },
};

const COLUMN_COUNTS = {
  ALL: 9,
  ACTIVE: 9,
  NEW: 9,
};

export const colorForAssessmentStatus = (status) => {
  switch (status) {
    case ASSESSMENT_STATES.PRE_ASSESSMENT_UNDER_REVIEW:
      return 'blue';
    case ASSESSMENT_STATES.PRE_ASSESSMENT_REJECTED:
      return 'red';
    case ASSESSMENT_STATES.WAITING_FOR_OPERATOR:
      return 'teal';
    case ASSESSMENT_STATES.ASSESSMENT:
      return 'orange';
    case ASSESSMENT_STATES.ASSESSMENT_UNDER_REVIEW:
      return 'orange';
    case ASSESSMENT_STATES.ASSESSMENT_REJECTED:
      return 'red';
    case ASSESSMENT_STATES.ASSESSMENT_POSTPONED:
      return 'yellow';
    case ASSESSMENT_STATES.ASSESSMENT_COMPLETED:
      return 'green';
    default:
      return null;
  }
};

const colorForOnboardingStatus = (status) => {
  switch (status) {
    case 'IN_PROGRESS':
      return 'blue';
    case 'WAITING_FOR_OPERATOR':
      return 'teal';
    case 'COMPLETED':
      return 'green';
    default:
      return null;
  }
};

const DetailsLink = ({uuid, currentUrl}) => {
  window.localStorage.setItem('backLink', currentUrl);

  return (
    <Popup
      trigger={
        <Button
          as={Link}
          to={{
            pathname: `/supplier/details/${uuid}`,
          }}
          size="small"
          primary
          compact
          icon>
          <Icon name="eye" />
        </Button>
      }
      content={<FormattedMessage id="GENERAL_SHOW_DETAILS" />}
      hideOnScroll
      on="hover"
      position="top center"
    />
  );
};

DetailsLink.propTypes = {
  uuid: PropTypes.string.isRequired,
  currentUrl: PropTypes.string.isRequired,
};

const DemandLink = ({uuid}) => (
  <Popup
    trigger={
      <Button
        as={Link}
        to={`/supplier/demandpreview/${uuid}`}
        size="small"
        primary
        compact
        icon>
        <Icon name="chart bar" />
      </Button>
    }
    content={<FormattedMessage id="GENERAL_DEMAND_PREVIEW" />}
    hideOnScroll
    on="hover"
    position="top center"
  />
);

DemandLink.propTypes = {
  uuid: PropTypes.string.isRequired,
};

const AssignedCategory = ({category, locale}) => {
  if (!category || !category.names || !category.externalId) {
    return null;
  }

  const style = {
    textDecoration: 'underline grey',
  };

  return (
    <Popup
      trigger={<span style={style}>{category.externalId}</span>}
      content={locale === 'de' ? category.names[0] : category.names[1]}
    />
  );
};

AssignedCategory.propTypes = {
  category: PropTypes.object,
  locale: PropTypes.string.isRequired,
};

const TableRow = ({supplier, locale, filter, currentUrl}) => (
  <Table.Row
    key={supplier.uuid}
    data-spec={`supplier-table-row-${supplier.uuid}`}>
    <Table.Cell>{get(supplier, 'internalSupplierIds[0]')}</Table.Cell>
    <Table.Cell>{supplier.name}</Table.Cell>
    <Table.Cell>
      <AssignedCategory locale={locale} category={supplier.assignedCategory} />
    </Table.Cell>

    <Table.Cell collapsing>
      <DateFormat date={supplier.createdAt} />
    </Table.Cell>

    {COLUMNS[filter].status && (
      <Table.Cell collapsing>
        <Label color={colorForAssessmentStatus(supplier.status)}>
          <FormattedMessage id={supplier.status} values={{postponeDate: ''}} />
        </Label>
      </Table.Cell>
    )}

    {COLUMNS[filter].onboardingStatus && (
      <Table.Cell collapsing>
        <Label color={colorForOnboardingStatus(supplier.onboardingStatus)}>
          <FormattedMessage id={`ONBOARDING_${supplier.onboardingStatus}`} />
        </Label>
      </Table.Cell>
    )}

    {COLUMNS[filter].preCheckState && (
      <Table.Cell collapsing>
        <FormattedMessage id={`PRE_CHECK_${supplier.preCheckState}`} />
      </Table.Cell>
    )}

    {COLUMNS[filter].testRequestDone && (
      <Table.Cell collapsing>
        <FormattedMessage
          id={supplier.testRequestDone ? 'GENERAL_YES' : 'GENERAL_NO'}
        />
      </Table.Cell>
    )}

    <Table.Cell>
      <DetailsLink uuid={supplier.uuid} currentUrl={currentUrl} />
      <ModuleConditional requiredModule={MODULE_TYPES.DEMAND_PREVIEW}>
        <DemandLink uuid={supplier.uuid} />
      </ModuleConditional>
    </Table.Cell>
  </Table.Row>
);

TableRow.propTypes = {
  supplier: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  currentUrl: PropTypes.string.isRequired,
};

@hasPaging()
class SupplierList extends React.Component {
  parseQuery = (props = this.props) => {
    return qs.parse(props.location.search.substr(1));
  };

  render() {
    const {
      supplierList,
      supplyConfiguration,
      history,
      location,
      locale,
      intl,
    } = this.props;

    if (supplierList.error) {
      return (
        <Message negative>
          <Message.Header>
            <FormattedMessage id="GENERAL_ERROR" />
          </Message.Header>
          <p>{supplierList.error}</p>
        </Message>
      );
    }

    if (supplierList.loading) {
      return (
        <React.Fragment>
          <Helmet>
            <title>{intl.formatMessage({id: 'SUPPLIER_LIST_HEADER'})}</title>
          </Helmet>
          <Loader active />
        </React.Fragment>
      );
    }

    const query = this.parseQuery();
    const firstLevel = query.firstLevel || 'ACTIVE';
    const secondLevel = query.secondLevel;
    const menuItems = get(
      supplyConfiguration,
      'supplyModules.SUPPLIER_DATABASE.configuration.listViews[0].filters'
    );
    const homeItem = menuItems.find((item) => item.type === firstLevel);
    const availableItems = menuItems.filter(
      (item) => item.parent === firstLevel
    );
    const homeLink = `/suppliers/list?${qs.stringify(
      Object.assign({}, query, {secondLevel: undefined})
    )}`;

    const colSpan = COLUMN_COUNTS[firstLevel];
    const currentUrl = `${location.pathname}${location.search}`;

    const content = supplierList.data;
    return (
      <React.Fragment>
        <Helmet>
          <title>{intl.formatMessage({id: 'SUPPLIER_LIST_HEADER'})}</title>
        </Helmet>
        <Menu tabular attached>
          <Menu.Item active={!secondLevel} as={Link} to={homeLink}>
            <Icon name="home" />
            &nbsp;
            <FormattedMessage id={homeItem.nameTranslationKey} />
          </Menu.Item>

          {availableItems.map((item, idx) => (
            <Menu.Item
              key={idx}
              active={secondLevel === item.type}
              as={Link}
              to={`/suppliers/list?${qs.stringify(
                Object.assign({}, query, {
                  secondLevel: item.type,
                })
              )}`}>
              <FormattedMessage id={item.nameTranslationKey} />
            </Menu.Item>
          ))}
        </Menu>

        <Segment attached>
          <Header size="large" as="h1">
            <Header.Content>
              <FormattedMessage id="SUPPLIER_LIST_HEADER" />
              <React.Fragment>
                <Header.Subheader>
                  <FormattedMessage id={'SUPPLIER_LIST_SUB_HEADER'} />
                </Header.Subheader>
              </React.Fragment>
            </Header.Content>
          </Header>
        </Segment>
        <Segment attached="bottom">
          <SupplierFilter history={history} location={location} />
        </Segment>
        <Segment color="purple" id="supplier-table-wrapper">
          <Table singleLine data-spec="supplier-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <FormattedMessage id="INTERNAL_SUPPLIER_ID" />
                </Table.HeaderCell>

                <Table.HeaderCell>
                  <FormattedMessage id="GENERAL_COMPANY" />
                </Table.HeaderCell>

                <Table.HeaderCell>
                  <FormattedMessage id="CATEGORY_SELECTOR_PLACEHOLDER" />
                </Table.HeaderCell>

                <Table.HeaderCell>
                  <FormattedMessage id="DATE_CREATED" />
                </Table.HeaderCell>

                {COLUMNS[firstLevel].status && (
                  <Table.HeaderCell>
                    <FormattedMessage id="GENERAL_STATUS" />
                  </Table.HeaderCell>
                )}

                {COLUMNS[firstLevel].onboardingStatus && (
                  <Table.HeaderCell>
                    <FormattedMessage id="ONBOARDING_STATUS" />
                  </Table.HeaderCell>
                )}

                {COLUMNS[firstLevel].preCheckState && (
                  <Table.HeaderCell width={1}>
                    <FormattedMessage id="PRE_CHECK_STATE" />
                  </Table.HeaderCell>
                )}

                {COLUMNS[firstLevel].testRequestDone && (
                  <Table.HeaderCell width={1}>
                    <FormattedMessage id="TEST_REQUEST" />
                  </Table.HeaderCell>
                )}

                <Table.HeaderCell width={1}>
                  <FormattedMessage id="GENERAL_ACTIONS" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {content.length === 0 ? (
                <EmptyTableState
                  colSpan={colSpan}
                  header="NO_SUPPLIERS_FOUND"
                  subHeader="NO_SUPPLIERS_FOUND_SUB"
                  icon="truck"
                  height="40vh"
                />
              ) : (
                content.map((supplier) => (
                  <TableRow
                    key={supplier.uuid}
                    supplier={supplier}
                    locale={locale}
                    filter={firstLevel}
                    currentUrl={currentUrl}
                  />
                ))
              )}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan={colSpan}>
                  <Pagination paging={supplierList.paging} />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Segment>
      </React.Fragment>
    );
  }
}

SupplierList.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  supplierList: PropTypes.object,
  supplyConfiguration: PropTypes.object,
  locale: PropTypes.string.isRequired,
  intl: PropTypes.object,
};

const mapStateToProps = ({
  supplierList,
  config,
  supplyConfiguration,
  locales,
}) => ({
  supplierList,
  config,
  supplyConfiguration,
  locale: locales.locale,
});

const fetchPageAndCategories = (query) => (dispatch) => {
  dispatch(fetchPage(query));
  dispatch(fetchCategories());
};

const mapDispatchToProps = (dispatch) => ({
  fetchPage: (query) => dispatch(fetchPageAndCategories(query)),
});

const SupplierListWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  const {supplyConfiguration, config} = props;

  const supplierId = supplyConfiguration.supplierId;

  // Using company does not see the supplier list
  if (!config.userConfig.offeringCompany) {
    // Using company with a supplier, redirect to details page
    return <Redirect to={`/supplier/details/${supplierId}`} />;
  } else {
    return <SupplierList {...props} />;
  }
});

export default injectIntl(SupplierListWrapper);
