import {MODULE_TYPES} from '@ecosio/auth';
import {wrappedGET} from '../helpers/fetcher';

// WARNING: if you add mappings here,
// make sure to update the MODULE_TYPES
// in react-auth
export const MODULE_URLS = [
  {path: '/documents', module: MODULE_TYPES.DMS},
  {path: '/suppliers', module: MODULE_TYPES.SUPPLIER_DATABASE},
  {path: '/supplier', module: MODULE_TYPES.SUPPLIER_DATABASE},
  {path: '/demandpreview', module: MODULE_TYPES.DEMAND_PREVIEW},
  {path: '/issues', module: MODULE_TYPES.ISSUE_TRACKER},
  {path: '/quality-assurance', module: MODULE_TYPES.QUALITY_ASSURANCE},
  {path: '/ideas', module: MODULE_TYPES.IDEAS},
  {path: '/masterdata/document-upload', module: MODULE_TYPES.MASTERDATA},
  {path: '/', module: MODULE_TYPES.DMS},
];

export const moduleFromUrl = (location) => {
  const pathname = location.pathname;

  for (const {path, module} of MODULE_URLS) {
    if (pathname.startsWith(path)) {
      return module;
    }
  }
  // Fallback to document management (see also RedirectToDrawings in AppRouter.jsx)
  return MODULE_TYPES.DMS;
};

const TYPES = {
  REQUEST: 'supplyConfiguration/request',
  RESPONSE: 'supplyConfiguration/response',
  ERROR: 'supplyConfiguration/error',
};

const fetchConfigurationRequest = () => ({
  type: TYPES.REQUEST,
});

const fetchConfigurationResponse = (data) => ({
  type: TYPES.RESPONSE,
  data,
});

const fetchConfigurationError = (error) => ({
  type: TYPES.ERROR,
  error,
});

export const fetchConfiguration = (offeringCompanyId) => (dispatch) => {
  dispatch(fetchConfigurationRequest());
  return wrappedGET(`/api/configuration?offeringCompanyId=${offeringCompanyId}`)
    .then((res) => {
      if (res.status !== 200) {
        dispatch(fetchConfigurationError(res.statusText));
      } else {
        dispatch(fetchConfigurationResponse(res.data));
      }
    })
    .catch((error) => {
      if (error.response.status === 417) {
        dispatch(fetchConfigurationError(417));
        return;
      }
      dispatch(fetchConfigurationError(error.message));
    });
};

const initialState = {
  // Map from WebModuleType -> WebModule
  supplyModules: null,
  supplierId: null,
  error: null,
  loading: true,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case TYPES.RESPONSE:
      return {
        ...state,
        loading: false,
        error: null,
        ...action.data,
      };
    case TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
