export const userLinks = [
  /*
  {
    name: 'GENERAL_PASSWORDRESET',
    slug: 'password-reset',
    render: true,
    target: '_blank',
    key: 1
  }
  */
  // not yet available
  /*
  {
    name: 'GENERAL_NOTIFICATIONS',
    slug: 'notifications',
    render: true,
    target: '_blank',
    key: 2
  },
  */
];

export const adminLinks = [
  {
    name: 'GENERAL_USERMANAGEMENT',
    slug: 'user-management',
    render: true,
    target: '_blank',
    key: 3,
  },
  {
    name: 'GENERAL_COMPANYDETAILS',
    slug: 'company-details',
    render: true,
    target: '_blank',
    key: 4,
  },
  {
    name: 'USER_DD_LOGOUPLOAD',
    slug: 'user-logo-upload',
    render: true,
    target: '_blank',
    key: 5,
  },
];
