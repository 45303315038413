import React, {Component} from 'react';
import {
  Segment,
  Button,
  Grid,
  Header,
  GridColumn,
  GridRow,
} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {injectIntl, FormattedMessage} from 'react-intl';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import MasterdataMessage, {formatMessage} from './MasterdataMessage';

const mapStateToProps = ({supplyConfiguration}) => {
  return {config: supplyConfiguration};
};

@connect(mapStateToProps)
class SendToHubStatus extends Component {
  constructor(props) {
    super(props);
    this.state = '';
  }

  render() {
    const {intl, config, history} = this.props;
    const uploadFileName = history.location.state;
    return (
      <Segment.Group>
        <Helmet>
          <title>
            {formatMessage(
              intl,
              'SUCCESSFULLY_SENT_STATUS_PAGE_TAB_TITLE',
              config
            )}
          </title>
        </Helmet>
        <Segment color="purple">
          <Header as="h1">
            <MasterdataMessage translationKey="DOCUMENT_SENT_SUCCESSFULLY" />
          </Header>
        </Segment>
        <Segment>
          <Grid>
            <GridRow>
              <GridColumn width={8}>
                <Segment attached clearing>
                  <Header as="h3">
                    <MasterdataMessage translationKey="DOCUMENT_SENT_SUCCESSFULLY_STATUS_PAGE_BODY" />
                  </Header>
                  <Header as="h5">
                    <FormattedMessage
                      id={'SUCCESSFULLY_SENT_FILE'}
                      values={{fileName: uploadFileName}}
                    />
                  </Header>
                  <Link to={`/masterdata/document-upload`}>
                    <Button color="green">
                      <MasterdataMessage translationKey="BUTTON_NAME_ON_SUCCESS_PAGE" />
                    </Button>
                  </Link>
                </Segment>
              </GridColumn>
            </GridRow>
          </Grid>
        </Segment>
      </Segment.Group>
    );
  }
}

SendToHubStatus.propTypes = {
  intl: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default injectIntl(SendToHubStatus);
