import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

export const BodyWrapper = ({body}) => {
  return body ? (
    <div style={{whiteSpace: 'pre-wrap'}} data-spec="body">
      {body}
    </div>
  ) : (
    <p>
      <FormattedMessage id="ISSUE_TEXT_PLACEHOLDER" />
    </p>
  );
};

BodyWrapper.propTypes = {
  body: PropTypes.string,
};

export const translateValue = (obj, locale) => {
  if (!obj) {
    return null;
  } else {
    const localeFixed = typeof locale === 'string' ? locale : 'de';
    return typeof obj[localeFixed] === 'string' ? obj[localeFixed] : obj.de;
  }
};

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
