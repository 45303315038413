import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Loader, Message} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {shapes} from '@ecosio/auth';
import {Redirect} from 'react-router-dom';
import {wrappedGET, wrappedPUT, wrappedPOST} from '../../helpers/fetcher';
import GeneralIssueView from './IssueTracker/GeneralIssueView';
import SpecialReleaseView from './QualityAssurance/SpecialRelease/SpecialReleaseView';
import ArticleInspectionView from './QualityAssurance/ArticleInspection/ArticleInspectionView';
import ComplaintView from './QualityAssurance/Complaint/ComplaintView';
import IdeasView from './Ideas/IdeasView';
import {supportedIssueTypeShape} from './shapes';

const isQualityAssurance = (issue) => {
  const type = issue.issueType;

  return (
    type === 'COMPLAINT' ||
    type === 'SPECIAL_RELEASE' ||
    type === 'ARTICLE_INSPECTION'
  );
};

class IssueModuleView extends Component {
  static propTypes = {
    match: PropTypes.any,
    config: shapes.config,
  };

  state = {
    loading: true,
    issue: null,
    error: null,
    editedCommentUuid: null,
    modalVisible: false,
  };

  componentDidMount() {
    return this.loadIssue();
  }

  onSubmit = (values) => {
    const request = {
      uuid: values.uuid,
      state: values.state,
      assigneeUuid: values.assignee?.uuid,
      complaintCompletionFreeText:
        values?.complaintData?.complaintCompletionFreeText,
      complaintState: values?.complaintData?.complaintState,
      partialApprovalUntil: values?.articleInspectionData?.partialApprovalUntil,
      articleInspectionState:
        values?.articleInspectionData?.articleInspectionState,
      articleInspectionCompletionFreeText:
        values?.articleInspectionData?.articleInspectionCompletionFreeText,
      specialReleaseState: values?.specialReleaseData?.specialReleaseState,
      specialReleaseFreeText:
        values?.specialReleaseData?.specialReleaseFreeText,
    };

    wrappedPUT('/api/issues', request)
      .then((res) => {
        this.onTriggerModal();
        this.setState({
          issue: res.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  loadIssue = () => {
    const {match} = this.props;
    const id = match.params.id;
    return wrappedGET(`/api/issues/${id}`)
      .then((res) => {
        this.setState({issue: res.data, loading: false, error: null});
      })
      .catch((e) => {
        console.error(e);
        this.setState({error: e, loading: false, issue: null});
      });
  };

  onUpdateIssue = (issue) => {
    this.setState({
      issue,
      loading: false,
      error: null,
    });
  };

  getIssueTypeConfig = (type) => {
    const url = this.props.match.url;
    let supportedIssueTypes;
    if (url.includes('issues')) {
      supportedIssueTypes = this.props.supplyConfiguration.supplyModules
        .ISSUE_TRACKER.configuration.issueType;
      return supportedIssueTypes;
    } else if (url.includes('ideas')) {
      supportedIssueTypes = this.props.supplyConfiguration.supplyModules.IDEAS
        .configuration.issueType;
      return supportedIssueTypes;
    } else {
      supportedIssueTypes = this.props.supplyConfiguration.supplyModules
        .QUALITY_ASSURANCE.configuration.supportedIssueTypes;
      return supportedIssueTypes.find((item) => item.issueType === type);
    }
  };

  onEditComment = (commentUuid) => {
    this.setState({
      editedCommentUuid: commentUuid,
    });
  };

  onTriggerModal = () => {
    const {modalVisible} = this.state;
    this.setState({modalVisible: !modalVisible});
  };

  onFollow = () => {
    wrappedPOST(`/api/issues/${this.state.issue.uuid}/follow`)
      .then((result) => {
        this.setState({issue: result.data});
      })
      .catch((err) => {
        console.error(err);
      });
  };

  onUnFollow = () => {
    wrappedPOST(`/api/issues/${this.state.issue.uuid}/unfollow`)
      .then((result) => {
        this.setState({issue: result.data});
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    const {type} = this.props.match.params;
    const {config, locale} = this.props;
    const {issue, loading, error, editedCommentUuid, modalVisible} = this.state;
    if (loading) {
      return <Loader active />;
    }
    if (error) {
      return (
        <Message error>
          <Message.Header>
            <FormattedMessage id="GENERAL_ERROR" />
          </Message.Header>
          <p>{error.message}</p>
        </Message>
      );
    }

    const {offeringCompany} = config.userConfig;

    if (offeringCompany && !issue.assignee?.uuid && isQualityAssurance(issue)) {
      return (
        <Redirect
          to={`/quality-assurance/edit/${issue.issueNumber}?issueType=${issue.issueType}`}
        />
      );
    }

    switch (issue.issueType.toLowerCase()) {
      case 'complaint':
        return (
          <ComplaintView
            {...this.props}
            template={this.getIssueTypeConfig('COMPLAINT')}
            locale={locale}
            onTriggerModal={this.onTriggerModal}
            modalVisible={modalVisible}
            issue={issue}
            loadIssue={this.loadIssue}
            onUpdateIssue={this.onUpdateIssue}
            onEditComment={this.onEditComment}
            editedCommentUuid={editedCommentUuid}
            config={config}
            onSubmit={this.onSubmit}
            onFollow={this.onFollow}
            onUnfollow={this.onUnFollow}
          />
        );
      case 'special_release':
        return (
          <SpecialReleaseView
            {...this.props}
            template={this.getIssueTypeConfig('SPECIAL_RELEASE')}
            locale={locale}
            issue={issue}
            loadIssue={this.loadIssue}
            onTriggerModal={this.onTriggerModal}
            modalVisible={modalVisible}
            onSubmit={this.onSubmit}
            config={config}
            onFollow={this.onFollow}
            onUnfollow={this.onUnFollow}
            onUpdateIssue={this.onUpdateIssue}
          />
        );
      case 'article_inspection':
        return (
          <ArticleInspectionView
            {...this.props}
            template={this.getIssueTypeConfig('ARTICLE_INSPECTION')}
            locale={locale}
            onTriggerModal={this.onTriggerModal}
            modalVisible={modalVisible}
            issue={issue}
            loadIssue={this.loadIssue}
            onUpdateIssue={this.onUpdateIssue}
            onEditComment={this.onEditComment}
            editedCommentUuid={editedCommentUuid}
            config={config}
            onSubmit={this.onSubmit}
            onFollow={this.onFollow}
            onUnfollow={this.onUnFollow}
          />
        );
      case 'free_form':
        return (
          <GeneralIssueView
            {...this.props}
            template={this.getIssueTypeConfig('FREE_FORM')}
            issue={issue}
            locale={locale}
            loadIssue={this.loadIssue}
            onTriggerModal={this.onTriggerModal}
            modalVisible={modalVisible}
            onSubmit={this.onSubmit}
            config={config}
            onFollow={this.onFollow}
            onUnfollow={this.onUnFollow}
          />
        );
      case 'ideas':
        return (
          <IdeasView
            {...this.props}
            template={this.getIssueTypeConfig('IDEAS')}
            issue={issue}
            locale={locale}
            loadIssue={this.loadIssue}
            onTriggerModal={this.onTriggerModal}
            modalVisible={modalVisible}
            onSubmit={this.onSubmit}
            config={config}
            onFollow={this.onFollow}
            onUnfollow={this.onUnFollow}
          />
        );
      default:
        throw new Error(`Unknown issue type: ${type.id}`);
    }
  }
}

IssueModuleView.propTypes = {
  supplyConfiguration: supportedIssueTypeShape,
  locale: PropTypes.string,
};

const mapStateToProps = ({config, locales, supplyConfiguration}) => ({
  config,
  supplyConfiguration,
  locale: locales.locale,
});

export default connect(mapStateToProps, null)(IssueModuleView);
