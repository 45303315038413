import React from 'react';
import {Redirect, Route, Router, Switch} from 'react-router-dom';
import {Loader} from 'semantic-ui-react';
import {useConfig} from '@ecosio/auth';
import PropTypes from 'prop-types';
import axios from 'axios';
import Layout from './components/Layout/index';
import DrawingList from './components/DMS/Drawings/DrawingList';
import DrawingDetails from './components/DMS/Drawings/DrawingDetails';
import SupplierList from './components/SupplierDB/SupplierList';
import SupplierDetails from './components/SupplierDetails';
import DemandPreview from './components/DemandPreview';
import {MODULE_URLS} from './reducers/configuration';
import IssueModuleList from './components/IssueModules/IssueModuleList';
import IssueModuleView from './components/IssueModules/IssueModuleView';
import IssueModuleEdit from './components/IssueModules/IssueModuleEdit';
import GeneralIssueCreate from './components/IssueModules/IssueTracker/GeneralIssueCreate';
import IdeasCreate from './components/IssueModules/Ideas/IdeasCreate';
import DocumentList from './components/DMS/Documents/DocumentList';
import CreateDocument from './components/DMS/Documents/CreateDocument';
import DocumentDetails from './components/DMS/Documents/DocumentDetails';
import EditDocument from './components/DMS/Documents/EditDocument';
import SpecialReleaseCreate from './components/IssueModules/QualityAssurance/SpecialRelease/SpecialReleaseCreate';
import DocumentUpload from './components/Masterdata/DocumentUploadPage';
import DocumentUploadStatus from './components/Masterdata/SendToHubStatus';
import MappingLogFailedStatus from './components/Masterdata/MappingLogFailedStatus';

const allowedPaths = [];

const NotFound = () => <p>404 - Not found</p>;

const RedirectToDrawings = () => <Redirect to="/documents/drawings/list" />;
/**
 * *************** IMPORTANT ***********
 * When you change/remove/add routes here, you need to fix the nginx.conf
 * file for this app in the backend/src/main/resources folder.
 *
 */

const parseModules = (rawString) => {
  try {
    if (Array.isArray(rawString)) {
      return rawString;
    }

    if (typeof rawString === 'undefined') {
      return [];
    }

    return JSON.parse(rawString);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const loadCustomConfig = async () => {
  const userConfig = await axios
    .get('/api/client-config')
    .then((result) => result.data);

  let enabledModules = '[]';
  try {
    const enabledModulesResult = await axios.get(
      '/api/client-config/enabled-modules'
    );
    enabledModules = enabledModulesResult?.data;
  } catch (error) {
    console.error(error);
  }

  const modules = parseModules(enabledModules);
  return Object.assign(userConfig, {modules});
};

const AppRouter = ({history}) => {
  const {loading} = useConfig({
    allowedPaths,
    routeModuleMap: MODULE_URLS,
    loadCustomConfig,
  });

  if (loading) {
    return <Loader active />;
  }

  return (
    <Router history={history}>
      <Layout>
        <Switch>
          <Route exact path="/demandpreview" component={DemandPreview} />
          <Route
            exact
            path="/documents/general/list"
            component={DocumentList}
          />
          <Route
            exact
            path="/documents/general/create"
            component={CreateDocument}
          />
          <Route
            path="/documents/general/details/:uuid"
            component={DocumentDetails}
          />
          <Route
            path="/documents/general/edit/:uuid"
            component={EditDocument}
          />
          <Route
            exact
            path="/documents/drawings/list"
            component={DrawingList}
          />
          <Route
            path="/documents/drawings/details/:uuid"
            component={DrawingDetails}
          />
          <Route path="/suppliers/list" component={SupplierList} />
          <Route
            path="/supplier/details/:uuid/:page?"
            component={SupplierDetails}
          />
          <Route
            path="/supplier/demandpreview/:uuid"
            exact
            component={DemandPreview}
          />
          <Route exact path="/" component={RedirectToDrawings} />

          <Route exact path="/issues" component={IssueModuleList} />
          <Route path="/issues/create" component={GeneralIssueCreate} />
          <Route path="/issues/view/:id" component={IssueModuleView} />
          <Route path="/issues/edit/:id" component={IssueModuleEdit} />

          <Route exact path="/quality-assurance" component={IssueModuleList} />
          <Route
            path="/quality-assurance/create"
            component={SpecialReleaseCreate}
          />
          <Route
            path="/quality-assurance/view/:id"
            component={IssueModuleView}
          />
          <Route
            path="/quality-assurance/edit/:id"
            component={IssueModuleEdit}
          />

          <Route exact path="/ideas" component={IssueModuleList} />
          <Route path="/ideas/create" component={IdeasCreate} />
          <Route path="/ideas/view/:id" component={IssueModuleView} />
          <Route path="/ideas/edit/:id" component={IssueModuleEdit} />

          <Route
            exact
            path="/masterdata/document-upload"
            component={DocumentUpload}
          />
          <Route
            path="/masterdata/document-upload/status"
            component={DocumentUploadStatus}
          />
          <Route
            path="/masterdata/document-upload/mapping-log-status"
            component={MappingLogFailedStatus}
          />

          <Route path="*" component={NotFound} />
        </Switch>
      </Layout>
    </Router>
  );
};

AppRouter.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AppRouter;
