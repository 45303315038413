import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import {Segment, Button, Header} from 'semantic-ui-react';
import {Form as PathForm, FormGroup, Input} from '@ecosio/pathform';
import {Link} from 'react-router-dom';
import {FormattedMessage as Msg} from 'react-intl';
import AttachmentList from '../../AttachmentList';
import OrderSelector from '../../OrderSelector';
import {issueShape} from '../../IssueTracker/shapes';

class ComplaintEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attachments: [],
    };
  }

  onUpload = (files) => {
    this.setState((prevState) => ({
      attachments: prevState.attachments.concat(files.map((f) => ({file: f}))),
    }));
  };

  render() {
    const {issue, fields, onSubmit, onAttachmentRemoved} = this.props;
    const {attachments} = this.state;
    return (
      <PathForm
        fields={fields}
        initialValues={issue}
        onSubmit={(values) => onSubmit(values, 'COMPLAINT', null, attachments)}>
        {(ctx) => (
          <Fragment>
            <Segment.Group>
              <Segment color="purple">
                <Header size="large">
                  <Header.Content>
                    <Msg id="GENERAL_COMPLAINT" />:{' '}
                    {issue.complaintData.externalComplaintReference}
                  </Header.Content>
                </Header>
                <Input name="assignee.uuid" path="assignee.uuid" width="4" />
              </Segment>
              <Segment clearing>
                <FormGroup>
                  <Input name="subject" path="subject" width="16" />
                </FormGroup>
                <FormGroup>
                  <Input name="body" path="body" width="16" />
                </FormGroup>
                <AttachmentList
                  onUpload={this.onUpload}
                  attachments={issue?.attachments}
                  onRemove={onAttachmentRemoved}
                />
              </Segment>
            </Segment.Group>
            <Segment.Group>
              <Segment>
                <Header>
                  <Msg id="GENERAL_GENERAL" />
                </Header>
                <FormGroup widths="equal">
                  <Input
                    path="complaintData.complaintType"
                    name="complaintData.complaintType"
                  />
                  <Input
                    path="complaintData.errorType"
                    name="complaintData.errorType"
                  />
                  <Input
                    path="complaintData.errorLocation"
                    name="complaintData.errorLocation"
                  />
                </FormGroup>
              </Segment>
              <Segment>
                <Header>
                  <Msg id="RESPONSIBLE_OFFERING_EMPLOYEE" />
                </Header>
                <FormGroup widths="equal">
                  <Input
                    path="offeringCompanyAssignee.name"
                    name="offeringCompanyAssignee.name"
                  />
                  <Input
                    path="offeringCompanyAssignee.email"
                    name="offeringCompanyAssignee.email"
                  />
                  <Input
                    path="offeringCompanyAssignee.phoneNumber"
                    name="offeringCompanyAssignee.phoneNumber"
                  />
                </FormGroup>
              </Segment>
              <Segment>
                <Header>
                  <Msg id="GENERAL_ORDER_INFORMATION" />
                </Header>
                <FormGroup widths="equal">
                  <OrderSelector ctx={ctx} disabled={true} />
                  <Input name="factoryIdentifier" path="factoryIdentifier" />
                </FormGroup>
                <FormGroup widths="equal">
                  <Input
                    name="complaintData.orderDate"
                    path="complaintData.orderDate"
                    stripTimezone={false}
                  />
                  <Input
                    name="complaintData.orderQuantity"
                    path="complaintData.orderQuantity"
                  />
                  <Input
                    name="complaintData.complaintQuantity"
                    path="complaintData.complaintQuantity"
                  />
                </FormGroup>
              </Segment>
              <Segment>
                <Header>
                  <Msg id="GENERAL_ARTICLE_INFORMATION" />
                </Header>
                <FormGroup widths="equal">
                  <Input name="materialNumber" path="materialNumber" />
                  <Input name="drawingRevision" path="drawingRevision" />
                  <Input
                    name="supplierMaterialNumber"
                    path="supplierMaterialNumber"
                  />
                </FormGroup>
                <FormGroup widths="equal">
                  <Input
                    name="materialShortText.de"
                    path="materialShortText.de"
                  />
                </FormGroup>
              </Segment>
              <Segment clearing>
                <Header>
                  <Msg id="CAUSED_COSTS_THROUGH_COMPLAINTS" />
                </Header>
                <FormGroup widths="equal">
                  <Input
                    name="complaintData.complaintCosts.totalCost"
                    path="complaintData.complaintCosts.totalCost"
                  />
                  <Input
                    name="complaintData.complaintCosts.supplierCost"
                    path="complaintData.complaintCosts.supplierCost"
                  />
                </FormGroup>
                <FormGroup widths="equal">
                  <Input
                    name="complaintData.complaintCosts.description"
                    path="complaintData.complaintCosts.description"
                  />
                </FormGroup>
                <Button
                  type="button"
                  negative
                  floated="right"
                  as={Link}
                  to={`/quality-assurance/view/${issue?.issueNumber}?issueType=${issue?.issueType}`}>
                  <Msg id="GENERAL_CANCEL" />
                </Button>
                <Button type="submit" positive floated="right">
                  <Msg id="GENERAL_SAVE" />
                </Button>
              </Segment>
            </Segment.Group>
          </Fragment>
        )}
      </PathForm>
    );
  }
}

ComplaintEdit.propTypes = {
  issue: issueShape,
  //TODO: specify
  fields: PropTypes.any,
  onSubmit: PropTypes.func,
  onUpload: PropTypes.func,
};

export default ComplaintEdit;
