import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Segment,
  Button,
  Header,
  Grid,
  Loader,
  Modal,
  FormGroup,
} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {FormattedMessage as Msg} from 'react-intl';
import {Form as PathForm, Input, ExternalSubmit} from '@ecosio/pathform';
import {Attachment} from '@ecosio/components';
import {shapes} from '@ecosio/auth';
import {BoldContent} from '../../IssueTracker/IssueTrackerHelpers';
import CommentCreate from '../../CommentCreate';
import CommentList from '../../CommentList';
import {issueShape} from '../../IssueTracker/shapes';
import {FieldContent, SubHeaderMeta} from '../../../FormHelpers';
import {BodyWrapper, ButtonRow, translateValue} from '../Common';
import {StateLabel} from '../../StateLabel';
import DownloadIssueButton from '../DownloadIssueButton';
import DateFormat from '../../../DateFormat';
import {CONTEXT_PATH} from '../../../../constants';
import FavoriteButton from '../../FavoriteButton';
import FavoriteList from '../../FavoriteList';
import configShape from '../../../../shapes';
import ReopenIssueButton from '../ReopenIssueButton';

class SpecialReleaseView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {editedCommentUuid: null};
  }

  onEditComment = (commentUuid) => {
    this.setState({
      editedCommentUuid: commentUuid,
    });
  };

  render() {
    const {editedCommentUuid} = this.state;
    const {
      locale,
      config,
      issue,
      loadIssue,
      modalVisible,
      onTriggerModal,
      onSubmit,
      template,
      onFollow,
      onUnfollow,
      onUpdateIssue,
    } = this.props;
    const offeringCompany = config.userConfig.offeringCompany;
    const {
      editIssueHeaderOfferingCompany,
      editIssueHeaderUsingCompany,
    } = template;

    if (!issue) {
      return <Loader active />;
    }
    const fields = {
      'specialReleaseData.specialReleaseState': {
        path: 'specialReleaseData.specialReleaseState',
        type: 'dropdown',
        options: [
          {
            text: 'SPECIAL_RELEASES_APPROVED',
            value: 'APPROVED',
          },
          {
            text: 'SPECIAL_RELEASES_REJECTED',
            value: 'REJECTED',
          },
        ],
        input: {
          label: 'SPECIAL_RELEASES_STATUS',
          translateOptions: true,
        },
      },
      'specialReleaseData.specialReleaseFreeText': {
        path: 'specialReleaseData.specialReleaseFreeText',
        type: 'textarea',
        input: {
          label: 'SPECIAL_RELEASES_COMPLETION_FREETEXT',
          type: 'text',
        },
      },
    };
    return (
      <div style={{marginBottom: '120px'}}>
        <Segment.Group>
          <Segment color="purple">
            <Header size="large">
              <Header.Content>
                <StateLabel state={issue?.state} />{' '}
                <Msg id="GENERAL_SPECIAL_RELEASE" />
                <Header.Subheader data-spec="subject">
                  {' '}
                  {issue.subject}
                </Header.Subheader>
              </Header.Content>
            </Header>
            <SubHeaderMeta
              state={issue?.state}
              assignee={issue?.assignee?.name}
              closedAt={issue?.closedAt}
              closedBy={issue?.closedBy}
            />
          </Segment>
          <SpecialReleaseButtons
            offeringCompany={offeringCompany}
            editIssueHeaderOfferingCompany={editIssueHeaderOfferingCompany}
            editIssueHeaderUsingCompany={editIssueHeaderUsingCompany}
            modalVisible={modalVisible}
            onTriggerModal={onTriggerModal}
            onSubmit={onSubmit}
            issue={issue}
            fields={fields}
            locale={locale}
            onFollow={onFollow}
            onUnfollow={onUnfollow}
            config={config}
            onUpdateIssue={onUpdateIssue}
          />
        </Segment.Group>
        <Segment.Group>
          <Segment color="purple">
            <Header size="large">
              <Header.Content>
                {issue.subject}
                <Header.Subheader>
                  <span style={{paddingRight: '40px'}}>
                    {issue.author.name}
                  </span>
                  <span>
                    <DateFormat date={issue.createdAt} />
                  </span>
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Segment>
          <Segment>
            <SpecialReleaseFields
              locale={locale}
              issue={issue}
              config={config}
            />
          </Segment>
          <Segment clearing>
            <BodyWrapper body={issue.body} />
            <div style={{marginTop: '20px'}}>
              {issue.attachments.length > 0 ? (
                <BoldContent>
                  <Msg id="GENERAL_DOWNLOAD" />:
                </BoldContent>
              ) : null}
              <div>
                <Attachment
                  attachments={issue?.attachments}
                  contextPath={CONTEXT_PATH}
                />
              </div>
            </div>
          </Segment>
        </Segment.Group>
        <Grid>
          <CommentList
            userID={config.userConfig.email}
            onLoadIssue={loadIssue}
            issue={issue}
            onEditComment={this.onEditComment}
            editedCommentUuid={editedCommentUuid}
            isOfferingCompany={offeringCompany}
          />
          <CommentCreate onLoadIssue={loadIssue} issue={issue} />
        </Grid>
      </div>
    );
  }
}

SpecialReleaseView.propTypes = {
  loadIssue: PropTypes.func,
  modalVisible: PropTypes.bool,
  onTriggerModal: PropTypes.func,
  onSubmit: PropTypes.func,
  //TODO specify
  template: PropTypes.any,
  issue: issueShape,
  config: shapes.userConfig,
  match: PropTypes.any,
  locale: PropTypes.string.isRequired,
  onFollow: PropTypes.func.isRequired,
  onUnfollow: PropTypes.func.isRequired,
};

const SpecialReleaseFields = ({issue, locale, config}) => {
  return (
    <Segment.Group>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header>
                <Msg id="GENERAL_GENERAL" />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              <BoldContent>
                <Msg id="GENERAL_SUPPLIER" />:
              </BoldContent>
              <p>
                <Link to={`/supplier/details/${issue?.supplier?.uuid}`}>
                  {issue?.supplier?.name}
                </Link>
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header>
                <Msg id="GENERAL_ORDER_INFORMATION" />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              <FieldContent value={issue.orderNumber} label="GENERAL_ORDER" />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                value={issue.orderPosition}
                label="GENERAL_ORDER_POSITION"
              />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                value={issue.factoryIdentifier}
                label="GENERAL_FACTORY_IDENTIFIER"
              />
            </Grid.Column>
          </Grid.Row>
          {config?.userConfig?.offeringCompany && (
            <Grid.Row>
              <Grid.Column>
                <FavoriteList issue={issue} />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Segment>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header>
                <Msg id="GENERAL_ARTICLE_INFORMATION" />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              <FieldContent
                value={issue.materialNumber}
                label="GENERAL_CUSTOMER_MATERIAL"
              />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                value={issue.supplierMateriaNumber}
                label="GENERAL_SUPPLIER_MATERIAL"
              />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                value={issue.drawingRevision}
                label="GENERAL_DRAWING_REVISION"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <FieldContent
                value={translateValue(issue?.materialShortText, locale)}
                label="MATERIAL_SHORT_TEXT"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Segment.Group>
  );
};

SpecialReleaseFields.propTypes = {
  issue: issueShape,
  locale: PropTypes.string.isRequired,
  config: configShape.isRequired,
};

const SpecialReleaseButtons = ({
  modalVisible,
  onTriggerModal,
  onSubmit,
  issue,
  fields,
  editIssueHeaderUsingCompany,
  editIssueHeaderOfferingCompany,
  offeringCompany,
  locale,
  onFollow,
  onUnfollow,
  config,
  onUpdateIssue,
}) => {
  let ButtonBar;
  const onReopen = (issue) => {
    onUpdateIssue(issue);
  };

  const onError = (error) => {
    console.error(error);
  };

  if (issue.state === 'CLOSED') {
    ButtonBar = (
      <Segment clearing>
        <ButtonRow>
          <div>
            <StateLabel
              state={issue?.specialReleaseData?.specialReleaseState}
            />
            <FieldContent
              inline
              dataSpec="stateDescription"
              value={issue?.specialReleaseData?.specialReleaseFreeText}
              leftCaption={
                <Fragment>
                  {' '}
                  <span style={{fontWeight: '700'}}>
                    <Msg id="GENERAL_DESCRIPTION" />:{' '}
                  </span>
                </Fragment>
              }
            />
          </div>
          <div>
            <DownloadIssueButton
              issueUuid={issue.uuid}
              closed={true}
              locale={locale}
              isOfferingCompany={offeringCompany}
            />
            <FavoriteButton
              issue={issue}
              config={config}
              onFollow={onFollow}
              onUnfollow={onUnfollow}
            />
            <ReopenIssueButton
              issue={issue}
              isOfferingCompany={offeringCompany}
              onReopenSuccess={onReopen}
              onReopenError={onError}
            />
          </div>
        </ButtonRow>
      </Segment>
    );
  } else if (
    (offeringCompany && editIssueHeaderOfferingCompany) ||
    (!offeringCompany && editIssueHeaderUsingCompany)
  ) {
    ButtonBar = (
      <Segment clearing>
        <DownloadIssueButton
          issueUuid={issue.uuid}
          closed={false}
          locale={locale}
          isOfferingCompany={offeringCompany}
        />
        <Button
          floated="right"
          primary
          as={Link}
          to={`/quality-assurance/edit/${issue.issueNumber}?issueType=${issue.issueType}`}>
          <Msg id="GENERAL_EDIT_TICKET" />
        </Button>
        {offeringCompany && (
          <Button
            floated="right"
            primary
            onClick={onTriggerModal}
            data-spec="openModal">
            <Msg id="STATUS_ASSIGNEE_BUTTON_CONTENT" />
          </Button>
        )}
        <FavoriteButton
          issue={issue}
          config={config}
          onFollow={onFollow}
          onUnfollow={onUnfollow}
        />
        {offeringCompany && (
          <Modal
            open={modalVisible}
            closeOnDimmerClick={true}
            onClose={onTriggerModal}>
            <Modal.Header>
              <Msg id="EDIT_TICKET_STATUS" />
            </Modal.Header>
            <Modal.Content>
              <PathForm
                onSubmit={onSubmit}
                initialValues={issue}
                formid="modal-autoCompleteDropdown"
                fields={fields}>
                {() => (
                  <Fragment>
                    <FormGroup widths="equal">
                      <Input
                        name="specialReleaseData.specialReleaseState"
                        path="specialReleaseData.specialReleaseState"
                        translateOptions={true}
                      />
                    </FormGroup>
                    <FormGroup widths="equal">
                      <Input
                        name="specialReleaseData.specialReleaseFreeText"
                        path="specialReleaseData.specialReleaseFreeText"
                      />
                    </FormGroup>
                  </Fragment>
                )}
              </PathForm>
            </Modal.Content>
            <Modal.Actions>
              <ExternalSubmit
                formid="modal-autoCompleteDropdown"
                positive
                data-spec="modalSubmit">
                <Msg id="QA_TICKET_SET_TO_COMPLETE" />
              </ExternalSubmit>
              <ExternalSubmit negative onClick={onTriggerModal}>
                <Msg id="GENERAL_CANCEL" />
              </ExternalSubmit>
            </Modal.Actions>
          </Modal>
        )}
      </Segment>
    );
  }
  return <Fragment>{ButtonBar}</Fragment>;
};

SpecialReleaseButtons.propTypes = {
  issue: issueShape,
  locale: PropTypes.string,
  onTriggerModal: PropTypes.func,
  modalVisible: PropTypes.bool,
  onSubmit: PropTypes.func,
  fields: PropTypes.object,
  editIssueHeaderUsingCompany: PropTypes.bool,
  editIssueHeaderOfferingCompany: PropTypes.bool,
  offeringCompany: PropTypes.bool,
};

export default SpecialReleaseView;
