import React from 'react';
import qs from 'qs';
import {Header, Button, Popup, Segment, Form} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {Form as PathForm, Input, FormGroup, Reset} from '@ecosio/pathform';
import {connect} from 'react-redux';
import AssignedCategorySelector from '../../SupplierDetails/PageHeader/AssignedCategorySelector';

const DEFAULT_FILTER = {
  sort: 'createdAt,desc',
};

const fields = {
  query: {
    path: 'query',
    type: 'text',
    input: {
      type: 'text',
      disabled: false,
    },
  },
  createdFrom: {
    path: 'createdFrom',
    type: 'date',
    input: {
      type: 'text',
      label: 'CREATED_FROM',
      onlyDateValue: true,
    },
  },
  createdTo: {
    path: 'createdTo',
    type: 'date',
    input: {
      type: 'text',
      label: 'CREATED_TO',
      onlyDateValue: true,
    },
  },
  categoryId: {
    path: 'categoryId',
    type: 'dropdown',
    input: {
      type: 'text',
      label: 'ASSIGNED_CATEGORY_ID',
    },
  },
  supplierId: {
    path: 'supplierId',
    type: 'dropdown-autocomplete',
    input: {
      url: '/api/suppliers/suggest',
      label: 'GENERAL_SUPPLIER_NAME',
    },
  },
};

const AdvancedFilter = ({offeringCompany, locale, ...props}) => (
  <Popup {...props} position="bottom right" on="click" wide="very">
    <div style={{minWidth: '600px'}}>
      <Segment>
        <Header as="h3">
          <Header.Content>
            <FormattedMessage id="GENERAL_FILTERS" />
          </Header.Content>
        </Header>
        <div className="ui form">
          <FormGroup>
            <Input
              name="createdFrom"
              path="createdFrom"
              stripTimezone={false}
              width={8}
            />
            <Input
              name="createdTo"
              path="createdTo"
              stripTimezone={false}
              width={8}
            />
          </FormGroup>

          {offeringCompany && (
            <>
              <FormGroup widths="equal">
                <AssignedCategorySelector locale={locale} name="categoryId" />
              </FormGroup>
              <FormGroup widths="equal">
                <Input name="supplierId" path="supplierId" />
              </FormGroup>
            </>
          )}

          <FormGroup>
            <Form.Field>
              <label />
              <Reset floated="right" resetValues={{}}>
                <FormattedMessage id="GENERAL_RESET_FORM" />
              </Reset>
            </Form.Field>
          </FormGroup>
        </div>
      </Segment>
    </div>
  </Popup>
);

AdvancedFilter.propTypes = {
  offeringCompany: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = ({categories}) => ({categories});

@connect(mapStateToProps)
@injectIntl
export default class DocumentFilter extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    offeringCompany: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    intl: PropTypes.object,
  };

  constructor(props) {
    super(props);

    const {location} = this.props;
    // qs returns an empty object at parseerror or empty string
    // substr needed for querystring indicator questionmark
    const searchObj =
      location.search && location.search.trim() !== ''
        ? qs.parse(this.props.location.search.substr(1))
        : {};

    this.state = {query: Object.assign({}, DEFAULT_FILTER, searchObj)};
  }

  onApply = (values) => {
    const query = qs.stringify(values, {indices: false, skipNulls: true});
    this.props.history.push({
      search: `?${query}`,
    });
  };

  render() {
    const {query} = this.state;
    const {intl, offeringCompany, locale} = this.props;

    return (
      <>
        <Header as="h3">
          <Header.Content>
            <FormattedMessage id="GENERAL_FILTER_PANEL_HEADER" />
          </Header.Content>

          <PathForm
            onSubmit={this.onApply}
            fields={fields}
            initialValues={query}>
            {() => (
              <FormGroup>
                <Input
                  name="query"
                  path="query"
                  renderLabel={false}
                  width={15}
                  placeholder={intl.formatMessage({
                    id: 'DMS_DOCUMENT_SEARCH_PLACEHOLDER',
                  })}
                />

                <AdvancedFilter
                  intl={intl}
                  offeringCompany={offeringCompany}
                  locale={locale}
                  trigger={
                    <Button color="purple" type="button">
                      <FormattedMessage id="GENERAL_ADVANCED" />
                    </Button>
                  }
                />
                <Button type="submit" positive>
                  <FormattedMessage id="GENERAL_APPLY" />
                </Button>
              </FormGroup>
            )}
          </PathForm>
        </Header>
      </>
    );
  }
}
