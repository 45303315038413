import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  Segment,
  Button,
  Header,
  Grid,
  Modal,
  FormGroup,
  Icon,
  Message,
} from 'semantic-ui-react';
import {FormattedMessage as Msg, injectIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {Form as PathForm, Input, ExternalSubmit} from '@ecosio/pathform';
import {shapes} from '@ecosio/auth';
import qs from 'qs';

import configShape from '../../../../shapes';
import DateFormat from '../../../DateFormat';
import CommentCreate from '../../CommentCreate';
import CommentList from '../../CommentList';
import {BoldContent} from '../../IssueTracker/IssueTrackerHelpers';
import {issueShape} from '../../IssueTracker/shapes';
import {StateLabel} from '../../StateLabel';
import {FieldContent, SubHeaderMeta} from '../../../FormHelpers';
import {BodyWrapper, ButtonRow, translateValue} from '../Common';
import {wrappedGET} from '../../../../helpers/fetcher';
import DownloadIssueButton from '../DownloadIssueButton';
import {CONTEXT_PATH} from '../../../../constants';
import FavoriteButton from '../../FavoriteButton';
import FavoriteList from '../../FavoriteList';
import ReopenIssueButton from '../ReopenIssueButton';
import DimensionPanels from './DimensionPanels';

const AttachmentList = ({issue}) => {
  const [associatedFiles, setAssociatedFiles] = useState([]);
  useEffect(() => {
    if (issue.associatedFiles?.length > 0) {
      const query = qs.stringify(
        {
          fileUuid: issue.associatedFiles,
        },
        {arrayFormat: 'repeat'}
      );
      wrappedGET(`/api/issues/${issue.uuid}/associatedFiles?${query}`)
        .then((res) => setAssociatedFiles(res.data))
        .catch((err) => console.error(err));
    }
  }, [issue.associatedFiles]);

  const hasAttachments =
    issue.attachments.length > 0 || issue.associatedFiles.length > 0;

  return (
    <div style={{marginTop: '20px'}}>
      {hasAttachments && (
        <BoldContent>
          <Msg id="GENERAL_DOWNLOAD" />:
        </BoldContent>
      )}
      <div>
        {issue.attachments.map((attachment) => (
          <Button
            icon
            positive
            key={attachment.uuid}
            as="a"
            target="_blank"
            href={`${CONTEXT_PATH}/api/attachments/${attachment.uuid}`}>
            <Icon name="download" /> {attachment.originalFileName}
          </Button>
        ))}

        {associatedFiles.map((file) => (
          <Button
            icon
            positive
            key={file.uuid}
            as="a"
            target="_blank"
            href={`${CONTEXT_PATH}/api/documents/${file.uuid}/download`}>
            <Icon name="download" /> {file.fileName}
          </Button>
        ))}
      </div>
    </div>
  );
};

AttachmentList.propTypes = {
  issue: issueShape,
};

const ComplaintView = (props) => {
  const {
    issue,
    onUpdateIssue,
    loadIssue,
    config,
    editedCommentUuid,
    onEditComment,
    locale,
    onTriggerModal,
    modalVisible,
    onSubmit,
    template,
    onFollow,
    onUnfollow,
  } = props;
  const userId = config.userConfig.email;
  const {offeringCompany} = config.userConfig;

  const {
    editIssueHeaderOfferingCompany,
    editIssueHeaderUsingCompany,
    editIssueSpecificDataOfferingCompany,
    editIssueSpecificDataUsingCompany,
  } = template;

  const fields = {
    state: {
      path: 'state',
      type: 'dropdown',
      options: [
        {
          text: 'QA_STATUS_OPEN',
          value: 'OPEN',
        },
        {
          text: 'QA_STATUS_CLOSED',
          value: 'CLOSED',
        },
      ],
      input: {
        label: 'GENERAL_STATUS',
        translateOptions: true,
      },
    },
    'complaintData.complaintState': {
      path: 'complaintData.complaintState',
      type: 'dropdown',
      options: [
        {
          text: 'COMPLAINT_STATUS_JUSTIFIED',
          value: 'JUSTIFIED',
        },
        {
          text: 'COMPLAINT_STATUS_NOT_JUSTIFIED',
          value: 'NOT_JUSTIFIED',
        },
      ],
      input: {
        label: 'COMPLAINT_STATUS',
      },
    },
    'complaintData.complaintCompletionFreeText': {
      path: 'complaintData.complaintCompletionFreeText',
      type: 'textarea',
      input: {label: 'COMPLAINT_COMPLETION_FREETEXT', type: 'text'},
    },
  };
  return (
    <Fragment>
      <ComplaintHeader
        offeringCompany={offeringCompany}
        issue={issue}
        modalVisible={modalVisible}
        onTriggerModal={onTriggerModal}
        fields={fields}
        onSubmit={onSubmit}
        config={config}
        editIssueHeaderOfferingCompany={editIssueHeaderOfferingCompany}
        editIssueHeaderUsingCompany={editIssueHeaderUsingCompany}
        locale={locale}
        onFollow={onFollow}
        onUnfollow={onUnfollow}
        onUpdateIssue={onUpdateIssue}
      />

      <WarningMessage />
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment.Group>
              <Segment color="purple">
                <Header size="large">
                  <Header.Content>
                    {issue.subject}
                    <Header.Subheader>
                      <DateFormat date={issue.createdAt} />
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </Segment>
              <Segment>
                <ComplaintHeaderFields
                  issue={issue}
                  locale={locale}
                  config={config}
                />
              </Segment>
              <Segment clearing>
                <BodyWrapper body={issue.body} />

                <AttachmentList issue={issue} />
              </Segment>
            </Segment.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <DimensionPanels
              offeringCompany={offeringCompany}
              template={template}
              issue={issue}
              onUpdateIssue={onUpdateIssue}
              editIssueSpecificDataOfferingCompany={
                editIssueSpecificDataOfferingCompany
              }
              editIssueSpecificDataUsingCompany={
                editIssueSpecificDataUsingCompany
              }
            />
          </Grid.Column>
        </Grid.Row>
        <CommentList
          userID={userId}
          onLoadIssue={loadIssue}
          issue={issue}
          onEditComment={onEditComment}
          editedCommentUuid={editedCommentUuid}
          isOfferingCompany={offeringCompany}
        />
        <CommentCreate onLoadIssue={loadIssue} issue={issue} />
      </Grid>
    </Fragment>
  );
};

ComplaintView.propTypes = {
  //TODO: specify
  onUpdateIssue: PropTypes.func,
  template: PropTypes.any,
  issue: issueShape,
  locale: PropTypes.string,
  config: shapes.config,
  loadIssue: PropTypes.func,
  editedCommentUuid: PropTypes.bool,
  onEditComment: PropTypes.func,
  onTriggerModal: PropTypes.func,
  modalVisible: PropTypes.bool,
  onSubmit: PropTypes.func,
  onFollow: PropTypes.func.isRequired,
  onUnfollow: PropTypes.func.isRequired,
};

const WarningMessage = injectIntl(({intl}) => {
  return (
    <Message warning>
      <div
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: 'ISSUE_COMPLAINT_GENERAL_INFO_TICKET',
          }),
        }}
      />
    </Message>
  );
});

WarningMessage.propTypes = {
  intl: PropTypes.object,
};

const ComplaintHeaderButtons = ({
  issue,
  onTriggerModal,
  modalVisible,
  fields,
  onSubmit,
  editIssueHeaderOfferingCompany,
  editIssueHeaderUsingCompany,
  offeringCompany,
  locale,
  config,
  onFollow,
  onUnfollow,
  onUpdateIssue,
}) => {
  const {state} = issue;
  let ButtonBar;
  const onReopen = (issue) => {
    onUpdateIssue(issue);
  };

  const onError = (error) => {
    console.error(error);
  };

  if (state === 'CLOSED') {
    ButtonBar = (
      <ButtonRow>
        <div>
          <StateLabel state={issue?.complaintData?.complaintState} />
          <FieldContent
            dataSpec="stateDescription"
            inline
            value={issue?.complaintData?.complaintCompletionFreeText}
            leftCaption={
              <Fragment>
                {' '}
                <span style={{fontWeight: '700'}}>
                  <Msg id="GENERAL_DESCRIPTION" />:{' '}
                </span>
              </Fragment>
            }
          />
        </div>

        <div>
          <DownloadIssueButton
            issueUuid={issue.uuid}
            closed={true}
            locale={locale}
            isOfferingCompany={offeringCompany}
          />
          <FavoriteButton
            issue={issue}
            config={config}
            onFollow={onFollow}
            onUnfollow={onUnfollow}
          />
          <ReopenIssueButton
            issue={issue}
            isOfferingCompany={offeringCompany}
            onReopenSuccess={onReopen}
            onReopenError={onError}
          />
        </div>
      </ButtonRow>
    );
  } else if (
    (offeringCompany && editIssueHeaderOfferingCompany) ||
    (!offeringCompany && editIssueHeaderUsingCompany)
  ) {
    ButtonBar = (
      <Fragment>
        <DownloadIssueButton
          issueUuid={issue.uuid}
          closed={false}
          locale={locale}
          isOfferingCompany={offeringCompany}
        />
        <Button
          floated="right"
          primary
          as={Link}
          to={`/quality-assurance/edit/${issue.issueNumber}?issueType=${issue.issueType}`}>
          <Msg id="GENERAL_EDIT_TICKET" />
        </Button>
        <Button
          floated="right"
          primary
          onClick={onTriggerModal}
          data-spec="openModal">
          <Msg id="STATUS_ASSIGNEE_BUTTON_CONTENT" />
        </Button>
        <FavoriteButton
          issue={issue}
          config={config}
          onFollow={onFollow}
          onUnfollow={onUnfollow}
        />
        <Modal
          open={modalVisible}
          closeOnDimmerClick={true}
          onClose={onTriggerModal}>
          <Modal.Header>
            <Msg id="EDIT_TICKET_STATUS" />
          </Modal.Header>
          <Modal.Content>
            <PathForm
              onSubmit={onSubmit}
              initialValues={issue}
              formid="modal-autoCompleteDropdown"
              fields={fields}>
              {() => (
                <Fragment>
                  <FormGroup widths="equal">
                    <Input
                      name="complaintData.complaintState"
                      path="complaintData.complaintState"
                      translateOptions={true}
                    />
                  </FormGroup>
                  <FormGroup widths="equal">
                    <Input
                      name="complaintData.complaintCompletionFreeText"
                      path="complaintData.complaintCompletionFreeText"
                    />
                  </FormGroup>
                </Fragment>
              )}
            </PathForm>
          </Modal.Content>
          <Modal.Actions>
            <ExternalSubmit
              formid="modal-autoCompleteDropdown"
              positive
              data-spec="modalSubmit">
              <Msg id="QA_TICKET_SET_TO_COMPLETE" />
            </ExternalSubmit>
            <ExternalSubmit negative onClick={onTriggerModal}>
              <Msg id="GENERAL_CANCEL" />
            </ExternalSubmit>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
  return <Segment clearing>{ButtonBar}</Segment>;
};

ComplaintHeaderButtons.propTypes = {
  issue: issueShape,
  locale: PropTypes.string,
  onTriggerModal: PropTypes.func,
  modalVisible: PropTypes.bool,
  onSubmit: PropTypes.func,
  fields: PropTypes.object,
  editIssueHeaderOfferingCompany: PropTypes.bool,
  editIssueHeaderUsingCompany: PropTypes.bool,
  offeringCompany: PropTypes.bool,
  config: configShape.isRequired,
  onFollow: PropTypes.func.isRequired,
  onUnfollow: PropTypes.func.isRequired,
};

const ComplaintHeader = ({
  issue,
  modalVisible,
  onTriggerModal,
  fields,
  onSubmit,
  editIssueHeaderOfferingCompany,
  editIssueHeaderUsingCompany,
  offeringCompany,
  locale,
  config,
  onFollow,
  onUnfollow,
  onUpdateIssue,
}) => {
  return (
    <>
      <Segment.Group>
        <Segment color="purple">
          <Header size="large">
            <Header.Content>
              <StateLabel state={issue?.state} /> <Msg id="GENERAL_COMPLAINT" />
              : {issue.complaintData.externalComplaintReference}
            </Header.Content>
            <Header.Subheader data-spec="subject">
              {issue.subject}
            </Header.Subheader>
          </Header>
          <SubHeaderMeta
            state={issue?.state}
            assignee={issue?.assignee?.name}
            closedAt={issue?.closedAt}
            closedBy={issue?.closedBy}
          />
        </Segment>
        <ComplaintHeaderButtons
          offeringCompany={offeringCompany}
          issue={issue}
          onTriggerModal={onTriggerModal}
          modalVisible={modalVisible}
          fields={fields}
          onSubmit={onSubmit}
          editIssueHeaderOfferingCompany={editIssueHeaderOfferingCompany}
          editIssueHeaderUsingCompany={editIssueHeaderUsingCompany}
          locale={locale}
          config={config}
          onFollow={onFollow}
          onUnfollow={onUnfollow}
          onUpdateIssue={onUpdateIssue}
        />
      </Segment.Group>
      {issue?.state === 'CLOSED' ? (
        <Message info>
          <Icon name="info circle" />
          <Msg id="COMPLAINT_CLOSED_MESSAGE" />
        </Message>
      ) : null}
    </>
  );
};

ComplaintHeader.propTypes = {
  issue: issueShape,
  locale: PropTypes.string,
  onTriggerModal: PropTypes.func,
  modalVisible: PropTypes.bool,
  onSubmit: PropTypes.func,
  fields: PropTypes.object,
  editIssueHeaderOfferingCompany: PropTypes.bool,
  editIssueHeaderUsingCompany: PropTypes.bool,
  offeringCompany: PropTypes.bool,
  config: configShape.isRequired,
  onFollow: PropTypes.func.isRequired,
  onUnfollow: PropTypes.func.isRequired,
};

const ComplaintHeaderFields = ({issue, locale, config}) => {
  return (
    <Segment.Group>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header>
                <Msg id="GENERAL_GENERAL" />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              <BoldContent>
                <Msg id="GENERAL_SUPPLIER" />:
              </BoldContent>
              <p>
                <Link to={`/supplier/details/${issue?.supplier?.uuid}`}>
                  {issue?.supplier?.name}
                </Link>
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              <BoldContent>
                <Msg id="GENERAL_TYPE_CODE" />:
              </BoldContent>
              <p>
                <Msg id={`TYPE_CODE_${issue.complaintData.complaintType}`} />
              </p>
            </Grid.Column>
            <Grid.Column>
              <BoldContent>
                <Msg id="GENERAL_ERROR_TYPE" />:
              </BoldContent>
              <p>
                <Msg id={`ERROR_TYPE_${issue.complaintData.errorType}`} />
              </p>
            </Grid.Column>
            <Grid.Column>
              <BoldContent>
                <Msg id="GENERAL_ERROR_LOCATION" />:
              </BoldContent>
              <p>
                <Msg
                  id={`ERROR_LOCATION_${issue.complaintData.errorLocation}`}
                />
              </p>
            </Grid.Column>
          </Grid.Row>
          {issue?.state === 'CLOSED' ? (
            <Grid.Row columns="3">
              <Grid.Column>
                <BoldContent>
                  <Msg id="COMPLAINT_STATUS" />:
                </BoldContent>
                <p>{issue?.complaintData?.complaintState}</p>
              </Grid.Column>
              <Grid.Column>
                <BoldContent>
                  <Msg id="COMPLAINT_COMPLETION_FREETEXT" />:
                </BoldContent>
                <p>{issue?.complaintData?.complaintCompletionFreeText}</p>
              </Grid.Column>
            </Grid.Row>
          ) : null}
          {config.userConfig.offeringCompany && (
            <Grid.Row columns={3}>
              <Grid.Column>
                <FavoriteList issue={issue} asHeader={true} />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Segment>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header>
                <Msg id="RESPONSIBLE_OFFERING_EMPLOYEE" />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              <FieldContent
                value={issue?.offeringCompanyAssignee?.name}
                label="GENERAL_NAME"
              />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                value={issue?.offeringCompanyAssignee?.email}
                label="GENERAL_EMAIL"
              />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                value={issue?.offeringCompanyAssignee?.phoneNumber}
                label="GENERAL_TELEPHONE"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header>
                <Msg id="GENERAL_ORDER_INFORMATION" />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              <FieldContent value={issue?.orderNumber} label="GENERAL_ORDER" />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                value={issue?.orderPosition}
                label="GENERAL_ORDER_POSITION"
              />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                value={issue?.factoryIdentifier}
                label="GENERAL_FACTORY_IDENTIFIER"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              <FieldContent
                dataSpec="orderData"
                date
                value={issue?.complaintData?.orderDate}
                label="COMPLAINT_ORDER_DATE"
              />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                dataSpec="orderQuantity"
                value={issue?.complaintData?.orderQuantity}
                label="COMPLAINT_ORDER_QUANTITY"
              />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                dataSpec="complaintQuantity"
                value={issue?.complaintData?.complaintQuantity}
                label="COMPLAINT_COMPLAINT_QUANTITY"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header>
                <Msg id="GENERAL_ARTICLE_INFORMATION" />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              <FieldContent
                value={issue?.materialNumber}
                label="GENERAL_CUSTOMER_MATERIAL"
              />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                value={issue?.supplierMaterialNumber}
                label="GENERAL_SUPPLIER_MATERIAL"
              />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                value={issue?.drawingRevision}
                label="GENERAL_DRAWING_REVISION"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="1">
            <Grid.Column>
              <FieldContent
                value={translateValue(issue?.materialShortText, locale)}
                label="MATERIAL_SHORT_TEXT"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header>
                <Msg id="CAUSED_COSTS_THROUGH_COMPLAINTS" />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              <FieldContent
                value={issue?.complaintData?.complaintCosts?.totalCost}
                label="GENERAL_TOTAL_COSTS"
              />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                value={issue?.complaintData?.complaintCosts?.supplierCost}
                label="SUPPLIER_COSTS"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              <FieldContent
                value={issue?.complaintData?.complaintCosts?.description}
                label="GENERAL_DESCRIPTION"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Segment.Group>
  );
};

ComplaintHeaderFields.propTypes = {
  issue: issueShape,
  locale: PropTypes.string.isRequired,
};
export default ComplaintView;
