import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension/logOnlyInProduction';
import {createBrowserHistory} from 'history';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import rootReducer from './reducers';
import {CONTEXT_PATH} from './constants';

export const history = createBrowserHistory({
  basename: CONTEXT_PATH,
});

const routingMiddleware = routerMiddleware(history);

const middleWares = [
  applyMiddleware(routingMiddleware),
  applyMiddleware(thunk),
];

const initState = {};

const enhancer = composeWithDevTools(...middleWares);

export default createStore(
  connectRouter(history)(rootReducer),
  initState,
  enhancer
);
