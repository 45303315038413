import React from 'react';
import PropTypes from 'prop-types';
import {Table, Button, Icon} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {FormatDateTime, Pagination} from '@ecosio/components';
import {CONTEXT_PATH} from '../../constants';

const API_URL = `${CONTEXT_PATH}/api/demandpreview`;

const onDownloadClicked = () => {};

const DownloadExcelRaw = ({uuid, timerange, locale}) => {
  return (
    <Button
      icon
      compact
      onClick={onDownloadClicked}
      timeRange={timerange}
      fileUuid={uuid}
      as="a"
      target="_blank"
      color="green"
      className={`download-${timerange}-excel-button`}
      href={`${API_URL}/${uuid}/${timerange}?locale=${locale}`}>
      <Icon name="download" />
      <FormattedMessage id={`DOWNLOAD_EXCEL_${timerange.toUpperCase()}`} />
    </Button>
  );
};

DownloadExcelRaw.propTypes = {
  uuid: PropTypes.string,
  timerange: PropTypes.string,
  locale: PropTypes.string,
};

const DownloadExcel = connect(
  ({locales}) => ({...locales}),
  null
)(DownloadExcelRaw);

const rowFormatter = (viewedAt, isOfferingCompany) => {
  // Only format unread entries for offering companies
  if (!isOfferingCompany) {
    return {};
  }

  // viewedAt == null => urnead
  if (viewedAt == null) {
    return {style: {fontWeight: 'bolder'}, positive: true};
  } else {
    return {};
  }
};

const DemandPreviewTableHead = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>
          <FormattedMessage id="DEMAND_PREVIEW_RECEIVED_AT" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="DEMAND_PREVIEW_SENDER" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="DEMAND_PREVIEW_RECEIVER" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="DEMAND_PREVIEW_EXCEL_DOWNLOAD" />
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

const DemandPreviewTableBody = ({data, isOfferingCompany}) => {
  return (
    <Table.Body className="_sie_dp_t_body">
      {data.map((item, idx) => (
        <Table.Row
          key={idx}
          {...rowFormatter(item.viewedAt, isOfferingCompany)}>
          <Table.Cell>
            <FormatDateTime stripTimezone={false} dateString={item.createdAt} />
          </Table.Cell>
          <Table.Cell>{item.senderName}</Table.Cell>
          <Table.Cell>{item.receiverName}</Table.Cell>

          <Table.Cell>
            <DownloadExcel uuid={item.uuid} timerange="weekly" />
            <DownloadExcel uuid={item.uuid} timerange="monthly" />
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  );
};

DemandPreviewTableBody.propTypes = {
  data: PropTypes.array,
  onLinkClick: PropTypes.func,
};

const DemandPreviewTableFoot = ({paging}) => {
  return (
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell colSpan="4">
          <Pagination paging={paging} />
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  );
};

DemandPreviewTableFoot.propTypes = {
  paging: PropTypes.object,
};

const DemandPreviewTable = ({data, paging, onLinkClick, config}) => {
  const isOfferingCompany = config.userConfig.offeringCompany;
  return (
    <Table selectable className="_sie_dp_table">
      <DemandPreviewTableHead />
      <DemandPreviewTableBody
        data={data}
        onLinkClick={onLinkClick}
        isOfferingCompany={isOfferingCompany}
      />
      <DemandPreviewTableFoot paging={paging} />
    </Table>
  );
};

DemandPreviewTable.propTypes = {
  data: PropTypes.array,
  paging: PropTypes.object,
  onLinkClick: PropTypes.func,
  config: PropTypes.object,
};

export default DemandPreviewTable;
