import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown, Icon} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {LocaleAnchor} from '@ecosio/customer-layout';

/**
 * Determine if the passed in module (arg1) should be shown
 * for the userConfiguration (arg2).
 */
export const shouldShowModule = (
  {usingCompanyOnly, offeringCompanyOnly},
  {offeringCompany}
) => {
  try {
    // module is shown for both offering and using
    if (!usingCompanyOnly && !offeringCompanyOnly) {
      return true;
    }

    // if it's only shown for usingCompanies: check if we're a using company
    if (usingCompanyOnly) {
      return !offeringCompany;
    }

    // if it's only shown for offering companies, check if we're an offering company
    return offeringCompany;
    // we'd rather show the module than fail completely here, as
    // this is not security relevant.
  } catch (e) {
    // TODO: sentry
    console.error(e);
    return true;
  }
};

const ModuleDropdown = ({userConfig, selectedModule, newHeader}) => {
  const modules = userConfig.modules
    .filter((mod) => shouldShowModule(mod, userConfig))
    .map((mod) => {
      return (
        <Dropdown.Item
          data-spec={`mod-${mod.name}`}
          text={<FormattedMessage id={`GENERAL_${mod.name}`} />}
          active={mod.name === selectedModule}
          key={mod.name}
          as={LocaleAnchor}
          url={mod.url}
        />
      );
    });

  const trigger = (
    <span data-spec="hdr-modules">
      {!newHeader ? (
        <span>
          <Icon name="cube" />{' '}
        </span>
      ) : null}
      <FormattedMessage
        id={
          selectedModule
            ? `GENERAL_${selectedModule}`
            : 'GENERAL_MODULE_SELECTION'
        }
      />
    </span>
  );

  return (
    <Dropdown
      pointing={!newHeader ? 'top right' : ''}
      item
      trigger={trigger}
      data-spec="module-dd">
      <Dropdown.Menu>{modules}</Dropdown.Menu>
    </Dropdown>
  );
};

ModuleDropdown.propTypes = {
  userConfig: PropTypes.shape({
    selectedEdiPermission: PropTypes.string,
    modules: PropTypes.array,
  }),
  selectedModule: PropTypes.string,
};

export default ModuleDropdown;
