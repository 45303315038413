import {combineReducers} from 'redux';
import {reducer as locales} from '@ecosio/locales';
import {configReducer as config} from '@ecosio/auth';
import {drawingListReducer as drawingList} from './drawingList';
import {drawingDetailsReducer as drawingDetails} from './drawingDetails';
import {reducer as supplierList} from './supplierList';
import {reducer as supplyConfiguration} from './configuration';
import {reducer as supplierExchange} from './supplierExchange';
import {reducer as issueList} from './issueList';
import {issueReducer as issue} from './issue';
import {demandPreviewReducer as demandPreview} from './demandPreview';
import {reducer as categories} from './categories';
import {reducer as formState} from './formState';

export default combineReducers({
  locales,
  issue,
  issueList,
  config,
  drawingList,
  drawingDetails,
  supplierList,
  supplierExchange,
  supplyConfiguration,
  demandPreview,
  categories,
  formState,
});
