import React from 'react';
import {SidebarNavItem} from '@ecosio/customer-layout';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {sidebarPropTypes} from '../index';
import {BackButton} from './BackButton';

/**
 * Renders the supplier detail page of a supplier in the PRE-SSA state.
 *
 */
export const SupplierSSADetailSidebar = ({supplierId, offeringCompany}) => {
  if (!supplierId) {
    return [];
  }
  const url = `/supplier/details/${supplierId}/pre_assessment`;
  return [
    <BackButton offeringCompany={offeringCompany} key="back-button" />,
    <SidebarNavItem
      key="0"
      link={
        <NavLink to={url}>
          <FormattedMessage id="PRE_ASSESSMENT" />
        </NavLink>
      }
    />,
  ];
};

SupplierSSADetailSidebar.propTypes = sidebarPropTypes;
