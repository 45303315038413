import React, {Component} from 'react';
import {Icon, Label, Form, Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import AttachmentDownloadButton from './AttachmentDownloadButton';

class AttachmentList extends Component {
  static defaultProps = {
    width: 4,
  };

  onUpload = (ev) => {
    if (this.props.onUpload) {
      this.props.onUpload(Array.from(ev.target.files));
    }
  };

  onRemove = (uuid) => {
    if (this.props.onRemove) {
      this.props.onRemove(uuid);
    }
  };

  render() {
    const attachments = this.props.attachments || [];
    const {intl} = this.props;
    return (
      <Form.Field width={this.props.width}>
        <label>
          <FormattedMessage id="GENERAL_ATTACHMENTS" />
        </label>

        <Label.Group size="large">
          {attachments
            .filter((att) => Boolean(att.uuid))
            .map((att) => (
              <Label key={att.uuid}>
                {att.originalFileName}&nbsp;
                <Popup
                  content={intl.formatMessage({id: 'GENERAL_DELETE'})}
                  trigger={
                    <Icon
                      name="close"
                      onClick={() => this.onRemove(att.uuid)}
                    />
                  }
                />
                <span style={{marginLeft: 5}}>
                  <AttachmentDownloadButton attachment={att} />
                </span>
              </Label>
            ))}
        </Label.Group>

        {/* TODO add more than one upload */}
        <input type="file" onChange={this.onUpload} multiple />
      </Form.Field>
    );
  }
}

AttachmentList.propTypes = {
  attachments: PropTypes.array,
  onUpload: PropTypes.func,
  onRemove: PropTypes.func,
  width: PropTypes.number,
};

export default injectIntl(AttachmentList);
