import React, {Fragment, Component} from 'react';
import {FormattedMessage as Msg} from 'react-intl';
import {
  Header,
  Grid,
  List,
  Segment,
  Button,
  Icon,
  Table,
  Loader,
} from 'semantic-ui-react';
import {InputArray, Submit} from '@ecosio/pathform';
import {connect} from 'react-redux';
import {
  InputWrapper,
  FormGroupWrapper,
  TableHeaderItem,
  TableCellItem,
  UploadWrapper,
  getDownloadUrl,
  ResponsiveGridColumn,
} from '../FormHelpers';
import {SelectedCategories} from './PreAssessment';
import CategorySelector from './CategorySelector';

export const OfferingCompanyAttachmentsList = ({supplier}) => (
  <List>
    {supplier.attachments.map((attachment, idx) => (
      <List.Item key={idx}>
        <UploadWrapper
          useForm={false}
          path="attachments.file"
          name={`attachments[${idx}].file`}
          downloadUrl={getDownloadUrl(
            supplier.uuid,
            'GENERAL',
            attachment.uuid
          )}
          originalFileName={attachment.originalFileName}
        />
      </List.Item>
    ))}
  </List>
);

const UsingCompanyAttachmentsList = ({supplier}) => (
  <InputArray name="attachments">
    {({fields}) => (
      <Fragment>
        <Button
          type="button"
          compact
          positive
          onClick={() => fields.push({})}
          icon>
          <Icon name="add" />
          &nbsp;
          <Msg id="ATTACHMENT_ADD_NEW" />
        </Button>
        <List>
          {fields.map((name, idx) => {
            const attachment = supplier.attachments[idx];
            const downloadUrl =
              attachment &&
              attachment.uuid &&
              getDownloadUrl(supplier.uuid, 'GENERAL', attachment.uuid);
            const fileName = attachment && attachment.originalFileName;

            return (
              <List.Item key={idx}>
                <UploadWrapper
                  useForm
                  path="attachments.file"
                  name={`${name}.file`}
                  downloadUrl={downloadUrl}
                  originalFileName={fileName}
                  downloadButton
                />
              </List.Item>
            );
          })}
        </List>
      </Fragment>
    )}
  </InputArray>
);

const ContactEntry = ({name, useForm, fields, idx}) => (
  <Grid.Row width={16} idx={idx}>
    <Grid.Column width={16}>
      <FormGroupWrapper useForm={useForm} widths="equal">
        <InputWrapper
          useForm={useForm}
          path="companyContacts.headerTranslationKey"
          name={`${name}.headerTranslationKey`}
        />
        <InputWrapper
          useForm={useForm}
          path="companyContacts.name"
          name={`${name}.name`}
        />
        <InputWrapper
          useForm={useForm}
          path="companyContacts.telephone"
          name={`${name}.telephone`}
        />
        <InputWrapper
          useForm={useForm}
          path="companyContacts.fax"
          name={`${name}.fax`}
        />
        <InputWrapper
          useForm={useForm}
          path="companyContacts.email"
          name={`${name}.email`}
        />
        <Button
          style={{
            marginTop: '23px',
            marginBottom: '1px',
          }}
          icon
          color="red"
          type="button"
          onClick={() => fields.remove(idx)}>
          <Icon name="delete" />
        </Button>
      </FormGroupWrapper>
    </Grid.Column>
  </Grid.Row>
);

export const GeneralContactsView = ({
  useForm,
  supplier,
  categories,
  locale,
  selectedCategories,
}) => (
  <Fragment>
    {useForm ? (
      <Fragment>
        <Segment>
          <Header size="large">
            <Msg id="COMPANY_CONTACTS" />
          </Header>
          <UsingCompanyContactList />
        </Segment>

        <Segment>
          <Header size="large">
            <Msg id="GENERAL_PRODUCT_GROUPS" />
          </Header>
          <CategorySelector
            depth={3}
            name="categoryIds"
            path="categoryIds"
            categories={categories}
            locale={locale}
            sort
            multiple={false}
          />
        </Segment>
      </Fragment>
    ) : (
      <Fragment>
        <Segment>
          <Header size="large">
            <Msg id="COMPANY_CONTACTS" />
          </Header>
          <OfferingCompanyContactList supplier={supplier} />
        </Segment>
        <Segment>
          <Header size="large">
            <Msg id="GENERAL_PRODUCT_GROUPS" />
          </Header>
          <SelectedCategories
            selectedCategories={selectedCategories}
            locale={locale}
          />
        </Segment>
      </Fragment>
    )}
  </Fragment>
);

export const OfferingCompanyContactList = ({supplier}) => {
  const isEmpty = supplier.companyContacts.length === 0;

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <TableHeaderItem label="GENERAL_CONTACT_POSITION" />
          <TableHeaderItem label="GENERAL_NAME" />
          <TableHeaderItem label="GENERAL_TELEPHONE_SHORT" />
          <TableHeaderItem label="GENERAL_FAX" />
          <TableHeaderItem label="GENERAL_EMAIL" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {isEmpty ? (
          <Table.Row>
            <Table.Cell colSpan="7" textAlign="center">
              <b>
                <Msg id="GENERAL_NO_RESULTS" />
              </b>
            </Table.Cell>
          </Table.Row>
        ) : (
          supplier.companyContacts.map((contact, idx) => (
            <Table.Row key={idx}>
              <TableCellItem
                content={
                  <Msg id={contact.headerTranslationKey || 'NOT_SPECIFIED'} />
                }
              />
              <TableCellItem content={contact.name} />
              <TableCellItem content={contact.telephone} />
              <TableCellItem content={contact.fax} />
              <TableCellItem content={contact.email} />
            </Table.Row>
          ))
        )}
      </Table.Body>
    </Table>
  );
};

const UsingCompanyContactList = () => (
  <InputArray name="companyContacts">
    {({fields}) => {
      return (
        <Fragment>
          <Button
            compact
            positive
            icon
            type="button"
            onClick={() =>
              fields.insert(0, {
                headerTranslationKey: '',
                name: '',
                telephone: '',
                fax: '',
                email: '',
              })
            }>
            <Icon name="add" />
            &nbsp;
            <Msg id="ADD_COMPANY_CONTACT" />
          </Button>
          <Header size="medium">
            <Msg id="CURRENT_COMPANY_CONTACTS" />
          </Header>
          {fields.map((name, idx) => {
            return (
              <ContactEntry
                idx={idx}
                key={idx}
                name={name}
                fields={fields}
                useForm
              />
            );
          })}
        </Fragment>
      );
    }}
  </InputArray>
);

const mapStateToProps = ({categories}) => ({categories});

@connect(mapStateToProps)
class GeneralContacts extends Component {
  render() {
    const {
      useForm,
      supplier,
      selectedCategories,
      locale,
      categories,
      configuration,
    } = this.props;
    const categoryIds = (selectedCategories[0] || []).map((cat) => cat.uuid);
    if (!categories) {
      return <Loader active />;
    }

    const countries = configuration.countryOptions;
    const hasAttachments = supplier.attachments.length > 0;
    const showAttachmentsSegment = useForm ? true : hasAttachments;
    return (
      <Grid data-spec="generalContacts">
        <Grid.Row>
          <ResponsiveGridColumn>
            <Segment.Group>
              <Segment>
                <Grid divided>
                  <Grid.Row data-spec="contact-data-page-header">
                    <Grid.Column>
                      <Header as="h1" size="large">
                        <Msg
                          id="CONTACT_DATA_PAGE_HEADER"
                          values={{company: supplier.contactData.company}}
                        />
                      </Header>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Header size="medium">
                        <Msg id="GENERAL_ADDRESS_DATA" />
                      </Header>
                      <FormGroupWrapper useForm={useForm} widths="equal">
                        <InputWrapper
                          useForm={useForm}
                          path="contactData.company"
                        />
                      </FormGroupWrapper>
                      <FormGroupWrapper useForm={useForm}>
                        <InputWrapper
                          useForm={useForm}
                          path="addressData.street"
                          width={12}
                        />
                        <InputWrapper
                          useForm={useForm}
                          path="addressData.streetNumber"
                          width={4}
                        />
                      </FormGroupWrapper>
                      <FormGroupWrapper useForm={useForm} widths="equal">
                        <InputWrapper
                          useForm={useForm}
                          path="addressData.postalCode"
                        />
                        <InputWrapper
                          useForm={useForm}
                          path="addressData.city"
                        />
                      </FormGroupWrapper>
                      <FormGroupWrapper useForm={useForm} widths="equal">
                        <InputWrapper
                          useForm={useForm}
                          path="addressData.region"
                        />
                        <InputWrapper
                          useForm={useForm}
                          path="addressData.country"
                          translateOptions
                          search
                          options={countries}
                        />
                      </FormGroupWrapper>
                    </Grid.Column>
                    <Grid.Column>
                      <Header size="medium">
                        <Msg id="GENERAL_CONTACT_DATA" />
                      </Header>
                      <FormGroupWrapper useForm={useForm} widths="equal">
                        <InputWrapper
                          useForm={useForm}
                          path="loginEmail"
                          width={8}
                        />
                      </FormGroupWrapper>
                      <FormGroupWrapper useForm={useForm} widths="equal">
                        <InputWrapper
                          useForm={useForm}
                          path="contactData.fax"
                        />
                        <InputWrapper
                          useForm={useForm}
                          path="contactData.telephone"
                        />
                      </FormGroupWrapper>
                      <FormGroupWrapper useForm={useForm} widths="equal">
                        <InputWrapper
                          useForm={useForm}
                          path="contactData.email"
                        />
                        <InputWrapper
                          useForm={useForm}
                          path="contactData.website"
                        />
                      </FormGroupWrapper>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>

              <GeneralContactsView
                supplier={supplier}
                useForm={useForm}
                selectedCategories={selectedCategories}
                locale={locale}
                categoryIds={categoryIds}
                categories={categories}
              />

              {showAttachmentsSegment && (
                <Segment>
                  <Header as="h1" size="large">
                    <Msg id="GENERAL_ATTACHMENTS" />
                  </Header>

                  {useForm ? (
                    <UsingCompanyAttachmentsList supplier={supplier} />
                  ) : (
                    <OfferingCompanyAttachmentsList supplier={supplier} />
                  )}
                </Segment>
              )}

              {useForm && (
                <Segment clearing>
                  <Submit floated="right" positive>
                    <Icon name="checkmark" />
                    &nbsp;
                    <Msg id="GENERAL_SAVE" />
                  </Submit>
                </Segment>
              )}
            </Segment.Group>
          </ResponsiveGridColumn>
        </Grid.Row>
      </Grid>
    );
  }
}

export default GeneralContacts;
