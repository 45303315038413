import React from 'react';
import {SidebarNavItem} from '@ecosio/customer-layout';
import {NavLink} from 'react-router-dom';
import {Icon} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';

export const DocumentManagementDetailsSidebar = () => {
  return (
    <SidebarNavItem
      key="-1"
      link={
        <NavLink to="/documents/drawings/list">
          <Icon name="arrow left" />
          &nbsp;
          <FormattedMessage id="SUPPLIER_DETAILS_BACK_BUTTON" />
        </NavLink>
      }
    />
  );
};
