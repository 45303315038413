import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Grid, Button} from 'semantic-ui-react';
import {Input} from '@ecosio/pathform';
import {FormattedMessage as Msg} from 'react-intl';
import DimensionEditButton from '../DimensionEditButton';
import {FieldContent} from '../../../../FormHelpers';

const DimensionFour = ({
  offeringCompany,
  onEditDimension,
  onCancelDimension,
  edit,
  dimensionData,
  panelKey,
  editIssueSpecificDataOfferingCompany,
  editIssueSpecificDataUsingCompany,
}) => {
  return (
    <Grid>
      {edit !== panelKey ? (
        <Fragment>
          <Grid.Row>
            <Grid.Column>
              <FieldContent
                value={dimensionData?.freeText}
                dataSpec="complaintData.complaintReport.dimension4.freeText"
              />
            </Grid.Column>
          </Grid.Row>
          <DimensionEditButton
            panelKey="d4"
            offeringCompany={offeringCompany}
            onEditDimension={onEditDimension}
            editIssueSpecificDataOfferingCompany={
              editIssueSpecificDataOfferingCompany
            }
            editIssueSpecificDataUsingCompany={
              editIssueSpecificDataUsingCompany
            }
          />
        </Fragment>
      ) : (
        <Fragment>
          <Grid.Row>
            <Grid.Column>
              <Input
                name="complaintData.complaintReport.dimension4.freeText"
                path="complaintData.complaintReport.dimension4.freeText"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button
                type="button"
                floated="right"
                negative
                onClick={onCancelDimension}>
                <Msg id="GENERAL_CANCEL" />
              </Button>
              <Button
                type="submit"
                floated="right"
                positive
                data-spec="d4SaveButton">
                <Msg id="SAVE_DIMENSION" />
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Fragment>
      )}
    </Grid>
  );
};

DimensionFour.propTypes = {
  offeringCompany: PropTypes.bool,
  onEditDimension: PropTypes.func,
  onCancelDimension: PropTypes.func,
  //edit is a false if there is no edit mode activated, but if activated "edit" is a string
  edit: PropTypes.any,
  panelKey: PropTypes.string,
  dimensionData: PropTypes.object,
  editIssueSpecificDataOfferingCompany: PropTypes.bool,
  editIssueSpecificDataUsingCompany: PropTypes.bool,
};

export default DimensionFour;
