import React from 'react';

export const SenderOrReceiverComponent = ({drawing, offeringCompany}) => {
  const {receiverEmailAddresses, senderName, receiverName} = drawing;
  if (offeringCompany) {
    if (
      Array.isArray(receiverEmailAddresses) &&
      receiverEmailAddresses.length
    ) {
      return <span>{receiverEmailAddresses.join(', ')}</span>;
    } else {
      return <span>{receiverName}</span>;
    }
  } else {
    return <span>{senderName}</span>;
  }
};
