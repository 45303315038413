import moment from 'moment';
import {getAxiosConfig} from '@ecosio/components';
import {wrappedGET} from '../helpers/fetcher';

const TYPES = {
  DRAWING_LIST_REQUEST: 'drawingList/request',
  DRAWING_LIST_RESPONSE: 'drawingList/response',
  DRAWING_LIST_ERROR: 'drawingList/error',
  DRAWING_LIST_DOWNLOAD: 'drawingList/download',
};

const drawingListRequest = () => ({
  type: TYPES.DRAWING_LIST_REQUEST,
});

const drawingListResponse = (value) => {
  const {content, ...rest} = value;
  return {
    type: TYPES.DRAWING_LIST_RESPONSE,
    data: content,
    paging: {...rest},
  };
};

const drawingListError = (error) => ({
  type: TYPES.DRAWING_LIST_ERROR,
  error,
});

const formatDate = (timestamp) => {
  if (timestamp) {
    const date = moment(timestamp);
    return date.format('YYYY-MM-DD');
  } else {
    return undefined;
  }
};

const fixFilters = ({createdFrom, createdTo, ...rest}) => {
  return {
    ...rest,
    createdFrom: formatDate(createdFrom),
    createdTo: formatDate(createdTo),
  };
};

export const fetchPage = (query) => (dispatch) => {
  if (!query.sort) {
    query.sort = 'createdAt,desc';
  }

  if (query.status === 'ALL') {
    delete query.status;
  }

  dispatch(drawingListRequest());
  const cfg = getAxiosConfig(fixFilters(query));
  const url = `/api/drawings`;

  return wrappedGET(url, cfg)
    .then((res) => {
      if (res.status !== 200) {
        dispatch(drawingListError(res.statusText));
      } else {
        dispatch(drawingListResponse(res.data));
      }
    })
    .catch((res) => dispatch(drawingListError(res.message)));
};

export const downloadFile = (drawingId, offeringCompany) => (dispatch) => {
  dispatch({
    type: TYPES.DRAWING_LIST_DOWNLOAD,
    drawingId,
    offeringCompany,
  });
};

const initialState = {
  paging: null,
  data: null,
  error: null,
  loading: true,
};

export const drawingListReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.DRAWING_LIST_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case TYPES.DRAWING_LIST_RESPONSE:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.data,
        paging: action.paging,
      };
    case TYPES.DRAWING_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case TYPES.DRAWING_LIST_DOWNLOAD:
      if (action.offeringCompany) {
        return state;
      } else {
        const data = state.data.slice();
        const idx = data.findIndex((d) => d.uuid === action.drawingId);
        data[idx] = {...data[idx], status: 'PICKEDUP'};
        return {
          ...state,
          data,
        };
      }

    default:
      return state;
  }
};
