import PropTypes from 'prop-types';

export const referenceShape = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const nameAndUuidShape = PropTypes.shape({
  uuid: PropTypes.string,
  name: PropTypes.string,
});

export const attachmentShape = PropTypes.shape({
  authorities: PropTypes.array,
  companyUuids: PropTypes.array,
  contentType: PropTypes.string.isRequired,
  etag: PropTypes.isRequired,
  originalFileName: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  uuid: PropTypes.string.isRequired,
});

export const commentShape = PropTypes.shape({
  authorName: PropTypes.string.isRequired,
  authorUuid: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  companyType: PropTypes.string.isRequired,
  parentUuid: PropTypes.string,
  attachments: PropTypes.arrayOf(attachmentShape),
});

export const issueShape = PropTypes.shape({
  // internal unique identifier
  uuid: PropTypes.string,
  subject: PropTypes.string,
  body: PropTypes.string,
  authorName: PropTypes.string,
  authorUuid: PropTypes.string,
  // auto-increment digit for the UI (1,2,3,4,...)
  issueNumber: PropTypes.number,
  supplier: nameAndUuidShape,
  assignee: nameAndUuidShape,
  internal: PropTypes.bool,
  author: nameAndUuidShape,
  closed: PropTypes.bool,
  createdAt: PropTypes.string,
  owningCompanyUuid: PropTypes.string,
  updatedAt: PropTypes.string,
  attachments: PropTypes.arrayOf(attachmentShape),
  comments: PropTypes.arrayOf(commentShape),
  followers: PropTypes.arrayOf(referenceShape),
});
