import React, {Component, Fragment} from 'react';
import {
  Menu,
  Segment,
  Header,
  Button,
  Icon,
  Loader,
  Message,
} from 'semantic-ui-react';
import {FormattedMessage as Msg, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {Link, Redirect, NavLink} from 'react-router-dom';
import {hasPaging, Pagination, DataGrid} from '@ecosio/components';
import qs from 'qs';
import PropTypes from 'prop-types';
import {fetchPage} from '../../reducers/issueList';
import {moduleFromUrl} from '../../reducers/configuration';
import {fetchCategories} from '../../reducers/categories';
import IssueModuleFilter from './IssueModuleFilter';
import {
  complaintConfig,
  specialReleaseConfig,
  articleInspectionConfig,
  generalIssueConfig,
  ideasConfig,
} from './DataGridConfig';

export const rowFormatter = (row) => {
  return {
    positive: row.viewedAt == null,
    custom: {
      condition: row.state === 'CLOSED',
      style: {color: '#A0A0A0'},
    },
  };
};

// mapping dataGrid ids to elasticsearch sort columns
const fieldsMap = {
  supplier: 'supplier.name.keyword',
  subject: 'subject.keyword',
};

const revertMap = (obj) => {
  return Object.assign({}, ...Object.entries(obj).map(([a, b]) => ({[b]: a})));
};

const reverseFieldMap = revertMap(fieldsMap);

const direction = {
  ascending: 'asc',
  descending: 'desc',
};

// see https://gitlab.ecosio.com/code/ecosio-react-components/-/issues/80
const reverseDir = revertMap(direction);

export const handleSort = (history, location, {col, dir}) => {
  const parsedUrl = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  // sort has been removed
  if (!dir) {
    parsedUrl.sort = undefined;
    return history.push({
      ...location,
      search: `?${qs.stringify(parsedUrl)}`,
    });
  }

  let sort = col;
  // if we have an elasticField to dataGrid field mapping, use this
  if (fieldsMap[col]) {
    sort = fieldsMap[col];
  }

  if (!sort) {
    console.warn('Unable to get sort field, fallback to createdAt');
    sort = 'createdAt';
  }

  let sortDir = direction[dir];

  if (!sortDir) {
    console.warn('Unable to map correct sortDir, falling back to desc');
    sortDir = 'desc';
  }

  parsedUrl.sort = `${sort},${sortDir}`;

  history.push({
    ...location,
    search: `?${qs.stringify(parsedUrl)}`,
  });
};

export const getInitialSort = (location) => {
  const parsedUrl = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  let initialSort = 'createdAt';
  let initialDir = 'descending';

  if (!parsedUrl.sort) {
    return [initialSort, initialDir];
  }
  // tell the DataGrid what our sort setup is
  const sort = parsedUrl.sort.split(',');
  if (sort && sort.length > 1) {
    if (reverseFieldMap[sort[0]]) {
      initialSort = reverseFieldMap[sort[0]];
    } else {
      initialSort = sort[0];
    }
    // https://gitlab.ecosio.com/code/ecosio-react-components/-/issues/80
    initialDir = reverseDir[sort[1]];
  } else {
    console.warn(
      'Could not extract sort fields from url, falling back to defaults'
    );
  }

  return [initialSort, initialDir];
};

@hasPaging()
class IssueModuleList extends Component {
  parseQuery = (props = this.props) => {
    return qs.parse(props.location.search.substr(1));
  };

  render() {
    const {
      issueList,
      history,
      location,
      offeringCompanyFlag,
      issueType,
      intl,
    } = this.props;

    const query = qs.parse(this.props.location.search.substr(1));
    const currentIssueType = query.issueType;
    const currentStatus = query.status;
    const issueModule = location.pathname;

    let header;
    let subheader;
    let listTitle;
    switch (issueModule.substr(1)) {
      case 'issues':
        header = 'GENERAL_ISSUE_HEADER';
        subheader = 'GENERAL_ISSUE_SUBHEADER';
        listTitle = 'GENERAL_ISSUE_LIST_TITLE';
        break;
      case 'quality-assurance':
        header = 'QUALITY_ASSURANCE_HEADER';
        subheader = 'QUALITY_ASSURANCE_SUBHEADER';
        listTitle = 'QUALITY_ASSURANCE_LIST_TITLE';
        break;
      case 'ideas':
        header = 'IDEAS_HEADER';
        subheader = 'IDEAS_SUBHEADER';
        listTitle = 'IDEAS_LIST_TITLE';
        break;
      default:
        console.error('No Module found');
    }

    if (issueList.loading) {
      return <Loader active />;
    }

    if (issueList.error) {
      return (
        <Message negative>
          <Message.Header>
            <Msg id="GENERAL_ERROR" />
          </Message.Header>
          <p>{issueList.error}</p>
        </Message>
      );
    }

    const tabs = [
      {status: 'ALL', label: 'TICKET_ALL'},
      {status: 'OPEN', label: 'TICKET_OPEN'},
      {status: 'CLOSED', label: 'TICKET_CLOSED'},
      {status: 'MY', label: 'TICKET_MY'},
    ].map(({status, label}, idx) => (
      <Menu.Item
        data-spec={`${status}_TICKET_TAB`}
        key={idx}
        as={NavLink}
        to={`${issueModule}?${qs.stringify({...query, status})}`}
        isActive={() => status === currentStatus}>
        <Msg id={label} />
      </Menu.Item>
    ));

    let dataGridConfig;
    if (currentIssueType === 'COMPLAINT') {
      dataGridConfig = complaintConfig(currentStatus === 'OPEN');
    } else if (currentIssueType === 'SPECIAL_RELEASE') {
      dataGridConfig = specialReleaseConfig(currentStatus === 'OPEN');
    } else if (currentIssueType === 'ARTICLE_INSPECTION') {
      dataGridConfig = articleInspectionConfig(currentStatus === 'OPEN');
    } else if (currentIssueType === 'FREE_FORM') {
      dataGridConfig = generalIssueConfig(currentStatus === 'OPEN');
    } else if (currentIssueType === 'IDEAS') {
      dataGridConfig = ideasConfig(currentStatus === 'OPEN');
    }

    const renderFooter = ({Table, cols}) => {
      return (
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan={cols}>
              <Pagination paging={issueList.paging} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      );
    };

    const isVisible = offeringCompanyFlag
      ? issueType.uiCreationByOfferingCompany
      : issueType.uiCreationByUsingCompany;

    const [initialSort, initialDir] = getInitialSort(location);

    return (
      <Fragment>
        <Helmet>
          <title>{intl.formatMessage({id: listTitle})}</title>
        </Helmet>
        <Menu tabular attached>
          {tabs}
        </Menu>
        <Segment attached>
          <Header size="large" as="h1">
            <Header.Content>
              <Msg id={header} />
              <Header.Subheader>
                <Msg id={subheader} />
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Segment>
        <Segment attached>
          <IssueModuleFilter
            history={history}
            location={location}
            currentIssueType={currentIssueType}
            offeringCompanyFlag={offeringCompanyFlag}
            issueList={issueList.data}
            currentStatus={currentStatus}
          />
        </Segment>
        {isVisible && (
          <CreateIssueButton
            link={`${issueModule}/create?issueType=${issueType.issueType}`}
            issueModule={currentIssueType}
          />
        )}

        <DataGrid
          initialSortColumn={initialSort}
          initialSortDirection={initialDir}
          renderFooter={renderFooter}
          translated
          data-spec="issue-table"
          data={issueList.data}
          actions={[]}
          config={dataGridConfig}
          table={{striped: false}}
          loading={issueList.loading}
          onSort={(params) => handleSort(history, location, params)}
          rowFormatter={rowFormatter}
          emptyState={{
            icon: 'tasks',
            header: 'NO_ISSUES_FOUND',
            subHeader: 'NO_ISSUES_FOUND_SUB',
          }}
        />
      </Fragment>
    );
  }
}

IssueModuleList.propTypes = {
  issueList: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  offeringCompanyFlag: PropTypes.bool,
  supportedIssueTypes: PropTypes.array,
  intl: PropTypes.object,
  issueType: PropTypes.string,
};

export const CreateIssueButton = ({link, issueModule}) => {
  return (
    <Segment clearing attached="bottom">
      <Button
        floated="right"
        icon
        color="green"
        as={Link}
        to={link}
        data-spec={`CREATE_${issueModule}`}>
        <Icon name="add" /> <Msg id={`CREATE_${issueModule}`} />
      </Button>
    </Segment>
  );
};

CreateIssueButton.propTypes = {
  link: PropTypes.string.isRequired,
  issueModule: PropTypes.string,
};

const fetchPageAndCategories = (query) => (dispatch) => {
  dispatch(fetchPage(query));
  dispatch(fetchCategories());
};

const mapDispatchToProps = (dispatch) => ({
  fetchPage: (query) => dispatch(fetchPageAndCategories(query)),
});

const mapStateToProps = ({config, issueList, supplyConfiguration}) => ({
  offeringCompanyFlag: config.userConfig.offeringCompany,
  issueList,
  supplyConfiguration,
});

const findIssueTypeConfiguration = (configuration, type) => {
  // IDEAS and ISSUES modules
  if (configuration.issueType) {
    return configuration.issueType;
  } else if (configuration.supportedIssueTypes) {
    const supportedType = configuration.supportedIssueTypes.find(
      (conf) => conf.issueType === type
    );
    return supportedType || configuration.supportedIssueTypes[0];
  } else {
    console.warn(
      'Failed to find issue type for type ',
      type,
      ' in configuration',
      configuration
    );
  }
};

const ListWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  const {supplyConfiguration} = props;
  const query = qs.parse(props.location.search.substr(1));
  const currentIssueType = query.issueType;
  const currentStatus = query.status;
  const module = moduleFromUrl(props.location);
  const configuration = supplyConfiguration.supplyModules[module].configuration;
  const issueType = findIssueTypeConfiguration(configuration, currentIssueType);

  if (!issueType) {
    throw new Error('No issue type configurations found at all');
  }

  if (!currentIssueType || !currentStatus) {
    const path = props.location.pathname;
    return (
      <Redirect to={`${path}?issueType=${issueType.issueType}&status=OPEN`} />
    );
  } else {
    return <IssueModuleList issueType={issueType} {...props} />;
  }
});

export default injectIntl(ListWrapper);
