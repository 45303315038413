import {SidebarNavItem} from '@ecosio/customer-layout';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import React from 'react';
import {MODULE_TYPES} from '@ecosio/auth';
import qs from 'qs';
import ConditionalOnModule from '../../ModuleConditional';

export const IssueSidebar = () => {
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  return [
    <ConditionalOnModule key={0} requiredModule={MODULE_TYPES.ISSUE_TRACKER}>
      <SidebarNavItem
        link={
          <NavLink
            to="/issues"
            isActive={() => query.issueType === 'FREE_FORM'}>
            <FormattedMessage id="ISSUES_MENU_ITEM" />
          </NavLink>
        }
        description={null}
      />
    </ConditionalOnModule>,
    <ConditionalOnModule key={1} requiredModule={MODULE_TYPES.IDEAS}>
      <SidebarNavItem
        link={
          <NavLink to="/ideas" isActive={() => query.issueType === 'IDEAS'}>
            <FormattedMessage id="IDEAS_MENU_ITEM" />
          </NavLink>
        }
        description={null}
      />
    </ConditionalOnModule>,
  ];
};
