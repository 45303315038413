import React from 'react';
import {Input, InputArray, FormGroup} from '@ecosio/pathform';
import {FormattedMessage} from 'react-intl';
import {Button, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import AssignedCategorySelector from '../../SupplierDetails/PageHeader/AssignedCategorySelector';
import {RemoveButton} from '../../FormHelpers';

const pathForType = {
  ALL: null,
  SUPPLIER: 'supplier',
  CATEGORY: 'category',
};

const RecipientSelector = ({values, name, locale}) => {
  return (
    <InputArray name={name}>
      {({fields}) => {
        const fieldList = fields.map((name, idx) => {
          const type = values.recipients[idx].type;
          const fieldPath = type ? pathForType[type] : null;
          const defaultText = values.recipients[idx]?.name;
          const Component =
            fieldPath === 'category' ? AssignedCategorySelector : Input;

          return (
            <FormGroup key={idx}>
              <Input
                width={8}
                name={`${name}.type`}
                path="recipientType"
                renderLabel={false}
                translateOptions={true}
              />
              <Component
                width={8}
                name={`${name}.uuid`}
                path={fieldPath}
                defaultText={defaultText}
                valueProperty="uuid"
                locale={locale}
                renderLabel={false}
              />
              {idx > 0 && <RemoveButton fields={fields} idx={idx} />}
            </FormGroup>
          );
        });

        return (
          <>
            {fieldList}
            <Button onClick={() => fields.push({})} positive>
              <Icon name="add" />
              &nbsp;
              <FormattedMessage id="GENERAL_ADD_RECIPIENT" />
            </Button>
            <br />
          </>
        );
      }}
    </InputArray>
  );
};

RecipientSelector.propTypes = {
  values: PropTypes.object,
  name: PropTypes.string,
  locale: PropTypes.string.isRequired,
};

RecipientSelector.defaultProps = {
  name: 'recipients',
};

export default RecipientSelector;
