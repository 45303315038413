import React, {Component, Fragment} from 'react';
import {Loader, Table, Header, Icon, Popup, Segment} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {InputArray, Input, Form, Submit} from '@ecosio/pathform';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setSupplier} from '../../reducers/supplierExchange';
import {NotSpecified} from '../FormHelpers';
import {wrappedPOST, wrappedGET} from '../../helpers/fetcher';
import FormStateToRedux from '../FormStateToRedux';
import PageHeader from './PageHeader';
import {
  supplierShape,
  configurationShape,
  factsAndFiguresExchangeShape,
  factsAndFiguresExchangeRowShape,
} from './shapes';

const ValueCell = injectIntl(({figure, intl}) => {
  const value = figure.value;

  if (value == null) {
    return (
      <Table.Cell>
        <NotSpecified />
      </Table.Cell>
    );
  } else {
    const formattedValue = intl.formatNumber(value);
    return (
      <Table.Cell>
        <FormattedMessage
          id={figure.unitTranslationKey}
          values={{value: formattedValue}}
        />
      </Table.Cell>
    );
  }
});

const TableHeader = ({factsAndFigures}) => {
  const headerCells = factsAndFigures.rows[0].values.map(({from}, idx) => (
    <Table.HeaderCell key={idx}>{from.substr(0, 4)}</Table.HeaderCell>
  ));

  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell />
        {headerCells}
      </Table.Row>
    </Table.Header>
  );
};

TableHeader.propTypes = {
  factsAndFigures: factsAndFiguresExchangeShape.isRequired,
};

const OfferingCompanyFactsAndFigures = ({factsAndFigures}) => {
  const rows = factsAndFigures.rows.map(({pathKey, values}) => {
    return (
      <Table.Row key={pathKey}>
        <Table.Cell>
          <b>
            <FormattedMessage id={values[0].nameTranslationKey} />
          </b>
        </Table.Cell>
        {values.map((figure) => (
          <ValueCell key={figure.uuid} figure={figure} />
        ))}
      </Table.Row>
    );
  });

  return (
    <Table celled collapsing>
      <TableHeader factsAndFigures={factsAndFigures} />

      <Table.Body>{rows}</Table.Body>
    </Table>
  );
};

OfferingCompanyFactsAndFigures.propTypes = {
  factsAndFigures: factsAndFiguresExchangeShape.isRequired,
};

const TableInputCell = ({rows, idx, name, pathKey}) => {
  const figure = rows.values[idx];
  const {unitTranslationKey, needsActualValue, value, forecast} = figure;
  const unit = (
    <FormattedMessage id={unitTranslationKey} values={{value: ''}} />
  );
  const input = (
    <Input
      name={`${name}.value`}
      path={pathKey}
      renderLabel={false}
      inputLabel={{
        content: unit,
      }}
      labelPosition="right"
      disabled={value != null && !forecast}
    />
  );

  let inner = null;
  if (needsActualValue) {
    const popupContent = (
      <Fragment>
        <Header as="h4">
          <FormattedMessage id="GENERAL_INFORMATION" />
        </Header>
        <p>
          <FormattedMessage id="SSA_NEEDS_ACTUAL_VALUE" />
        </p>
      </Fragment>
    );
    inner = <Popup trigger={input} content={popupContent} />;
  } else {
    inner = input;
  }

  return <Table.Cell error={needsActualValue}>{inner}</Table.Cell>;
};

TableInputCell.propTypes = {
  idx: PropTypes.number,
  name: PropTypes.string,
  pathKey: PropTypes.string,
  rows: factsAndFiguresExchangeRowShape.isRequired,
};

@connect(null, {setSupplier})
class UsingCompanyFactsAndFigures extends Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = {notification: null};
  }
  onSubmit = (values) => {
    const {supplier} = this.props;
    wrappedPOST(`/api/suppliers/${supplier.uuid}/factsAndFigures`, values)
      .then((res) => {
        this.props.setNotification();
        this.props.setSupplier(res.data);
        window.scrollTo(0, 0);
      })
      .catch(() => {
        this.props.setErrorNotification();
      });
  };

  render() {
    const {factsAndFigures, configuration} = this.props;
    const formFields = configuration.factsAndFigures.formFields;
    // number of periods + 1 for definition column
    const colSpan = configuration.factsAndFigures.offsets.length + 1;

    return (
      <Form
        fields={formFields}
        initialValues={factsAndFigures}
        onSubmit={this.onSubmit}>
        {() => (
          <Fragment>
            <FormStateToRedux form="factsAndFigures" />

            <Table compact collapsing definition>
              <TableHeader factsAndFigures={factsAndFigures} />

              <Table.Body>
                <InputArray name="rows">
                  {({fields}) =>
                    fields.map((name, idx) => {
                      const row = factsAndFigures.rows[idx];
                      const pathKey = row.pathKey;
                      if (row.length === 0) {
                        return null;
                      }
                      return (
                        <Table.Row key={idx}>
                          <Table.Cell>
                            <FormattedMessage
                              id={row.values[0].nameTranslationKey}
                            />
                          </Table.Cell>

                          <InputArray name={`${name}.values`}>
                            {({fields}) =>
                              fields.map((name, idx) => {
                                return (
                                  <TableInputCell
                                    key={idx}
                                    rows={row}
                                    idx={idx}
                                    name={name}
                                    pathKey={pathKey}
                                  />
                                );
                              })
                            }
                          </InputArray>
                        </Table.Row>
                      );
                    })
                  }
                </InputArray>
              </Table.Body>
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell colSpan={colSpan}>
                    <Submit positive floated="right">
                      <Icon name="checkmark" />
                      &nbsp;
                      <FormattedMessage id="GENERAL_SAVE" />
                    </Submit>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Fragment>
        )}
      </Form>
    );
  }
}

UsingCompanyFactsAndFigures.propTypes = {
  factsAndFigures: factsAndFiguresExchangeShape.isRequired,
  supplier: supplierShape.isRequired,
  setSupplier: PropTypes.func,
  configuration: configurationShape.isRequired,
  setNotification: PropTypes.func.isRequired,
  setErrorNotification: PropTypes.func.isRequired,
};

export default class FactsAndFigures extends Component {
  state = {factsAndFigures: null, notification: null};

  componentDidMount() {
    const {supplier} = this.props;
    wrappedGET(`/api/suppliers/${supplier.uuid}/factsAndFigures`)
      .then((res) => this.setState({factsAndFigures: res.data}))
      .catch((err) => console.error(err));
  }

  setNotification = (obj) => {
    const notification = !obj
      ? {
          positive: true,
          header: <FormattedMessage id="GENERAL_SUBMIT_SUCCESS_HEADER" />,
          body: <FormattedMessage id="GENERAL_SUBMIT_SUCCESS_MSG" />,
        }
      : obj;

    this.setState({
      notification,
    });
  };

  setErrorNotification = () => {
    this.setState({
      notification: {
        negative: true,
        header: <FormattedMessage id="GENERAL_SUBMIT_ERROR_HEADER" />,
        body: <FormattedMessage id="GENERAL_SUBMIT_ERROR_MSG" />,
      },
    });
  };

  render() {
    const {supplier, configuration, useForm, currentPage} = this.props;
    const {period} = configuration.factsAndFigures;
    const {factsAndFigures} = this.state;

    if (period === 'QUARTERLY') {
      return <p>Not yet implemented</p>;
    }

    if (factsAndFigures) {
      return (
        <Fragment>
          <PageHeader
            notification={this.state.notification}
            hideNotification={this.hideNotification}
            setNotification={this.setNotification}
            supplier={supplier}
            assessmentState={supplier.status}
            offeringCompany={!useForm}
            currentPage={currentPage}
          />

          <Segment data-spec="fact-and-figures">
            <Header size="large" as="h1">
              <FormattedMessage id="FACTS_AND_FIGURES_HEADER" />
            </Header>

            {useForm ? (
              <UsingCompanyFactsAndFigures
                supplier={supplier}
                factsAndFigures={factsAndFigures}
                configuration={configuration}
                setNotification={this.setNotification}
                setErrorNotification={this.setErrorNotification}
              />
            ) : (
              <OfferingCompanyFactsAndFigures
                factsAndFigures={factsAndFigures}
                configuration={configuration}
              />
            )}
          </Segment>
        </Fragment>
      );
    }

    return <Loader active />;
  }
}

FactsAndFigures.propTypes = {
  supplier: supplierShape.isRequired,
  configuration: configurationShape.isRequired,
  useForm: PropTypes.bool,
  currentPage: PropTypes.string,
};
