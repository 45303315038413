import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Loader, Message} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {shapes} from '@ecosio/auth';
import {wrappedGET, wrappedPOST} from '../../helpers/fetcher';
import ComplaintEdit from './QualityAssurance/Complaint/ComplaintEdit';
import ArticleInspectionEdit from './QualityAssurance/ArticleInspection/ArticleInspectionEdit';
import SpecialReleaseCreate from './QualityAssurance/SpecialRelease/SpecialReleaseCreate';
import {supportedIssueTypeShape} from './shapes';
import GeneralIssueCreate from './IssueTracker/GeneralIssueCreate';
import IdeasCreate from './Ideas/IdeasCreate';

export const makeUploadRequests = (attachments, issueNumber) => {
  const requests = (attachments || [])
    .filter((att) => Boolean(att.file))
    .map((att) => {
      const formData = new window.FormData();
      formData.append('file', att.file);
      return wrappedPOST(`/api/issues/${issueNumber}/attachment`, formData);
    });
  return Promise.all(requests);
};

class IssueModuleEdit extends Component {
  static propTypes = {
    config: shapes.config,
    history: PropTypes.any,
    supplyConfiguration: supportedIssueTypeShape,
    onAttachmentRemoved: PropTypes.func,
    onUpload: PropTypes.func,
    match: PropTypes.object,
  };

  state = {loading: true, issue: null, error: null};

  componentDidMount() {
    return this.loadIssue();
  }

  loadIssue = () => {
    const {match} = this.props;
    const id = match.params.id;
    return wrappedGET(`/api/issues/${id}`)
      .then((res) => {
        this.setState({issue: res.data, loading: false, error: null});
      })
      .catch((e) => {
        console.error(e);
        this.setState({error: e, loading: false, issue: null});
      });
  };

  onFollow = () => {
    wrappedPOST(`/api/issues/${this.state.issue.uuid}/follow`)
      .then((result) => {
        this.setState({issue: result.data});
      })
      .catch((err) => {
        console.error(err);
      });
  };

  onUnFollow = () => {
    wrappedPOST(`/api/issues/${this.state.issue.uuid}/unfollow`)
      .then((result) => {
        this.setState({issue: result.data});
      })
      .catch((err) => {
        console.error(err);
      });
  };

  //onUpload functions stores the attachments inside the main issue state
  //it triggers a rendering process and removes field values that were not stored by adding a new attachment
  /*
  onUpload = files => {
    this.setState(oldState => {
      const newAttachments = oldState.issue?.attachments?.slice() || [];
      files.forEach(file => {
        newAttachments.push({file});
      });
      return {
        issue: {...oldState.issue, attachments: newAttachments}
      };
    });
  };
*/
  onAttachmentRemoved = (uuid) => {
    this.setState((oldState) => {
      const newAttachments = oldState.issue.attachments.filter(
        (att) => att.uuid !== uuid
      );
      return {
        issue: {...oldState.issue, attachments: newAttachments},
      };
    });
  };
  //attachments are only transfered from complaints and sampling
  onSubmit = (values, issueType, issueState, attachments) => {
    const uuid = this.props.match.params.id;
    const issue = Object.assign({}, values, {
      issueType,
      attachments,
      materialShortText: {
        de: values.materialShortText?.de,
        en: values.materialShortText?.de,
      },
    });

    // Meh
    let outerIssueNumber;
    let url = uuid ? '/api/issues/update' : '/api/issues';
    if (issueState) {
      url += `?previousState=${issueState}`;
    }

    return wrappedPOST(url, issue)
      .then((res) => {
        const issueNumber = res.data.issueNumber;
        outerIssueNumber = issueNumber;
        return makeUploadRequests(issue.attachments, issueNumber);
      })
      .then(() => {
        this.props.history.push(`/quality-assurance/view/${outerIssueNumber}`);
      })
      .catch((error) => {
        this.setState({
          error,
        });
        console.error(error);
      });
  };

  getIssueTypeFields = (type) => {
    const {
      supportedIssueTypes,
    } = this.props.supplyConfiguration.supplyModules.QUALITY_ASSURANCE.configuration;
    const template = supportedIssueTypes.find(
      (item) => item.issueType === type
    );
    const defaultTemplate = template.defaultTemplate;
    return template.issueTemplates[defaultTemplate].formFields;
  };

  render() {
    const {type} = this.props.match.params;
    const {issue, loading, error} = this.state;
    if (loading) {
      return <Loader active />;
    }

    if (error) {
      return (
        <Message error>
          <Message.Header>
            <FormattedMessage id="GENERAL_ERROR" />
          </Message.Header>
          <p>{error.message}</p>
        </Message>
      );
    }
    switch (issue.issueType.toLowerCase()) {
      case 'complaint':
        return (
          <ComplaintEdit
            {...this.props}
            fields={this.getIssueTypeFields('COMPLAINT')}
            issue={issue}
            onSubmit={this.onSubmit}
            onUpload={this.onUpload}
            onAttachmentRemoved={this.onAttachmentRemoved}
          />
        );
      case 'special_release':
        // SpecialReleaseCreate handles its own config fields for create and edit
        return (
          <SpecialReleaseCreate
            {...this.props}
            onAttachmentRemoved={this.onAttachmentRemoved}
            issue={issue}
            onFollow={this.onFollow}
            onUnFollow={this.onUnFollow}
          />
        );
      case 'article_inspection':
        return (
          <ArticleInspectionEdit
            {...this.props}
            fields={this.getIssueTypeFields('ARTICLE_INSPECTION')}
            issue={issue}
            onSubmit={this.onSubmit}
            onUpload={this.onUpload}
            onAttachmentRemoved={this.onAttachmentRemoved}
            onFollow={this.onFollow}
            onUnFollow={this.onUnFollow}
          />
        );
      case 'free_form':
        return (
          <GeneralIssueCreate
            {...this.props}
            onAttachmentRemoved={this.onAttachmentRemoved}
            issue={issue}
          />
        );
      case 'ideas':
        return (
          <IdeasCreate
            {...this.props}
            onAttachmentRemoved={this.onAttachmentRemoved}
            issue={issue}
          />
        );
      default:
        throw new Error(`Unknown issue type: ${type}`);
    }
  }
}

const mapStateToProps = ({config, locales, supplyConfiguration}) => ({
  supplyConfiguration,
  config,
  locale: locales.locale,
});

export default connect(mapStateToProps, null)(IssueModuleEdit);
