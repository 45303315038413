import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button, Icon, Popup} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import configShape from '../../shapes';
import {issueShape} from './IssueTracker/shapes';

const mapStateToProps = ({config}) => ({config: config.userConfig});

export const isFollower = (issue, config) => {
  const followers = issue?.followers || [];
  return followers.some((follower) => follower.name === config.email);
};

function FavoriteButton({issue, config, onFollow, onUnfollow}) {
  const isFollowing = isFollower(issue, config);

  return (
    <Popup
      content={
        <FormattedMessage
          id={isFollowing ? 'GENERAL_UNFOLLOW' : 'GENERAL_FOLLOW'}
        />
      }
      trigger={
        <Button
          icon
          type="button"
          onClick={isFollowing ? onUnfollow : onFollow}
          floated="right">
          <Icon name={isFollowing ? 'star' : 'star outline'} />{' '}
        </Button>
      }
    />
  );
}

FavoriteButton.propTypes = {
  issue: issueShape.isRequired,
  onFollow: PropTypes.func.isRequired,
  onUnfollow: PropTypes.func.isRequired,
  config: configShape.isRequired,
};

export default connect(mapStateToProps)(FavoriteButton);
