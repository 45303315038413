import React from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {dynID} from '@ecosio/pathform';
import PropTypes from 'prop-types';

const mapStateToProps = ({supplyConfiguration}) => {
  return {config: supplyConfiguration};
};

const getTranslationKeyResult = (config, translationKey) => {
  const translationKeys =
    config.supplyModules.MASTERDATA.configuration.uploadConfig.translationKeys;

  return translationKeys[translationKey];
};

const MasterdataMessage = ({translationKey, config}) => {
  const translationKeyResult = getTranslationKeyResult(config, translationKey);

  return (
    <FormattedMessage
      id={dynID(translationKeyResult ? translationKeyResult : translationKey)}
    />
  );
};

export const formatMessage = (intl, translationKey, config) => {
  const translationKeyResult = getTranslationKeyResult(config, translationKey);
  return intl.formatMessage({
    id: dynID(translationKeyResult ? translationKeyResult : translationKey),
  });
};

MasterdataMessage.propTypes = {
  translationKey: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, null)(MasterdataMessage);
