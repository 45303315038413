import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Segment,
  Button,
  Header,
  Grid,
  Loader,
  Modal,
  FormGroup,
} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {FormattedMessage as Msg} from 'react-intl';
import {Form as PathForm, Input, ExternalSubmit} from '@ecosio/pathform';
import {Attachment} from '@ecosio/components';
import {shapes} from '@ecosio/auth';
import configShape from '../../../shapes';
import {FieldContent, SubHeaderMeta} from '../../FormHelpers';
import {BodyWrapper} from '../QualityAssurance/Common';
import {StateLabel} from '../StateLabel';
import {BoldContent} from '../IssueTracker/IssueTrackerHelpers';
import CommentCreate from '../CommentCreate';
import CommentList from '../CommentList';
import {issueShape} from '../IssueTracker/shapes';
import DateFormat from '../../DateFormat';
import {CONTEXT_PATH} from '../../../constants';
import FavoriteButton from '../FavoriteButton';
import FavoriteList from '../FavoriteList';

class IdeasView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {editedCommentUuid: null};
  }

  onEditComment = (commentUuid) => {
    this.setState({
      editedCommentUuid: commentUuid,
    });
  };

  render() {
    const {editedCommentUuid} = this.state;
    const {
      locale,
      config,
      issue,
      loadIssue,
      modalVisible,
      onTriggerModal,
      onSubmit,
      template,
      onFollow,
      onUnfollow,
    } = this.props;
    const offeringCompany = config?.userConfig?.offeringCompany;

    const {
      editIssueHeaderOfferingCompany,
      editIssueHeaderUsingCompany,
    } = template;

    if (!issue) {
      return <Loader active />;
    }

    const fields = {
      state: {
        path: 'state',
        type: 'dropdown',
        options: [
          {
            text: 'GENERAL_ISSUE_OPEN',
            value: 'OPEN',
          },
          {
            text: 'GENERAL_ISSUE_CLOSED',
            value: 'CLOSED',
          },
        ],
        input: {
          label: 'IDEAS_STATUS',
        },
      },
    };
    return (
      <div style={{marginBottom: '120px'}}>
        <Segment.Group>
          <Segment color="purple">
            <Header size="large">
              <Header.Content>
                <Fragment>
                  {issue?.state === 'CLOSED' ? (
                    <StateLabel state={issue?.state} />
                  ) : (
                    <Fragment>
                      <StateLabel state={issue?.state} />
                    </Fragment>
                  )}
                </Fragment>{' '}
                <Msg id="GENERAL_IDEAS" />
                <Header.Subheader>{issue?.subject}</Header.Subheader>
              </Header.Content>
            </Header>
            <SubHeaderMeta
              state={issue?.state}
              assignee={issue?.assignee?.name}
              closedAt={issue?.closedAt}
              closedBy={issue?.closedBy}
            />
          </Segment>
          <IdeasButtons
            offeringCompany={offeringCompany}
            userCompany={config.userConfig.offeringCompany}
            editIssueHeaderOfferingCompany={editIssueHeaderOfferingCompany}
            editIssueHeaderUsingCompany={editIssueHeaderUsingCompany}
            modalVisible={modalVisible}
            onTriggerModal={onTriggerModal}
            onSubmit={onSubmit}
            issue={issue}
            fields={fields}
            locale={locale}
            onFollow={onFollow}
            onUnfollow={onUnfollow}
          />
        </Segment.Group>
        <Segment.Group>
          <Segment color="purple">
            <Header size="large">
              <Header.Content>
                {issue?.subject}
                <Header.Subheader>
                  <span style={{paddingRight: '40px'}}>
                    {issue?.author?.name}
                  </span>
                  <span>
                    <DateFormat date={issue?.createdAt} />
                  </span>
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Segment>
          <Segment>
            <IdeasFields locale={locale} issue={issue} config={config} />
          </Segment>
          <Segment clearing>
            <BodyWrapper body={issue?.body} />
            <div style={{marginTop: '20px'}}>
              {issue?.attachments?.length > 0 ? (
                <BoldContent>
                  <Msg id="GENERAL_DOWNLOAD" />:
                </BoldContent>
              ) : null}
              <div>
                <Attachment
                  attachments={issue?.attachments}
                  contextPath={CONTEXT_PATH}
                />
              </div>
            </div>
          </Segment>
        </Segment.Group>
        <Grid>
          <CommentList
            userID={config?.userConfig?.email}
            onLoadIssue={loadIssue}
            issue={issue}
            onEditComment={this.onEditComment}
            editedCommentUuid={editedCommentUuid}
            isOfferingCompany={offeringCompany}
          />
          <CommentCreate onLoadIssue={loadIssue} issue={issue} />
        </Grid>
      </div>
    );
  }
}

IdeasView.propTypes = {
  loadIssue: PropTypes.func,
  modalVisible: PropTypes.bool,
  onTriggerModal: PropTypes.func,
  onSubmit: PropTypes.func,
  //TODO specify
  template: PropTypes.any,
  issue: issueShape,
  config: shapes.userConfig,
  match: PropTypes.any,
  locale: PropTypes.string.isRequired,
  onFollow: PropTypes.func.isRequired,
  onUnfollow: PropTypes.func.isRequired,
};

const IdeasFields = ({issue, config}) => {
  return (
    <Segment.Group>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header>
                <Msg id="GENERAL_GENERAL" />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              <FieldContent
                label="GENERAL_SUPPLIER"
                value={issue?.supplier?.name}
                link={`/supplier/details/${issue?.supplier?.uuid}`}
              />
            </Grid.Column>
            {config.userConfig.offeringCompany && (
              <Grid.Column>
                <FavoriteList issue={issue} />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Segment>
    </Segment.Group>
  );
};

IdeasFields.propTypes = {
  issue: issueShape,
  locale: PropTypes.string.isRequired,
  config: configShape.isRequired,
};

const IdeasButtons = ({
  modalVisible,
  onTriggerModal,
  onSubmit,
  issue,
  fields,
  offeringCompany,
  userCompany,
  onFollow,
  onUnfollow,
  //locale
}) => {
  if (
    (userCompany && offeringCompany) ||
    (!userCompany && !offeringCompany) ||
    (!userCompany && offeringCompany)
  ) {
    return (
      <Segment clearing>
        <Button
          floated="right"
          primary
          as={Link}
          to={`/issues/edit/${issue.issueNumber}?issueType=IDEAS`}>
          <Msg id="GENERAL_EDIT_TICKET" />
        </Button>
        {offeringCompany && (
          <Button floated="right" primary onClick={onTriggerModal}>
            <Msg id="STATUS_ASSIGNEE_BUTTON_CONTENT_2" />
          </Button>
        )}
        <FavoriteButton
          issue={issue}
          onFollow={onFollow}
          onUnfollow={onUnfollow}
        />
        <Modal
          open={modalVisible}
          closeOnDimmerClick={true}
          onClose={onTriggerModal}>
          <Modal.Header>
            <Msg id="EDIT_TICKET_STATUS" />
          </Modal.Header>
          <Modal.Content>
            <PathForm
              onSubmit={onSubmit}
              initialValues={issue}
              formid="modal-autoCompleteDropdown"
              fields={fields}>
              {() => (
                <Fragment>
                  <FormGroup widths="equal">
                    <Input name="state" path="state" translateOptions={true} />
                  </FormGroup>
                </Fragment>
              )}
            </PathForm>
          </Modal.Content>
          <Modal.Actions>
            <ExternalSubmit formid="modal-autoCompleteDropdown" positive>
              <Msg id="IDEAS_SET_TO_COMPLETE" />
            </ExternalSubmit>
            <ExternalSubmit negative onClick={onTriggerModal}>
              <Msg id="GENERAL_CANCEL" />
            </ExternalSubmit>
          </Modal.Actions>
        </Modal>
      </Segment>
    );
  }
};

IdeasButtons.propTypes = {
  issue: issueShape,
  onTriggerModal: PropTypes.func,
  modalVisible: PropTypes.bool,
  onSubmit: PropTypes.func,
  fields: PropTypes.object,
  editIssueHeaderUsingCompany: PropTypes.bool,
  editIssueHeaderOfferingCompany: PropTypes.bool,
  offeringCompany: PropTypes.bool,
  onFollow: PropTypes.func.isRequired,
  onUnfollow: PropTypes.func.isRequired,
};

export default IdeasView;
