import React from 'react';
import PropTypes from 'prop-types';
import {withRouter, Switch} from 'react-router-dom';
import {SidebarHeader} from './Common';
/**
 * Example for the renderRoutes function:
 * const renderRoutes = navProps => [
 *   <Route key="0" path="/foo" render={renderFooNavItems} />,
 *   <Route key="1" path="/bar" render={renderBarNavItems} />
 * ];
 *
 * where `renderFooNavItems` returns a list of <SidebarNavItem />
 *
 */
// eslint-disable-next-line react/prefer-stateless-function
class DynamicSidebar extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    avatar: PropTypes.any,
    header: PropTypes.string,
    subheader: PropTypes.string,
    renderRoutes: PropTypes.func,
  };

  render() {
    const {children, avatar, header, subheader, renderRoutes} = this.props;

    return (
      <div className="ui staticsidebar-container">
        <div className="ui staticsidebar">
          <SidebarHeader
            avatar={avatar}
            header={header}
            subheader={subheader}
          />
          <nav role="navigation">
            <ul>
              <Switch>{renderRoutes(this.props)}</Switch>
            </ul>
          </nav>
        </div>
        <div className="ui viewport">{children}</div>
      </div>
    );
  }
}

export default withRouter(DynamicSidebar);
