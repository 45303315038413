import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Header, List} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {issueShape} from './IssueTracker/shapes';
import {BoldContent} from './IssueTracker/IssueTrackerHelpers';

FavoriteList.propTypes = {
  issue: issueShape.isRequired,
};

function FavoriteList({issue, asHeader}) {
  const followers = issue?.followers || [];
  const headerStyle = asHeader ? {} : {paddingBottom: 0};
  return (
    <Grid>
      <Grid.Row style={headerStyle}>
        <Grid.Column>
          {asHeader ? (
            <Header>
              <FormattedMessage id="GENERAL_FOLLOWERS" />
            </Header>
          ) : (
            <BoldContent>
              <FormattedMessage id="GENERAL_FOLLOWERS" />
            </BoldContent>
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{paddingTop: 0}}>
        <Grid.Column>
          {followers.length === 0 ? (
            <FormattedMessage id="GENERAL_NO_FOLLOWERS" />
          ) : (
            <List bulleted>
              {followers.map((follower) => (
                <List.Item key={follower.uuid}>{follower.name}</List.Item>
              ))}
            </List>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

FavoriteList.defaultProps = {
  asHeader: false,
};

FavoriteList.propTypes = {
  asHeader: PropTypes.bool,
};

export default FavoriteList;
