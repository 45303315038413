import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {toast} from '@ecosio/components';
import {FormattedMessage as Msg} from 'react-intl';
import {
  Form as SemForm,
  Button,
  Segment,
  Grid,
  Header,
} from 'semantic-ui-react';
import {wrappedPUT} from '../../helpers/fetcher';
import AttachmentCommentWrapper from './AttachmentCommentWrapper';
import {commentShape, issueShape} from './IssueTracker/shapes.js';

class CommentCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {createdComment: '', submitted: false};
  }

  onChangeInputFields = (text, attachments) => {
    this.setState({
      submitted: false,
      createdComment: {text, attachments},
    });
  };

  onSubmitCreatedComment = () => {
    const {uuid} = this.props.issue;
    const {onLoadIssue} = this.props;
    const {createdComment} = this.state;
    const url = `/api/issues/${uuid}/comment`;
    return wrappedPUT(url, createdComment)
      .then((res) => {
        if (res.status === 200) {
          onLoadIssue();
          this.setState({submitted: true});
        } else {
          toast({
            title: <Msg id="GENERAL_ERROR" />,
            description: <Msg id="COMMENT_CREATE_ERROR" />,
            type: 'error',
            time: 10000,
          });
          console.error("Comment couldn't be created", res.statusText);
        }
      })
      .catch((res) => {
        toast({
          title: <Msg id="GENERAL_ERROR" />,
          description: <Msg id="COMMENT_CREATE_ERROR" />,
          type: 'error',
          time: 10000,
        });
        console.error("Comment couldn't be created", res.message);
      });
  };

  render() {
    const {createdComment, submitted} = this.state;
    const attachmentsEmpty = (createdComment.attachments || []).length === 0;
    const textEmpty = (createdComment.text || '').trim().length === 0;
    const buttonDisabled = attachmentsEmpty && textEmpty;

    return (
      <Grid.Row>
        <Grid.Column width={12}>
          <Segment color="purple" clearing>
            <Header as="h2" size="medium">
              <Msg id="COMMENT_CREATE_HEADER" />
            </Header>
            <SemForm onSubmit={this.onSubmitCreatedComment}>
              <AttachmentCommentWrapper
                onChange={(text, attachments) =>
                  this.onChangeInputFields(text, attachments)
                }
                attachments={createdComment.attachments || []}
                commentText={createdComment.text || ''}
                submitted={submitted}
              />
              <Button
                disabled={buttonDisabled}
                color="green"
                type="submit"
                floated="right">
                <Msg id="GENERAL_SEND_COMMENT" />
              </Button>
            </SemForm>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

CommentCreate.propTypes = {
  issue: issueShape,
  onLoadIssue: PropTypes.func,
  createdComment: commentShape,
  submited: PropTypes.bool,
};

export default CommentCreate;
