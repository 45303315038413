import React from 'react';
import {Label} from 'semantic-ui-react';
import {FormattedMessage as Msg} from 'react-intl';
import PropTypes from 'prop-types';

const colorForState = (state) => {
  switch (state) {
    // Complaints closed states
    case 'JUSTIFIED':
      return 'orange';
    case 'NOT_JUSTIFIED':
      return 'grey';
    // special release closed states
    case 'APPROVED':
      return 'orange';
    case 'NOT_APPROVED':
      return 'grey';
    // article inspection closed states
    case 'FULLY_APPROVED':
      return 'orange';
    case 'PARTIALLY_APPROVED':
      return 'yellow';
    case 'DECLINED':
      return 'grey';
    case 'CANCELLED':
      return 'red';
    // issue states
    case 'OPEN':
      return 'red';
    case 'CREATED':
      return 'blue';
    case 'CLOSED':
      return 'green';
    default:
      return null;
  }
};

export const StateLabel = ({state}) => {
  const actualState = state || 'OPEN';
  const color = colorForState(state);
  const text = `ISSUE_STATE_${actualState}`;
  return (
    <NoMarginLabel color={color}>
      <Msg id={text} />
    </NoMarginLabel>
  );
};

StateLabel.propTypes = {
  state: PropTypes.string,
};

const NoMarginLabel = ({children, ...props}) => (
  <Label style={{marginLeft: 0}} {...props}>
    {children}
  </Label>
);

NoMarginLabel.propTypes = {
  children: PropTypes.node,
};
