import React from 'react';
import axios from 'axios';
import {
  Segment,
  Header,
  Loader,
  Message,
  Grid,
  List,
  Button,
  Icon,
} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {FormatByteSize, FormatDateTime} from '@ecosio/components';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {isDev} from '../../../util';
import StatusLabel from '../Drawings/StatusLabel';
import {NotSpecified} from '../../FormHelpers';
import {CONTEXT_PATH} from '../../../constants';
import {Recipient} from './DocumentList';

const mapStateToProps = ({config}) => ({config});

@connect(mapStateToProps)
export default class DocumentDetails extends React.Component {
  static propTypes = {
    match: PropTypes.object,
    // TODO proper shape
    config: PropTypes.object,
  };

  state = {
    loading: true,
    error: null,
    data: null,
  };

  async componentDidMount() {
    const {uuid} = this.props.match.params;
    try {
      const response = await axios.get(`/api/documents/${uuid}`);
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        data: response.data,
        error: null,
        loading: false,
      });
    } catch (error) {
      console.error(error);
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        loading: false,
        error: error,
        data: null,
      });
    }
  }

  render() {
    const {data: document, loading, error} = this.state;
    if (loading) {
      return <Loader active />;
    }

    if (error) {
      return (
        <Message error>
          <Message.Header>
            <FormattedMessage id="GENERAL_ERROR" />
          </Message.Header>
          <p>{error.message}</p>
        </Message>
      );
    }

    const {config} = this.props;

    const showDownloadButton = !document.exceptionMessage;
    const downloadHistory = document.downloadRecords || [];
    const recipients = document.recipients;

    const isOfferingCompany = config.userConfig.offeringCompany;

    return (
      <Segment.Group>
        <Segment color="purple">
          <Header as="h1" size="large">
            <FormattedMessage
              id="DOCUMENT_DETAILS_HEADER"
              values={{fileName: document.title}}
            />

            {isOfferingCompany && (
              <Button
                floated="right"
                primary
                as={Link}
                to={`/documents/general/edit/${document.uuid}`}>
                <Icon name="edit" />
                &nbsp;
                <FormattedMessage id="DMS_EDIT_DOCUMENT" />
              </Button>
            )}
          </Header>
        </Segment>
        <Segment>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={10}>
                <Header as="h2" size="medium">
                  <FormattedMessage id="DOCUMENT_INFORMATION" />
                </Header>

                <List divided relaxed>
                  <List.Item>
                    <List.Header>
                      <FormattedMessage id="DOCUMENT_TITLE" />
                    </List.Header>
                    <List.Description>
                      {document.title || <NotSpecified />}
                    </List.Description>
                  </List.Item>

                  <List.Item>
                    <List.Header>
                      <FormattedMessage id="DOCUMENT_DESCRIPTION" />
                    </List.Header>
                    <List.Description>
                      {document.description || <NotSpecified />}
                    </List.Description>
                  </List.Item>

                  <List.Item>
                    <List.Header>
                      <FormattedMessage id="DOCUMENT_UPLOAD_DATE" />
                    </List.Header>
                    <List.Description>
                      <FormatDateTime dateString={document.createdAt} />
                    </List.Description>
                  </List.Item>

                  <List.Item>
                    <List.Header>
                      <FormattedMessage id="DRAWING_AVAILABLE_UNTIL" />
                    </List.Header>
                    <List.Description>
                      <FormatDateTime
                        dateString={document.expiryInfo?.expiryDate}
                      />
                    </List.Description>
                  </List.Item>

                  <List.Item>
                    <List.Header>
                      <FormattedMessage id="GENERAL_STATUS" />
                    </List.Header>
                    <List.Description>
                      <StatusLabel status={document.status} />
                    </List.Description>
                  </List.Item>
                </List>
                {isOfferingCompany && (
                  <>
                    <Header as="h2" size="medium">
                      <FormattedMessage id="DMS_RECIPIENTS_INFO" />
                    </Header>
                    <List divided relaxed>
                      {recipients
                        .filter((r) => !r.hidden)
                        .map((r, idx) => (
                          <List.Item key={idx}>
                            <List.Item>
                              <List.Header>
                                <FormattedMessage
                                  id={`RECIPIENT_TYPE_${r.type}`}
                                />
                              </List.Header>
                              <List.Description>
                                <Recipient recipient={r} />
                              </List.Description>
                            </List.Item>
                          </List.Item>
                        ))}
                    </List>
                  </>
                )}
              </Grid.Column>

              <Grid.Column width={6}>
                <Header size="medium" as="h2">
                  <FormattedMessage id="GENERAL_FILE" />
                </Header>
                <List relaxed divided>
                  <List.Item>
                    <List.Header>
                      <FormattedMessage id="GENERAL_FILE_NAME" />
                    </List.Header>
                    <List.Description>{document.fileName}</List.Description>
                  </List.Item>

                  <List.Item>
                    <List.Header>
                      <FormattedMessage id="GENERAL_FILE_SIZE" />
                    </List.Header>
                    <List.Description>
                      {FormatByteSize(document.fileSize || 0)}
                    </List.Description>
                  </List.Item>

                  <List.Item>
                    <List.Header>
                      <FormattedMessage id="GENERAL_MD5_HASH" />
                    </List.Header>
                    <List.Description as="code">
                      {document.fileChecksum}
                    </List.Description>
                  </List.Item>
                </List>
                {showDownloadButton && (
                  <Button
                    positive
                    href={`${
                      isDev ? 'http://127.0.0.1:8822' : ''
                    }${CONTEXT_PATH}/api/documents/${document.uuid}/download`}
                    target="_blank"
                    disabled={
                      document.status !== 'PICKEDUP' &&
                      document.status !== 'DATA_RECEIVED'
                    }>
                    <Icon name="download" />{' '}
                    <FormattedMessage id="GENERAL_DOWNLOAD" />
                  </Button>
                )}

                <Header size="medium" as="h2">
                  <FormattedMessage id="DRAWING_DOWNLOAD_HISTORY" />
                </Header>
                <List divided>
                  {downloadHistory.length === 0 ? (
                    <p>
                      <FormattedMessage id="DRAWING_NO_DOWNLOADS" />
                    </p>
                  ) : (
                    downloadHistory.map((entry, idx) => (
                      <List.Item key={idx}>
                        <List style={{padding: '0'}}>
                          <List.Item>
                            <b>{entry.userName}</b>
                          </List.Item>
                          <List.Item>
                            <FormatDateTime dateString={entry.downloadedAt} />
                          </List.Item>
                        </List>
                      </List.Item>
                    ))
                  )}
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Segment.Group>
    );
  }
}
