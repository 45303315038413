import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Icon, Grid, Modal, Popup} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import {connect} from 'react-redux';
import {FORM_ERROR} from 'final-form';

import {Form, Input, ExternalSubmit} from '@ecosio/pathform';
import {ASSESSMENT_STATES} from '../../../helpers/assessment_states';
import {postSupplier} from '../../../reducers/supplierExchange';
import {convertToFormData} from '../../../util';
import {
  getDownloadUrl,
  UploadWrapper,
  ResponsiveGridColumn,
} from '../../FormHelpers';
import FormStateToRedux, {getFormState} from '../../FormStateToRedux';
import {supplierShape} from '../shapes';
import {CONTEXT_PATH} from '../../../constants';
import AssignedCategorySelector from './AssignedCategorySelector';

@connect(null, {postSupplier})
class PostponeModal extends Component {
  onSend = (values) => {
    const nextAssessment = moment(values.nextAssessment);
    const formatted = nextAssessment.format('YYYY-MM-DD');
    const previousState = this.props.supplier.status;
    const supplier = {
      ...this.props.supplier,
      ...values,
      status: 'ASSESSMENT_POSTPONED',
      nextAssessment: formatted,
    };

    const formData = convertToFormData(supplier);
    this.props.postSupplier(formData, {previousState});
    this.props.onClose();
  };

  render() {
    const {supplier, ...modalProps} = this.props;
    const fields = {
      nextAssessment: {
        path: 'nextAssessment',
        type: 'date',
        input: {
          type: 'text',
          stripTimezone: false,
        },
      },
    };
    return (
      <Modal {...modalProps}>
        <Modal.Header>
          <p>
            <FormattedMessage id="POSTPONE_MODAL_HEADER" />
          </p>
        </Modal.Header>
        <Modal.Content>
          <FormattedMessage id="POSTPONE_MODAL_BODY" />

          <Form
            subscription={{invalid: true, pristine: true}}
            formid="postpone-modal-form"
            fields={fields}
            onSubmit={this.onSend}
            initialValues={supplier}>
            {() => (
              <Input
                name="nextAssessment"
                path="nextAssessment"
                renderLabel={false}
              />
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <ExternalSubmit positive formid="postpone-modal-form">
            <FormattedMessage id="GENERAL_CONFIRM" />
          </ExternalSubmit>
          <Button negative onClick={this.props.onClose}>
            <FormattedMessage id="GENERAL_CANCEL" />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

PostponeModal.propTypes = {
  supplier: supplierShape,
  postSupplier: PropTypes.func,
  onClose: PropTypes.func,
};

@connect(null, {postSupplier})
class InfoModal extends Component {
  onSend = (values) => {
    const previousState = this.props.supplier.status;

    const supplier = {
      ...this.props.supplier,
      ...values,
      status: 'ASSESSMENT',
    };

    const formData = convertToFormData(supplier);
    this.props.postSupplier(formData, {previousState});
    this.props.onClose();
  };

  render() {
    const {supplier, ...modalProps} = this.props;
    const fields = {
      assessmentFeedback: {
        path: 'assessmentFeedback',
        type: 'textarea',
        input: {
          type: 'text',
        },
      },
    };

    return (
      <Modal {...modalProps}>
        <Modal.Header>
          <FormattedMessage id="INFO_MODAL_HEADER" />
        </Modal.Header>
        <Modal.Content>
          <p>
            <FormattedMessage id="INFO_MODAL_BODY" />
          </p>

          <Form
            subscription={{invalid: true, pristine: true}}
            onSubmit={this.onSend}
            fields={fields}
            initialValues={supplier}
            formid="info-modal-form">
            {() => (
              <Input
                name="assessmentFeedback"
                path="assessmentFeedback"
                renderLabel={false}
              />
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <ExternalSubmit positive formid="info-modal-form">
            <FormattedMessage id="GENERAL_CONFIRM" />
          </ExternalSubmit>
          <Button negative onClick={this.props.onClose}>
            <FormattedMessage id="GENERAL_CANCEL" />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

InfoModal.propTypes = {
  supplier: supplierShape,
  postSupplier: PropTypes.func,
  onClose: PropTypes.func,
};

const buildPreCheckUrl = (supplier) => {
  return `${CONTEXT_PATH}/api/suppliers/${supplier.uuid}/attachments/${supplier.preCheckProtocol.uuid}?type=PRE_CHECK`;
};

const ButtonBar = ({
  positive,
  negative,
  postpone,
  additionalInfo,
  onButtonClicked,
  onEditButtonClick,
  supplier,
}) => {
  // hide the popup when the button is clickable (for now)
  const popupStyle = positive && positive.disabled ? undefined : {opacity: '0'};
  const hasPreCheckProtocol = supplier.preCheckProtocol.uuid != null;
  const preCheckUrl = hasPreCheckProtocol ? buildPreCheckUrl(supplier) : null;

  return (
    <Fragment>
      <Button.Group>
        {positive && (
          <Popup
            position="top center"
            style={popupStyle}
            trigger={
              <span>
                <Button
                  positive
                  icon
                  onClick={() => onButtonClicked(positive.state)}
                  disabled={positive.disabled}>
                  <Icon name="checkmark" />
                  &nbsp;
                  <FormattedMessage id={positive.text} />
                </Button>
              </span>
            }
            content={
              positive.disabled && <FormattedMessage id={positive.helpText} />
            }
          />
        )}

        {negative && (
          <Button
            negative
            icon
            onClick={() => onButtonClicked(negative.state)}
            disabled={negative.disabled}>
            <Icon name="close" />
            &nbsp;
            <FormattedMessage id={negative.text} />
          </Button>
        )}

        {additionalInfo && (
          <Button
            color="blue"
            icon
            onClick={additionalInfo.onClick}
            disabled={additionalInfo.disabled}>
            <Icon name="add" />
            &nbsp;
            <FormattedMessage id={additionalInfo.text} />
          </Button>
        )}

        {postpone && (
          <Button
            color="yellow"
            onClick={postpone.onClick}
            disabled={postpone.disabled}>
            <Icon name="clock outline" />
            &nbsp;
            <FormattedMessage id={postpone.text} />
          </Button>
        )}
      </Button.Group>
      <Popup
        style={!hasPreCheckProtocol ? undefined : {opacity: '0'}}
        position="top center"
        trigger={
          <span>
            <Button
              color="purple"
              style={{marginLeft: '1.5rem'}}
              disabled={!hasPreCheckProtocol}
              as="a"
              href={preCheckUrl}
              target="_blank">
              <Icon name="download" />
              &nbsp;
              <FormattedMessage id="DOWNLOAD_PRE_CHECK_PROTOCOL" />
            </Button>
          </span>
        }
        content={
          !hasPreCheckProtocol && (
            <FormattedMessage id="NO_PRE_CHECK_PROTOCOL" />
          )
        }
      />

      <Button
        color="purple"
        style={{marginLeft: '0.2rem'}}
        data-spec="edit-button"
        onClick={onEditButtonClick}>
        <Icon name="edit" />
        &nbsp;
        <FormattedMessage id="EDIT_HEADER_FORM" />
      </Button>
    </Fragment>
  );
};

ButtonBar.propTypes = {
  supplier: supplierShape,
  postpone: PropTypes.shape({
    disabled: PropTypes.bool,
    state: PropTypes.string,
    text: PropTypes.string,
  }),
  negative: PropTypes.shape({
    state: PropTypes.string,
    text: PropTypes.string,
  }),
  positive: PropTypes.shape({
    disabled: PropTypes.bool,
    state: PropTypes.string,
    text: PropTypes.string,
  }),
  additionalInfo: PropTypes.shape({
    disabled: PropTypes.bool,
    text: PropTypes.string,
  }),
  onButtonClicked: PropTypes.func,
  onEditButtonClick: PropTypes.func,
};

@connect(null, {postSupplier})
class HeaderForm extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    supplier: PropTypes.any.isRequired,
    setNotification: PropTypes.func.isRequired,
  };

  onSubmit = (values) => {
    const supplier = {
      ...this.props.supplier,
      ...values,
    };

    const formData = convertToFormData(supplier);
    this.props.postSupplier(formData).catch((error) => {
      console.error(error);
      this.props.setNotification({
        negative: true,
        header: <FormattedMessage id="GENERAL_SUBMIT_ERROR_HEADER" />,
        body: <FormattedMessage id="GENERAL_SUBMIT_ERROR_MSG" />,
      });
    });
  };

  render() {
    const {locale, supplier} = this.props;

    const fields = {
      assessmentFeedback: {
        path: 'assessmentFeedback',
        type: 'textarea',
        input: {
          type: 'text',
          label: 'ASSESSMENT_FEEDBACK',
        },
      },
      assignedCategoryId: {
        path: 'assignedCategoryId',
        type: 'dropdown',
        input: {
          type: 'text',
          label: 'ASSIGNED_CATEGORY_ID',
          translateOptions: false,
          rows: 6,
          required: true,
        },
      },
      testRequestDone: {
        path: 'testRequestDone',
        type: 'dropdown',
        input: {
          type: 'text',
          label: 'TEST_REQUEST_DONE',
          required: true,
        },
        options: [
          {text: 'GENERAL_YES', value: true},
          {
            text: 'GENERAL_NO',
            value: false,
          },
        ],
      },
      preCheckState: {
        path: 'preCheckState',
        type: 'dropdown',
        input: {
          type: 'text',
          label: 'PRE_CHECK_STATE',
          required: true,
        },
        options: [
          {text: 'PRE_CHECK_OPEN', value: 'OPEN'},
          {text: 'PRE_CHECK_REJECTED', value: 'REJECTED'},
          {text: 'PRE_CHECK_ACCEPTED', value: 'ACCEPTED'},
        ],
      },
      preCheckProtocol: {
        path: 'preCheckProtocol',
        type: 'upload',
        input: {
          type: 'text',
          label: 'PRE_CHECK_PROTOCOL',
          inline: false,
        },
      },
      internalSupplierIds: {
        path: 'internalSupplierIds',
        type: 'text',
        input: {
          type: 'text',
          label: 'INTERNAL_SUPPLIER_ID',
        },
      },
    };

    const preCheck = supplier.preCheckProtocol;
    const downloadUrl =
      preCheck.uuid &&
      getDownloadUrl(supplier.uuid, 'PRE_CHECK', preCheck.uuid);

    return (
      <Form
        validate={mayAcceptSupplier}
        subscription={{invalid: true, pristine: true}}
        onSubmit={this.onSubmit}
        fields={fields}
        initialValues={supplier}>
        {() => (
          <Fragment>
            <FormStateToRedux form="supplier" />

            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <AssignedCategorySelector
                    locale={locale}
                    supplierId={supplier.uuid}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Input
                    name="testRequestDone"
                    path="testRequestDone"
                    translateOptions
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={8}>
                  <Input name="assessmentFeedback" path="assessmentFeedback" />
                  <Input
                    name="internalSupplierIds"
                    path="internalSupplierIds"
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Input
                    name="preCheckState"
                    path="preCheckState"
                    translateOptions
                  />

                  <UploadWrapper
                    useForm
                    path="preCheckProtocol"
                    name="preCheckProtocol.file"
                    inline={false}
                    downloadUrl={downloadUrl}
                    originalFileName={preCheck.originalFileName}
                    buttonSize="small"
                    downloadButton={false}
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Button
                    positive
                    icon
                    type="button"
                    onClick={() =>
                      this.onSubmit(getFormState('supplier')?.values)
                    }>
                    <Icon name="checkmark" />
                    &nbsp;
                    <FormattedMessage id="GENERAL_SAVE" />
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Fragment>
        )}
      </Form>
    );
  }
}

const mayAcceptSupplier = (supplier) => {
  if (!supplier) {
    return false;
  }

  const hasAssignedCategory = !!supplier.assignedCategoryId;
  const testRequestDone = supplier.testRequestDone === true;
  const preCheckDone = supplier.preCheckState === 'ACCEPTED';
  // See https://gitlab.ecosio.com/code/frontend/issues/578
  const isValid = hasAssignedCategory && testRequestDone && preCheckDone;
  if (isValid) {
    return undefined;
  } else {
    return {
      [FORM_ERROR]: 'placeholder',
    };
  }
};

const mapStateToProps = ({formState}) => ({
  supplierForm: formState.supplier,
});

@connect(mapStateToProps)
export default class OfferingCompanyHeader extends Component {
  static propTypes = {
    assessmentState: PropTypes.string.isRequired,
    updateSupplierState: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    supplier: PropTypes.any,
    locale: PropTypes.string,
  };

  state = {
    postponeModalOpen: false,
    infoModalOpen: false,
    formOpen: false,
  };

  openPostponeModal = () => {
    this.setState({
      postponeModalOpen: true,
    });
  };

  closePostponeModal = () => {
    this.setState({
      postponeModalOpen: false,
    });
  };

  openInfoModal = () => {
    this.setState({
      infoModalOpen: true,
    });
  };

  closeInfoModal = () => {
    this.setState({
      infoModalOpen: false,
    });
  };

  toggleFormVisible = () => {
    this.setState((prevState) => ({
      formOpen: !prevState.formOpen,
    }));
  };

  onButtonClicked = (newState) => {
    const {openModal, updateSupplierState} = this.props;
    const {assignedCategoryId, assessmentFeedback} = this.state;

    openModal(() => {
      updateSupplierState(
        newState,
        {assignedCategoryId, assessmentFeedback},
        getFormState('supplier')
      );
    });
  };

  render() {
    const {
      assessmentState,
      supplier,
      locale,
      supplierForm,
      setNotification,
    } = this.props;
    const {formOpen} = this.state;

    let content = null;
    const isValid = supplierForm?.valid;

    switch (assessmentState) {
      case ASSESSMENT_STATES.PRE_ASSESSMENT_UNDER_REVIEW:
        content = (
          <Fragment>
            <Grid.Row>
              <Grid.Column>
                <ButtonBar
                  positive={{
                    state: ASSESSMENT_STATES.WAITING_FOR_OPERATOR,
                    text: 'ACCEPT_SSA',
                  }}
                  negative={{
                    state: ASSESSMENT_STATES.PRE_ASSESSMENT_REJECTED,
                    text: 'REJECT_SSA',
                  }}
                  onButtonClicked={this.onButtonClicked}
                  onEditButtonClick={this.toggleFormVisible}
                  supplier={supplier}
                />
              </Grid.Column>
            </Grid.Row>

            {formOpen && (
              <Grid.Row>
                <ResponsiveGridColumn>
                  <HeaderForm
                    locale={locale}
                    supplier={supplier}
                    setNotification={setNotification}
                  />
                </ResponsiveGridColumn>
              </Grid.Row>
            )}
          </Fragment>
        );
        break;
      case ASSESSMENT_STATES.PRE_ASSESSMENT_REJECTED:
        content = (
          <Fragment>
            <Grid.Row>
              <Grid.Column>
                <ButtonBar
                  positive={{
                    text: 'RE_ACTIVATE_SSA',
                    state: ASSESSMENT_STATES.PRE_ASSESSMENT_UNDER_REVIEW,
                  }}
                  onButtonClicked={this.onButtonClicked}
                  onEditButtonClick={this.toggleFormVisible}
                  supplier={supplier}
                />
              </Grid.Column>
            </Grid.Row>

            {formOpen && (
              <Grid.Row>
                <ResponsiveGridColumn>
                  <HeaderForm
                    locale={locale}
                    supplier={supplier}
                    setNotification={setNotification}
                  />
                </ResponsiveGridColumn>
              </Grid.Row>
            )}
          </Fragment>
        );
        break;
      case ASSESSMENT_STATES.WAITING_FOR_OPERATOR:
        content = null;
        break;

      case ASSESSMENT_STATES.ASSESSMENT:
        content = (
          <Fragment>
            <Grid.Row>
              <Grid.Column>
                <ButtonBar
                  positive={{
                    state: ASSESSMENT_STATES.ASSESSMENT_COMPLETED,
                    text: 'ACCEPT_SUPPLIER',
                    helpText: 'ACCEPT_SUPPLIER_HELP_TEXT',
                    disabled: !isValid,
                  }}
                  negative={{
                    state: ASSESSMENT_STATES.ASSESSMENT_REJECTED,
                    text: 'REJECT_SUPPLIER',
                  }}
                  postpone={{
                    state: ASSESSMENT_STATES.ASSESSMENT_POSTPONED,
                    text: 'POSTPONE_SUPPLIER',
                    disabled: true,
                  }}
                  additionalInfo={{
                    text: 'ADDITIONAL_INFORMATION_SUPPLIER',
                    disabled: true,
                  }}
                  onButtonClicked={this.onButtonClicked}
                  onEditButtonClick={this.toggleFormVisible}
                  supplier={supplier}
                />
              </Grid.Column>
            </Grid.Row>

            {formOpen && (
              <Grid.Row>
                <ResponsiveGridColumn>
                  <HeaderForm
                    locale={locale}
                    supplier={supplier}
                    setNotification={setNotification}
                  />
                </ResponsiveGridColumn>
              </Grid.Row>
            )}
          </Fragment>
        );
        break;

      case ASSESSMENT_STATES.ASSESSMENT_UNDER_REVIEW:
        content = (
          <Fragment>
            <Grid.Row>
              <Grid.Column>
                <ButtonBar
                  positive={{
                    state: ASSESSMENT_STATES.ASSESSMENT_COMPLETED,
                    text: 'ACCEPT_SUPPLIER',
                    helpText: 'ACCEPT_SUPPLIER_HELP_TEXT',
                    disabled: !isValid,
                  }}
                  negative={{
                    state: ASSESSMENT_STATES.ASSESSMENT_REJECTED,
                    text: 'REJECT_SUPPLIER',
                  }}
                  postpone={{
                    state: ASSESSMENT_STATES.ASSESSMENT_POSTPONED,
                    text: 'POSTPONE_SUPPLIER',
                    onClick: this.openPostponeModal,
                  }}
                  additionalInfo={{
                    text: 'ADDITIONAL_INFORMATION_SUPPLIER',
                    onClick: this.openInfoModal,
                  }}
                  onButtonClicked={this.onButtonClicked}
                  onEditButtonClick={this.toggleFormVisible}
                  supplier={supplier}
                />
              </Grid.Column>
            </Grid.Row>
            {formOpen && (
              <Grid.Row>
                <ResponsiveGridColumn>
                  <HeaderForm
                    locale={locale}
                    supplier={supplier}
                    setNotification={setNotification}
                  />
                </ResponsiveGridColumn>
              </Grid.Row>
            )}
          </Fragment>
        );
        break;

      case ASSESSMENT_STATES.ASSESSMENT_POSTPONED:
        content = (
          <Fragment>
            <Grid.Row>
              <Grid.Column>
                <ButtonBar
                  positive={{
                    state: ASSESSMENT_STATES.ASSESSMENT_COMPLETED,
                    text: 'ACCEPT_SUPPLIER',
                    helpText: 'ACCEPT_SUPPLIER_HELP_TEXT',
                    disabled: !isValid,
                  }}
                  negative={{
                    state: ASSESSMENT_STATES.ASSESSMENT_REJECTED,
                    text: 'REJECT_SUPPLIER',
                  }}
                  postpone={{
                    state: ASSESSMENT_STATES.ASSESSMENT_POSTPONED,
                    text: 'POSTPONE_SUPPLIER',
                    onClick: this.openPostponeModal,
                  }}
                  additionalInfo={{
                    text: 'ADDITIONAL_INFORMATION_SUPPLIER',
                    onClick: this.openInfoModal,
                  }}
                  onButtonClicked={this.onButtonClicked}
                  onEditButtonClick={this.toggleFormVisible}
                  supplier={supplier}
                />
              </Grid.Column>
            </Grid.Row>

            {formOpen && (
              <Grid.Row>
                <ResponsiveGridColumn>
                  <HeaderForm
                    locale={locale}
                    supplier={supplier}
                    setNotification={setNotification}
                  />
                </ResponsiveGridColumn>
              </Grid.Row>
            )}
          </Fragment>
        );
        break;
      case ASSESSMENT_STATES.ASSESSMENT_REJECTED:
        content = (
          <Grid.Row>
            <Grid.Column>
              <ButtonBar
                positive={{
                  text: 'RE_ACTIVATE_SSA',
                  state: ASSESSMENT_STATES.ASSESSMENT,
                }}
                onButtonClicked={this.onButtonClicked}
                onEditButtonClick={this.toggleFormVisible}
                supplier={supplier}
              />
            </Grid.Column>
          </Grid.Row>
        );
        break;
      case ASSESSMENT_STATES.ASSESSMENT_COMPLETED:
        content = (
          <Fragment>
            <Grid.Row width={8}>
              <ButtonBar
                onEditButtonClick={this.toggleFormVisible}
                supplier={supplier}
              />
            </Grid.Row>

            {formOpen && (
              <Grid.Row>
                <ResponsiveGridColumn>
                  <HeaderForm
                    locale={locale}
                    supplier={supplier}
                    setNotification={setNotification}
                  />
                </ResponsiveGridColumn>
              </Grid.Row>
            )}
          </Fragment>
        );
        break;
    }

    return (
      <Fragment>
        <PostponeModal
          onOpen={this.openPostponeModal}
          onClose={this.closePostponeModal}
          open={this.state.postponeModalOpen}
          supplier={supplier}
        />
        <InfoModal
          onOpen={this.openInfoModal}
          onClose={this.closeInfoModal}
          open={this.state.infoModalOpen}
          supplier={supplier}
        />
        <Grid divided="vertically">{content}</Grid>
      </Fragment>
    );
  }
}
