import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, Icon} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {LocaleAnchor} from '@ecosio/customer-layout';
import {userLinks, adminLinks} from './UserLinks';

const AdminLinks = ({baseUrl}) => {
  return (
    <Fragment>
      <Dropdown.Header>
        <FormattedMessage id="GENERAL_ADMIN" />
      </Dropdown.Header>
      {adminLinks.map(
        (item) =>
          item.render && (
            <Dropdown.Item
              as={LocaleAnchor}
              key={item.key}
              url={`${baseUrl}/${item.slug}`}
              target={item.target}>
              <FormattedMessage id={item.name} />
            </Dropdown.Item>
          )
      )}
    </Fragment>
  );
};

AdminLinks.propTypes = {
  baseUrl: PropTypes.string.isRequired,
};

const UserLinks = ({baseUrl}) => (
  <Fragment>
    <Dropdown.Header>
      <FormattedMessage id="GENERAL_SETTINGS" />
    </Dropdown.Header>

    {userLinks.map(
      (item) =>
        item.render && (
          <Dropdown.Item
            as={LocaleAnchor}
            key={item.key}
            data-spec={`usr-${item.key}`}
            url={`${baseUrl}/${item.slug}`}
            target={item.target}>
            <FormattedMessage id={item.name} />
          </Dropdown.Item>
        )
    )}
  </Fragment>
);

UserLinks.propTypes = {
  baseUrl: PropTypes.string.isRequired,
};

const Logout = ({baseUrl}) => (
  <Dropdown.Item
    as={LocaleAnchor}
    url={`${baseUrl}/logout`}
    data-spec="logout-menu-point">
    <FormattedMessage id="GENERAL_LOGOUT" />
  </Dropdown.Item>
);

const Settings = ({baseUrl}) => {
  return (
    <Dropdown.Item
      as={LocaleAnchor}
      url={`${baseUrl}/settings`}
      data-spec="settings-menu-point">
      <FormattedMessage id="GENERAL_SETTINGS" />
    </Dropdown.Item>
  );
};
Settings.propTypes = {
  baseUrl: PropTypes.string.isRequired,
};

Logout.propTypes = {
  baseUrl: PropTypes.string.isRequired,
};

const UserDropdown = ({baseUrl, userConfig}) => {
  const isAdmin = userConfig.authorities.indexOf('COMPANY_ADMIN') > -1;

  const trigger = (
    <span>
      <Icon name="user" /> {userConfig.email}
    </span>
  );
  return (
    <Dropdown item pointing="top right" trigger={trigger} data-spec="user-dd">
      <Dropdown.Menu>
        {/*
        <UserLinks baseUrl={baseUrl} />
        */}
        {isAdmin && <AdminLinks baseUrl={baseUrl} />}
        <Settings baseUrl={baseUrl} />
        <Logout baseUrl={baseUrl} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

UserDropdown.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  userConfig: PropTypes.object,
};

export default UserDropdown;
