import React, {Component, Fragment} from 'react';
import {Button, Header, Segment, Popup, Form} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import qs from 'qs';
import {connect} from 'react-redux';
import {Form as PathForm, FormGroup, Input, Reset} from '@ecosio/pathform';
import AssignedCategorySelector from '../SupplierDetails/PageHeader/AssignedCategorySelector';

const createTypedStatusField = (type, intl) => {
  let label;
  let options;
  switch (type) {
    case 'COMPLAINT':
      label = 'COMPLAINT_STATUS';
      options = [
        {text: intl.formatMessage({id: 'JUSTIFIED'}), value: 'JUSTIFIED'},
        {
          text: intl.formatMessage({id: 'NOT_JUSTIFIED'}),
          value: 'NOT_JUSTIFIED',
        },
      ];
      break;
    case 'SPECIAL_RELEASE':
      label = 'SPECIAL_RELEASE_STATE';
      options = [
        {
          text: intl.formatMessage({id: 'SPECIAL_RELEASES_APPROVED'}),
          value: 'APPROVED',
        },
        {
          text: intl.formatMessage({id: 'SPECIAL_RELEASES_REJECTED'}),
          value: 'REJECTED',
        },
      ];
      break;
    case 'ARTICLE_INSPECTION':
      label = 'ARTICLE_INSPECTION_STATE';
      options = [
        {
          text: intl.formatMessage({id: 'ARTICLE_INSPECTION_FULLY_APPROVED'}),
          value: 'FULLY_APPROVED',
        },
        {
          text: intl.formatMessage({
            id: 'ARTICLE_INSPECTION_PARTIALLY_APPROVED',
          }),
          value: 'PARTIALLY_APPROVED',
        },
        {
          text: intl.formatMessage({id: 'ARTICLE_INSPECTION_DECLINED'}),
          value: 'DECLINED',
        },
        {
          text: intl.formatMessage({id: 'ARTICLE_INSPECTION_CANCELLED'}),
          value: 'CANCELLED',
        },
      ];
      break;
    default:
      return;
  }

  return {
    path: 'typedStatus',
    type: 'dropdown',
    input: {
      text: 'text',
      label,
    },
    options,
  };
};

class IssueModuleFilter extends Component {
  constructor(props) {
    super(props);
    const {location} = props;

    const initialFilterValues = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    this.state = {
      initialFilterValues,
    };
  }

  onApply = (values) => {
    this.props.history.push({
      search: `?${qs.stringify(values)}`,
    });
  };

  render() {
    const {initialFilterValues} = this.state;
    const {
      intl,
      currentIssueType,
      offeringCompanyFlag,
      currentStatus,
    } = this.props;

    const typedStatus = createTypedStatusField(
      this.props.currentIssueType,
      this.props.intl
    );

    if (typedStatus) {
      fields.typedStatus = typedStatus;
    }

    return (
      <Fragment>
        <Header size="medium" as="h3">
          <Header.Content>
            <FormattedMessage id="GENERAL_FILTER_PANEL_HEADER" />
          </Header.Content>
        </Header>
        <PathForm
          onSubmit={this.onApply}
          fields={fields}
          initialValues={initialFilterValues}>
          {() => (
            <Fragment>
              <FormGroup>
                <Input
                  name="query"
                  path="query"
                  renderLabel={false}
                  width="15"
                  placeholder={intl.formatMessage({
                    id: 'GENERAL_SEARCH_PLACEHOLDER',
                  })}
                />
                <AdvancedPop
                  offeringCompanyFlag={offeringCompanyFlag}
                  currentIssueType={currentIssueType}
                  currentStatus={currentStatus}
                  trigger={
                    <Button color="purple" type="button">
                      <FormattedMessage id="GENERAL_ADVANCED" />
                    </Button>
                  }
                />
                <Button type="submit" positive>
                  <FormattedMessage id="GENERAL_APPLY" />
                </Button>
              </FormGroup>
            </Fragment>
          )}
        </PathForm>
      </Fragment>
    );
  }
}

IssueModuleFilter.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  intl: PropTypes.object,
};

const fields = {
  query: {
    path: 'query',
    type: 'text',
    input: {
      type: 'text',
      disabled: false,
    },
  },
  orderNumber: {
    path: 'orderNumber',
    type: 'text',
    input: {
      // url: '/api/lineItem/suggest/orderNumber',
      text: 'text',
      label: 'GENERAL_ORDER_NUMBER',
    },
  },
  materialNumber: {
    path: 'materialNumber',
    type: 'text',
    input: {
      text: 'text',
      label: 'MATERIAL_NUMBER',
    },
  },
  supplierMaterialNumber: {
    path: 'supplierMaterialNumber',
    type: 'text',
    input: {
      text: 'text',
      label: 'SUPPLIER_MATERIAL_NUMBER',
    },
  },
  factoryIdentifier: {
    path: 'factoryIdentifier',
    type: 'text',
    input: {
      text: 'text',
      label: 'FACTORY_IDENTIFIER',
    },
  },
  externalComplaintReference: {
    path: 'externalComplaintReference',
    type: 'text',
    input: {
      text: 'text',
      label: 'COMPLAINT_NUMBER_FILTER',
    },
  },

  // TODO figure out how to pre-fill values in autocomplete dropdown here
  supplierName: {
    path: 'supplierName',
    type: 'text',
    input: {
      // url: '/api/suppliers/suggest',
      label: 'GENERAL_SUPPLIER',
    },
  },

  assigneeName: {
    path: 'assigneeName',
    type: 'text',
    input: {
      // url: '/api/users/suggest',
      label: 'GENERAL_ASSIGNEE',
    },
  },
  createdFrom: {
    path: 'createdFrom',
    type: 'date',
    input: {
      onlyDateValue: true,
      text: 'text',
      label: 'CREATED_FROM',
    },
  },
  createdTo: {
    path: 'createdTo',
    type: 'date',
    input: {
      onlyDateValue: true,
      text: 'text',
      label: 'CREATED_TO',
    },
  },
  closedAtFrom: {
    path: 'closedAtFrom',
    type: 'date',
    input: {
      onlyDateValue: true,
      text: 'text',
      label: 'GENERAL_CLOSED_AT_FROM',
    },
  },
  closedAtTo: {
    path: 'closedAtTo',
    type: 'date',
    input: {
      onlyDateValue: true,
      text: 'text',
      label: 'GENERAL_CLOSED_AT_TO',
    },
  },
  closedByName: {
    path: 'closedByName',
    type: 'text',
    input: {
      text: 'text',
      label: 'GENERAL_CLOSED_BY',
    },
  },
  status: {
    path: 'status',
    type: 'dropdown',
    input: {
      text: 'text',
      label: 'GENERAL_ISSUE_STATUS',
    },
    options: [
      {text: 'OPEN', value: 'OPEN'},
      {text: 'CLOSED', value: 'CLOSED'},
      {text: 'ALL', value: 'ALL'},
      {text: 'MY', value: 'MY'},
    ],
  },
  assignedCategoryIds: {
    path: 'assignedCategoryIds',
    type: 'dropdown',
    input: {
      type: 'text',
      multiple: true,
      label: 'ASSIGNED_CATEGORY_ID',
    },
  },
};

let AdvancedPop = ({
  locale,
  offeringCompanyFlag,
  currentIssueType,
  currentStatus,
  ...props
}) => {
  return (
    <Popup {...props} on="click" wide="very" position="bottom right">
      <div style={{minWidth: '600px'}}>
        <Segment>
          <Header as="h3">
            <Header.Content>
              <FormattedMessage id="GENERAL_FILTERS" />
            </Header.Content>
          </Header>
          <div className="ui form">
            {!offeringCompanyFlag ? (
              <Fragment>
                <FormGroup widths="equal">
                  <Input
                    name="createdFrom"
                    path="createdFrom"
                    stripTimezone={false}
                  />
                  <Input
                    name="createdTo"
                    path="createdTo"
                    stripTimezone={false}
                  />
                </FormGroup>
                <FormGroup widths="equal">
                  <Input
                    name="closedAtFrom"
                    path="closedAtFrom"
                    stripTimezone={false}
                  />
                  <Input
                    name="closedAtTo"
                    path="closedAtTo"
                    stripTimezone={false}
                  />
                </FormGroup>
                <FormGroup widths="equal">
                  <Input name="closedByName" path="closedByName" width={8} />
                  <Input name="status" path="status" width={8} />
                </FormGroup>
                <FormGroup>
                  <Input
                    name="materialNumber"
                    path="materialNumber"
                    width={8}
                  />
                  <Input
                    width={8}
                    name="supplierMaterialNumber"
                    path="supplierMaterialNumber"
                  />
                </FormGroup>
                <FormGroup>
                  <Input name="orderNumber" path="orderNumber" width={8} />
                  <Input
                    width={8}
                    name="factoryIdentifier"
                    path="factoryIdentifier"
                  />
                </FormGroup>
                {currentIssueType === 'COMPLAINT' && (
                  <FormGroup>
                    <Input
                      name="externalComplaintReference"
                      path="externalComplaintReference"
                      width={8}
                    />
                  </FormGroup>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <FormGroup widths="equal">
                  <Input
                    name="createdFrom"
                    path="createdFrom"
                    stripTimezone={false}
                  />
                  <Input
                    name="createdTo"
                    path="createdTo"
                    stripTimezone={false}
                  />
                </FormGroup>
                <FormGroup widths="equal">
                  <Input
                    name="closedAtFrom"
                    path="closedAtFrom"
                    stripTimezone={false}
                  />
                  <Input
                    name="closedAtTo"
                    path="closedAtTo"
                    stripTimezone={false}
                  />
                </FormGroup>
                <FormGroup widths="equal">
                  <Input name="closedByName" path="closedByName" width={8} />
                  <Input name="status" path="status" width={8} />
                </FormGroup>
                <FormGroup widths="equal">
                  <AssignedCategorySelector
                    locale={locale}
                    name="assignedCategoryIds"
                    valueProperty="uuid"
                  />
                </FormGroup>
                <FormGroup widths="equal">
                  <Input name="typedStatus" path="typedStatus" width={8} />
                </FormGroup>
                <FormGroup>
                  <Input
                    name="materialNumber"
                    path="materialNumber"
                    width={8}
                  />
                  <Input
                    width={8}
                    name="supplierMaterialNumber"
                    path="supplierMaterialNumber"
                  />
                </FormGroup>
                <FormGroup>
                  <Input name="orderNumber" path="orderNumber" width={8} />
                  <Input
                    width={8}
                    name="factoryIdentifier"
                    path="factoryIdentifier"
                  />
                </FormGroup>
                <FormGroup>
                  <Input name="assigneeName" path="assigneeName" width={8} />
                  <Input name="supplierName" path="supplierName" width={8} />
                </FormGroup>
                {currentIssueType === 'COMPLAINT' && (
                  <FormGroup>
                    <Input
                      name="externalComplaintReference"
                      path="externalComplaintReference"
                      width={8}
                    />
                  </FormGroup>
                )}

                <FormGroup>
                  <Form.Field>
                    <label />
                    <Reset
                      floated="right"
                      resetValues={{
                        status: currentStatus,
                        issueType: currentIssueType,
                      }}>
                      <FormattedMessage id="GENERAL_RESET_FORM" />
                    </Reset>
                  </Form.Field>
                </FormGroup>
              </Fragment>
            )}
          </div>
        </Segment>
      </div>
    </Popup>
  );
};

const mapStateToProps = ({locales}) => ({
  locale: locales.locale,
});

AdvancedPop = connect(mapStateToProps)(AdvancedPop);

AdvancedPop.propTypes = {
  offeringCompanyFlag: PropTypes.bool.isRequired,
  currentIssueType: PropTypes.string.isRequired,
  locale: PropTypes.string,
};

export default injectIntl(IssueModuleFilter);
