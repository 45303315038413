import React from 'react';
import {FormattedMessage as Msg} from 'react-intl';
import {Header, Grid, Icon, Segment} from 'semantic-ui-react';
import {Submit} from '@ecosio/pathform';
import {
  TextBoxWrapper,
  UploadWrapper,
  getDownloadUrl,
  ResponsiveGridColumn,
} from '../FormHelpers';

const CoreCompetencies = ({useForm, supplier}) => {
  const attachment = supplier.coreCompetencies.attachment;
  const downloadUrl =
    attachment.uuid &&
    getDownloadUrl(supplier.uuid, 'CORE_COMPETENCIES', attachment.uuid);

  return (
    <Segment>
      <Grid data-spec="coreCompetencies-grid">
        <Grid.Row>
          <Grid.Column>
            <Header size="huge" data-spec="core-competencies-page-header">
              <Msg
                id="CORE_COMPETENCIES_PAGE_HEADER"
                values={{company: supplier.contactData.company}}
              />
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <ResponsiveGridColumn widescreen={8} computer={8}>
            <TextBoxWrapper
              useForm={useForm}
              path="coreCompetencies.freeText"
              name="coreCompetencies.freeText"
            />

            <UploadWrapper
              useForm={useForm}
              path="coreCompetencies.attachment.file"
              downloadUrl={downloadUrl}
              originalFileName={attachment.originalFileName}
            />
          </ResponsiveGridColumn>
        </Grid.Row>
        {useForm && (
          <Grid.Row>
            <ResponsiveGridColumn widescreen={8} computer={8}>
              <Submit positive floated="right">
                <Icon name="checkmark" />
                &nbsp;
                <Msg id="GENERAL_SAVE" />
              </Submit>
            </ResponsiveGridColumn>
          </Grid.Row>
        )}
      </Grid>
    </Segment>
  );
};

export default CoreCompetencies;
