import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Grid} from 'semantic-ui-react';
import CommentEditView from './CommentEditView';
import CommentEditForm from './CommentEditForm';
import {issueShape} from './IssueTracker/shapes.js';

const CommentList = ({
  onLoadIssue,
  issue,
  onEditComment,
  editedCommentUuid,
  userID,
  isOfferingCompany,
}) => {
  return (
    <Grid.Row>
      <Grid.Column width={12}>
        {issue.comments.map((item) => {
          const isUser = userID === item.authorName;
          const showEditButton = isOfferingCompany
            ? item.companyType === 'OFFERING_COMPANY'
            : item.companyType === 'USING_COMPANY';
          return (
            <Fragment key={item.uuid}>
              {editedCommentUuid !== item.uuid ? (
                <CommentEditView
                  comment={item}
                  onEditComment={onEditComment}
                  isUser={isUser}
                  showEditButton={showEditButton}
                />
              ) : (
                <CommentEditForm
                  isUser={isUser}
                  issueUuid={issue.uuid}
                  onLoadIssue={onLoadIssue}
                  onEditComment={onEditComment}
                  editedCommentUuid={editedCommentUuid}
                  comment={item}
                />
              )}
            </Fragment>
          );
        })}
      </Grid.Column>
    </Grid.Row>
  );
};

//CommentList.whyDidYouRender = true;

CommentList.propTypes = {
  onLoadIssue: PropTypes.func,
  userID: PropTypes.string.isRequired,
  issue: issueShape.isRequired,
  onEditComment: PropTypes.func.isRequired,
  editedCommentUuid: PropTypes.string,
  isOfferingCompany: PropTypes.bool,
};

export default CommentList;
