import React, {Component, Fragment} from 'react';
import axios from 'axios';
import {
  Attachment,
  CommentField,
  insertFileIntoText,
  postAttachment,
} from '@ecosio/components';
import PropTypes from 'prop-types';
import {CONTEXT_PATH} from '../../constants';
import {attachmentShape} from './IssueTracker/shapes';

class AttachmentCommentWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attachments: this.props.attachments,
      text: this.props.commentText,
      url: '/api/attachments',
      // res: {}
    };
  }

  emitStateChange = () => {
    this.props.onChange(this.state.text, this.state.attachments);
  };

  dropHandler = (e) => {
    e.preventDefault();
    const {url, attachments, text} = this.state;
    const {onUploadResult, onUploadError} = this;
    const file = e.dataTransfer.files[0];
    const cursorPosition = e.target.selectionStart;
    const res = postAttachment(file, url, axios, onUploadResult, onUploadError);
    const space = text.length > 0 ? ' ' : '';
    res
      .then((res) => {
        const url = `${space}[${res.originalFileName}](${CONTEXT_PATH}/api/attachments/${res.uuid})`;
        this.setState(
          {
            // res,
            attachments: [...attachments, res],
            text: insertFileIntoText(text, url, cursorPosition || 0) || text,
          },
          this.emitStateChange
        );
      })
      .catch((err) => console.error(err));
  };

  handleText = (e) => {
    const text = e.target.value;
    this.setState(
      {
        text,
      },
      this.emitStateChange
    );
  };

  onUploadResult = (res) => {
    const {attachments} = this.state;
    this.setState(
      {
        // res,
        attachments: [...attachments, res],
      },
      this.emitStateChange
    );
  };

  onUploadError = (error) => {
    console.error(error);
  };

  onDeleteAttachment = (attachmentUuid) => {
    let {attachments, text} = this.state;
    let markdownUrl = attachments.map(
      (attach) =>
        `[${attach.originalFileName}](${CONTEXT_PATH}/api/attachments/${attach.uuid})`
    );
    attachments = attachments.filter((item) => item.uuid !== attachmentUuid);
    markdownUrl = markdownUrl.find(
      (url) => text.includes(url) && url.includes(attachmentUuid)
    );
    text = text.replace(`${markdownUrl}`, '');
    this.setState(
      {
        attachments,
        text,
      },
      this.emitStateChange
    );
  };

  render() {
    let {text, attachments} = this.state;
    const {url} = this.state;
    const {submitted} = this.props;
    if (submitted) {
      text = '';
      attachments = [];
    }
    return (
      <Fragment>
        <CommentField
          onText={this.handleText}
          text={text}
          dropHandler={this.dropHandler}
        />
        <Attachment
          attachments={attachments}
          onDelete={this.onDeleteAttachment}
          onUploadResult={this.onUploadResult}
          onUploadError={this.onUploadError}
          url={url}
          axios={axios}
          contextPath={CONTEXT_PATH}
        />
      </Fragment>
    );
  }
}

AttachmentCommentWrapper.propTypes = {
  submitted: PropTypes.bool,
  onChange: PropTypes.func,
  commentText: PropTypes.string,
  attachments: PropTypes.arrayOf(attachmentShape),
};

export default AttachmentCommentWrapper;
