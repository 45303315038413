import React from 'react';
import {connect} from 'react-redux';
import {Header, Message, Segment, Button, Popup} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Helmet} from 'react-helmet';
import {
  hasPaging,
  Pagination,
  DataGrid,
  FormatDateTime,
  FormatByteSize,
} from '@ecosio/components';
import PropTypes from 'prop-types';
import qs from 'qs';

import {Link} from 'react-router-dom';
import {isDev} from '../../../util';
import {CONTEXT_PATH} from '../../../constants';
import {downloadFile, fetchPage} from '../../../reducers/drawingList';
import {wrappedPOST} from '../../../helpers/fetcher';
import StatusLabel from './StatusLabel';
import DrawingFilter from './DrawingFilter';

const mapStateToProps = ({drawingList, config}) => ({
  config: config.userConfig,
  drawingList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPage: (query) => dispatch(fetchPage(query)),
  downloadFile,
});

const dataGridConfig = (isOfferingCompany, onRenewDrawing, onDownload) => ({
  idSelector: 'uuid',
  fields: [
    {
      id: 'createdAt',
      label: 'DRAWING_UPLOAD_DATE',
      sortable: true,
      hidden: false,
      hideable: false,
      render: (Table, value) => (
        <Table.Cell collapsing>
          <FormatDateTime dateString={value} stripTimezone={false} />
        </Table.Cell>
      ),
    },
    {
      id: 'senderName',
      label: 'GENERAL_PARTNER',
      sortable: true,
      // supplier-only column
      hidden: isOfferingCompany,
      render: (Table, value) => <Table.Cell>{value}</Table.Cell>,
    },
    {
      id: 'receiverName',
      label: 'GENERAL_SUPPLIER',
      sortable: true,
      // offering company-only column
      hidden: !isOfferingCompany,
      render: (Table, value) => <Table.Cell>{value}</Table.Cell>,
    },
    {
      id: 'receiverEmailAddresses',
      label: 'RECEIVER_EMAIL_ADDRESSES',
      sortable: true,
      // offering company-only column
      hidden: !isOfferingCompany,
      render: (Table, value) => <Table.Cell>{value?.join(', ')}</Table.Cell>,
    },
    {
      id: 'fileTypeMetadata.orderNumber',
      label: 'GENERAL_DMS_REFERENCE_NUMBER',
      sortable: true,
      render: (Table, value) => <Table.Cell>{value}</Table.Cell>,
    },
    {
      id: 'expiryDate',
      label: 'DRAWING_AVAILABLE_UNTIL',
      sortable: true,
      hidden: false,
      render: (Table, value) => (
        <Table.Cell collapsing>
          <FormatDateTime stripTimezone={false} dateString={value} />
        </Table.Cell>
      ),
    },
    {
      id: 'status',
      label: 'GENERAL_STATUS',
      hidden: false,
      sortable: true,
      render: (Table, value) => (
        <Table.Cell collapsing>
          <StatusLabel status={value} asLabel />
        </Table.Cell>
      ),
    },
    {
      id: 'fileSize',
      label: 'GENERAL_FILE_SIZE',
      sortable: true,
      hidden: false,
      render: (Table, value) => (
        <Table.Cell collapsing>
          {typeof value === 'number' && value >= 0 && FormatByteSize(value)}
        </Table.Cell>
      ),
    },
    {
      id: '',
      label: 'GENERAL_ACTIONS',
      hidden: false,
      render: (Table, value, drawing) => (
        <Table.Cell collapsing>
          <Popup
            trigger={
              <Button
                as={Link}
                to={`/documents/drawings/details/${drawing.uuid}`}
                size="small"
                compact
                primary
                icon="eye"
              />
            }
            content={<FormattedMessage id="DRAWING_SHOW_DETAILS" />}
          />
          {isOfferingCompany && (
            <Popup
              trigger={
                <Button
                  icon="refresh"
                  compact
                  primary
                  size="small"
                  onClick={() => onRenewDrawing(drawing.uuid)}
                />
              }
              content={<FormattedMessage id="DRAWING_PROLONG_VALIDITY" />}
            />
          )}

          <Popup
            trigger={
              <Button
                icon="download"
                size="small"
                compact
                positive
                href={`${
                  isDev ? 'http://127.0.0.1:8822' : ''
                }${CONTEXT_PATH}/drawings/${drawing.uuid}/download`}
                target="_blank"
                disabled={
                  drawing.status !== 'PICKEDUP' &&
                  drawing.status !== 'DATA_RECEIVED'
                }
                onClick={() => onDownload(drawing.uuid, isOfferingCompany)}
                data-spec={`drawing-download-${drawing.uuid}`}
              />
            }
            content={<FormattedMessage id="GENERAL_DOWNLOAD" />}
          />
        </Table.Cell>
      ),
    },
  ],
});

export const convertDirection = (dir) => {
  if (!dir) {
    return undefined;
  }

  return {
    ascending: 'asc',
    descending: 'desc',
  }[dir];
};

export const convertDirectionInverse = (dir) => {
  if (!dir) {
    return undefined;
  }

  return {
    asc: 'ascending',
    desc: 'descending',
  }[dir];
};

export const parseSort = (query) => {
  if (query.sort) {
    const [sort, direction] = query.sort.split(',');
    return [sort, convertDirectionInverse(direction) || 'descending'];
  } else {
    return ['createdAt', 'descending'];
  }
};

@connect(mapStateToProps, mapDispatchToProps)
@hasPaging()
class DrawingList extends React.Component {
  // TODO better prop types
  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.any.isRequired,
    drawingList: PropTypes.any.isRequired,
    config: PropTypes.any.isRequired,
    downloadFile: PropTypes.func,
    intl: PropTypes.object,
  };

  onDownload = (drawingId, offeringCompany) => {
    this.props.downloadFile(drawingId, offeringCompany);
  };

  onRenewDrawing = (drawingId) => {
    return wrappedPOST(`/api/drawings/${drawingId}`)
      .then((res) => {
        // TODO update expiry date in table?
        if (res.status !== 200) {
          console.error(res.statusText);
        }
      })
      .catch((err) => console.error(err));
  };

  onSort = ({col, dir}) => {
    const fieldMapping = {
      receiverEmailAddresses: 'receiverEmailAddresses.raw',
      receiverName: 'receiverName.raw',
      senderName: 'senderName.raw',
    };

    if (fieldMapping[col]) {
      col = fieldMapping[col];
    }

    const parsedQuery = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    if (!dir) {
      parsedQuery.sort = undefined;
      return this.props.history.push({
        ...this.props.location,
        search: `?${qs.stringify(parsedQuery)}`,
      });
    }
    const direction = convertDirection(dir);

    const search = qs.stringify({
      ...parsedQuery,
      sort: `${col},${direction}`,
    });

    this.props.history.push({
      search: `?${search}`,
    });
  };

  render() {
    const {drawingList, config, intl, location, history} = this.props;
    const parsedQuery = qs.parse(location.search, {ignoreQueryPrefix: true});
    const [initialSort, initialDirection] = parseSort(parsedQuery);

    if (drawingList.error) {
      return (
        <Message negative>
          <Message.Header>
            <FormattedMessage id="GENERAL_ERROR" />
          </Message.Header>
          <p>Failed to fetch drawings: {drawingList.error}</p>
        </Message>
      );
    }

    const content = drawingList.data || [];
    const {offeringCompany} = config;
    const subHeaderId = offeringCompany
      ? 'DRAWING_LIST_SUB_HEADER'
      : 'DRAWING_LIST_SUB_HEADER_USING';

    const renderFooter = ({Table, cols}) => {
      return (
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan={cols}>
              <Pagination paging={drawingList.paging} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      );
    };

    return (
      <React.Fragment>
        <Helmet>
          <title>{intl.formatMessage({id: 'DRAWING_LIST'})}</title>
        </Helmet>
        <Segment color="purple" attached="top">
          <Header size="large" as="h1">
            <Header.Content>
              <FormattedMessage id="DRAWING_LIST" />
              <React.Fragment>
                <Header.Subheader>
                  <FormattedMessage id={subHeaderId} />
                </Header.Subheader>
              </React.Fragment>
            </Header.Content>
          </Header>
        </Segment>

        <Segment attached="bottom">
          <DrawingFilter location={location} history={history} />
        </Segment>

        <Segment color="purple" id="drawing-table-wrapper">
          <DataGrid
            initialSortColumn={initialSort}
            initialSortDirection={initialDirection}
            translated
            data={content}
            config={dataGridConfig(
              offeringCompany,
              this.onRenewDrawing,
              this.onDownload
            )}
            table={{striped: false}}
            loading={drawingList.loading}
            renderFooter={renderFooter}
            onSort={this.onSort}
            emptyState={{
              icon: 'file',
              header: 'NO_DOCUMENTS_FOUND',
              subHeader: 'NO_DOCS_FOUND_SUB',
            }}
          />
        </Segment>
      </React.Fragment>
    );
  }
}

export default injectIntl(DrawingList);
