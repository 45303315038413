import {FormattedMessage} from 'react-intl';
import React from 'react';

export const isDev =
  process.env.NODE_ENV === 'dev' ||
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === undefined;

export const msg = (id, defaultMsg) => (
  <FormattedMessage id={id} defaultMessage={defaultMsg} />
);

export const findOfferingCompanyId = (config) => {
  if (config.offeringCompany) {
    return config.company.uuid;
  } else {
    const permission = config.ediPermissions.find(
      (p) => p.uuid === config.selectedEdiPermission
    );
    return permission.offeringCompany.uuid;
  }
};

const isArray = (value) => {
  const toString = {}.toString;

  return toString.call(value) === '[object Array]';
};

const isObject = (value) => {
  return !isArray(value) && typeof value === 'object' && !!value;
};

export const convertToFormData = (jsonObject, parentKey, carryFormData) => {
  let index = 0;
  const formData = carryFormData || new window.FormData();

  for (const key of Object.keys(jsonObject)) {
    const value = jsonObject[key];
    if (value !== null && value !== undefined) {
      let propName = parentKey || key;

      if (parentKey && isObject(jsonObject)) {
        // eww ugly special casing for page validation...
        if (parentKey === 'pageValidation') {
          propName = `${parentKey}[${key}]`;
        } else {
          propName = `${parentKey}.${key}`;
        }
      }

      if (parentKey && isArray(jsonObject)) {
        propName = `${parentKey}[${index}]`;
      }

      // Handle uploaded files and lists of files
      if (value instanceof window.File) {
        formData.append(propName, value);
      } else if (value instanceof window.FileList) {
        for (let j = 0; j < value.length; j++) {
          formData.append(`${propName}[${j}]`, value.item(j));
        }
      } else if (value instanceof window.Blob) {
        formData.append(propName, value);
      } else if (isArray(value) || isObject(value)) {
        // Recurse for objects and arrays
        convertToFormData(value, propName, formData);
      } else if (typeof value === 'boolean') {
        // Serialize booleans as true/false
        formData.append(propName, +value ? 'true' : 'false');
      } else {
        formData.append(propName, value);
      }
    }

    index += 1;
  }

  return formData;
};
