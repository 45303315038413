import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Segment, Button, Header, Loader, Grid} from 'semantic-ui-react';
import {Form as PathForm, FormGroup, Input} from '@ecosio/pathform';
import {Link} from 'react-router-dom';
import {FormattedMessage as Msg} from 'react-intl';
import {connect} from 'react-redux';
import {shapes} from '@ecosio/auth';
import {wrappedPOST} from '../../../../helpers/fetcher';
import {issueShape} from '../../IssueTracker/shapes';
import OrderSelector from '../../OrderSelector';
import AttachmentList from '../../AttachmentList';
import {makeUploadRequests} from '../../IssueModuleEdit';
import FavoriteButton from '../../FavoriteButton';
import FavoriteList from '../../FavoriteList';

class SpecialReleaseCreate extends Component {
  state = {
    attachments: [],
    submitting: false,
  };

  onUpload = (files) => {
    this.setState((prevState) => ({
      attachments: prevState.attachments.concat(files.map((f) => ({file: f}))),
    }));
  };

  onSubmit = (values) => {
    const uuid = this.props.match.params.id;
    const issue = Object.assign({}, values, {
      issueType: 'SPECIAL_RELEASE',
      state: 'OPEN',
      specialReleaseData: {},
      materialShortText: {
        de: values.materialShortText?.de,
        en: values.materialShortText?.de,
      },
    });

    this.setState({submitting: true});
    let outerIssueNumber;
    return wrappedPOST(uuid ? '/api/issues/update' : '/api/issues', issue)
      .then((res) => {
        const issueNumber = res.data.issueNumber;
        outerIssueNumber = issueNumber;
        return makeUploadRequests(this.state.attachments, issueNumber);
      })
      .then(() => {
        this.setState({submitting: false});
        this.props.history.push(`/quality-assurance/view/${outerIssueNumber}`);
      })
      .catch((error) => {
        this.setState({submitting: false});
        console.error(error);
      });
  };

  render() {
    const {issue, onAttachmentRemoved} = this.props;
    const isEdit = this.props.match.params.id != null;
    const {offeringCompany} = this.props.config.userConfig;

    if (!issue && isEdit) {
      return <Loader active />;
    }

    const {
      supportedIssueTypes,
    } = this.props.supplyConfiguration?.supplyModules?.QUALITY_ASSURANCE?.configuration;
    const specialReleaseTemplate = supportedIssueTypes.find(
      (item) => item.issueType === 'SPECIAL_RELEASE'
    );
    const fields =
      specialReleaseTemplate?.issueTemplates?.specialReleaseTemplate
        ?.formFields;
    const newFields = Object.assign({}, fields, {
      subject: {
        input: {
          label: 'GENERAL_SPECIAL_RELEASE_SHORT_TEXT',
          type: 'text',
          required: true,
        },
        path: 'subject',
        type: 'text',
      },
      body: {
        input: {
          type: 'text',
          required: true,
          label: 'GENERAL_SPECIAL_RELEASE_LONG_TEXT',
        },
        path: 'subject',
        type: 'textarea',
      },
      'attachments.file': {
        path: 'attachments.file',
        type: 'upload',
        input: {
          label: 'GENERAL_UPLOAD',
        },
      },
      'assignee.uuid': {
        path: 'assignee.uuid',
        type: 'dropdown-autocomplete',
        input: {
          url: '/api/users/suggest',
          label: 'GENERAL_ASSIGNEE',
          defaultText: issue?.assignee?.name,
        },
      },
    });
    return (
      <PathForm
        onSubmit={this.onSubmit}
        fields={newFields}
        initialValues={issue}>
        {(ctx) => (
          <Segment.Group>
            <Segment color="purple">
              <Header size="large">
                <Header.Content>
                  <Msg id="GENERAL_SPECIAL_RELEASE" />
                </Header.Content>
              </Header>
              {issue?.uuid && (
                <div>
                  <FavoriteButton
                    issue={issue}
                    config={this.props.config}
                    onFollow={this.props.onFollow}
                    onUnfollow={this.props.onUnFollow}
                  />
                </div>
              )}
              {offeringCompany && (
                <Input name="assignee.uuid" path="assignee.uuid" width="4" />
              )}

              <FormGroup>
                <Input
                  name="subject"
                  path="subject"
                  width="16"
                  data-spec="description"
                />
              </FormGroup>

              <FormGroup>
                <Input name="body" path="body" width="16" data-spec="body" />
              </FormGroup>

              {offeringCompany && (
                <FormGroup>
                  <Input
                    name="supplier.uuid"
                    path="supplier.uuid"
                    url="/api/suppliers/suggest"
                    width="8"
                    defaultText={issue?.supplier?.name}
                  />
                </FormGroup>
              )}

              <AttachmentList
                onUpload={this.onUpload}
                attachments={issue?.attachments}
                onRemove={onAttachmentRemoved}
              />
            </Segment>

            <Segment>
              <Header>
                <Msg id="GENERAL_ORDER_INFORMATION" />
              </Header>
              <FormGroup widths="equal">
                <OrderSelector ctx={ctx} />
                <Input name="factoryIdentifier" path="factoryIdentifier" />
              </FormGroup>
            </Segment>
            {this.props.config?.userConfig?.offeringCompany && (
              <Segment>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <FavoriteList issue={issue} asHeader={true} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            )}
            <Segment clearing>
              <Header>
                <Msg id="GENERAL_ARTICLE_INFORMATION" />
              </Header>
              <FormGroup widths="equal">
                <Input name="materialNumber" path="materialNumber" />
                <Input name="drawingRevision" path="drawingRevision" />
                <Input
                  name="supplierMaterialNumber"
                  path="supplierMaterialNumber"
                />
              </FormGroup>
              <FormGroup widths="equal">
                <Input
                  name="materialShortText.de"
                  path="materialShortText.de"
                />
              </FormGroup>
              <Button
                type="button"
                negative
                floated="right"
                as={Link}
                to="/quality-assurance?issueType=SPECIAL_RELEASE">
                <Msg id="GENERAL_CANCEL" />
              </Button>
              <Button
                type="submit"
                positive
                floated="right"
                loading={this.state.submitting}
                disabled={this.state.submitting}>
                <Msg id="GENERAL_SEND" />
              </Button>
            </Segment>
          </Segment.Group>
        )}
      </PathForm>
    );
  }
}

const supplyShape = PropTypes.shape({
  configuration: PropTypes.object,
  imageUrl: PropTypes.string,
  type: PropTypes.string,
  uuid: PropTypes.string,
});

const supplyModulesShape = PropTypes.shape({
  DEMAND: supplyShape,
  DOCUMENT_MANAGMENT: supplyShape,
  ISSUE_TRACKER: supplyShape,
  QUALITY_ASSURANCE: supplyShape,
  SUPPLIER_DATABASE: supplyShape,
  SUPPLIER_PERFORMANCE: supplyShape,
});

const supplyConfigShape = PropTypes.shape({
  error: PropTypes.bool,
  loading: PropTypes.bool,
  supplierId: PropTypes.string,
  supplyModules: supplyModulesShape,
});

SpecialReleaseCreate.propTypes = {
  config: shapes.config,
  supplyConfiguration: supplyConfigShape,
  match: PropTypes.any,
  history: PropTypes.any,
  onSubmit: PropTypes.func,
  issue: issueShape,
  onFollow: PropTypes.func.isRequired,
  onUnfollow: PropTypes.func.isRequired,
};

const mapStateToProps = ({config, supplyConfiguration}) => ({
  config,
  supplyConfiguration,
});

export default connect(mapStateToProps, null)(SpecialReleaseCreate);
