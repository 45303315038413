import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, Icon} from 'semantic-ui-react';

class EdiPermissionDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.getFromUUID(props.userConfig.selectedEdiPermission),
      ediPermissions: this.props.userConfig.ediPermissions.map((item) => ({
        key: item.uuid,
        text: item.offeringCompany.name,
        value: item.uuid,
      })),
    };
  }

  changeHandler = (e, data) => {
    this.setState({
      selected: this.getFromUUID(data.value),
    });

    this.props.onSwitchEdiPermission();
  };

  getFromUUID = (uuid) => {
    const filtered = this.props.userConfig.ediPermissions
      .filter((item) => item.uuid === uuid)
      // eslint-disable-next-line radar/no-identical-functions
      .map((item) => ({
        key: item.uuid,
        text: item.offeringCompany.name,
        value: item.uuid,
      }));
    return filtered.length ? filtered[0] : null;
  };

  render() {
    if (!this.state.ediPermissions || !this.state.ediPermissions.length) {
      return null;
    }

    const trigger = (
      <span>
        <Icon name="compress" /> {this.state.selected.text}
      </span>
    );
    return (
      <Dropdown
        item
        data-spec="perm-dd"
        pointing="top right"
        defaultValue={this.props.userConfig.selectedEdiPermission}
        onChange={this.changeHandler}
        options={this.state.ediPermissions}
        trigger={trigger}
      />
    );
  }
}

EdiPermissionDropdown.propTypes = {
  userConfig: PropTypes.shape({
    selectedEdiPermission: PropTypes.string,
    ediPermissions: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string,
        offeringCompany: PropTypes.shape({
          name: PropTypes.string,
          uuid: PropTypes.string,
        }),
      })
    ),
  }),
  onSwitchEdiPermission: PropTypes.func,
};

export default EdiPermissionDropdown;
