import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {MODULE_TYPES} from '@ecosio/auth';

const mapStateToProps = ({config: {userConfig}}) => ({...userConfig});

/**
 * Renders its children only if the current user has the requiredModule
 * loaded.
 */
export const ConditionalOnModule = ({children, requiredModule, modules}) => {
  if (!requiredModule) {
    console.warn('No required module given, rendering content anyway.');
    return <React.Fragment>{children}</React.Fragment>;
  }
  const exists = (modules || []).find(
    (module) => module.name === requiredModule
  );
  return exists ? <React.Fragment>{children}</React.Fragment> : null;
};

ConditionalOnModule.propTypes = {
  requiredModule: PropTypes.oneOf(Object.values(MODULE_TYPES)),
  modules: PropTypes.arrayOf(PropTypes.shape({name: PropTypes.string})),
  children: PropTypes.element,
};

export default connect(mapStateToProps)(ConditionalOnModule);
