import React, {Fragment} from 'react';
import {FormattedMessage as Msg} from 'react-intl';
import {Header, Grid, Icon, Table, Segment} from 'semantic-ui-react';
import {InputArray, Submit} from '@ecosio/pathform';
import {
  InputWrapper,
  FormGroupWrapper,
  TableHeaderItem,
  TableCellItem,
  ResponsiveGridColumn,
} from '../FormHelpers';

const LocationView = ({supplier, locale, useForm}) => (
  <Fragment>
    {useForm ? (
      <UsingLocationsView supplier={supplier} useForm={useForm} />
    ) : (
      <OfferingLocationView supplier={supplier} locale={locale} />
    )}
  </Fragment>
);

const UsingLocationsView = ({supplier, useForm}) => {
  return (
    <InputArray name="locations">
      {({fields}) => {
        return fields.map((name, idx) => {
          return (
            <Grid.Row key={idx}>
              <Grid.Column>
                <Header size="medium" as="h3">
                  <Msg id={supplier.locations[idx].region} />
                </Header>
                <FormGroupWrapper useForm={useForm} widths="equal">
                  <InputWrapper
                    useForm={useForm}
                    path="locations.manufacturing"
                    name={`${name}.manufacturing`}
                  />
                  <InputWrapper
                    useForm={useForm}
                    path="locations.researchAndDevelopment"
                    name={`${name}.researchAndDevelopment`}
                  />
                </FormGroupWrapper>
              </Grid.Column>
            </Grid.Row>
          );
        });
      }}
    </InputArray>
  );
};

const OfferingLocationView = ({supplier}) => {
  return (
    <Table collapsing definition celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <TableHeaderItem label="GENERAL_MANUFACTURING" />
          <TableHeaderItem label="GENERAL_R_AND_D" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {supplier.locations.map((location, idx) => {
          return (
            <Table.Row key={idx}>
              <TableCellItem content={<Msg id={location.region} />} />
              <TableCellItem content={location.manufacturing} />
              <TableCellItem content={location.researchAndDevelopment} />
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

const Locations = ({supplier, locale, useForm, configuration}) => {
  const countries = configuration.countryOptions;

  return (
    <Grid data-spec="locations-grid">
      <Grid.Row>
        <ResponsiveGridColumn widescreen={8} computer={8}>
          <Segment.Group>
            <Segment>
              <Header as="h1" size="huge" data-spec="locations-page-header">
                <Msg
                  id="LOCATIONS_PAGE_HEADER"
                  values={{company: supplier.contactData.company}}
                />
              </Header>

              <FormGroupWrapper>
                <InputWrapper
                  useForm={useForm}
                  name="countryOfHeadquarters"
                  path="countryOfHeadquarters"
                  options={countries}
                />
              </FormGroupWrapper>
            </Segment>

            <Segment>
              <Header as="h2" size="large">
                <Msg id="OTHER_SUPPLIER_LOCATIONS" />
              </Header>

              <LocationView
                supplier={supplier}
                locale={locale}
                useForm={useForm}
              />
            </Segment>

            {useForm && (
              <Segment clearing>
                <Submit positive floated="right">
                  <Icon name="checkmark" />
                  &nbsp;
                  <Msg id="GENERAL_SAVE" />
                </Submit>
              </Segment>
            )}
          </Segment.Group>
        </ResponsiveGridColumn>
      </Grid.Row>
    </Grid>
  );
};

export default Locations;
