import React from 'react';
import {Button, Icon, List, Message} from 'semantic-ui-react';
import {FormattedMessage as Msg, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {ASSESSMENT_STATES} from '../../../helpers/assessment_states';
import {convertToFormData} from '../../../util';
import {getFormState} from '../../FormStateToRedux';

const allValid = (supplier) => {
  for (const key of Object.keys(supplier.pageValidation)) {
    // Ignore the pre-assessment page for validation purposes
    if (key === 'PRE_ASSESSMENT' || key === 'SUPPLIER_PERFORMANCE') {
      continue;
    }

    const isValid = supplier.pageValidation[key];
    if (!isValid) {
      return false;
    }
  }
  return true;
};

const AssessmentFeedback = ({supplier}) => {
  const feedback = supplier.assessmentFeedback;
  if (!feedback) {
    return null;
  }

  return (
    <Message compact info>
      <Message.Header>
        <Msg id="ASSESSMENT_FEEDBACK_SUPPLIER" />:{' '}
      </Message.Header>
      <p>{feedback}</p>
    </Message>
  );
};

export const ValidationFeedback = ({supplier}) => {
  const pagesValid = allValid(supplier);
  if (pagesValid) {
    return null;
  }

  const validation = supplier.pageValidation;
  const invalidPages = Object.keys(validation).filter((k) => {
    if (k === 'PRE_ASSESSMENT' || k === 'SUPPLIER_PERFORMANCE') {
      return false;
    } else {
      return validation[k] === false;
    }
  });

  return (
    <React.Fragment>
      <Msg id="VALIDATION_FEEDBACK_TEXT" />

      <List bulleted>
        {invalidPages.map((page, idx) => (
          <List.Item key={idx}>
            <Msg id={`${page}_MENU_ITEM`} />
          </List.Item>
        ))}
      </List>
    </React.Fragment>
  );
};

const mapStateToProps = ({formState}) => ({
  factsAndFiguresForm: formState.factsAndFigures,
  supplierForm: formState.supplier,
});

@connect(mapStateToProps)
@injectIntl
export default class UsingCompanyHeader extends React.Component {
  constructor(props) {
    super(props);

    this.submitForm = this.submitForm.bind(this);
  }

  state = {
    showFeedback: false,
  };

  submitForm() {
    // TODO show confirm modal before sending
    const {supplier, currentPage, hideNotification} = this.props;
    const form =
      currentPage === 'FACTS_AND_FIGURES'
        ? getFormState('factsAndFigures')
        : getFormState('supplier');

    if (typeof hideNotification === 'function') {
      hideNotification();
    }

    if (currentPage === 'FACTS_AND_FIGURES') {
      if (form?.valid && allValid(supplier)) {
        this.props.updateSupplierStatus(
          supplier.uuid,
          ASSESSMENT_STATES.ASSESSMENT_UNDER_REVIEW,
          {}
        );
      } else {
        this.props.setNotification({
          negative: true,
          header: <Msg id="GENERAL_SUBMIT_FAIL_MESSAGE" />,
          body: <ValidationFeedback supplier={supplier} />,
        });
      }
    } else {
      if (form?.valid && allValid(supplier)) {
        const toSubmit = Object.assign({}, supplier, form.values);
        const parameters = {previousState: toSubmit.status};
        toSubmit.status = ASSESSMENT_STATES.ASSESSMENT_UNDER_REVIEW;
        const formData = convertToFormData(toSubmit);
        this.props.postSupplier(formData, parameters);
      } else {
        this.props.setNotification({
          negative: true,
          header: <Msg id="GENERAL_SUBMIT_FAIL_MESSAGE" />,
          body: <ValidationFeedback supplier={supplier} />,
        });
      }
    }
  }

  render() {
    const {showFeedback} = this.state;
    const {
      assessmentState: state,
      supplier,
      supplierForm,
      factsAndFiguresForm,
      currentPage,
    } = this.props;
    const form =
      currentPage === 'FACTS_AND_FIGURES' ? factsAndFiguresForm : supplierForm;
    const formValid = form?.valid;
    const buttonDisabled = !formValid;

    switch (state) {
      case ASSESSMENT_STATES.ASSESSMENT:
        return (
          <React.Fragment>
            <Button
              positive
              icon
              type="button"
              onClick={this.submitForm}
              disabled={buttonDisabled}>
              <Icon name="checkmark" />
              &nbsp;
              <Msg id="USING_COMPANY_SEND_SSA" />
            </Button>
            {showFeedback && <ValidationFeedback supplier={supplier} />}
            <br />
            <AssessmentFeedback supplier={supplier} />
          </React.Fragment>
        );

      default:
        return <AssessmentFeedback supplier={supplier} />;
    }
  }
}
