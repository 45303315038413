import {wrappedGET} from '../helpers/fetcher';

const TYPES = {
  REQUEST: 'categories/request',
  RESPONSE: 'categories/response',
  ERROR: 'categories/error',
};

const categoriesRequest = () => ({
  type: TYPES.REQUEST,
});

const categoriesResponse = (data) => ({
  type: TYPES.RESPONSE,
  data,
});

const categoriesError = (error) => ({
  type: TYPES.ERROR,
  error,
});

export const fetchCategories = (supplierId) => (dispatch) => {
  dispatch(categoriesRequest());

  const url =
    supplierId == null
      ? '/api/suppliers/categories'
      : `/api/suppliers/${supplierId}/categories`;
  return wrappedGET(url)
    .then((res) => {
      if (res.status === 200) {
        dispatch(categoriesResponse(res.data));
      } else {
        dispatch(categoriesError(res.statusText));
      }
    })
    .catch((error) => dispatch(categoriesError(error)));
};

const initialState = {
  data: null,
  loading: true,
  error: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case TYPES.RESPONSE:
      // Filter out values that don't have a parent (skip the root element)
      // eslint-disable-next-line no-case-declarations
      const data = action.data.filter((c) => !!c.parent);
      return {
        ...state,
        error: null,
        loading: false,
        // Store the root element separately
        rootEl: action.data.find((c) => !c.parent),
        data,
      };

    case TYPES.ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        data: null,
      };

    default:
      return state;
  }
};
