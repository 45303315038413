import {getAxiosConfig} from '@ecosio/components';
import {wrappedGET} from '../helpers/fetcher';

const TYPES = {
  REQUEST: 'supplierList/request',
  RESPONSE: 'supplierList/response',
  ERROR: 'supplierList/error',
};

const initialState = {
  data: null,
  error: null,
  loading: true,
};

const supplierListRequest = () => ({
  type: TYPES.REQUEST,
});

const supplierListResponse = (data) => {
  const {content, ...rest} = data;
  return {
    type: TYPES.RESPONSE,
    data: content,
    paging: {...rest},
  };
};

const supplierListError = (error) => ({
  type: TYPES.ERROR,
  error,
});

export const fetchPage = (query) => (dispatch) => {
  const cfg = getAxiosConfig(query);
  // 10 suppliers per page looks odd
  // cfg.params.size = 20;
  cfg.params.sort = cfg.params.sort || 'name.raw,asc';

  dispatch(supplierListRequest());
  const url = '/api/suppliers';
  return wrappedGET(url, cfg)
    .then((res) => {
      if (res.status !== 200) {
        dispatch(supplierListError(res.statusText));
      } else {
        dispatch(supplierListResponse(res.data));
      }
    })
    .catch((err) => dispatch(supplierListError(err.message)));
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case TYPES.RESPONSE:
      return {
        ...state,
        error: null,
        loading: false,
        data: action.data,
        paging: action.paging,
      };

    case TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
