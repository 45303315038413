import PropTypes from 'prop-types';

// can be either string or object, therefore any
const fieldVisibilityShape = PropTypes.shape({
  employees: PropTypes.any,
  equityratio: PropTypes.any,
  investmentrd: PropTypes.any,
  revenue: PropTypes.any,
});

// see FactsAndFiguresConfiguration.java in backend
// check https://gitlab.ecosio.com/code/web-al/blob/master/src/main/java/at/erpel/web/al/entity/configuration/supplierDatabase/FactsAndFiguresConfiguration.java
export const factsAndFiguresShape = PropTypes.shape({
  fieldVisibility: fieldVisibilityShape,
  formFields: fieldVisibilityShape,
  offsets: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
    })
  ),
  period: PropTypes.string,
  units: fieldVisibilityShape,
});

export const factsAndFiguresExchangeRowShape = PropTypes.shape({
  pathKey: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      forecast: PropTypes.bool,
      from: PropTypes.string,
      nameTranslationKey: PropTypes.string,
      needsActualValue: PropTypes.bool,
      pathKey: PropTypes.string,
      to: PropTypes.string,
      unitTranslationKey: PropTypes.string,
      uuid: PropTypes.string,
      year: PropTypes.number,
    })
  ),
});

// see FactsAndFiguresExchangeShape.java in backend
// check https://gitlab.ecosio.com/code/web-al/blob/master/src/main/java/at/erpel/supply/api/data/rest/FactsAndFiguresExchange.java
export const factsAndFiguresExchangeShape = PropTypes.shape({
  rows: PropTypes.arrayOf(factsAndFiguresExchangeRowShape),
});

const attachmentShape = PropTypes.shape({
  authorities: PropTypes.arrayOf(PropTypes.any),
  companyUuids: PropTypes.arrayOf(PropTypes.string),
});

const addressDataShape = PropTypes.shape({
  country: PropTypes.string,
});

const companyContactsShape = PropTypes.arrayOf(
  PropTypes.shape({
    headerTranslationKey: PropTypes.string,
  })
);

const contactDataShape = PropTypes.shape({
  company: PropTypes.string,
});

const coreCompetenciesShape = PropTypes.shape({
  attachment: attachmentShape,
});

const productsShape = PropTypes.shape({
  attachment: attachmentShape,
});

const locationShape = PropTypes.arrayOf(
  PropTypes.shape({
    region: PropTypes.string,
  })
);

const abilitiesShape = PropTypes.arrayOf(
  PropTypes.shape({
    attachment: attachmentShape,
    uuid: PropTypes.string,
  })
);

const categoryIdsShape = PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string));

const customerReferencesShape = PropTypes.shape({
  attachment: attachmentShape,
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
    })
  ),
});

const pageValidationShape = PropTypes.shape({
  ABILITIES_AND_CERTIFICATES: PropTypes.bool,
  CORE_COMPETENCIES: PropTypes.bool,
  FACTS_AND_FIGURES: PropTypes.bool,
  GENERAL_AND_CONTACTS: PropTypes.bool,
  LOCATIONS: PropTypes.bool,
  PRE_ASSESSMENT: PropTypes.bool,
  PRODUCTS: PropTypes.bool,
  REFERENCE_CUSTOMERS: PropTypes.bool,
  SUPPLIER_PERFORMANCE: PropTypes.bool,
});

// see Supplier.java in backend
// check https://gitlab.ecosio.com/code/web-al/blob/master/src/main/java/at/erpel/web/al/entity/supplierDatabase/Supplier.java
export const supplierShape = PropTypes.shape({
  abilities: abilitiesShape,
  addressData: addressDataShape,
  attachments: PropTypes.array,
  categoryIds: categoryIdsShape,
  companyContacts: companyContactsShape,
  contactData: contactDataShape,
  coreCompetencies: coreCompetenciesShape,
  createdAt: PropTypes.string,
  customerReferences: customerReferencesShape,
  erpelCompanyId: PropTypes.string,
  formId: PropTypes.string,
  internalSupplierIds: PropTypes.arrayOf(PropTypes.string),
  locations: locationShape,
  loginEmail: PropTypes.string,
  offeringCompanyId: PropTypes.string,
  onboardingStatus: PropTypes.string,
  pageValidation: pageValidationShape,
  preCheckProtocol: attachmentShape,
  preCheckState: PropTypes.string,
  products: productsShape,
  status: PropTypes.string,
  testRequestDone: PropTypes.bool,
  updatedAt: PropTypes.string,
  uuid: PropTypes.string,
});

// see SidebarConfiguration.java in backend
// https://gitlab.ecosio.com/code/frontend/blob/master/apps/supply-parent/supply-api/src/main/java/at/erpel/web/al/entity/configuration/supplierDatabase/SidebarConfiguration.java
const sidebarConfigurationsShape = PropTypes.arrayOf(
  PropTypes.shape({
    formId: PropTypes.string,
    pages: PropTypes.arrayOf(
      PropTypes.shape({
        fields: PropTypes.shape({}),
        type: PropTypes.string,
        uuid: PropTypes.string,
      })
    ),
  })
);

const abilityConfigurationShape = PropTypes.shape({
  requiredAbilities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.shape({
        de: PropTypes.string,
        en: PropTypes.string,
      }),
      uuid: PropTypes.string,
    })
  ),
});

export const configurationShape = PropTypes.shape({
  abilityConfiguration: abilityConfigurationShape,
  categoryTreeId: PropTypes.string,
  countryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  factsAndFigures: factsAndFiguresShape,
  listViews: PropTypes.arrayOf(PropTypes.any),
  sidebarConfigurations: sidebarConfigurationsShape,
  uuid: PropTypes.string,
});

export const matchShape = PropTypes.shape({
  isExact: PropTypes.bool,
  params: PropTypes.shape({
    page: PropTypes.string,
    uuid: PropTypes.string,
  }),
  path: PropTypes.string,
  url: PropTypes.string,
});

const selectedCategoriesShape = PropTypes.arrayOf(
  PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      externalId: PropTypes.string,
      name: PropTypes.shape({
        de: PropTypes.string,
        en: PropTypes.string,
      }),
      parent: PropTypes.string,
      updatedAt: PropTypes.string,
      uuid: PropTypes.string,
    })
  )
);

// see SupplierExchange.java in backend
// check https://gitlab.ecosio.com/code/frontend/blob/master/apps/supply-parent/supply-api/src/main/java/at/erpel/supply/api/data/rest/SupplierExchange.java
export const supplierExchangeShape = PropTypes.shape({
  configuration: configurationShape,
  error: PropTypes.any,
  loading: PropTypes.bool,
  selectedCategories: selectedCategoriesShape,
  supplier: supplierShape,
  uploading: PropTypes.bool,
});
