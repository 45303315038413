import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
  ToastContainer,
  BrowserWarningMessage,
  isInternetExplorerEOL,
} from '@ecosio/components';
import {MODULE_TYPES, shapes, switchModule} from '@ecosio/auth';
import {FormattedMessage} from 'react-intl';
import {Loader, Message} from 'semantic-ui-react';
import {get} from 'lodash';
import {localesMap as fallbackLocales, switchLocale} from '@ecosio/locales';
import {Helmet} from 'react-helmet';

import {fetchConfiguration, moduleFromUrl} from '../../reducers/configuration';
import {ASSESSMENT_STATES} from '../../helpers/assessment_states';
import {findOfferingCompanyId} from '../../util';
import {OfferingCompanySidebar} from './Navigation/OfferingCompanySidebar';
import {SupplierDetailSidebar} from './Navigation/SupplierDetailSidebar';
import {SupplierSSADetailSidebar} from './Navigation/SupplierSSADetailSidebar';
import {DocumentManagementSidebar} from './Navigation/DocumentManagementSidebar';
import {IssueSidebar} from './Navigation/IssueTrackerSidebar';
import {DocumentManagementDetailsSidebar} from './Navigation/DocumentManagementDetailsSidebar';
import {QualityAssuranceSidebar} from './Navigation/QualityAssuranceSidebar';
import DynamicSidebar from './Sidebar/DynamicSidebar';
import Header from './Header/Header';

const mapStateToProps = ({
  locales,
  config,
  supplyConfiguration,
  supplierExchange,
}) => ({
  locale: locales.locale,
  config,
  supplyConfiguration,
  supplierExchange,
});

const mapDispatchToProps = (dispatch) => ({
  switchModule: (module) => dispatch(switchModule(module)),
  fetchConfiguration: (offeringCompanyId) =>
    dispatch(fetchConfiguration(offeringCompanyId)),
});

export const sidebarPropTypes = {
  supplierDetailPages: PropTypes.array,
  supplierId: PropTypes.string,
  supplier: PropTypes.object,
  offeringCompany: PropTypes.bool,
};

export const renderSupplierDatabaseRoutes = (
  navProps,
  listViews,
  supplierDetailPages,
  supplier,
  offeringCompany
) => {
  const supplierId = supplier && supplier.uuid;
  const status = supplier && supplier.status;

  // TODO: constants
  const isSsaSupplier =
    status === ASSESSMENT_STATES.PRE_ASSESSMENT_UNDER_REVIEW ||
    status === ASSESSMENT_STATES.PRE_ASSESSMENT_REJECTED;

  const DetailItemsRenderer = isSsaSupplier
    ? SupplierSSADetailSidebar
    : SupplierDetailSidebar;

  DetailItemsRenderer.propTypes = {
    supplierDetailPages: PropTypes.array,
    supplierId: PropTypes.string,
    supplier: PropTypes.object,
    offeringCompany: PropTypes.bool,
  };

  return [
    <Route
      key="0"
      path="/suppliers/list/:filter?"
      render={(props) => (
        <OfferingCompanySidebar {...props} listViews={listViews} />
      )}
    />,
    <Route
      key="1"
      path="/supplier/demandpreview/:uuid"
      render={(props) => (
        <DetailItemsRenderer
          {...props}
          supplierDetailPages={supplierDetailPages}
          supplierId={supplierId}
          supplier={supplier}
          offeringCompany={offeringCompany}
        />
      )}
    />,
    <Route
      key="2"
      path="/supplier/details"
      // eslint-disable-next-line radar/no-identical-functions
      render={(props) => (
        <DetailItemsRenderer
          {...props}
          supplierDetailPages={supplierDetailPages}
          supplierId={supplierId}
          supplier={supplier}
          offeringCompany={offeringCompany}
        />
      )}
    />,
  ];
};

const ModuleNotAvailable = ({config}) => {
  console.warn('config error, module not available. Redirect to accounts');
  const reasonSuffix = '?reason=moduleNotAvailable';
  const authServer = config.authServer;
  const redirectTo = `${authServer}${reasonSuffix}`;
  window.location.assign(redirectTo);
  return <Loader active />;
};

ModuleNotAvailable.propTypes = {
  config: PropTypes.object,
};

/**
 * Builds an array of <Route /> components that should be rendered by the sidebar.
 * @param navProps Props of the dynamic sidebar
 * @param modules List of modules
 * @param config State of the config reducer
 * @param supplierExchange (optional) data for the supplier currently selected
 * @returns {*}
 */
export const renderRoutes = (navProps, modules, config, supplierExchange) => {
  const path = 'SUPPLIER_DATABASE.configuration.listViews';
  const listViews = get(modules, path) || [];
  const supplier = supplierExchange.supplier;
  const formId = get(supplierExchange, 'supplier.formId');
  const sidebarConfigurations =
    get(supplierExchange, 'configuration.sidebarConfigurations') || [];
  const detailPages = sidebarConfigurations.find((p) => p.formId === formId);
  switch (config.selectedModule) {
    // Currently only the supplier database has a sidebar with items in it
    case MODULE_TYPES.SUPPLIER_DATABASE:
      return renderSupplierDatabaseRoutes(
        navProps,
        listViews,
        detailPages && detailPages.pages,
        supplier,
        config.userConfig.offeringCompany
      );
    case MODULE_TYPES.DMS:
      return [
        <Route
          key="0"
          path="/documents"
          render={(props) => <DocumentManagementSidebar {...props} />}
        />,
        <Route
          key="1"
          path="/documents/drawings/details"
          render={(props) => <DocumentManagementDetailsSidebar {...props} />}
        />,
      ];
    case MODULE_TYPES.DEMAND_PREVIEW:
      return [];
    case MODULE_TYPES.ISSUE_TRACKER:
      return [
        <Route
          key="0"
          path="/issues"
          render={(props) => <IssueSidebar {...props} />}
        />,
      ];
    case MODULE_TYPES.IDEAS:
      return [
        <Route
          key="0"
          path="/ideas"
          render={(props) => <IssueSidebar {...props} />}
        />,
      ];
    case MODULE_TYPES.QUALITY_ASSURANCE:
      return [
        <Route
          key="0"
          path="/quality-assurance"
          render={(props) => (
            <QualityAssuranceSidebar
              {...props}
              qualityAssurance={modules?.QUALITY_ASSURANCE}
            />
          )}
        />,
      ];
    case MODULE_TYPES.MASTERDATA:
      return [];
    default:
      return [];
  }
};

class LayoutComponent extends Component {
  componentDidMount() {
    const {config} = this.props;
    const offeringCompanyId = findOfferingCompanyId(config.userConfig);

    this.props.fetchConfiguration(offeringCompanyId);
    this.props.switchModule(moduleFromUrl(this.props.location));
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const newModule = moduleFromUrl(this.props.location);
      const oldModule = prevProps.config.selectedModule;
      if (newModule !== oldModule) {
        this.props.switchModule(moduleFromUrl(this.props.location));
      }
    }
  }

  render() {
    const {locale, supplyConfiguration, config, supplierExchange} = this.props;

    if (supplyConfiguration.error) {
      throw new Error(supplyConfiguration.error);
    }

    let content = this.props.children;
    if (supplyConfiguration.loading) {
      content = <Loader active />;
    } else if (supplyConfiguration.error) {
      if (supplyConfiguration.error === 417) {
        return <ModuleNotAvailable config={config} />;
      }
      content = (
        <Message error>
          <Message.Header>
            <FormattedMessage id="GENERAL_ERROR" />
          </Message.Header>
          <p>
            <FormattedMessage id="GENERAL_APPLICATION_ERROR" />
          </p>
        </Message>
      );
    }

    const {selectedModule} = config;
    const modules = supplyConfiguration.supplyModules;
    if (!supplyConfiguration.loading && config) {
      const moduleNames = Object.keys(modules);
      if (moduleNames.indexOf(selectedModule) === -1) {
        return <ModuleNotAvailable config={config} />;
      }
    }

    const localesMap = config?.userConfig?.supportedLocales || fallbackLocales;

    return (
      <div id="supply-layout" data-spec="layout">
        {isInternetExplorerEOL() ? <BrowserWarningMessage static /> : null}
        <ToastContainer position="bottom-right" />
        <Helmet>
          <html lang={locale} />
        </Helmet>
        <Header
          baseUrl={this.props.config.authServer}
          userConfig={this.props.config.userConfig}
          locale={this.props.locale}
          onSwitchLocale={(e, {value}) =>
            switchLocale(value, config.authServer)
          }
          localesMap={localesMap}
          selectedModule={selectedModule}
          logoUrl={config.authServer}
        />
        <DynamicSidebar
          location={this.props.location}
          renderRoutes={(navProps) =>
            renderRoutes(navProps, modules, config, supplierExchange)
          }
          header={this.props.config.userConfig.email}
          subheader={this.props.config.userConfig.company.name}>
          {content}
        </DynamicSidebar>
      </div>
    );
  }
}

LayoutComponent.propTypes = {
  locale: PropTypes.string,
  children: PropTypes.node,
  config: PropTypes.shape({
    authServer: PropTypes.string,
    userConfig: shapes.userConfig,
    selectedModule: PropTypes.string,
  }),
  switchModule: PropTypes.func,
  fetchConfiguration: PropTypes.func.isRequired,
  supplyConfiguration: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired,
  supplierExchange: PropTypes.any,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutComponent)
);
