import React from 'react';
import {Label} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {msg} from '../../../util';

const Text = ({color, children}) => (
  <span className={`ui ${color} text`}>{children}</span>
);

Text.propTypes = {
  color: PropTypes.string,
  children: PropTypes.any,
};

const StatusLabel = ({status, asLabel}) => {
  let label = null;
  const Component = asLabel ? Label : Text;

  switch (status) {
    case 'METADATA_RECEIVED':
      label = (
        <Component color="orange">{msg('STATUS_METADATA_RECEIVED')}</Component>
      );
      break;
    case 'DATA_RECEIVED':
      label = <Component color="blue">{msg('STATUS_DATA_RECEIVED')}</Component>;
      break;
    case 'PICKEDUP':
      label = <Component color="green">{msg('STATUS_PICKEDUP')}</Component>;
      break;
    case 'EXPIRED':
      label = <Component color="red">{msg('STATUS_EXPIRED')}</Component>;
      break;
    case 'EXCEPTION':
      label = <Component color="red">{msg('STATUS_EXCEPTION')}</Component>;
      break;
  }

  return label;
};

StatusLabel.propTypes = {
  color: PropTypes.string,
  asLabel: PropTypes.bool,
};

export default StatusLabel;
