import {wrappedGET} from '../helpers/fetcher';

const API_URL = '/api/demandpreview';

const TYPES = {
  DEMAND_PREVIEW_LIST_REQUEST: 'demandPreview/list/request',
  DEMAND_PREVIEW_LIST_RESPONSE: 'demandPreview/list/response',
  DEMAND_PREVIEW_LIST_ERROR: 'demandPreview/list/error',
};

const initState = {
  paging: {
    last: false,
    totalPages: 0,
    totalElements: 0,
    size: 0,
    number: 1,
    first: true,
    numberOfElements: 0,
  },
  load: false,
  error: null,
  data: [],
  download: null,
};

const demandPreviewRequest = () => ({
  type: TYPES.DEMAND_PREVIEW_LIST_REQUEST,
});

const demandPreviewResponse = (data) => {
  const {content, ...rest} = data;
  return {
    type: TYPES.DEMAND_PREVIEW_LIST_RESPONSE,
    data: content,
    paging: {...rest},
  };
};

const demandPreviewError = (error) => ({
  type: TYPES.DEMAND_PREVIEW_LIST_ERROR,
  error,
});

export const fetchDemandPreviewPage = (uuid, query) => (dispatch) => {
  const cfg = {
    params: Object.assign(
      {},
      {
        page: 1,
        size: 10,
        sort: 'createdAt,desc',
      },
      query
    ),
  };

  dispatch(demandPreviewRequest());

  const url = uuid ? `${API_URL}/${uuid}` : API_URL;
  return wrappedGET(url, cfg)
    .then((res) => {
      if (res.status === 200) {
        dispatch(demandPreviewResponse(res.data));
      } else {
        dispatch(demandPreviewError(res.statusText));
      }
    })
    .catch((res) => dispatch(demandPreviewError(res.message)));
};

export const demandPreviewReducer = (state = initState, action) => {
  switch (action.type) {
    case TYPES.DEMAND_PREVIEW_LIST_REQUEST:
      return {
        ...state,
        load: true,
        error: null,
      };
    case TYPES.DEMAND_PREVIEW_LIST_RESPONSE:
      return {
        ...state,
        load: false,
        data: action.data,
        paging: action.paging,
        error: null,
      };
    case TYPES.DEMAND_PREVIEW_LIST_ERROR:
      return {
        ...state,
        load: false,
        error: action.error,
      };
    default:
      return state;
  }
};
