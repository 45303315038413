import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Segment,
  Button,
  Header,
  Grid,
  Modal,
  FormGroup,
  List,
} from 'semantic-ui-react';
import {FormattedMessage as Msg} from 'react-intl';
import {Link} from 'react-router-dom';
import {Attachment} from '@ecosio/components';
import {Form as PathForm, Input, ExternalSubmit} from '@ecosio/pathform';
import {shapes} from '@ecosio/auth';
import DateFormat from '../../../DateFormat';
import CommentCreate from '../../CommentCreate';
import CommentList from '../../CommentList';
import {BoldContent} from '../../IssueTracker/IssueTrackerHelpers';
import {issueShape} from '../../IssueTracker/shapes';
import {FieldContent, SubHeaderMeta} from '../../../FormHelpers';
import {articleInspectionShape} from '../../shapes';
import {BodyWrapper, ButtonRow, translateValue} from '../Common';
import {StateLabel} from '../../StateLabel';
import DownloadIssueButton from '../DownloadIssueButton';
import {CONTEXT_PATH} from '../../../../constants';
import FavoriteButton from '../../FavoriteButton';
import FavoriteList from '../../FavoriteList';
import ReopenIssueButton from '../ReopenIssueButton';
import ArticleInspectionRequiredList from './ArticleInspectionRequiredList';

const ARTICLE_INSPECTION_STATES = {
  FULLY_APPROVED: 'FULLY_APPROVED',
  PARTIALLY_APPROVED: 'PARTIALLY_APPROVED',
  DECLINED: 'DECLINED',
  CANCELLED: 'CANCELLED',
};

const ArticleInspectionView = ({
  issue,
  loadIssue,
  onUpdateIssue,
  config,
  editedCommentUuid,
  onEditComment,
  locale,
  onTriggerModal,
  modalVisible,
  onSubmit,
  template,
  onFollow,
  onUnfollow,
}) => {
  const userId = config.userConfig.email;
  const {offeringCompany} = config.userConfig;
  const {
    editIssueHeaderOfferingCompany,
    editIssueHeaderUsingCompany,
    editIssueSpecificDataOfferingCompany,
    editIssueSpecificDataUsingCompany,
  } = template;
  const fields = {
    'articleInspectionData.articleInspectionState': {
      path: 'articleInspectionData.articleInspectionState',
      type: 'dropdown',
      options: Object.keys(ARTICLE_INSPECTION_STATES).map((state) => ({
        text: `ARTICLE_INSPECTION_${state}`,
        value: state,
        key: state,
      })),
      input: {
        label: 'ARTICLE_INSPECTION_STATE',
        translateOptions: true,
      },
    },
    'articleInspectionData.articleInspectionCompletionFreeText': {
      path: 'articleInspectionData.articleInspectionCompletionFreeText',
      type: 'textarea',
      input: {
        label: 'ARTICLE_INSPECTION_FREE_TEXT',
      },
    },
    'articleInspectionData.partialApprovalUntil': {
      path: 'articleInspectionData.partialApprovalUntil',
      type: 'date',
      input: {
        type: 'text',
        label: 'PARTIAL_APPROVAL_UNTIL',
        onlyDateValue: true,
      },
    },
  };
  return (
    <Fragment>
      <ArticleInspectionHeader
        issue={issue}
        editIssueHeaderOfferingCompany={editIssueHeaderOfferingCompany}
        editIssueHeaderUsingCompany={editIssueHeaderUsingCompany}
        modalVisible={modalVisible}
        onTriggerModal={onTriggerModal}
        fields={fields}
        onSubmit={onSubmit}
        config={config}
        offeringCompany={offeringCompany}
        locale={locale}
        onFollow={onFollow}
        onUnfollow={onUnfollow}
        onUpdateIssue={onUpdateIssue}
      />
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment.Group>
              <Segment color="purple">
                <Header size="large">
                  <Header.Content>
                    {issue?.subject}
                    <Header.Subheader>
                      <DateFormat date={issue?.createdAt} />
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </Segment>
              <Segment>
                <ArticleInspectionHeaderFields
                  issue={issue}
                  locale={locale}
                  offeringCompany={offeringCompany}
                  config={config}
                />
              </Segment>
              <Segment clearing>
                <BodyWrapper body={issue.body} />
                <div style={{marginTop: '20px'}}>
                  {issue?.attachments?.length > 0 ? (
                    <BoldContent>
                      <Msg id="GENERAL_DOWNLOAD" />:
                    </BoldContent>
                  ) : null}
                  <div>
                    <Attachment
                      contextPath={CONTEXT_PATH}
                      attachments={issue?.attachments.filter((att) =>
                        Boolean(att.uuid)
                      )}
                    />
                  </div>
                </div>
              </Segment>
            </Segment.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ArticleInspectionRequiredList
              offeringCompany={offeringCompany}
              onUpdateIssue={onUpdateIssue}
              loadIssue={loadIssue}
              issue={issue}
              template={template}
              editIssueSpecificDataOfferingCompany={
                editIssueSpecificDataOfferingCompany
              }
              editIssueSpecificDataUsingCompany={
                editIssueSpecificDataUsingCompany
              }
            />
          </Grid.Column>
        </Grid.Row>
        {config.userConfig?.offeringCompany && (
          <Grid.Row>
            <Grid.Column>
              <Segment>
                <FavoriteList issue={issue} asHeader={true} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        )}{' '}
        <CommentList
          userID={userId}
          onLoadIssue={loadIssue}
          issue={issue}
          onEditComment={onEditComment}
          editedCommentUuid={editedCommentUuid}
          isOfferingCompany={offeringCompany}
        />
        <CommentCreate onLoadIssue={loadIssue} issue={issue} />
      </Grid>
    </Fragment>
  );
};

ArticleInspectionView.propTypes = {
  issue: issueShape,
  template: articleInspectionShape,
  locale: PropTypes.string,
  config: shapes.config,
  onUpdateIssue: PropTypes.func,
  loadIssue: PropTypes.func,
  editedCommentUuid: PropTypes.bool,
  onEditComment: PropTypes.func,
  onTriggerModal: PropTypes.func,
  modalVisible: PropTypes.bool,
  onSubmit: PropTypes.func,
};

const ArticleInspectionHeaderButtons = ({
  issue,
  onTriggerModal,
  modalVisible,
  fields,
  onSubmit,
  offeringCompany,
  editIssueHeaderUsingCompany,
  editIssueHeaderOfferingCompany,
  locale,
  config,
  onFollow,
  onUnfollow,
  onUpdateIssue,
}) => {
  let ButtonBar;
  const onReopen = (issue) => {
    onUpdateIssue(issue);
  };

  const onError = (error) => {
    console.error(error);
  };
  if (issue.state === 'CLOSED') {
    ButtonBar = (
      <ButtonRow>
        <List horizontal>
          <List.Item>
            <StateLabel
              state={issue?.articleInspectionData?.articleInspectionState}
            />
          </List.Item>
          <List.Item>
            <FieldContent
              inline
              value={
                issue?.articleInspectionData
                  ?.articleInspectionCompletionFreeText
              }
              leftCaption={
                <Fragment>
                  {' '}
                  <span style={{fontWeight: '700'}}>
                    <Msg id="GENERAL_DESCRIPTION" />:{' '}
                  </span>
                </Fragment>
              }
            />
          </List.Item>
          <List.Item>
            <FieldContent
              date
              inline
              value={issue?.articleInspectionData?.partialApprovalUntil}
              leftCaption={
                <Fragment>
                  {' '}
                  <span style={{fontWeight: '700'}}>
                    <Msg id="PARTIAL_APPROVAL_UNTIL" />:{' '}
                  </span>
                </Fragment>
              }
            />
          </List.Item>
        </List>
        <div>
          <DownloadIssueButton
            issueUuid={issue.uuid}
            closed={true}
            locale={locale}
            isOfferingCompany={offeringCompany}
          />
          <FavoriteButton
            issue={issue}
            config={config}
            onFollow={onFollow}
            onUnfollow={onUnfollow}
          />
          <ReopenIssueButton
            issue={issue}
            isOfferingCompany={offeringCompany}
            onReopenSuccess={onReopen}
            onReopenError={onError}
          />
        </div>
      </ButtonRow>
    );
  } else if (
    (offeringCompany && editIssueHeaderOfferingCompany) ||
    (!offeringCompany && editIssueHeaderUsingCompany)
  ) {
    ButtonBar = (
      <Fragment>
        <DownloadIssueButton
          issueUuid={issue.uuid}
          closed={false}
          locale={locale}
          isOfferingCompany={offeringCompany}
        />
        <Button
          floated="right"
          primary
          as={Link}
          to={`/quality-assurance/edit/${issue.issueNumber}?issueType=${issue.issueType}`}>
          <Msg id="GENERAL_EDIT_TICKET" />
        </Button>
        <Button floated="right" primary onClick={onTriggerModal}>
          <Msg id="STATUS_ASSIGNEE_BUTTON_CONTENT" />
        </Button>
        <FavoriteButton
          issue={issue}
          config={config}
          onFollow={onFollow}
          onUnfollow={onUnfollow}
        />
        <Modal
          open={modalVisible}
          closeOnDimmerClick={true}
          onClose={onTriggerModal}>
          <Modal.Header>
            <Msg id="EDIT_TICKET_STATUS" />
          </Modal.Header>
          <Modal.Content>
            <PathForm
              onSubmit={onSubmit}
              initialValues={issue}
              formid="modal-autoCompleteDropdown"
              fields={fields}>
              {({values}) => (
                <Fragment>
                  <FormGroup widths="equal">
                    <Input
                      name="articleInspectionData.articleInspectionState"
                      path="articleInspectionData.articleInspectionState"
                      translateOptions={true}
                    />
                  </FormGroup>

                  <FormGroup widths="equal">
                    <Input
                      name="articleInspectionData.articleInspectionCompletionFreeText"
                      path="articleInspectionData.articleInspectionCompletionFreeText"
                    />
                  </FormGroup>

                  {values.articleInspectionData.articleInspectionState ===
                    ARTICLE_INSPECTION_STATES.PARTIALLY_APPROVED && (
                    <FormGroup widths="equal">
                      <Input
                        name="articleInspectionData.partialApprovalUntil"
                        path="articleInspectionData.partialApprovalUntil"
                        stripTimezone={false}
                      />
                    </FormGroup>
                  )}
                </Fragment>
              )}
            </PathForm>
          </Modal.Content>
          <Modal.Actions>
            <ExternalSubmit formid="modal-autoCompleteDropdown" positive>
              <Msg id="QA_TICKET_SET_TO_COMPLETE" />
            </ExternalSubmit>
            <ExternalSubmit negative onClick={onTriggerModal}>
              <Msg id="GENERAL_CANCEL" />
            </ExternalSubmit>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
  return <Segment clearing>{ButtonBar}</Segment>;
};

ArticleInspectionHeaderButtons.propTypes = {
  issue: issueShape,
  locale: PropTypes.string,
  onTriggerModal: PropTypes.func,
  modalVisible: PropTypes.bool,
  onSubmit: PropTypes.func,
  fields: PropTypes.object,
  offeringCompany: PropTypes.bool,
  editIssueHeaderUsingCompany: PropTypes.bool,
  editIssueHeaderOfferingCompany: PropTypes.bool,
};

const ArticleInspectionHeader = ({
  issue,
  modalVisible,
  onTriggerModal,
  fields,
  onSubmit,
  offeringCompany,
  editIssueHeaderUsingCompany,
  editIssueHeaderOfferingCompany,
  locale,
  config,
  onUnfollow,
  onFollow,
  onUpdateIssue,
}) => {
  return (
    <Fragment>
      <Segment.Group>
        <Segment color="purple">
          <Header size="large">
            <Header.Content>
              <StateLabel state={issue?.state} />{' '}
              <Msg id="GENERAL_ARTICLE_INSPECTION" />
              :&nbsp;{issue.articleInspectionData.articleInspectionReference}
              <Header.Subheader data-spec="subject">
                {issue.subject}
              </Header.Subheader>
            </Header.Content>
          </Header>
          <SubHeaderMeta
            state={issue?.state}
            assignee={issue?.assignee?.name}
            closedAt={issue?.closedAt}
            closedBy={issue?.closedBy}
          />
        </Segment>
        <ArticleInspectionHeaderButtons
          editIssueHeaderUsingCompany={editIssueHeaderUsingCompany}
          editIssueHeaderOfferingCompany={editIssueHeaderOfferingCompany}
          offeringCompany={offeringCompany}
          issue={issue}
          onTriggerModal={onTriggerModal}
          modalVisible={modalVisible}
          fields={fields}
          onSubmit={onSubmit}
          locale={locale}
          config={config}
          onFollow={onFollow}
          onUnfollow={onUnfollow}
          onUpdateIssue={onUpdateIssue}
        />
      </Segment.Group>
    </Fragment>
  );
};

ArticleInspectionHeader.propTypes = {
  issue: issueShape,
  locale: PropTypes.string,
  onTriggerModal: PropTypes.func,
  modalVisible: PropTypes.bool,
  onSubmit: PropTypes.func,
  fields: PropTypes.object,
  offeringCompany: PropTypes.bool,
  editIssueHeaderUsingCompany: PropTypes.bool,
  editIssueHeaderOfferingCompany: PropTypes.bool,
};

const ArticleInspectionHeaderFields = ({issue, locale, offeringCompany}) => {
  return (
    <Segment.Group>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header>
                <Msg id="GENERAL_GENERAL" />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              <BoldContent>
                <Msg id="GENERAL_SUPPLIER" />:
              </BoldContent>
              <p>
                <Link to={`/supplier/details/${issue?.supplier?.uuid}`}>
                  {issue?.supplier?.name}
                </Link>
              </p>
            </Grid.Column>
            {offeringCompany && (
              <Grid.Column>
                <FieldContent
                  value={issue?.articleInspectionData?.priorArticleInspection}
                  label="PREVIOUS_ARTICLE_INSPECTION"
                />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header>
                <Msg id="GENERAL_PURCHASER" />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              <FieldContent
                value={issue?.offeringCompanyAssignee?.name}
                label="OFFERING_COMPANY_ASSIGNEE"
              />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                value={issue?.offeringCompanyAssignee?.phoneNumber}
                label="OFFERING_COMPANY_PHONE_NUMBER"
              />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                value={issue?.offeringCompanyAssignee?.email}
                label="OFFERING_COMPANY_EMAIL"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header>
                <Msg id="GENERAL_ORDER_INFORMATION" />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              <FieldContent value={issue?.orderNumber} label="GENERAL_ORDER" />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                value={issue?.orderPosition}
                label="GENERAL_ORDER_POSITION"
              />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                value={issue?.factoryIdentifier}
                label="GENERAL_FACTORY_IDENTIFIER"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              <FieldContent
                date
                value={issue?.articleInspectionData?.orderDate}
                label="ARTICLE_INSPECTION_ORDER_DATE"
              />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                value={issue?.articleInspectionData?.orderQuantity}
                label="ARTICLE_INSPECTION_ORDER_QUANTITY"
              />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                date
                value={issue?.articleInspectionData?.deliveryDate}
                label="ARTICLE_INSPECTION_DELIVERY_DATE"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header>
                <Msg id="GENERAL_ARTICLE_INFORMATION" />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              <FieldContent
                value={issue?.materialNumber}
                label="GENERAL_CUSTOMER_MATERIAL"
              />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                value={issue?.supplierMaterialNumber}
                label="GENERAL_SUPPLIER_MATERIAL"
              />
            </Grid.Column>
            <Grid.Column>
              <FieldContent
                value={issue?.drawingRevision}
                label="GENERAL_DRAWING_REVISION"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <FieldContent
                value={translateValue(issue?.materialShortText, locale)}
                label="MATERIAL_SHORT_TEXT"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      {offeringCompany && (
        <Segment>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header>
                  <Msg id="INTERNAL_INFORMATION" />
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <FieldContent
                  value={
                    issue?.articleInspectionData?.researchAndDevelopmentContact
                  }
                  label="RESEARCH_AND_DEVELOPMENT_CONTACT"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="3">
              <Grid.Column>
                <FieldContent
                  value={issue?.articleInspectionData?.testBenchAttempt}
                  label="TEST_BENCH_ATTEMPT"
                  checkbox
                />
              </Grid.Column>
              <Grid.Column>
                <FieldContent
                  value={
                    issue?.articleInspectionData?.testBenchAttemptDescription
                  }
                  label="TEST_BENCH_ATTEMPT_FREETEXT"
                />
              </Grid.Column>
              <Grid.Column>
                <FieldContent
                  value={issue?.articleInspectionData?.testBenchAttemptDate}
                  label="TEST_BENCH_ATTEMPT_DATE"
                  date
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="3">
              <Grid.Column>
                <FieldContent
                  value={issue?.articleInspectionData?.fieldTestDone}
                  label="FIELD_TEST"
                  checkbox
                />
              </Grid.Column>
              <Grid.Column>
                <FieldContent
                  value={issue?.articleInspectionData?.fieldTestDescription}
                  label="FIELD_TEST_FREETEXT"
                />
              </Grid.Column>
              <Grid.Column>
                <FieldContent
                  value={issue?.articleInspectionData?.fieldTestDate}
                  label="FIELD_TEST_DATE"
                  date
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row colums="3">
              <Grid.Column>
                <FieldContent
                  value={issue?.articleInspectionData?.qaContact}
                  label="ARTICLE_INSPECTION_QA_CONTACT"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="3">
              <Grid.Column>
                <FieldContent
                  value={issue?.articleInspectionData?.createTestPlan}
                  label="ARTICLE_INSPECTION_CREATE_TEST_PLAN"
                  checkbox
                />
              </Grid.Column>
              <Grid.Column>
                <FieldContent
                  value={issue?.articleInspectionData?.freeTextTestPlan}
                  label="ARTICLE_INSPECTION_FREETEXT_TEST_PLAN"
                />
              </Grid.Column>
              <Grid.Column>
                <FieldContent
                  value={issue?.articleInspectionData?.dateTestPlan}
                  label="ARTICLE_INSPECTION_DATE_TEST_PLAN"
                  date
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      )}
    </Segment.Group>
  );
};

ArticleInspectionHeaderFields.propTypes = {
  issue: issueShape,
  locale: PropTypes.string,
  offeringCompany: PropTypes.bool,
};

export default ArticleInspectionView;
