import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown, Flag} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';

const LocaleDropdown = ({locale, localesMap, onSwitchLocale, newHeader}) => {
  if (!localesMap) {
    console.error('LocaleDropdown expects localesMap property');
    return null;
  }

  const renderFlags = localesMap.map((item) => (
    <Dropdown.Item
      value={item.locale}
      key={item.key}
      onClick={onSwitchLocale}
      data-spec={`loc-${item.key}`}>
      <span>
        <Flag name={item.flag} />
        <FormattedMessage id={item.label} />
      </span>
    </Dropdown.Item>
  ));

  const currentFlag = [
    ...localesMap
      .filter((item) => item.locale === locale)
      .map((item) => item.flag),
  ];

  return (
    <Dropdown
      item
      data-spec="locale-dd"
      pointing={!newHeader ? 'top right' : ''}
      trigger={<FormattedMessage id={currentFlag} />}>
      <Dropdown.Menu>{renderFlags}</Dropdown.Menu>
    </Dropdown>
  );
};

LocaleDropdown.propTypes = {
  onSwitchLocale: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  localesMap: PropTypes.array.isRequired,
};

export default LocaleDropdown;
