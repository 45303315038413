import React from 'react';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import {Input, Form as SemForm, TextArea, Label} from 'semantic-ui-react';

export const InputArea = (props) => {
  return (
    <SemForm.Field>
      <TextArea
        {...props.input}
        value={props.input.value}
        onChange={(event, data) => {
          props.input.onChange(data.value);
        }}
      />
    </SemForm.Field>
  );
};

export const InputField = (props) => {
  return (
    <SemForm.Field>
      <Input
        {...props.input}
        value={props.input.value}
        onChange={(event, data) => {
          return props.input.onChange(data.value);
        }}
      />
    </SemForm.Field>
  );
};

export const ErrorLabel = ({error}) => (
  <div style={{marginTop: '3px'}}>
    <Label color="red">
      <i>
        <FormattedMessage id={error} />
      </i>
    </Label>
  </div>
);
export const BoldContent = ({children}) => (
  <span style={{fontWeight: '700'}}>{children}</span>
);

export const GreyContent = ({children}) => (
  <span style={{color: 'rgb(0,0,0,0.7)'}}>{children}</span>
);

InputArea.propTypes = {
  input: PropTypes.any,
};

InputField.propTypes = {
  input: PropTypes.any,
};

ErrorLabel.propTypes = {
  error: PropTypes.string,
};

BoldContent.propTypes = {
  children: PropTypes.any,
};

GreyContent.propTypes = {
  children: PropTypes.any,
};
