import React from 'react';
import {Button, Header, Segment, Popup, Form, Select} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import qs from 'qs';

const DEFAULT_FILTER = {
  status: 'ALL',
  dateShortcut: 'ALL',
  query: '',
  sort: 'createdAt,desc',
};

const DATE_FILTER_SHORTCUTS = [
  'ALL',
  'TODAY',
  'YESTERDAY',
  'LAST_3_DAYS',
  'LAST_WEEK',
];

const STATUS_FILTER_TYPES = {
  ALL: 'ALL',
  METADATA_RECEIVED: 'METADATA_RECEIVED',
  IN_UPLOAD: 'IN_UPLOAD',
  DATA_RECEIVED: 'DATA_RECEIVED',
  PICKEDUP: 'PICKEDUP',
  EXPIRED: 'EXPIRED',
  EXCEPTION: 'EXCEPTION',
};

const AdvancedPop = ({sortstate, onChangeSort, intl, ...props}) => {
  const ORDER = [
    {
      key: 'asc',
      text: intl.formatMessage({id: 'GENERAL_ASCENDING'}),
      value: 'asc',
    },
    {
      key: 'desc',
      text: intl.formatMessage({id: 'GENERAL_DESCENDING'}),
      value: 'desc',
    },
  ];

  const SORT = [
    {
      key: 'createdAt',
      text: intl.formatMessage({id: 'DATE_CREATED'}),
      value: 'createdAt',
    },
  ];

  const [sortProp, sortValue] = sortstate.split(',');

  return (
    <Popup {...props} position="bottom right" on="click" wide>
      <div style={{minWidth: '600px'}}>
        <Segment>
          <Header as="h3">
            <Header.Content>
              <FormattedMessage id="GENERAL_SORTING" />
            </Header.Content>
          </Header>
          <Form>
            <Form.Group>
              <Form.Field>
                <Select
                  name="prop"
                  onChange={onChangeSort}
                  defaultValue={sortProp}
                  compact
                  options={SORT}
                  style={{width: '250px'}}
                />
              </Form.Field>

              <Form.Field>
                <Select
                  name="order"
                  onChange={onChangeSort}
                  defaultValue={sortValue}
                  compact
                  options={ORDER}
                  style={{width: '250px'}}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Segment>
      </div>
    </Popup>
  );
};

AdvancedPop.propTypes = {
  sortstate: PropTypes.string.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

class DrawingFilter extends React.Component {
  constructor(props) {
    super(props);

    const {location} = this.props;
    // qs returns an empty object at parseerror or empty string
    // substr needed for querystring indicator questionmark
    const searchObj =
      location.search && location.search.trim() !== ''
        ? qs.parse(location.search.substr(1))
        : {};

    this.state = Object.assign({}, DEFAULT_FILTER, searchObj);
  }

  onApply = () => {
    this.props.history.push({
      search: `?${qs.stringify(this.state)}`,
    });
  };

  onChangeInput = (e) => {
    this.setState({
      query: e.target.value,
    });
  };

  onChangeStatus = (e, {value}) => {
    this.setState(
      {
        status: value,
      },
      this.onApply
    );
  };

  onChangeDateShortcut = (e, {value}) => {
    this.setState(
      {
        dateShortcut: value,
      },
      this.onApply
    );
  };

  onChangeSort = (e, {value, name}) => {
    /*
      Order of the querystringparams is always ?sort=Property,Order in Spring
      decide via the name of the forminput where what we have to set
    */
    const [prop, order] = this.state.sort.split(',');
    return this.setState({
      sort:
        name === 'prop' ? [value, order].join(',') : [prop, value].join(','),
    });
  };

  render() {
    const {intl} = this.props;
    const STATUS_FILTERS = Object.keys(STATUS_FILTER_TYPES).map((key) => {
      return {
        value: key,
        text: intl.formatMessage({id: `DMS_${key}_MENU_ITEM`}),
        key,
      };
    });

    const DATE_FILTERS = DATE_FILTER_SHORTCUTS.map((key) => {
      return {
        value: key,
        text: intl.formatMessage({
          id: `DMS_DATE_FILTER_${key}`,
        }),
        key,
      };
    });

    return (
      <React.Fragment>
        <Header size="medium" as="h3">
          <Header.Content>
            <FormattedMessage id="GENERAL_FILTER_PANEL_HEADER" />
          </Header.Content>
        </Header>
        <Form onSubmit={this.onApply} id="drawing-filter">
          <Form.Input type="text" action>
            {/* autofocus if we are currently filtering */}
            <input
              className="_si_search"
              placeholder={intl.formatMessage({
                id: 'DRAWING_SEARCH_PLACEHOLDER',
              })}
              onChange={this.onChangeInput}
              value={this.state.query}
              autoFocus={this.state.query && this.state.query > 0}
              data-spec="drawing-search-input"
            />
            <Select
              className="_si_datesc"
              onChange={this.onChangeDateShortcut}
              compact
              options={DATE_FILTERS}
              value={this.state.dateShortcut}
              style={{width: '250px'}}
            />
            <Select
              className="_si_status"
              onChange={this.onChangeStatus}
              compact
              options={STATUS_FILTERS}
              value={this.state.status}
              style={{width: '250px'}}
            />
            <AdvancedPop
              intl={intl}
              onChangeSort={this.onChangeSort}
              sortstate={this.state.sort}
              trigger={
                <Button color="purple" type="button">
                  <FormattedMessage id="GENERAL_ADVANCED" />
                </Button>
              }
            />
            <Button type="submit" positive data-spec="drawing-apply-filter">
              <FormattedMessage id="GENERAL_APPLY" />
            </Button>
          </Form.Input>
        </Form>
      </React.Fragment>
    );
  }
}

DrawingFilter.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  intl: PropTypes.object,
};
export default injectIntl(DrawingFilter);
